export enum TagSize {
  MINI = "mini",
  SMALL = "small",
  MEDIUM = "medium",
  DEFAULT = "",
  LARGE = "large",
  NARROW = "narrow",
  SQUARE = "square",
}

export enum TagColor {
  GOLD = "gold",
  PURPLE = "purple",
  GREEN = "green",
  BLUE = "blue",
  GRAY = "dark-gray",
  DEFAULT = "",
}

export enum TagFill {
  DEFAULT = "",
  HOLLOW = "hollow",
  TRANSLUCENT = "translucent",
}
