import {
  ApiError,
  ManageCrmService,
  OrganizationDataKeyResponse,
  OrganizationResponseForAdmins,
} from "client/openapi";
import CRMdata from "./data/CRMdata";
// import { CRMsearch } from "./search/CRMsearch";
import { useEffect, useState } from "react";
import Notifications from "util/notifications";
import { CRMsearch } from "./search/search";
import VisualizationView from "./visualize/VisualizationView";

export default function CRM({
  organization,
}: {
  organization: OrganizationResponseForAdmins;
}) {
  const [activeView, setActiveView] = useState<"data" | "visualize" | "search">(
    "data"
  );
  const [error, setError] = useState<ApiError>();
  const [studentKeys, setStudentKeys] = useState<OrganizationDataKeyResponse[]>(
    []
  );
  const [parentKeys, setParentKeys] = useState<OrganizationDataKeyResponse[]>(
    []
  );

  useEffect(() => {
    const fetchStudentKeys = async () => {
      ManageCrmService.getStudentDataKeys({ orgId: organization.id })
        .then((keys) => setStudentKeys(keys))
        .catch((e) => setError(e));
    };

    const fetchParentKeys = async () => {
      ManageCrmService.getParentDataKeys({ orgId: organization.id })
        .then((keys) => setParentKeys(keys))
        .catch((e) => setError(e));
    };

    fetchStudentKeys();
    fetchParentKeys();
  }, [organization.id]);

  useEffect(() => {
    if (error) {
      Notifications.error(error.body.message);
    }
  }, [error]);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="inline-flex rounded-full border border-[#98acfc] p-1 mb-6 mt-4">
        <button
          className={`px-4 py-2 rounded-full text-sm transition-colors duration-200 ${
            activeView === "data"
              ? "bg-[#98acfc] text-white"
              : "text-[#98acfc] hover:bg-[#98acfc]/10"
          }`}
          onClick={() => setActiveView("data")}
        >
          Data
        </button>
        <button
          className={`px-4 py-2 rounded-full text-sm transition-colors duration-200 ${
            activeView === "visualize"
              ? "bg-[#98acfc] text-white"
              : "text-[#98acfc] hover:bg-[#98acfc]/10"
          }`}
          onClick={() => setActiveView("visualize")}
        >
          Visualize
        </button>
        <button
          className={`px-4 py-2 rounded-full text-sm transition-colors duration-200 ${
            activeView === "search"
              ? "bg-[#98acfc] text-white"
              : "text-[#98acfc] hover:bg-[#98acfc]/10"
          }`}
          onClick={() => setActiveView("search")}
        >
          Search
        </button>
      </div>

      {activeView === "data" ? (
        <CRMdata organization={organization} />
      ) : activeView === "visualize" ? (
        <VisualizationView
          orgId={organization.id}
          studentKeys={studentKeys}
          parentKeys={parentKeys}
        />
      ) : (
        <CRMsearch
          orgId={organization.id}
          studentKeys={studentKeys}
          parentKeys={parentKeys}
        />
      )}
    </div>
  );
}
