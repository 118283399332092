import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { InviteResponse, InvitesService, InviteStatus } from "client/openapi";
import Notifications from "util/notifications";
import { useState } from "react";

export default function Invites({
  invites,
  setInvites,
}: {
  invites: InviteResponse[];
  setInvites: (invites: InviteResponse[]) => void;
}) {
  const [processingInviteId, setProcessingInviteId] = useState<number | null>(
    null
  );

  function resolveInvite({
    inviteId,
    decision,
  }: {
    inviteId: number;
    decision: InviteStatus;
  }) {
    setProcessingInviteId(inviteId);
    InvitesService.resolveOrgInvite({
      inviteId: inviteId,
      inviteStatus: decision,
    })
      .then(async () => {
        Notifications.success(
          `Invite ${
            decision === InviteStatus.ACCEPTED
              ? "accepted! Reload the page for changes to take effect!"
              : "rejected!"
          }`
        );

        setInvites(
          invites.filter((remaining_invite) => remaining_invite.id !== inviteId)
        );
      })
      .catch((error) => {
        Notifications.error("Unable to respond to invite.");
      })
      .finally(() => {
        setProcessingInviteId(null);
      });
  }
  return (
    <DialogContent className="dialog-content max-w-[800px]">
      <div className="mt-3 font-semibold text-center header text-lg">
        Invites
      </div>

      <div className="border rounded-lg border-gray-400 px-6 py-4 mt-6">
        <div className="w-full border-spacing-0.5">
          <div>
            <tr className="text-left text-xs text-gray-500">
              <th className="pb-2 whitespace-nowrap font-bold">
                Organizations
              </th>
            </tr>
          </div>
          <div>
            {invites.map((invite) => (
              <div className="header text-sm align-top mb-2" key={invite.id}>
                <div className="flex justify-between gap-2 font-bold mr-2 items-center">
                  <div className="flex gap-2 items-center mr-2">
                    <p className="text-nowrap whitespace-nowrap ">
                      {invite.org_name} ({invite.type})
                    </p>
                  </div>

                  <div
                    className="flex flex-row justify-end"
                    style={{ paddingTop: "2px" }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <Button
                        color={
                          processingInviteId === invite.id
                            ? ButtonColor.GRAY
                            : ButtonColor.RED
                        }
                        size={ButtonSize.EXTRA_SMALL}
                        disabled={processingInviteId === invite.id}
                        onClick={() => {
                          resolveInvite({
                            inviteId: invite.id,
                            decision: InviteStatus.DENIED,
                          });
                        }}
                      >
                        reject
                      </Button>
                    </div>
                    <div>
                      <Button
                        color={
                          processingInviteId === invite.id
                            ? ButtonColor.GRAY
                            : ButtonColor.GREEN
                        }
                        size={ButtonSize.EXTRA_SMALL}
                        disabled={processingInviteId === invite.id}
                        onClick={() => {
                          resolveInvite({
                            inviteId: invite.id,
                            decision: InviteStatus.ACCEPTED,
                          });
                        }}
                      >
                        accept
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <DialogActions>
        <DialogClose asChild>
          <DialogAction color={ButtonColor.PURPLE}>Close</DialogAction>
        </DialogClose>
      </DialogActions>
    </DialogContent>
  );
}
