import Students from "./Students";
import {
  StudentLimitedResponse,
  ParentLimitedResponse,
  MeetingLimitedResponse,
  Auth0AccountRole,
} from "client/openapi";
import MeetingHistory from "./Meetings";
import AdministrativeInfo from "pages/manage/students/[id]/StudentInfoTab/AdministrativeInfo";
import { useState } from "react";

export default function ParentInfoTab({
  parent,
  orgStudents,
  parentStudents,
  setParentStudents,
  meetings,
  exportingMeetings,
  setExportingMeetings,
  setStatus,
  setError,
}: {
  parent: ParentLimitedResponse;
  orgStudents: StudentLimitedResponse[];
  parentStudents: StudentLimitedResponse[];
  setParentStudents: (students: StudentLimitedResponse[]) => void;
  meetings: MeetingLimitedResponse[];
  exportingMeetings: boolean;
  setExportingMeetings: (boolean) => void;
  setStatus: (PageStatus) => void;
  setError: (APIResponse) => void;
}) {
  const [exportingData, setExportingData] = useState<boolean>(false);

  return (
    <div className="grid grid-cols-1 gap-y-5 md:grid-cols-3 h-full">
      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <AdministrativeInfo
          exportingData={exportingData}
          setExportingData={setExportingData}
          userType={Auth0AccountRole.PARENT}
          parent={parent}
          organization_id={parent.org_id}
          exportingMeetings={exportingMeetings}
          setStatus={setStatus}
          setError={setError}
        />
      </div>

      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <Students
          parent={parent}
          orgStudents={orgStudents}
          parentStudents={parentStudents}
          setParentStudents={setParentStudents}
        />
      </div>

      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <MeetingHistory
          meetings={meetings}
          exportingMeetings={exportingMeetings}
          setExportingMeetings={setExportingMeetings}
          parent={parent}
        />
      </div>
    </div>
  );
}
