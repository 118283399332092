/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CRMVisualizationColumnCreate } from "../models/CRMVisualizationColumnCreate";
import type { CRMVisualizationColumnResponse } from "../models/CRMVisualizationColumnResponse";
import type { CRMVisualizationColumnUpdate } from "../models/CRMVisualizationColumnUpdate";
import type { CRMVisualizationCreate } from "../models/CRMVisualizationCreate";
import type { CRMVisualizationResponse } from "../models/CRMVisualizationResponse";
import type { CRMVisualizationUpdate } from "../models/CRMVisualizationUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class VisualizationsService {
  /**
   * Get Visualizations
   * Get all visualizations for an organization.
   * @returns CRMVisualizationResponse Successful Response
   * @throws ApiError
   */
  public static getVisualizations({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<CRMVisualizationResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/visualizations/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Visualization
   * Create a new visualization.
   * @returns CRMVisualizationResponse Successful Response
   * @throws ApiError
   */
  public static createVisualization({
    requestBody,
  }: {
    requestBody: CRMVisualizationCreate;
  }): CancelablePromise<CRMVisualizationResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/visualizations/{org_id}",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Visualization
   * Get a specific visualization by ID.
   * @returns CRMVisualizationResponse Successful Response
   * @throws ApiError
   */
  public static getVisualization({
    visualizationId,
  }: {
    visualizationId: number;
  }): CancelablePromise<CRMVisualizationResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/visualizations/{visualization_id}",
      path: {
        visualization_id: visualizationId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Visualization
   * Update an existing visualization.
   * @returns CRMVisualizationResponse Successful Response
   * @throws ApiError
   */
  public static updateVisualization({
    visualizationId,
    requestBody,
  }: {
    visualizationId: number;
    requestBody: CRMVisualizationUpdate;
  }): CancelablePromise<CRMVisualizationResponse> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/visualizations/{visualization_id}",
      path: {
        visualization_id: visualizationId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Visualization
   * Delete a visualization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteVisualization({
    visualizationId,
  }: {
    visualizationId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/visualizations/{visualization_id}",
      path: {
        visualization_id: visualizationId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Visualization Columns
   * Get all columns for a visualization.
   * @returns CRMVisualizationColumnResponse Successful Response
   * @throws ApiError
   */
  public static getVisualizationColumns({
    visualizationId,
  }: {
    visualizationId: number;
  }): CancelablePromise<Array<CRMVisualizationColumnResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/visualizations/{visualization_id}/columns",
      path: {
        visualization_id: visualizationId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Visualization Column
   * Create a new column in a visualization.
   * @returns CRMVisualizationColumnResponse Successful Response
   * @throws ApiError
   */
  public static createVisualizationColumn({
    visualizationId,
    requestBody,
  }: {
    visualizationId: number;
    requestBody: CRMVisualizationColumnCreate;
  }): CancelablePromise<CRMVisualizationColumnResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/visualizations/{visualization_id}/columns",
      path: {
        visualization_id: visualizationId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Visualization Column
   * Update an existing column in a visualization.
   * @returns CRMVisualizationColumnResponse Successful Response
   * @throws ApiError
   */
  public static updateVisualizationColumn({
    visualizationId,
    columnId,
    requestBody,
  }: {
    visualizationId: number;
    columnId: number;
    requestBody: CRMVisualizationColumnUpdate;
  }): CancelablePromise<CRMVisualizationColumnResponse> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/visualizations/{visualization_id}/columns/{column_id}",
      path: {
        visualization_id: visualizationId,
        column_id: columnId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Visualization Column
   * Delete a column from a visualization.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteVisualizationColumn({
    visualizationId,
    columnId,
  }: {
    visualizationId: number;
    columnId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/visualizations/{visualization_id}/columns/{column_id}",
      path: {
        visualization_id: visualizationId,
        column_id: columnId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
