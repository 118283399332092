/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TutorSubjectAdminResponse } from "../models/TutorSubjectAdminResponse";
import type { TutorSubjectCreate } from "../models/TutorSubjectCreate";
import type { TutorSubjectPublicResponse } from "../models/TutorSubjectPublicResponse";
import type { TutorSubjectUpdate } from "../models/TutorSubjectUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TutorSubjectsService {
  /**
   * Get Subjects By Tutor
   * Get all tutor subjects for a specified tutor from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getSubjectsByTutor({
    tutorId,
    active,
    skip,
    limit = 1000,
  }: {
    tutorId: number;
    active?: boolean | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<
    Array<TutorSubjectAdminResponse | TutorSubjectPublicResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tutor_subjects/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        active: active,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Tutor Subject
   * Create a new tutor subject and returns the resulting subject from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createTutorSubject({
    requestBody,
  }: {
    requestBody: TutorSubjectCreate;
  }): CancelablePromise<
    TutorSubjectPublicResponse | TutorSubjectAdminResponse
  > {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tutor_subjects",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Subject
   * Update a tutor subject by its ID using specified attributes
   * within TutorSubjectUpdate to update the database and return
   * the updated subject.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateSubject({
    subjectId,
    requestBody,
  }: {
    subjectId: number;
    requestBody: TutorSubjectUpdate;
  }): CancelablePromise<
    TutorSubjectPublicResponse | TutorSubjectAdminResponse
  > {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutor_subjects/{subject_id}",
      path: {
        subject_id: subjectId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Set Custom Subject Pay Rate For Tutor
   * Set a custom rate of a tutor for a given subject.
   * @returns TutorSubjectAdminResponse Successful Response
   * @throws ApiError
   */
  public static setCustomSubjectPayRateForTutor({
    tutorId,
    orgSubjectId,
    customPayRate,
  }: {
    tutorId: number;
    orgSubjectId: number;
    customPayRate: number;
  }): CancelablePromise<TutorSubjectAdminResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tutor_subjects/set_custom_subject_pay_rate_for_tutor/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_subject_id: orgSubjectId,
        custom_pay_rate: customPayRate,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
