import React from "react";
import "./index.css"; // to ensure our styles are available

import {
  ComparisonOperator,
  Condition,
  ConditionGroup,
  LogicalOperator,
  OrganizationDataKeyResponse,
} from "client/openapi";
import { ConditionEditor } from "./editor";
import { Select } from "components/Select";
import { Button, ButtonFill } from "components/Button";

export function ConditionGroupBuilder({
  value,
  onChange,
  availableKeys,
  onRemoveGroup,
}: {
  value: ConditionGroup; // The group we are editing
  onChange: (updated: ConditionGroup) => void; // callback with new group
  availableKeys: OrganizationDataKeyResponse[]; // whichever fields (org_data_keys) user can pick
  // Optional: If you want to allow removing *this* entire group from the parent,
  // pass in a remove callback. For the top-level group, you might omit this.
  onRemoveGroup?: () => void;
}) {
  const { operator, conditions, negate } = value;

  function updateGroup(partial: Partial<ConditionGroup>) {
    onChange({ ...value, ...partial });
  }

  // Toggle AND/OR
  function handleOperatorChange(newOp: LogicalOperator) {
    updateGroup({ operator: newOp });
  }

  // Toggle group-level NOT
  function handleNegateChange() {
    updateGroup({ negate: !negate });
  }

  // Add a new Condition
  function addCondition() {
    const newCondition: Condition = {
      org_data_key_id: 0,
      comparison_operator: ComparisonOperator.EQUALS,
      value: "",
      negate: false,
    };
    updateGroup({ conditions: [...conditions, newCondition] });
  }

  // Add a nested ConditionGroup
  function addGroup() {
    const defaultCondition: Condition = {
      org_data_key_id: 0,
      comparison_operator: ComparisonOperator.EQUALS,
      value: "",
      negate: false,
    };
    const newGroup: ConditionGroup = {
      operator: LogicalOperator.AND,
      conditions: [defaultCondition],
      negate: false,
    };
    updateGroup({ conditions: [...conditions, newGroup] });
  }

  // Remove an item (Condition or ConditionGroup) from `conditions`
  function removeItem(index: number) {
    // Don't remove if it's the last condition
    if (conditions.length <= 1) {
      return;
    }

    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    updateGroup({ conditions: newConditions });
  }

  // Update a specific child item
  function updateItem(index: number, updated: Condition | ConditionGroup) {
    const newConditions = [...conditions];
    newConditions[index] = updated;
    updateGroup({ conditions: newConditions });
  }

  return (
    <div className="condition-group condition-group--row">
      {/* Center column: existing content (operator select, conditions list, add buttons). */}
      <div className="condition-group--center">
        <div className="condition-group-controls">
          <Select
            className="min-w-[150px]"
            value={{
              value: operator,
              label: operator === LogicalOperator.AND ? "AND" : "OR",
            }}
            onChange={(option) =>
              handleOperatorChange(option?.value as LogicalOperator)
            }
            options={[
              { value: LogicalOperator.AND, label: "AND" },
              { value: LogicalOperator.OR, label: "OR" },
            ]}
            placeholder="Select operator"
            isClearable={false}
          />

          {/* <Button
            color="#98acfc"
            fill={negate ? ButtonFill.DEFAULT : ButtonFill.HOLLOW}
            onClick={handleNegateChange}
          >
            NOT
          </Button> */}

          <Button
            color="#98acfc"
            fill={ButtonFill.HOLLOW}
            onClick={addCondition}
          >
            + Condition
          </Button>
          {/* <Button color="#98acfc" fill={ButtonFill.HOLLOW} onClick={addGroup}>
            + Nested Group
          </Button> */}

          {/* Right column: "Remove" button (if parent gave us `onRemoveGroup`) */}
          {onRemoveGroup && (
            <Button
              color="#98acfc"
              fill={ButtonFill.HOLLOW}
              onClick={onRemoveGroup}
            >
              Remove Group
            </Button>
          )}
        </div>

        {conditions.map((condOrGroup, idx) => {
          const isGroup = "conditions" in condOrGroup; // duck typing

          return (
            <div key={idx} className="condition-or-group-row">
              {isGroup ? (
                // Nested group
                <ConditionGroupBuilder
                  value={condOrGroup as ConditionGroup}
                  onChange={(updatedChild) => updateItem(idx, updatedChild)}
                  availableKeys={availableKeys}
                  // Pass a remove callback to allow removing the nested group
                  onRemoveGroup={() => removeItem(idx)}
                />
              ) : (
                // Single condition
                <ConditionEditor
                  condition={condOrGroup as Condition}
                  onChange={(updatedCond) => updateItem(idx, updatedCond)}
                  onRemove={() => removeItem(idx)}
                  availableKeys={availableKeys}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
