import {
  DialogContent,
  DialogClose,
  Dialog,
  DialogTrigger,
} from "components/Dialog";
import { Button, ButtonColor } from "components/Button";
import { useState } from "react";
import {
  Auth0AccountRole,
  CRMVisualizationColumnResponse,
} from "client/openapi";
import ComposeMessageDialog from "components/Dashboard/ComposeMessageDialog";
import AddOrEditColumnDialog from "./AddOrEditColumnDialog";
import DeleteColumnDialog from "./DeleteColumnDialog";
import { ExportsService } from "client/openapi";
import { saveAs } from "file-saver";
import Notifications from "util/notifications";

export default function ColumnActionsDialog({
  column,
  visualization,
  onColumnUpdated,
  onColumnAdded,
  onColumnDeleted,
  studentKeys,
  parentKeys,
  setIsOpen,
  orgId,
}: {
  column: CRMVisualizationColumnResponse;
  visualization: any;
  onColumnUpdated: (column: CRMVisualizationColumnResponse) => void;
  onColumnAdded: (column: CRMVisualizationColumnResponse) => void;
  onColumnDeleted: (columnId: number) => void;
  studentKeys: any[];
  parentKeys: any[];
  setIsOpen: (open: boolean) => void;
  orgId: number;
}) {
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    if (!orgId) {
      return Notifications.error("No organization ID available");
    }

    setIsExporting(true);
    try {
      // Get the IDs from the column's query results
      const ids =
        column.students_in_column?.map((student) => student.id) ||
        column.parents_in_column?.map((parent) => parent.id) ||
        [];
      let data;

      if (column.column_type === Auth0AccountRole.PARENT) {
        data = await ExportsService.exportParents({
          orgId,
          requestBody: ids,
        });
      } else {
        data = await ExportsService.exportStudents({
          orgId,
          requestBody: ids,
        });
      }

      const blob = new Blob([data], { type: "text/csv" });
      const entityType =
        column.column_type === Auth0AccountRole.PARENT ? "parents" : "students";
      const fileName = `${entityType}_column_results.csv`;

      saveAs(blob, fileName);
      Notifications.success(
        `${
          entityType.charAt(0).toUpperCase() + entityType.slice(1)
        } data has been exported successfully!`
      );
    } catch (err) {
      console.error("Export error:", err);
      Notifications.error("Failed to export data");
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <>
      <DialogContent className="column-actions-dialog-content">
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-4">
            Actions - <i className="text-gray-500">{column.name}</i>
          </h2>
          <div className="flex flex-row gap-2">
            <Dialog>
              <DialogTrigger>
                <Button color={ButtonColor.DARK_BLUE}>Message</Button>
              </DialogTrigger>
              <ComposeMessageDialog
                students={
                  column.column_type === Auth0AccountRole.ME
                    ? column.students_in_column || []
                    : undefined
                }
                parents={
                  column.column_type === Auth0AccountRole.PARENT
                    ? column.parents_in_column || []
                    : undefined
                }
                org_id={orgId}
              />
            </Dialog>
            <Button
              color={ButtonColor.SKYBLUE}
              onClick={handleExport}
              disabled={isExporting}
            >
              {isExporting ? "Exporting..." : "Export"}
            </Button>
            <Dialog>
              <DialogTrigger>
                <Button color={ButtonColor.PURPLE}>Edit Column</Button>
              </DialogTrigger>
              <AddOrEditColumnDialog
                visualization={visualization}
                existingColumn={column}
                onColumnUpdated={onColumnUpdated}
                onColumnAdded={onColumnAdded}
                studentKeys={studentKeys}
                parentKeys={parentKeys}
                setIsOpen={setIsOpen}
              />
            </Dialog>
            <Dialog>
              <DialogTrigger>
                <Button color={ButtonColor.RED}>Delete Column</Button>
              </DialogTrigger>
              <DeleteColumnDialog
                column={column}
                onDelete={() => onColumnDeleted(column.id)}
              />
            </Dialog>
          </div>
        </div>
      </DialogContent>
    </>
  );
}
