import {
  ApiError,
  TaskResponse,
  TaskStatus,
  TasksService,
  Auth0AccountRole,
  TaskRecipientCreate,
  TaskRecipientDelete,
  StudentLimitedResponse,
  ParentLimitedResponse,
  AccountLimitedResponse,
  OrganizationsService,
  TutorPublicResponse,
  OrganizationPublicResponse,
} from "client/openapi";
import { Dialog, DialogContent, DialogTrigger } from "components/Dialog";
import { useContext, useEffect, useRef, useState } from "react";
import Notifications from "util/notifications";
import "./index.css";
import { Select } from "components/Select";
import { DatePicker } from "rsuite";
import { Tag } from "components/Tag/TagChip";
import { TagColor, TagSize } from "components/Tag";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import DeleteTaskDialog from "./delete";
import CreateTaskDialog from "./create";

const getIdChanges = (
  originalIds: (string | number)[],
  updatedIds: (string | number)[],
  role: Auth0AccountRole,
  findAddedIds: boolean
) => {
  let addedIds: (string | number)[] = [];
  let removedIds: (string | number)[] = [];

  if (findAddedIds) {
    addedIds = updatedIds.filter((id) => !originalIds.includes(id));
  } else {
    removedIds = originalIds.filter((id) => !updatedIds.includes(id));
  }

  const ids = findAddedIds ? addedIds : removedIds;

  if (role === Auth0AccountRole.ORG_ADMIN) {
    return ids.map((id) => ({
      account_id: String(id),
      student_id: null,
      parent_id: null,
      tutor_id: null,
    }));
  } else if (role === Auth0AccountRole.ORG_TUTOR) {
    return ids.map((id) => ({
      account_id: null,
      student_id: null,
      parent_id: null,
      tutor_id: Number(id),
    }));
  } else if (role === Auth0AccountRole.PARENT) {
    return ids.map((id) => ({
      account_id: null,
      student_id: null,
      parent_id: Number(id),
      tutor_id: null,
    }));
  } else if (role === Auth0AccountRole.ME) {
    return ids.map((id) => ({
      account_id: null,
      student_id: Number(id),
      parent_id: null,
      tutor_id: null,
    }));
  }
  return [];
};

interface TaskFilters {
  search: string;
  sortBy: "due_date_asc" | "due_date_desc";
  dateRange: {
    start: string | null;
    end: string | null;
  };
  status: TaskStatus | "ALL";
  view:
    | "my_tasks"
    | "assigned_to_admins"
    | "assigned_to_tutors"
    | "assigned_to_parents"
    | "assigned_to_students"
    | "tagged_to_admins"
    | "tagged_to_parents"
    | "tagged_to_students"
    | "tagged_to_tutors";
  selectedUsers: { value: number | string; label: string }[];
}

interface UserOption {
  value: number | string;
  label: string;
}

interface TaskUpdateHelper {
  name?: string;
  content?: string;
  due_date?: string;
  status?: TaskStatus;
  assigned_admins?: UserOption[];
  assigned_tutors?: UserOption[];
  assigned_parents?: UserOption[];
  assigned_students?: UserOption[];
  tagged_admins?: UserOption[];
  tagged_tutors?: UserOption[];
  tagged_parents?: UserOption[];
  tagged_students?: UserOption[];
}

function ManageTasksDialog({
  startingView = "my_tasks",
  startingUserId,
  startingUserRole,
}: {
  startingView?: TaskFilters["view"];
  startingUserId?: number | string;
  startingUserRole?: Auth0AccountRole;
}) {
  const { currently_selected_role, currently_selected_organization } =
    useContext(OrgRolesAndAccountContext);

  const [organization, setOrganization] =
    useState<OrganizationPublicResponse>();

  const [allTasks, setAllTasks] = useState<TaskResponse[]>([]);
  const [myTasks, setMyTasks] = useState<Set<number>>(new Set());

  const [adminUsers, setAdminUsers] = useState<UserOption[]>([]);
  const [tutorUsers, setTutorUsers] = useState<UserOption[]>([]);
  const [studentUsers, setStudentUsers] = useState<UserOption[]>([]);
  const [parentUsers, setParentUsers] = useState<UserOption[]>([]);

  const [taskUpdates, setTaskUpdates] = useState<
    Record<number, TaskUpdateHelper>
  >({});
  const [expandedTasks, setExpandedTasks] = useState<Set<number>>(new Set());

  const [filters, setFilters] = useState<TaskFilters>({
    search: "",
    sortBy: "due_date_asc",
    dateRange: {
      start: null,
      end: null,
    },
    status: "ALL",
    view: startingView,
    selectedUsers: [],
  });
  const [error, setError] = useState<ApiError>();

  const dialogRef = useRef<HTMLDivElement>(null);

  // Add refreshTrigger state
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Create a refresh function
  const refreshTasks = () => setRefreshTrigger((prev) => prev + 1);

  // Add a new state to track which tasks are being updated
  const [updatingTasks, setUpdatingTasks] = useState<Set<number>>(new Set());

  // Fetch organization data once when component mounts
  useEffect(() => {
    if (currently_selected_organization && currently_selected_role) {
      Promise.all([
        // get tasks assigned to all admins, tutors, parents, and students
        // linked to the current account
        TasksService.getTasksByAccount(),
        // get tasks organization wide if on the admin dashboard
        currently_selected_role === Auth0AccountRole.ORG_ADMIN
          ? TasksService.getOrgTasks({
              orgId: currently_selected_organization,
            })
          : Promise.resolve([]),
        // get tasks for all students of tutors or parents
        // linked to the current account
        currently_selected_role === Auth0AccountRole.ORG_TUTOR ||
        currently_selected_role === Auth0AccountRole.PARENT
          ? TasksService.getTasksForStudentsOfAccount()
          : Promise.resolve([]),
        // get organization data
        OrganizationsService.getOrganization({
          orgId: currently_selected_organization,
        }),
      ]).then(([accountTasks, orgTasks, studentTasks, organizationResult]) => {
        setMyTasks(new Set(accountTasks.map((t) => t.id)));
        const uniqueTasks = new Map();
        [...accountTasks, ...orgTasks, ...studentTasks].forEach((task) => {
          uniqueTasks.set(task.id, task);
        });
        setAllTasks(Array.from(uniqueTasks.values()));
        setOrganization(organizationResult);
      });
    }
  }, [
    currently_selected_organization,
    currently_selected_role,
    refreshTrigger,
  ]);

  useEffect(() => {
    if (organization) {
      if ("students" in organization) {
        setStudentUsers(
          (organization.students as StudentLimitedResponse[])?.map((s) => ({
            value: s.id,
            label: `${s.first_name} ${s.last_name}`,
          })) ?? []
        );
      }

      if ("parents" in organization) {
        setParentUsers(
          (organization.parents as ParentLimitedResponse[])?.map((p) => ({
            value: p.id,
            label: `${p.first_name} ${p.last_name}`,
          })) ?? []
        );
      }

      if ("tutors" in organization) {
        setTutorUsers(
          (organization.tutors as TutorPublicResponse[])?.map((t) => ({
            value: t.id,
            label: `${t.first_name} ${t.last_name}`,
          })) ?? []
        );
      }

      if ("admins" in organization) {
        setAdminUsers(
          (organization.admins as AccountLimitedResponse[])?.map((a) => ({
            value: a.reference_id,
            label: `${a.first_name} ${a.last_name}`,
          })) ?? []
        );
      }
    }
  }, [organization]);

  // Reset selected users when view changes (e.g whether we are showing "My Tasks", "Admin Tasks",
  // "Parent Tasks", "Student Tasks", or "Tutor Tasks"). When selectedUsers is an empty list, all
  // tasks for the selected view will be shown, until a user filter (e.g a specific admins, parents,
  // students, or tutors) are selected.
  useEffect(() => {
    setFilters((prev) => ({ ...prev, selectedUsers: [] }));
  }, [filters.view]);

  const toggleTaskExpansion = (taskId: number) => {
    const newExpanded = new Set(expandedTasks);
    if (newExpanded.has(taskId)) {
      newExpanded.delete(taskId);
    } else {
      newExpanded.add(taskId);
    }
    setExpandedTasks(newExpanded);
  };

  useEffect(() => {
    if (!organization) return;

    if (startingUserId && startingUserRole) {
      let matchedUser: UserOption | undefined;

      switch (startingUserRole) {
        case Auth0AccountRole.ORG_ADMIN:
          matchedUser = adminUsers.find((u) => u.value === startingUserId);
          break;
        case Auth0AccountRole.ORG_TUTOR:
          matchedUser = tutorUsers.find((u) => u.value === startingUserId);
          break;
        case Auth0AccountRole.PARENT:
          matchedUser = parentUsers.find((u) => u.value === startingUserId);
          break;
        case Auth0AccountRole.ME:
          // ME role typically means a student account
          matchedUser = studentUsers.find((u) => u.value === startingUserId);
          break;
        default:
          // In case you have other roles
          break;
      }

      if (matchedUser) {
        setFilters((prev) => ({
          ...prev,
          selectedUsers: [
            matchedUser as { value: string | number; label: string },
          ],
        }));
      }
    }
  }, [
    organization,
    adminUsers,
    tutorUsers,
    parentUsers,
    studentUsers,
    startingUserId,
    startingUserRole,
  ]);

  const handleTaskUpdate = (
    currentTask: TaskResponse,
    taskUpdate: TaskUpdateHelper
  ) => {
    if (!taskUpdate) {
      return;
    }

    // Add task to updating set
    setUpdatingTasks(
      (prev) => new Set(Array.from(prev).concat(currentTask.id))
    );

    let nameChanged = taskUpdate.name && currentTask.name !== taskUpdate.name;
    let contentChanged =
      taskUpdate.content && currentTask.content !== taskUpdate.content;
    let dueDateChanged =
      taskUpdate.due_date && currentTask.due_date !== taskUpdate.due_date;
    let statusChanged =
      taskUpdate.status && currentTask.status !== taskUpdate.status;

    // Initialize original users from current task
    const original_users = {
      assignedAdmins: currentTask.assigned_users
        .filter((u) => u.account)
        .map((u) => u.account!.reference_id),
      assignedTutors: currentTask.assigned_users
        .filter((u) => u.tutor)
        .map((u) => u.tutor!.id),
      assignedStudents: currentTask.assigned_users
        .filter((u) => u.student)
        .map((u) => u.student!.id),
      assignedParents: currentTask.assigned_users
        .filter((u) => u.parent)
        .map((u) => u.parent!.id),
      taggedAdmins: currentTask.tagged_users
        .filter((u) => u.account)
        .map((u) => u.account!.reference_id),
      taggedTutors: currentTask.tagged_users
        .filter((u) => u.tutor)
        .map((u) => u.tutor!.id),
      taggedStudents: currentTask.tagged_users
        .filter((u) => u.student)
        .map((u) => u.student!.id),
      taggedParents: currentTask.tagged_users
        .filter((u) => u.parent)
        .map((u) => u.parent!.id),
    };

    // If we're only updating status, keep the same users
    const updatedUsers = {
      assignedAdmins:
        taskUpdate.assigned_admins?.map((u) => u.value as string) ??
        original_users.assignedAdmins,
      assignedTutors:
        taskUpdate.assigned_tutors?.map((u) => u.value as number) ??
        original_users.assignedTutors,
      assignedStudents:
        taskUpdate.assigned_students?.map((u) => u.value as number) ??
        original_users.assignedStudents,
      assignedParents:
        taskUpdate.assigned_parents?.map((u) => u.value as number) ??
        original_users.assignedParents,
      taggedAdmins:
        taskUpdate.tagged_admins?.map((u) => u.value as string) ??
        original_users.taggedAdmins,
      taggedTutors:
        taskUpdate.tagged_tutors?.map((u) => u.value as number) ??
        original_users.taggedTutors,
      taggedStudents:
        taskUpdate.tagged_students?.map((u) => u.value as number) ??
        original_users.taggedStudents,
      taggedParents:
        taskUpdate.tagged_parents?.map((u) => u.value as number) ??
        original_users.taggedParents,
    };

    const addedAssignedUsers: TaskRecipientCreate[] = [
      ...getIdChanges(
        original_users.assignedAdmins,
        updatedUsers.assignedAdmins,
        Auth0AccountRole.ORG_ADMIN,
        true
      ),
      ...getIdChanges(
        original_users.assignedTutors,
        updatedUsers.assignedTutors,
        Auth0AccountRole.ORG_TUTOR,
        true
      ),
      ...getIdChanges(
        original_users.assignedParents,
        updatedUsers.assignedParents,
        Auth0AccountRole.PARENT,
        true
      ),
      ...getIdChanges(
        original_users.assignedStudents,
        updatedUsers.assignedStudents,
        Auth0AccountRole.ME,
        true
      ),
    ];
    const removedAssignedUsers: TaskRecipientDelete[] = [
      ...getIdChanges(
        original_users.assignedAdmins,
        updatedUsers.assignedAdmins,
        Auth0AccountRole.ORG_ADMIN,
        false
      ),
      ...getIdChanges(
        original_users.assignedTutors,
        updatedUsers.assignedTutors,
        Auth0AccountRole.ORG_TUTOR,
        false
      ),
      ...getIdChanges(
        original_users.assignedParents,
        updatedUsers.assignedParents,
        Auth0AccountRole.PARENT,
        false
      ),
      ...getIdChanges(
        original_users.assignedStudents,
        updatedUsers.assignedStudents,
        Auth0AccountRole.ME,
        false
      ),
    ];
    const addedTaggedUsers: TaskRecipientCreate[] = [
      ...getIdChanges(
        original_users.taggedAdmins,
        updatedUsers.taggedAdmins,
        Auth0AccountRole.ORG_ADMIN,
        true
      ),
      ...getIdChanges(
        original_users.taggedTutors,
        updatedUsers.taggedTutors,
        Auth0AccountRole.ORG_TUTOR,
        true
      ),
      ...getIdChanges(
        original_users.taggedParents,
        updatedUsers.taggedParents,
        Auth0AccountRole.PARENT,
        true
      ),
      ...getIdChanges(
        original_users.taggedStudents,
        updatedUsers.taggedStudents,
        Auth0AccountRole.ME,
        true
      ),
    ];
    const removedTaggedUsers: TaskRecipientDelete[] = [
      ...getIdChanges(
        original_users.taggedAdmins,
        updatedUsers.taggedAdmins,
        Auth0AccountRole.ORG_ADMIN,
        false
      ),
      ...getIdChanges(
        original_users.taggedTutors,
        updatedUsers.taggedTutors,
        Auth0AccountRole.ORG_TUTOR,
        false
      ),
      ...getIdChanges(
        original_users.taggedParents,
        updatedUsers.taggedParents,
        Auth0AccountRole.PARENT,
        false
      ),
      ...getIdChanges(
        original_users.taggedStudents,
        updatedUsers.taggedStudents,
        Auth0AccountRole.ME,
        false
      ),
    ];

    if (
      nameChanged ||
      contentChanged ||
      dueDateChanged ||
      statusChanged ||
      addedAssignedUsers.length > 0 ||
      removedAssignedUsers.length > 0 ||
      addedTaggedUsers.length > 0 ||
      removedTaggedUsers.length > 0
    ) {
      TasksService.updateTask({
        taskId: currentTask.id,
        requestBody: {
          name: nameChanged ? taskUpdate.name : undefined,
          content: contentChanged ? taskUpdate.content : undefined,
          due_date: dueDateChanged ? taskUpdate.due_date : undefined,
          status: statusChanged ? taskUpdate.status : undefined,
          add_assigned_users:
            addedAssignedUsers.length > 0 ? addedAssignedUsers : undefined,
          remove_assigned_users:
            removedAssignedUsers.length > 0 ? removedAssignedUsers : undefined,
          add_tagged_users:
            addedTaggedUsers.length > 0 ? addedTaggedUsers : undefined,
          remove_tagged_users:
            removedTaggedUsers.length > 0 ? removedTaggedUsers : undefined,
        },
      })
        .then((updatedTask) => {
          setAllTasks(
            allTasks.map((t) => (t.id === currentTask.id ? updatedTask : t))
          );
          setTaskUpdates((prev) => {
            const newUpdates = { ...prev };
            delete newUpdates[currentTask.id];
            return newUpdates;
          });
          Notifications.success("Task updated successfully");
        })
        .catch((err) => {
          setError(err as ApiError);
        })
        .finally(() => {
          // Remove task from updating set
          setUpdatingTasks((prev) => {
            const newSet = new Set(prev);
            newSet.delete(currentTask.id);
            return newSet;
          });
        });
    }
  };

  const handleTaskDelete = (taskId: number) => {
    TasksService.deleteTask({ taskId })
      .then(() => {
        setAllTasks(allTasks.filter((t) => t.id !== taskId));
        Notifications.success("Task deleted successfully");
      })
      .catch((err) => {
        setError(err as ApiError);
      });
  };

  const handleUserClick = (userType: string, userId: number) => {
    if (userType === "admin") return; // Don't navigate for admin users
    window.open(`/manage/${userType}s/${userId}`, "_blank");
  };

  const filteredAndSortedTasks = allTasks
    .filter((task) => {
      // search by keyword
      const matchesSearch =
        filters.search === "" ||
        task.name.toLowerCase().includes(filters.search.toLowerCase()) ||
        task.content.toLowerCase().includes(filters.search.toLowerCase());

      const taskDate = new Date(task.due_date);

      // search by date range
      const matchesDateRange =
        (!filters.dateRange.start ||
          taskDate >= new Date(filters.dateRange.start)) &&
        (!filters.dateRange.end || taskDate <= new Date(filters.dateRange.end));

      // search by status
      const matchesStatus =
        filters.status === "ALL" || task.status === filters.status;

      // Filter based on view and selected users
      let matchesView = true;
      if (
        currently_selected_role &&
        [Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
          currently_selected_role
        )
      ) {
        switch (filters.view) {
          case "my_tasks":
            matchesView = myTasks.has(task.id);
            break;
          case "assigned_to_admins":
            matchesView = task.assigned_users.some((u) => u.account !== null);
            if (filters.selectedUsers.length > 0) {
              const selectedUserIds = new Set(
                filters.selectedUsers.map((u) => u.value)
              );
              matchesView =
                matchesView &&
                task.assigned_users.some(
                  (u) =>
                    u.account && selectedUserIds.has(u.account.reference_id)
                );
            }
            break;
          case "tagged_to_admins":
            matchesView = task.tagged_users.some((u) => u.account !== null);
            if (filters.selectedUsers.length > 0) {
              const selectedUserIds = new Set(
                filters.selectedUsers.map((u) => u.value)
              );
              matchesView =
                matchesView &&
                task.tagged_users.some(
                  (u) =>
                    u.account && selectedUserIds.has(u.account.reference_id)
                );
            }
            break;
          case "assigned_to_parents":
            matchesView = task.assigned_users.some((u) => u.parent !== null);
            if (filters.selectedUsers.length > 0) {
              const selectedUserIds = new Set(
                filters.selectedUsers.map((u) => u.value)
              );
              matchesView =
                matchesView &&
                task.assigned_users.some(
                  (u) => u.parent && selectedUserIds.has(u.parent.id)
                );
            }
            break;
          case "tagged_to_parents":
            matchesView = task.tagged_users.some((u) => u.parent !== null);
            if (filters.selectedUsers.length > 0) {
              const selectedUserIds = new Set(
                filters.selectedUsers.map((u) => u.value)
              );
              matchesView =
                matchesView &&
                task.tagged_users.some(
                  (u) => u.parent && selectedUserIds.has(u.parent.id)
                );
            }
            break;
          case "assigned_to_students":
            matchesView = task.assigned_users.some((u) => u.student !== null);
            if (filters.selectedUsers.length > 0) {
              const selectedUserIds = new Set(
                filters.selectedUsers.map((u) => u.value)
              );
              matchesView =
                matchesView &&
                task.assigned_users.some(
                  (u) => u.student && selectedUserIds.has(u.student.id)
                );
            }
            break;
          case "tagged_to_students":
            matchesView = task.tagged_users.some((u) => u.student !== null);
            if (filters.selectedUsers.length > 0) {
              const selectedUserIds = new Set(
                filters.selectedUsers.map((u) => u.value)
              );
              matchesView =
                matchesView &&
                task.tagged_users.some(
                  (u) => u.student && selectedUserIds.has(u.student.id)
                );
            }
            break;
          case "assigned_to_tutors":
            matchesView = task.assigned_users.some((u) => u.tutor !== null);
            if (filters.selectedUsers.length > 0) {
              const selectedUserIds = new Set(
                filters.selectedUsers.map((u) => u.value)
              );
              matchesView =
                matchesView &&
                task.assigned_users.some(
                  (u) => u.tutor && selectedUserIds.has(u.tutor.id)
                );
            }
            break;
          case "tagged_to_tutors":
            matchesView = task.tagged_users.some((u) => u.tutor !== null);
            if (filters.selectedUsers.length > 0) {
              const selectedUserIds = new Set(
                filters.selectedUsers.map((u) => u.value)
              );
              matchesView =
                matchesView &&
                task.tagged_users.some(
                  (u) => u.tutor && selectedUserIds.has(u.tutor.id)
                );
            }
            break;
        }
      }

      return matchesSearch && matchesDateRange && matchesStatus && matchesView;
    })
    .sort((a, b) => {
      const dateA = new Date(a.due_date);
      const dateB = new Date(b.due_date);
      return filters.sortBy === "due_date_asc"
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });

  const renderUserList = (
    type: "Assigned Users" | "Tagged Users",
    task: TaskResponse
  ) => {
    const current_users = {
      admin:
        type === "Assigned Users"
          ? task.assigned_users.filter((u) => u.account)
          : task.tagged_users.filter((u) => u.account),
      students:
        type === "Assigned Users"
          ? task.assigned_users.filter((u) => u.student)
          : task.tagged_users.filter((u) => u.student),
      parents:
        type === "Assigned Users"
          ? task.assigned_users.filter((u) => u.parent)
          : task.tagged_users.filter((u) => u.parent),
      tutors:
        type === "Assigned Users"
          ? task.assigned_users.filter((u) => u.tutor)
          : task.tagged_users.filter((u) => u.tutor),
    };

    // if task is being edited, show the edit form
    if (task.id in taskUpdates) {
      return (
        <div className="user-list mb-6">
          {type === "Assigned Users" && (
            <div className="mb-4">
              <h4 className="text-sm font-bold">Task Content</h4>
              <div className="content-input-wrapper">
                <textarea
                  className="input w-full min-h-20 text-sm resize-none"
                  value={taskUpdates[task.id].content as string}
                  onChange={(e) => {
                    const textarea = e.target;
                    textarea.style.height = "auto";
                    textarea.style.height = `${textarea.scrollHeight}px`;
                    setTaskUpdates({
                      ...taskUpdates,
                      [task.id]: {
                        ...taskUpdates[task.id],
                        content: e.target.value,
                      },
                    });
                  }}
                  style={{ height: "auto" }}
                />
              </div>
            </div>
          )}
          <h4 className="text-sm font-bold">{type}</h4>
          <div className="user-selects grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <label className="text-sm">Admins</label>
              <Select
                isMulti
                value={
                  type === "Assigned Users"
                    ? taskUpdates[task.id].assigned_admins
                    : taskUpdates[task.id].tagged_admins
                }
                onChange={(options) => {
                  setTaskUpdates({
                    ...taskUpdates,
                    [task.id]: {
                      ...taskUpdates[task.id],
                      ...(type === "Assigned Users"
                        ? {
                            assigned_admins: options,
                          }
                        : {
                            tagged_admins: options,
                          }),
                    },
                  });
                }}
                options={adminUsers}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                    pointerEvents: "auto",
                  }),
                  option: (base) => ({
                    ...base,
                    cursor: "pointer",
                  }),
                }}
              />
            </div>
            <div>
              <label className="text-sm">Tutors</label>
              <Select
                isMulti
                value={
                  type === "Assigned Users"
                    ? taskUpdates[task.id].assigned_tutors
                    : taskUpdates[task.id].tagged_tutors
                }
                onChange={(options) => {
                  setTaskUpdates({
                    ...taskUpdates,
                    [task.id]: {
                      ...taskUpdates[task.id],
                      ...(type === "Assigned Users"
                        ? {
                            assigned_tutors: options,
                          }
                        : {
                            tagged_tutors: options,
                          }),
                    },
                  });
                }}
                options={tutorUsers}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                    pointerEvents: "auto",
                  }),
                  option: (base) => ({
                    ...base,
                    cursor: "pointer",
                  }),
                }}
              />
            </div>
            <div>
              <label className="text-sm">Parents</label>
              <Select
                isMulti
                value={
                  type === "Assigned Users"
                    ? taskUpdates[task.id].assigned_parents
                    : taskUpdates[task.id].tagged_parents
                }
                onChange={(options) => {
                  setTaskUpdates({
                    ...taskUpdates,
                    [task.id]: {
                      ...taskUpdates[task.id],
                      ...(type === "Assigned Users"
                        ? {
                            assigned_parents: options,
                          }
                        : {
                            tagged_parents: options,
                          }),
                    },
                  });
                }}
                options={parentUsers}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                    pointerEvents: "auto",
                  }),
                  option: (base) => ({
                    ...base,
                    cursor: "pointer",
                  }),
                }}
              />
            </div>
            <div>
              <label className="text-sm">Students</label>
              <Select
                isMulti
                value={
                  type === "Assigned Users"
                    ? taskUpdates[task.id].assigned_students
                    : taskUpdates[task.id].tagged_students
                }
                onChange={(options) => {
                  setTaskUpdates({
                    ...taskUpdates,
                    [task.id]: {
                      ...taskUpdates[task.id],
                      ...(type === "Assigned Users"
                        ? {
                            assigned_students: options,
                          }
                        : {
                            tagged_students: options,
                          }),
                    },
                  });
                }}
                options={studentUsers}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                    pointerEvents: "auto",
                  }),
                  option: (base) => ({
                    ...base,
                    cursor: "pointer",
                  }),
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="user-list mb-2">
        {type === "Assigned Users" && (
          <div className="mb-4">
            <h4 className="text-sm font-bold">Task Content</h4>
            <p className="whitespace-pre-wrap">{task.content}</p>
          </div>
        )}
        <h4 className="text-sm font-bold">{type}</h4>
        <div className="user-tags">
          {current_users.admin.map((u) => (
            <Tag
              key={u.id}
              item={`${u.account?.first_name} ${u.account?.last_name}`}
              color={TagColor.PURPLE}
              size={TagSize.MEDIUM}
            />
          ))}
          {current_users.tutors.map((u) => (
            <div
              key={u.id}
              onClick={() => handleUserClick("tutor", u.tutor!.id)}
              className="inline-block cursor-pointer"
            >
              <Tag
                item={`${u.tutor?.first_name} ${u.tutor?.last_name}`}
                color={TagColor.GOLD}
                size={TagSize.MEDIUM}
              />
            </div>
          ))}
          {current_users.parents.map((u) => (
            <div
              key={u.id}
              onClick={() => handleUserClick("parent", u.parent!.id)}
              className="inline-block cursor-pointer"
            >
              <Tag
                item={`${u.parent?.first_name} ${u.parent?.last_name}`}
                color={TagColor.BLUE}
                size={TagSize.MEDIUM}
              />
            </div>
          ))}
          {current_users.students.map((u) => (
            <div
              key={u.id}
              onClick={() => handleUserClick("student", u.student!.id)}
              className="inline-block cursor-pointer"
            >
              <Tag
                item={`${u.student?.first_name} ${u.student?.last_name}`}
                color={TagColor.GREEN}
                size={TagSize.MEDIUM}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (error) {
      Notifications.error(error.message || "An unexpected error occurred.");
    }
  }, [error]);

  return (
    <DialogContent ref={dialogRef} className="manage-tasks-dialog">
      <div>
        <div className="task-filters">
          <div className="filters-row">
            <div className="filter-group">
              <label>Search</label>
              <input
                type="text"
                className="input w-full"
                placeholder="Search by key word..."
                value={filters.search}
                onChange={(e) =>
                  setFilters({ ...filters, search: e.target.value })
                }
              />
            </div>
            {currently_selected_role &&
              [Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
                currently_selected_role
              ) && (
                <div className="filter-group">
                  <label className="mb-2">View</label>
                  <Select
                    value={{
                      value: filters.view,
                      label:
                        filters.view === "my_tasks"
                          ? "My Tasks"
                          : filters.view === "assigned_to_admins"
                          ? "Tasks Assigned to Admins"
                          : filters.view === "tagged_to_admins"
                          ? "Tasks Tagged to Admins"
                          : filters.view === "assigned_to_tutors"
                          ? "Tasks Assigned to Tutors"
                          : filters.view === "tagged_to_tutors"
                          ? "Tasks Tagged to Tutors"
                          : filters.view === "assigned_to_parents"
                          ? "Tasks Assigned to Parents"
                          : filters.view === "tagged_to_parents"
                          ? "Tasks Tagged to Parents"
                          : filters.view === "assigned_to_students"
                          ? "Tasks Assigned to Students"
                          : filters.view === "tagged_to_students"
                          ? "Tasks Tagged to Students"
                          : "Tasks",
                    }}
                    onChange={(option) => {
                      if (option) {
                        setFilters({
                          ...filters,
                          view: option.value as TaskFilters["view"],
                          selectedUsers: [], // Reset selected users when view changes
                        });
                      }
                    }}
                    options={
                      currently_selected_role === Auth0AccountRole.ORG_ADMIN
                        ? [
                            { value: "my_tasks", label: "My Tasks" },
                            {
                              value: "tagged_to_students",
                              label: "Tasks Tagged to Students",
                            },
                            {
                              value: "tagged_to_parents",
                              label: "Tasks Tagged to Parents",
                            },

                            {
                              value: "tagged_to_tutors",
                              label: "Tasks Tagged to Tutors",
                            },
                            {
                              value: "tagged_to_admins",
                              label: "Tasks Tagged to Admins",
                            },
                            {
                              value: "assigned_to_admins",
                              label: "Tasks Assigned to Admins",
                            },
                            {
                              value: "assigned_to_tutors",
                              label: "Tasks Assigned to Tutors",
                            },
                            {
                              value: "assigned_to_parents",
                              label: "Tasks Assigned to Parents",
                            },
                            {
                              value: "assigned_to_students",
                              label: "Tasks Assigned to Students",
                            },
                          ]
                        : currently_selected_role === Auth0AccountRole.ORG_TUTOR
                        ? [
                            { value: "my_tasks", label: "My Tasks" },
                            {
                              value: "assigned_to_students",
                              label: "Students Assigned",
                            },
                            {
                              value: "tagged_to_students",
                              label: "Students Tagged",
                            },
                          ]
                        : []
                    }
                  />
                </div>
              )}
            {filters.view !== "my_tasks" &&
              currently_selected_role &&
              [Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
                currently_selected_role
              ) && (
                <div className="filter-group">
                  <label className="mb-2">Filter by Users</label>
                  <Select
                    isMulti
                    value={filters.selectedUsers}
                    onChange={(options) => {
                      setFilters({
                        ...filters,
                        selectedUsers: options as {
                          value: number | string;
                          label: string;
                        }[],
                      });
                    }}
                    options={
                      filters.view.includes("admins")
                        ? adminUsers
                        : filters.view.includes("parents")
                        ? parentUsers
                        : filters.view.includes("students")
                        ? studentUsers
                        : filters.view.includes("tutors")
                        ? tutorUsers
                        : []
                    }
                  />
                </div>
              )}
          </div>
          <div className="filters-row">
            <div className="filter-group">
              <label>Sort By</label>
              <Select
                value={{
                  value: filters.sortBy,
                  label:
                    filters.sortBy === "due_date_asc"
                      ? "Earlier Due Dates First"
                      : "Later Due Dates First",
                }}
                onChange={(option) => {
                  if (option) {
                    setFilters({
                      ...filters,
                      sortBy: option.value as TaskFilters["sortBy"],
                    });
                  }
                }}
                options={[
                  { value: "due_date_asc", label: "Earlier Due Dates First" },
                  { value: "due_date_desc", label: "Later Due Dates First" },
                ]}
              />
            </div>
            <div className="filter-group">
              <label>Status</label>
              <Select
                value={{
                  value: filters.status,
                  label:
                    filters.status === "ALL"
                      ? "All"
                      : filters.status.charAt(0).toUpperCase() +
                        filters.status.slice(1).toLowerCase(),
                }}
                onChange={(option) => {
                  if (option) {
                    setFilters({
                      ...filters,
                      status: option.value as TaskStatus | "ALL",
                    });
                  }
                }}
                options={[
                  { value: "ALL", label: "All" },
                  ...Object.values(TaskStatus).map((status) => ({
                    value: status,
                    label:
                      status.charAt(0).toUpperCase() +
                      status.slice(1).toLowerCase(),
                  })),
                ]}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                    pointerEvents: "auto",
                  }),
                  option: (base) => ({
                    ...base,
                    cursor: "pointer",
                  }),
                }}
              />
            </div>
            <div className="filter-group">
              <label>Start Date</label>
              <DatePicker
                oneTap
                value={
                  filters.dateRange.start
                    ? new Date(filters.dateRange.start)
                    : null
                }
                size="md"
                format="yyyy-MM-dd"
                placement="bottomStart"
                onChange={(val) => {
                  if (!val || isNaN(new Date(val).getTime())) {
                    setFilters({
                      ...filters,
                      dateRange: { ...filters.dateRange, start: null },
                    });
                    return;
                  }
                  try {
                    const localStart = new Date(val);
                    localStart.setHours(0, 0, 0, 0);
                    if (!isNaN(localStart.getTime())) {
                      setFilters({
                        ...filters,
                        dateRange: {
                          ...filters.dateRange,
                          start: localStart.toISOString(),
                        },
                      });
                    }
                  } catch (e) {
                    console.error("Invalid date:", e);
                  }
                }}
                container={() => dialogRef.current || document.body}
                cleanable
                block
              />
            </div>
            <div className="filter-group">
              <label>End Date</label>
              <DatePicker
                oneTap
                value={
                  filters.dateRange.end ? new Date(filters.dateRange.end) : null
                }
                size="md"
                format="yyyy-MM-dd"
                placement="bottomStart"
                onChange={(val) => {
                  if (!val || isNaN(new Date(val).getTime())) {
                    setFilters({
                      ...filters,
                      dateRange: { ...filters.dateRange, end: null },
                    });
                    return;
                  }
                  try {
                    const localEnd = new Date(val);
                    localEnd.setHours(23, 59, 59, 999);
                    if (!isNaN(localEnd.getTime())) {
                      setFilters({
                        ...filters,
                        dateRange: {
                          ...filters.dateRange,
                          end: localEnd.toISOString(),
                        },
                      });
                    }
                  } catch (e) {
                    console.error("Invalid date:", e);
                  }
                }}
                container={() => dialogRef.current || document.body}
                cleanable
                block
              />
            </div>
          </div>
        </div>
      </div>

      <div className="task-list">
        {filteredAndSortedTasks.length === 0 ? (
          <div className="text-center py-16 text-gray-500 text-2xl mt-28">
            <div>No tasks! Click the button below to create one</div>
            <div className="mt-8">
              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    fill={ButtonFill.HOLLOW}
                    color={ButtonColor.GREEN}
                    size={ButtonSize.LARGE}
                  >
                    Create Task +
                  </Button>
                </DialogTrigger>

                <CreateTaskDialog
                  students={
                    organization && "students" in organization
                      ? (organization.students as StudentLimitedResponse[])
                      : []
                  }
                  parents={
                    organization && "parents" in organization
                      ? (organization.parents as ParentLimitedResponse[])
                      : []
                  }
                  tutors={
                    organization && "tutors" in organization
                      ? (organization.tutors as TutorPublicResponse[])
                      : []
                  }
                  admins={
                    organization && "admins" in organization
                      ? (organization.admins as AccountLimitedResponse[])
                      : []
                  }
                  onClose={() => {
                    const dialog = document.querySelector(
                      ".create-task-dialog"
                    );
                    if (dialog) {
                      (dialog as HTMLElement).click();
                    }
                  }}
                  refreshTasks={refreshTasks}
                />
              </Dialog>
            </div>
          </div>
        ) : (
          filteredAndSortedTasks.map((task) => (
            <div
              key={task.id}
              className={`task-item border-l-4 ${
                task.status === TaskStatus.COMPLETE
                  ? "border-l-[#4adc8c]"
                  : task.status === TaskStatus.PENDING
                  ? "border-l-[#93c5fd]"
                  : task.status === TaskStatus.CANCELLED
                  ? "border-l-[#4b5563]"
                  : ""
              }`}
            >
              <div className="task-header">
                <div className="task-preview">
                  {task.id in taskUpdates ? (
                    <div className="task-edit-form flex flex-row">
                      <div className="name-input-wrapper">
                        <input
                          type="text"
                          className="mr-4 w-full"
                          value={taskUpdates[task.id].name as string}
                          onChange={(e) =>
                            setTaskUpdates({
                              ...taskUpdates,
                              [task.id]: {
                                ...taskUpdates[task.id],
                                name: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <DatePicker
                        oneTap
                        value={
                          new Date(taskUpdates[task.id].due_date as string)
                        }
                        size="md"
                        format="yyyy-MM-dd"
                        placement="bottomStart"
                        onChange={(val) => {
                          if (!val || isNaN(new Date(val).getTime())) return;
                          try {
                            const localDate = new Date(val);
                            if (!isNaN(localDate.getTime())) {
                              setTaskUpdates({
                                ...taskUpdates,
                                [task.id]: {
                                  ...taskUpdates[task.id],
                                  due_date: localDate.toISOString(),
                                },
                              });
                            }
                          } catch (e) {
                            console.error("Invalid date:", e);
                          }
                        }}
                        container={() => dialogRef.current || document.body}
                        cleanable
                      />
                    </div>
                  ) : (
                    <>
                      <h3>
                        {task.name}
                        <span className="text-sm text-gray-400 ml-2 italic">
                          - due: {new Date(task.due_date).toLocaleDateString()}
                        </span>
                      </h3>
                      {!expandedTasks.has(task.id) && (
                        <p>
                          {task.content.length > 125
                            ? `${task.content.substring(0, 125)}...`
                            : task.content}
                        </p>
                      )}
                    </>
                  )}
                </div>
                <div className="task-actions">
                  <Select
                    value={{
                      value: task.status,
                      label:
                        task.status.charAt(0).toUpperCase() +
                        task.status.slice(1).toLowerCase(),
                    }}
                    onChange={(option) => {
                      handleTaskUpdate(task, {
                        status: option.value as TaskStatus,
                      } as TaskUpdateHelper);
                    }}
                    options={Object.values(TaskStatus).map((status) => ({
                      value: status,
                      label:
                        status.charAt(0).toUpperCase() +
                        status.slice(1).toLowerCase(),
                    }))}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      container: (base) => ({
                        ...base,
                        width: "200px",
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                        pointerEvents: "auto",
                      }),
                      option: (base) => ({
                        ...base,
                        cursor: "pointer",
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                  />
                </div>
              </div>
              {expandedTasks.has(task.id) && (
                <div className="task-details">
                  {renderUserList("Assigned Users", task)}
                  {renderUserList("Tagged Users", task)}
                </div>
              )}
              <div className="bottom-actions mt-8">
                {expandedTasks.has(task.id) && !taskUpdates[task.id] && (
                  <>
                    <Button
                      color={ButtonColor.DARK_BLUE}
                      fill={ButtonFill.HOLLOW}
                      size={ButtonSize.EXTRA_SMALL}
                      onClick={() => {
                        setTaskUpdates({
                          ...taskUpdates,
                          [task.id]: {
                            name: task.name,
                            content: task.content,
                            due_date: task.due_date,
                            assigned_admins: task.assigned_users
                              .filter((u) => u.account)
                              .map((u) => ({
                                value: u.account?.reference_id,
                                label: `${u.account?.first_name} ${u.account?.last_name}`,
                              })),
                            assigned_tutors: task.assigned_users
                              .filter((u) => u.tutor)
                              .map((u) => ({
                                value: u.tutor?.id,
                                label: `${u.tutor?.first_name} ${u.tutor?.last_name}`,
                              })),
                            assigned_parents: task.assigned_users
                              .filter((u) => u.parent)
                              .map((u) => ({
                                value: u.parent?.id,
                                label: `${u.parent?.first_name} ${u.parent?.last_name}`,
                              })),
                            assigned_students: task.assigned_users
                              .filter((u) => u.student)
                              .map((u) => ({
                                value: u.student?.id,
                                label: `${u.student?.first_name} ${u.student?.last_name}`,
                              })),
                            tagged_admins: task.tagged_users
                              .filter((u) => u.account)
                              .map((u) => ({
                                value: u.account?.reference_id,
                                label: `${u.account?.first_name} ${u.account?.last_name}`,
                              })),
                            tagged_tutors: task.tagged_users
                              .filter((u) => u.tutor)
                              .map((u) => ({
                                value: u.tutor?.id,
                                label: `${u.tutor?.first_name} ${u.tutor?.last_name}`,
                              })),
                            tagged_parents: task.tagged_users
                              .filter((u) => u.parent)
                              .map((u) => ({
                                value: u.parent?.id,
                                label: `${u.parent?.first_name} ${u.parent?.last_name}`,
                              })),
                            tagged_students: task.tagged_users
                              .filter((u) => u.student)
                              .map((u) => ({
                                value: u.student?.id,
                                label: `${u.student?.first_name} ${u.student?.last_name}`,
                              })),
                          } as TaskUpdateHelper,
                        });
                      }}
                    >
                      <Pencil1Icon />
                    </Button>
                    <Dialog>
                      <DialogTrigger>
                        <Button
                          color={ButtonColor.BLACK}
                          fill={ButtonFill.HOLLOW}
                          size={ButtonSize.EXTRA_SMALL}
                        >
                          <TrashIcon />
                        </Button>
                      </DialogTrigger>

                      <DeleteTaskDialog
                        task={task}
                        onConfirm={() => handleTaskDelete(task.id)}
                      />
                    </Dialog>
                  </>
                )}
                {expandedTasks.has(task.id) && taskUpdates[task.id] ? (
                  <>
                    <Button
                      color={
                        updatingTasks.has(task.id)
                          ? ButtonColor.GRAY
                          : ButtonColor.GREEN
                      }
                      fill={ButtonFill.HOLLOW}
                      size={ButtonSize.EXTRA_SMALL}
                      onClick={() =>
                        handleTaskUpdate(task, taskUpdates[task.id])
                      }
                      disabled={updatingTasks.has(task.id)}
                    >
                      {updatingTasks.has(task.id) ? "Updating..." : "Apply"}
                    </Button>
                    <Button
                      color={ButtonColor.DARK_BLUE}
                      fill={ButtonFill.HOLLOW}
                      size={ButtonSize.EXTRA_SMALL}
                      onClick={() => {
                        const newUpdates = { ...taskUpdates };
                        delete newUpdates[task.id];
                        setTaskUpdates(newUpdates);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <button
                    className="expand-button"
                    onClick={() => toggleTaskExpansion(task.id)}
                  >
                    {expandedTasks.has(task.id) ? "−" : "+"}
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>

      {filteredAndSortedTasks.length !== 0 && (
        <div className="bottom-task-navbar ">
          <Dialog>
            <DialogTrigger asChild>
              <Button fill={ButtonFill.HOLLOW} color={ButtonColor.GREEN}>
                Create Task +
              </Button>
            </DialogTrigger>

            <CreateTaskDialog
              students={
                organization && "students" in organization
                  ? (organization.students as StudentLimitedResponse[])
                  : []
              }
              parents={
                organization && "parents" in organization
                  ? (organization.parents as ParentLimitedResponse[])
                  : []
              }
              tutors={
                organization && "tutors" in organization
                  ? (organization.tutors as TutorPublicResponse[])
                  : []
              }
              admins={
                organization && "admins" in organization
                  ? (organization.admins as AccountLimitedResponse[])
                  : []
              }
              onClose={() => {
                const dialog = document.querySelector(".create-task-dialog");
                if (dialog) {
                  (dialog as HTMLElement).click();
                }
              }}
              refreshTasks={refreshTasks}
            />
          </Dialog>
        </div>
      )}
    </DialogContent>
  );
}

export default ManageTasksDialog;
