import {
  MeetingAdminResponse,
  Auth0AccountRole,
  ExportsService,
  ApiError,
  TutorLimitedResponse,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { getButtonRoleColor } from "util/contextColor";

import Notifications from "util/notifications";
import moment from "moment";
import { concatenateName } from "util/concatenateName";
import { saveAs } from "file-saver";
import { APIResponse, PageStatus } from "types";
import { DownloadIcon } from "@radix-ui/react-icons";
import Avatar from "components/Avatar";
import { generateInitials } from "util/generateInitials";
import {
  concatenateMeetingDetails,
  returnSubjectName,
} from "util/concatenateSubject";
import "../index.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { useContext } from "react";
import { Dialog } from "components/Dialog";
import { IoEyeSharp } from "react-icons/io5";
import { DialogTrigger } from "components/Dialog";
import MeetingDialog from "components/MeetingDialog";
import { getAttendees } from "util/getAttendees";

export default function MeetingHistory({
  meetings,
  exportingMeetings,
  setExportingMeetings,
  tutor,
  exportingData,
  organization_id,
  setStatus,
  setError,
}: {
  meetings: MeetingAdminResponse[];
  exportingMeetings: boolean;
  setExportingMeetings: (boolean) => void;
  tutor: TutorLimitedResponse;
  exportingData: boolean;
  organization_id: number;
  setStatus: (PageStatus) => void;
  setError: (APIResponse) => void;
}) {
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);

  async function exportTutorMeetings() {
    if (!tutor) {
      return Notifications.error("Unable to export meeting data.");
    }

    if (exportingData) {
      return Notifications.error("Please wait for other export to complete.");
    }

    setExportingMeetings(true);

    await ExportsService.exportTutorMeetings({
      tutorId: tutor.id,
      until: moment().toISOString(),
    })
      .then((data) => {
        try {
          const blob = new Blob([data], { type: "text/csv" });
          const fileName = "export.csv";
          saveAs(blob, fileName);
          Notifications.success(
            `${concatenateName(
              tutor
            )}'s meeting export has been saved to your computer!`
          );
        } catch (error) {
          console.error(error);
          Notifications.error("Unable to save file.");
        }
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to export tutor's meetings" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    setExportingMeetings(false);
  }

  return (
    <section className="student-profile--section" id="student-meeting-history">
      <div className="student-profile--section-header">
        <h2>Meeting History</h2>

        <div className="flex flex-row gap-1.5 items-center">
          {meetings.length > 0 && (
            <Button
              color={getButtonRoleColor(
                currently_selected_role
                  ? currently_selected_role
                  : Auth0AccountRole.ME
              )}
              size={ButtonSize.SMALL}
              onClick={() => exportTutorMeetings()}
            >
              Export{" "}
              {exportingMeetings ? (
                <span
                  className="spinner-border spinner-border-square"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <DownloadIcon aria-label="Export Meetings" />
              )}
            </Button>
          )}
        </div>
      </div>

      <div className="student-profile--section-items">
        {meetings.length > 0 ? (
          meetings
            .sort(
              (a, b) => moment(b.start).valueOf() - moment(a.start).valueOf()
            )
            .map((m, i) => (
              <div
                key={i}
                className="student-profile--section-item student-detail--historical-meeting pl-4 py-4"
                style={{
                  borderLeft: "4px solid #3cb8e3",
                  backgroundColor: "#edf7fd",
                }}
              >
                <div className="student-detail--historical-meeting--details">
                  <p>
                    <b>{moment(m.start).format("ddd, MMM D, YYYY")}</b> &middot;{" "}
                    {moment(m.start).format("h:mma")} -{" "}
                    {moment(m.start).add(m.duration, "minutes").format("h:mma")}{" "}
                    &middot;{" "}
                    <Dialog>
                      <DialogTrigger>
                        <Button
                          size={ButtonSize.EXTRA_SMALL}
                          color={ButtonColor.DARK_BLUE}
                          fill={ButtonFill.HOLLOW}
                        >
                          <IoEyeSharp />
                        </Button>
                      </DialogTrigger>

                      <MeetingDialog
                        isCreate={false}
                        role={currently_selected_role as Auth0AccountRole}
                        event={m}
                        setEvents={() => Promise.resolve()}
                      />
                    </Dialog>
                  </p>
                  <p>
                    {returnSubjectName(m.org_subject)} &middot;{" "}
                    {getAttendees(Auth0AccountRole.ORG_TUTOR, m)} &middot;{" "}
                    {m.location?.name || m.other_location}
                  </p>
                </div>
              </div>
            ))
        ) : (
          <div className="student-profile--section-item">No meetings.</div>
        )}
      </div>
    </section>
  );
}
