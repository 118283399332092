import { useState } from "react";
import { Select } from "components/Select";
import "./index.css";

import {
  ConditionGroup,
  LogicalOperator,
  OrganizationDataKeyResponse,
  QueryCrmService,
  ComparisonOperator,
  ExportsService,
  ApiError,
  Auth0AccountRole,
} from "client/openapi";
import { ConditionGroupBuilder } from "./builder";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { Tag } from "components/Tag/TagChip";
import { TagColor, TagSize } from "components/Tag";
import { saveAs } from "file-saver";
import Notifications from "util/notifications";
import {
  isSingleEntityQueryValid,
  isMultiEntityQueryValid,
} from "./validation";
import ComposeMessageDialog from "components/Dashboard/ComposeMessageDialog";
import { DialogContent, DialogTrigger } from "components/Dialog";
import { Dialog } from "components/Dialog";

// Example endpoints we support
type CrmEndpoints =
  | "get_students"
  | "get_parents"
  | "get_students_with_parents"
  | "get_parents_with_students";

/**
 * Props:
 * - orgId: The current organization ID.
 * - studentKeys: array of OrgDataKeys for students.
 * - parentKeys: array of OrgDataKeys for parents.
 */
export function CRMsearch({
  orgId,
  studentKeys,
  parentKeys,
}: {
  orgId: number;
  studentKeys: Array<OrganizationDataKeyResponse>;
  parentKeys: Array<OrganizationDataKeyResponse>;
}) {
  // Let user pick an endpoint to call
  const [endpoint, setEndpoint] = useState<CrmEndpoints>("get_students");
  const [isExporting, setIsExporting] = useState(false);

  // Single or dual ConditionGroup
  const [condGroupA, setCondGroupA] = useState<ConditionGroup>({
    operator: LogicalOperator.AND,
    conditions: [
      {
        org_data_key_id: 0,
        comparison_operator: ComparisonOperator.EQUALS,
        value: "",
        negate: false,
      },
    ],
    negate: false,
  });
  const [condGroupB, setCondGroupB] = useState<ConditionGroup>({
    operator: LogicalOperator.AND,
    conditions: [
      {
        org_data_key_id: 0,
        comparison_operator: ComparisonOperator.EQUALS,
        value: "",
        negate: false,
      },
    ],
    negate: false,
  });

  // For demonstration only
  const [queryResults, setQueryResults] = useState<any>(null);

  /** Checks if the current query is valid */
  function isQueryValid(): boolean {
    if (isMultiEntity) {
      return isMultiEntityQueryValid(
        condGroupA,
        condGroupB,
        condGroupAKeys,
        condGroupBKeys
      );
    }
    return isSingleEntityQueryValid(condGroupA, condGroupAKeys);
  }

  /** Handler for exporting the data */
  async function handleExport() {
    if (!orgId || !queryResults || queryResults.length === 0) {
      return Notifications.error("No results to export");
    }

    setIsExporting(true);
    try {
      const ids = queryResults.map((result: any) => result.id);
      let data;

      if (
        endpoint === "get_students" ||
        endpoint === "get_students_with_parents"
      ) {
        data = await ExportsService.exportStudents({
          orgId,
          requestBody: ids,
        });
      } else {
        data = await ExportsService.exportParents({
          orgId,
          requestBody: ids,
        });
      }

      const blob = new Blob([data], { type: "text/csv" });
      const entityType =
        endpoint === "get_students" || endpoint === "get_students_with_parents"
          ? "students"
          : "parents";
      const fileName = `${entityType}_query_results.csv`;

      saveAs(blob, fileName);
      Notifications.success(
        `${
          entityType.charAt(0).toUpperCase() + entityType.slice(1)
        } data has been exported successfully!`
      );
    } catch (err) {
      const error = err as ApiError;
      console.error("Export error:", {
        status: error.status,
        message: error.message,
        error,
      });
      Notifications.error(`Failed to export data: ${error.message}`);
    } finally {
      setIsExporting(false);
    }
  }

  /** Handler for the "Run Query" button */
  async function handleRunQuery() {
    // Helper to process condition groups before sending
    const processConditionGroup = (group: ConditionGroup): ConditionGroup => {
      return {
        ...group,
        conditions: group.conditions.map((condition) => {
          if ("conditions" in condition) {
            // This is a nested group
            return processConditionGroup(condition as ConditionGroup);
          } else {
            // This is a single condition
            const isListOp = [
              ComparisonOperator.IN,
              ComparisonOperator.NOT_IN,
            ].includes(condition.comparison_operator);

            if (isListOp) {
              // Get the data type for this condition
              const key = condition.org_data_key_id;
              const keyDef = [...studentKeys, ...parentKeys].find(
                (k) => k.id === key
              );

              if (typeof condition.value === "string") {
                // Handle text input cases
                const values = condition.value.split(",");

                if (keyDef?.data_type === "FLOAT") {
                  // Convert to numbers, filter out invalid ones
                  const numberValues = values
                    .map((v) => v.trim())
                    .filter((v) => v !== "")
                    .map((v) => parseFloat(v))
                    .filter((n) => !isNaN(n));
                  return { ...condition, value: numberValues };
                } else {
                  // Keep as strings, just trim and filter empty
                  const textValues = values
                    .map((v) => v.trim())
                    .filter((v) => v !== "");
                  return { ...condition, value: textValues };
                }
              } else if (Array.isArray(condition.value)) {
                // Handle array values (from selects)
                if (keyDef?.data_type === "CUSTOM_DATA_TYPE") {
                  // Convert custom field values to strings
                  return {
                    ...condition,
                    value: condition.value.map((v) => String(v)),
                  };
                }
              }
            }
            return condition;
          }
        }),
      };
    };

    // Process condition groups
    const processedGroupA = processConditionGroup(condGroupA);
    const processedGroupB = processConditionGroup(condGroupB);

    if (endpoint === "get_students") {
      QueryCrmService.getStudents({
        orgId: orgId,
        requestBody: processedGroupA,
      })
        .then((results) => {
          Notifications.success("Search complete!");
          setQueryResults(results);
        })
        .catch((err) => {
          Notifications.error("Invalid search criteria");
          setQueryResults(null);
        });
    } else if (endpoint === "get_parents") {
      QueryCrmService.getParents({
        orgId: orgId,
        requestBody: processedGroupA,
      })
        .then((results) => {
          Notifications.success("Search complete!");
          setQueryResults(results);
        })
        .catch((err) => {
          Notifications.error("Invalid search criteria");
          setQueryResults(null);
        });
    } else if (endpoint === "get_students_with_parents") {
      QueryCrmService.getStudentsWithParents({
        orgId: orgId,
        requestBody: {
          student_criteria: processedGroupA,
          parent_criteria: processedGroupB,
        },
      })
        .then((results) => {
          Notifications.success("Search complete!");
          setQueryResults(results);
        })
        .catch((err) => {
          Notifications.error("Invalid search criteria");
          setQueryResults(null);
        });
    } else if (endpoint === "get_parents_with_students") {
      QueryCrmService.getParentsWithStudents({
        orgId: orgId,
        requestBody: {
          parent_criteria: processedGroupA,
          student_criteria: processedGroupB,
        },
      })
        .then((results) => {
          Notifications.success("Search complete!");
          setQueryResults(results);
        })
        .catch((err) => {
          Notifications.error("Invalid search criteria");
          setQueryResults(null);
        });
    }
  }

  const endpointOptions = [
    { value: "get_students", label: "Get Students" },
    { value: "get_parents", label: "Get Parents" },
    {
      value: "get_students_with_parents",
      label: "Get Students (with filtering by parent criteria)",
    },
    {
      value: "get_parents_with_students",
      label: "Get Parents (with filtering by student criteria)",
    },
  ];

  // Decide how many builders to show
  const isMultiEntity =
    endpoint === "get_students_with_parents" ||
    endpoint === "get_parents_with_students";

  // For ConditionGroup A:
  let condGroupAKeys = studentKeys; // default for "students"
  let condGroupBKeys = parentKeys; // default for the second group

  if (endpoint === "get_parents") {
    condGroupAKeys = parentKeys;
  } else if (endpoint === "get_parents_with_students") {
    // "main" = parents, "secondary" = students
    condGroupAKeys = parentKeys;
    condGroupBKeys = studentKeys;
  }

  return (
    <div className="query--container">
      <div style={{ marginBottom: "1rem" }}>
        <Select
          className="max-w-[750px]"
          value={endpointOptions.find((opt) => opt.value === endpoint) || null}
          onChange={(opt) => {
            if (opt) setEndpoint(opt.value as CrmEndpoints);
          }}
          options={endpointOptions}
          isClearable={false}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>

      {/* Condition Group A always shown. Title it accordingly. */}
      <h3>
        {endpoint === "get_students" ||
        endpoint === "get_students_with_parents" ? (
          <strong>Students</strong>
        ) : (
          <strong>Parents</strong>
        )}
      </h3>

      <ConditionGroupBuilder
        value={condGroupA}
        onChange={(updated) => setCondGroupA(updated)}
        availableKeys={condGroupAKeys}
      />

      {isMultiEntity && (
        <>
          <hr style={{ margin: "1rem 0" }} />
          <h3>
            {endpoint === "get_students_with_parents" ? (
              <strong>Parents</strong>
            ) : (
              <strong>Students</strong>
            )}
          </h3>
          <ConditionGroupBuilder
            value={condGroupB}
            onChange={(updated) => setCondGroupB(updated)}
            availableKeys={condGroupBKeys}
          />
        </>
      )}

      <div style={{ marginTop: "1rem" }}>
        <Button
          onClick={handleRunQuery}
          color={!isQueryValid() ? ButtonColor.GRAY : ButtonColor.GREEN}
          disabled={!isQueryValid()}
        >
          Run Query
        </Button>
      </div>

      <pre style={{ marginTop: "1rem", background: "#f5f5f5" }}>
        <div className="mb-4 font-bold">Results:</div>
        <div className="results-container">
          {queryResults?.map((result: any) => (
            <span className="results-tag" key={result.id}>
              <Tag
                color={TagColor.GREEN}
                size={TagSize.NARROW}
                item={`${result.first_name} ${result.last_name}`}
              />
            </span>
          ))}
        </div>
        {queryResults && queryResults.length > 0 && (
          <div className="flex flex-row gap-4">
            <div className="mt-4 mb-4">
              <Button
                onClick={handleExport}
                color={ButtonColor.PURPLE}
                size={ButtonSize.SMALL}
                disabled={isExporting}
              >
                {isExporting ? "Exporting..." : "Export"}
              </Button>
            </div>
            <div className="mt-4 mb-4">
              <Dialog>
                <DialogTrigger asChild>
                  <Button color={ButtonColor.PURPLE} size={ButtonSize.SMALL}>
                    Compose Message
                  </Button>
                </DialogTrigger>

                <ComposeMessageDialog
                  students={
                    endpoint === "get_students" ||
                    endpoint === "get_students_with_parents"
                      ? queryResults
                      : []
                  }
                  parents={
                    endpoint === "get_parents" ||
                    endpoint === "get_parents_with_students"
                      ? queryResults
                      : []
                  }
                  org_id={orgId}
                />
              </Dialog>
            </div>
          </div>
        )}
      </pre>
    </div>
  );
}
