import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { getButtonRoleColor } from "util/contextColor";
import { DownloadIcon } from "@radix-ui/react-icons";
import UserDataDialog from "components/Dashboard/UserDataDialog";
import {
  Auth0AccountRole,
  StudentLimitedResponse,
  ApiError,
  ParentLimitedResponse,
} from "client/openapi";
import Notifications from "util/notifications";
import { ExportsService } from "client/openapi";
import { saveAs } from "file-saver";
import { PageStatus } from "types";
import "../index.css";
import { useContext, useRef } from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { Dialog, DialogTrigger } from "components/Dialog";
import ManageTasksDialog from "components/Dashboard/ManageTasksDialog";

export default function AdministrativeInfo({
  exportingData,
  setExportingData,
  userType,
  student,
  parent,
  organization_id,
  exportingMeetings,
  showViewProfileButton,
  setStatus,
  setError,
}: {
  exportingData: boolean;
  setExportingData: (boolean) => void;
  userType: Auth0AccountRole;
  student?: StudentLimitedResponse;
  parent?: ParentLimitedResponse;
  organization_id: number;
  exportingMeetings?: boolean;
  showViewProfileButton?: boolean;
  setStatus?: (PageStatus) => void;
  setError?: (APIResponse) => void;
}) {
  const dialogRef = useRef<HTMLDivElement>(null);

  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);
  async function exportUser() {
    if (
      (userType === Auth0AccountRole.ME && !student) ||
      (userType === Auth0AccountRole.PARENT && !parent)
    ) {
      return Notifications.error("Unable to export data.");
    }

    if (exportingMeetings) {
      return Notifications.error("Please wait for other export to complete.");
    }

    setExportingData(true);

    if (userType === Auth0AccountRole.ME) {
      await ExportsService.exportStudents({
        requestBody: [student?.id as number],
        orgId: organization_id,
      })
        .then((data) => {
          try {
            const blob = new Blob([data], { type: "text/csv" });
            const fileName = `${student?.first_name}_${student?.last_name}_data_export.csv`;
            saveAs(blob, fileName);
            Notifications.success(
              `${student?.first_name}'s data export has been saved to your computer!`
            );
          } catch (error) {
            console.error(error);
            Notifications.error("Unable to save file.");
          }
        })
        .catch((e: ApiError) => {
          setStatus?.(PageStatus.ERROR);
          setError?.({ message: "Unable to export student's data" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    } else if (userType === Auth0AccountRole.PARENT) {
      await ExportsService.exportParents({
        requestBody: [parent?.id as number],
        orgId: organization_id,
      })
        .then((data) => {
          try {
            const blob = new Blob([data], { type: "text/csv" });
            const fileName = `${parent?.first_name}_${parent?.last_name}_data_export.csv`;
            saveAs(blob, fileName);
            Notifications.success(
              `${parent?.first_name}'s data export has been saved to your computer!`
            );
          } catch (error) {
            console.error(error);
            Notifications.error("Unable to save file.");
          }
        })
        .catch((e: ApiError) => {
          setStatus?.(PageStatus.ERROR);
          setError?.({ message: "Unable to export parent's data" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }

    setExportingData(false);
  }

  // If no role is selected, return null instead of undefined
  if (!currently_selected_role) {
    return null;
  }

  return (
    <section
      className="student-profile--section"
      id="student-about"
      ref={dialogRef}
    >
      <div className="student-profile--section-header">
        <h2>
          {userType === Auth0AccountRole.PARENT
            ? parent?.first_name
            : student?.first_name}
          's Data
        </h2>

        <div>
          {[Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
            currently_selected_role
          ) && (
            <Dialog>
              <DialogTrigger>
                <Button size={ButtonSize.SMALL} color={ButtonColor.SKYBLUE}>
                  See Tasks
                </Button>
              </DialogTrigger>

              <ManageTasksDialog
                startingView={
                  currently_selected_role === Auth0AccountRole.ORG_ADMIN
                    ? userType === Auth0AccountRole.PARENT
                      ? "tagged_to_parents"
                      : "tagged_to_students"
                    : userType === Auth0AccountRole.PARENT
                    ? "assigned_to_parents"
                    : "assigned_to_students"
                }
                startingUserId={
                  userType === Auth0AccountRole.PARENT
                    ? (parent?.id as number)
                    : (student?.id as number)
                }
                startingUserRole={
                  userType === Auth0AccountRole.PARENT
                    ? Auth0AccountRole.PARENT
                    : userType === Auth0AccountRole.ME
                    ? Auth0AccountRole.ME
                    : undefined
                }
              />
            </Dialog>
          )}
          {showViewProfileButton && (
            <span className="ml-2">
              <Button
                color={ButtonColor.PURPLE}
                size={ButtonSize.SMALL}
                onClick={() =>
                  window.open(
                    userType === Auth0AccountRole.PARENT
                      ? `${process.env.REACT_APP_FRONTEND_DOMAIN}/manage/parents/${parent?.id}`
                      : `${process.env.REACT_APP_FRONTEND_DOMAIN}/manage/students/${student?.id}`,
                    "_blank"
                  )
                }
              >
                View Profile
              </Button>
            </span>
          )}
          <span className="ml-2">
            <Button
              color={getButtonRoleColor(
                currently_selected_role
                  ? currently_selected_role
                  : Auth0AccountRole.ME
              )}
              size={ButtonSize.SMALL}
              onClick={() => exportUser()}
            >
              Export{" "}
              {exportingData ? (
                <span
                  className="spinner-border spinner-border-square"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <DownloadIcon aria-label="Export Data" />
              )}
            </Button>
          </span>
        </div>
      </div>

      <div className="student-profile--section-items">
        <div className="student-profile--section-item">
          <UserDataDialog
            beingViewedBy={currently_selected_role}
            userType={userType}
            student={student}
            parent={parent}
            dialogRef={dialogRef}
          />
        </div>
      </div>
    </section>
  );
}
