import { useRef, useState } from "react";
import "./index.css";
import {
  OrganizationPublicResponse,
  OrganizationWebPageResponse,
  TutorPublicResponse,
  TutorWebPageResponse,
} from "client/openapi";
import BookingInterface from "./BookingInterface";
import SubjectsGrid from "components/SubjectsGrid";

/** Darken a 6-char hex color a little */
function darkenColor(hexColor: string, amount: number = 20): string {
  const r = Math.max(0, parseInt(hexColor.slice(1, 3), 16) - amount).toString(
    16
  );
  const g = Math.max(0, parseInt(hexColor.slice(3, 5), 16) - amount).toString(
    16
  );
  const b = Math.max(0, parseInt(hexColor.slice(5, 7), 16) - amount).toString(
    16
  );
  return `#${r.padStart(2, "0")}${g.padStart(2, "0")}${b.padStart(2, "0")}`;
}

/** Scroll to a ref while offsetting for a fixed navbar */
function scrollToSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref.current) {
    const offset = 120;
    const elementPosition = ref.current.offsetTop - offset;
    window.scrollTo({ top: elementPosition, behavior: "smooth" });
  }
}

/** Get initials if the tutor has no photo */
function getInitials(firstName: string, lastName: string) {
  const f = firstName?.[0]?.toUpperCase() || "";
  const l = lastName?.[0]?.toUpperCase() || "";
  return f + l;
}

/** Truncate the bio if you wish to limit text */
function truncateBio(bio: string, length: number) {
  return bio.length > length ? bio.slice(0, length) + "..." : bio;
}

/**
 * Renders a booking page specifically for a TUTOR:
 * - Navbar toggle has "Quick Book", "Learn about {tutor.first_name}", "Subjects"
 * - No orgWebPage.bio
 * - Renders SINGLE tutor card instead of TutorsGrid
 * - SubjectsGrid uses tutor.subjects
 */
export function TutorPageContent({
  orgWebPage,
  organization,
  tutor,
  tutorWebPage,
}: {
  orgWebPage: OrganizationWebPageResponse;
  organization: OrganizationPublicResponse;
  tutor: TutorPublicResponse;
  tutorWebPage: TutorWebPageResponse;
}) {
  const [activeSection, setActiveSection] = useState<
    "quick" | "about" | "subjects"
  >("quick");

  const bookingRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const subjectsRef = useRef<HTMLDivElement>(null);

  // Basic data for the single tutor card
  const photoUrl = tutorWebPage?.photo_url;
  const bio = tutorWebPage?.bio || "";
  const fullName = `${tutor.first_name} ${tutor.last_name}`.trim();

  return (
    <>
      {/* Navbar */}
      <div className="navbar">
        {/* Left-aligned org/tutor logo (we'll still show the orgWebPage.photo_url) */}
        <div className="nav-left">
          {orgWebPage.photo_url && (
            <a href={`/organizations/${organization.id}`}>
              <img
                className="org-logo"
                src={orgWebPage.photo_url}
                alt={organization.name}
              />
            </a>
          )}
        </div>

        {/* Centered toggle group: Quick Book | Learn about X | Subjects */}
        <div className="nav-center">
          <div
            className="web-page-toggle-container"
            style={{
              backgroundColor: orgWebPage.secondary_color,
              borderColor: darkenColor(orgWebPage.secondary_color),
            }}
          >
            <button
              className={`web-page-toggle-button ${
                activeSection === "about" ? "active" : ""
              }`}
              onClick={() => {
                setActiveSection("about");
                scrollToSection(aboutRef);
              }}
            >
              <strong>
                <span style={{ fontWeight: 700 }}>
                  Learn about {tutor.first_name}
                </span>
              </strong>
            </button>
            <button
              className={`web-page-toggle-button ${
                activeSection === "quick" ? "active" : ""
              }`}
              onClick={() => {
                setActiveSection("quick");
                scrollToSection(bookingRef);
              }}
            >
              <strong>
                <span style={{ fontWeight: 700 }}>Quick Book</span>
              </strong>
            </button>
            <button
              className={`web-page-toggle-button ${
                activeSection === "subjects" ? "active" : ""
              }`}
              onClick={() => {
                setActiveSection("subjects");
                scrollToSection(subjectsRef);
              }}
            >
              <strong>
                <span style={{ fontWeight: 700 }}>Browse Subjects</span>
              </strong>
            </button>
          </div>
        </div>
      </div>

      {/* Single Tutor Card Section */}
      <div className="container mx-auto my-12" ref={aboutRef}>
        <div className="tutor-card max-w-4xl mx-auto">
          <div className="flex flex-row gap-4">
            <div className="tutor-card--image w-1/4">
              {photoUrl ? (
                <img src={photoUrl} alt={fullName} className="tutor-photo" />
              ) : (
                <div
                  className="tutor-initials"
                  style={{ backgroundColor: orgWebPage.primary_color }}
                >
                  <span className="tutor-initials--text">
                    {getInitials(tutor.first_name, tutor.last_name)}
                  </span>
                </div>
              )}
            </div>

            <div className="tutor--details w-3/4">
              <h3
                className="tutor--name"
                style={{ color: orgWebPage.text_color }}
              >
                {fullName}
              </h3>
              {bio && <p className="tutor--bio">{bio}</p>}
            </div>
          </div>

          <div className="tutor-card--footer"></div>
        </div>
      </div>

      <div className="profile_body mt-12">
        <hr className="section-divider" />
        {/* Booking Section */}
        <div className="container mx-auto" ref={bookingRef}>
          <BookingInterface
            availableSubjects={tutor.subjects
              .filter((s) => s.active)
              .map((s) => s.org_subject!)}
            availableTutors={[tutor]}
            orgId={organization.id}
          />
        </div>

        <hr className="section-divider" />

        {/* Subjects Section: uses tutor.subjects */}
        <div className="container mx-auto my-12" ref={subjectsRef}>
          <SubjectsGrid
            subjects={tutor.subjects
              .filter((s) => s.active)
              .map((s) => s.org_subject!)}
            orgWebPage={orgWebPage}
          />
        </div>
      </div>

      {/* Bottom Navbar (same as org) */}
      <div className="bottom-navbar">
        <div className="bottom-left">
          <strong>Contact</strong>
          <br />
          Phone Number: {organization.public_phone_number || "N/A"}
          <br />
          Email: {organization.public_email || "N/A"}
        </div>
        <div className="bottom-right">
          <strong>Address</strong>
          <br />
          {organization.address_line_1}
          {organization.address_line_2 && `, ${organization.address_line_2}`}
          <br />
          {organization.city}, {organization.state} {organization.zip}
        </div>
      </div>
    </>
  );
}
