import { useState, useEffect } from "react";
import { DialogClose, DialogContent } from "components/Dialog";
import { Button, ButtonColor, ButtonFill } from "components/Button";
import { Select } from "components/Select";
import { ConditionGroupBuilder } from "../search/builder";
import {
  ConditionGroup,
  LogicalOperator,
  ComparisonOperator,
  Auth0AccountRole,
  CRMVisualizationColumnCreate,
  CRMVisualizationColumnUpdate,
  CRMVisualizationResponse,
  CRMVisualizationColumnResponse,
  OrganizationDataKeyResponse,
} from "client/openapi";
import { VisualizationsService } from "client/openapi";
import Notifications from "util/notifications";
import { isSingleEntityQueryValid } from "../search/validation";

type CrmEndpoints = "get_students" | "get_parents";

export default function AddOrEditColumnDialog({
  visualization,
  existingColumn,
  onColumnAdded,
  onColumnUpdated,
  studentKeys,
  parentKeys,
  setIsOpen,
}: {
  visualization: CRMVisualizationResponse;
  existingColumn?: CRMVisualizationColumnResponse;
  onColumnAdded: (column: CRMVisualizationColumnResponse) => void;
  onColumnUpdated: (column: CRMVisualizationColumnResponse) => void;
  studentKeys: OrganizationDataKeyResponse[];
  parentKeys: OrganizationDataKeyResponse[];
  setIsOpen: (open: boolean) => void;
}) {
  const [name, setName] = useState(existingColumn?.name || "");
  const [position, setPosition] = useState(
    existingColumn?.order_index || visualization.columns.length + 1
  );
  const [endpoint, setEndpoint] = useState<CrmEndpoints>(() => {
    if (existingColumn) {
      return existingColumn.column_type === Auth0AccountRole.PARENT
        ? "get_parents"
        : "get_students";
    } else if (visualization.columns.length > 0) {
      return visualization.columns[0].column_type === Auth0AccountRole.PARENT
        ? "get_parents"
        : "get_students";
    }
    return "get_students";
  });

  const [condGroup, setCondGroup] = useState<ConditionGroup>(
    (existingColumn?.query as ConditionGroup) || {
      operator: LogicalOperator.AND,
      conditions: [
        {
          org_data_key_id: 0,
          comparison_operator: ComparisonOperator.EQUALS,
          value: "",
          negate: false,
        },
      ],
      negate: false,
    }
  );

  const [isFormDisabled, setIsFormDisabled] = useState(false);

  useEffect(() => {
    if (existingColumn) {
      setName(existingColumn.name);
      setPosition(existingColumn.order_index);
      setEndpoint(
        existingColumn.column_type === Auth0AccountRole.PARENT
          ? "get_parents"
          : "get_students"
      );
      setCondGroup(existingColumn.query as ConditionGroup);
    } else {
      setName("");
      setPosition(visualization.columns.length + 1);
      setEndpoint(
        visualization.columns.length > 0 &&
          visualization.columns[0].column_type === Auth0AccountRole.PARENT
          ? "get_parents"
          : "get_students"
      );
      setCondGroup({
        operator: LogicalOperator.AND,
        conditions: [
          {
            org_data_key_id: 0,
            comparison_operator: ComparisonOperator.EQUALS,
            value: "",
            negate: false,
          },
        ],
        negate: false,
      });
    }
  }, [visualization, existingColumn]);

  const handleSubmit = async () => {
    try {
      if (!name.trim()) {
        return Notifications.error("Column name is required");
      }
      const availableKeys =
        endpoint === "get_parents" ? parentKeys : studentKeys;
      if (!isSingleEntityQueryValid(condGroup, availableKeys)) {
        return Notifications.error("Please fill in all required query fields");
      }
      if (existingColumn) {
        const updateData: CRMVisualizationColumnUpdate = {
          name: name.trim(),
          order_index: position,
          column_type:
            endpoint === "get_parents"
              ? Auth0AccountRole.PARENT
              : Auth0AccountRole.ME,
          query: condGroup,
        };
        VisualizationsService.updateVisualizationColumn({
          visualizationId: visualization.id,
          columnId: existingColumn.id,
          requestBody: updateData,
        })
          .then((updatedColumn) => {
            onColumnUpdated(updatedColumn);
            Notifications.success(
              `Column "${existingColumn.name}" updated successfully!`
            );
          })
          .catch((error) => {
            console.error("Error updating column:", error);
            Notifications.error(
              `Could not update column ${existingColumn.name}`
            );
          });
      } else {
        const createData: CRMVisualizationColumnCreate = {
          visualization_id: visualization.id,
          name: name.trim(),
          order_index: position,
          query: condGroup,
          column_type:
            endpoint === "get_parents"
              ? Auth0AccountRole.PARENT
              : Auth0AccountRole.ME,
        };
        VisualizationsService.createVisualizationColumn({
          visualizationId: visualization.id,
          requestBody: createData,
        })
          .then((newColumn) => {
            onColumnAdded(newColumn);
            Notifications.success(
              `Column "${name.trim()}" created successfully!`
            );
          })
          .catch((error) => {
            console.error("Error creating column:", error);
            Notifications.error(`Could not create column ${name.trim()}`);
          });
      }
      setIsOpen(false);
    } catch (error) {
      console.error("Error saving column:", error);
      Notifications.error(
        `Failed to ${existingColumn ? "update" : "create"} column`
      );
    }
  };

  const endpointOptions = [
    { value: "get_students", label: "Get Students" },
    { value: "get_parents", label: "Get Parents" },
  ];

  function computeDisabled(): boolean {
    const hasName = name.trim().length > 0;
    const hasValidPosition = position > 0;
    const hasValidQuery = isSingleEntityQueryValid(
      condGroup,
      endpoint === "get_parents" ? parentKeys : studentKeys
    );
    if (position < 1) {
      Notifications.error("Position must be at least 1");
      return true;
    }
    if (existingColumn && position > visualization.columns.length) {
      Notifications.error(
        `Position cannot be greater than ${visualization.columns.length}`
      );
      return true;
    }

    if (!existingColumn && position > visualization.columns.length + 1) {
      Notifications.error(
        `Position cannot be greater than ${visualization.columns.length + 1}`
      );
      return true;
    }
    if (!hasName || !hasValidPosition || !hasValidQuery) {
      return true;
    }
    if (existingColumn) {
      const nameChanged = name.trim() !== existingColumn.name;
      const positionChanged = position !== existingColumn.order_index;
      const endpointChanged =
        (endpoint === "get_parents"
          ? Auth0AccountRole.PARENT
          : Auth0AccountRole.ME) !== existingColumn.column_type;
      const queryChanged =
        JSON.stringify(condGroup) !== JSON.stringify(existingColumn.query);
      return (
        !nameChanged && !positionChanged && !endpointChanged && !queryChanged
      );
    }
    return false;
  }

  useEffect(() => {
    setIsFormDisabled(computeDisabled());
  }, [name, position, endpoint, condGroup, existingColumn]);

  return (
    <DialogContent className="visualization-column-dialog-content">
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-4">
          {existingColumn ? "Edit Column" : "Add Column"}
        </h2>
        <div className="space-y-4">
          <div className="flex gap-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Column Name
              </label>
              <input
                type="text"
                className="input w-full"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter column name"
              />
            </div>
            <div className="w-16">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Position
              </label>
              <input
                type="number"
                className="input w-full"
                value={position}
                onChange={(e) => setPosition(parseInt(e.target.value))}
                placeholder="Enter position"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Query Type
            </label>
            <Select
              value={
                endpointOptions.find((opt) => opt.value === endpoint) || null
              }
              onChange={(opt) => {
                if (opt) setEndpoint(opt.value as CrmEndpoints);
              }}
              options={endpointOptions}
              isClearable={false}
            />
          </div>
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Query Conditions
            </label>
            <ConditionGroupBuilder
              value={condGroup}
              onChange={setCondGroup}
              availableKeys={
                endpoint === "get_parents" ? parentKeys : studentKeys
              }
            />
          </div>
        </div>
        <div className="flex justify-center gap-2 mt-4">
          <Button
            color={isFormDisabled ? ButtonColor.GRAY : ButtonColor.PURPLE}
            onClick={handleSubmit}
            disabled={isFormDisabled}
          >
            {existingColumn ? "Update" : "Add"}
          </Button>
          <DialogClose>
            <Button
              fill={ButtonFill.HOLLOW}
              color={ButtonColor.BLACK}
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </DialogClose>
        </div>
      </div>
    </DialogContent>
  );
}
