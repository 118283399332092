/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskCreate } from "../models/TaskCreate";
import type { TaskResponse } from "../models/TaskResponse";
import type { TaskUpdate } from "../models/TaskUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class TasksService {
  /**
   * Get Org Tasks
   * Get all tasks for an organization, optionally filtered by status.
   * @returns TaskResponse Successful Response
   * @throws ApiError
   */
  public static getOrgTasks({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<TaskResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tasks/org/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Tasks By Account
   * Get all tasks assigned to a user's account or linked students, parents, or tutors.
   * @returns TaskResponse Successful Response
   * @throws ApiError
   */
  public static getTasksByAccount(): CancelablePromise<Array<TaskResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tasks/account",
    });
  }

  /**
   * Get Tasks For Students Of Account
   * An account can be linked to a tutor, and that tutor can have students.
   * An account can also be linked to a parent, and that parent can have students.
   *
   * This gets all tasks for students linked to the account via its roles as a tutor
   * or parent.
   * @returns TaskResponse Successful Response
   * @throws ApiError
   */
  public static getTasksForStudentsOfAccount(): CancelablePromise<
    Array<TaskResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/tasks/students_of_account",
    });
  }

  /**
   * Create Task
   * Create a new task.
   * @returns TaskResponse Successful Response
   * @throws ApiError
   */
  public static createTask({
    requestBody,
  }: {
    requestBody: TaskCreate;
  }): CancelablePromise<TaskResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/tasks",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Task
   * Update an existing task.
   * @returns TaskResponse Successful Response
   * @throws ApiError
   */
  public static updateTask({
    taskId,
    requestBody,
  }: {
    taskId: number;
    requestBody: TaskUpdate;
  }): CancelablePromise<TaskResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/tasks/{task_id}",
      path: {
        task_id: taskId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Task
   * Delete a task.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteTask({
    taskId,
  }: {
    taskId: number;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/tasks/{task_id}",
      path: {
        task_id: taskId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
