import { BarChartIcon } from "@radix-ui/react-icons";
import {
  ApiError,
  Auth0AccountRole,
  ChartsService,
  MeetingStats,
  ChartSubscriptionResponse,
  OrganizationResponseForAdmins,
  OrganizationsService,
  StatisticsService,
} from "client/openapi";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import "./index.css";
import TabNavbar from "components/TabNavbar";
import { IoFolderOutline } from "react-icons/io5";
import { MdOutlineAttachMoney } from "react-icons/md";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import CreateChartDialog from "./Metrics/createChartDialog";
import { Dialog, DialogTrigger } from "components/Dialog";
import Metrics from "./Metrics";
import OrgPayments from "./PaymentsTab/PaymentsTab";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import CRM from "./CRM";

export default function OrgPerformance() {
  const { currently_selected_role, currently_selected_organization } =
    useContext(OrgRolesAndAccountContext);

  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();

  const [organization, setOrganization] =
    useState<OrganizationResponseForAdmins>();

  const [meetingStats, setMeetingStats] = useState<MeetingStats>();
  const [meetingStatsMonth, setMeetingStatsMonth] = useState<MeetingStats>();

  const [activeTab, setActiveTab] = useState<string>("Financials");
  const [charts, setCharts] = useState<ChartSubscriptionResponse[]>();
  const [updateCharts, setUpdateCharts] = useState<boolean>(false);

  const options = [
    { value: "Financials", icon: MdOutlineAttachMoney },
    { value: "CRM", icon: IoFolderOutline },
    { value: "Metrics", icon: BarChartIcon },
  ];

  // useEffect to check URL hash, navigates the user to the appropriate tab
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#financials") {
      setActiveTab("Financials");
    }

    if (hash === "#crm") {
      setActiveTab("CRM");
    }

    if (hash === "#metrics") {
      setActiveTab("Metrics");
    }
  }, []);

  async function fetchCharts(orgId: number) {
    await ChartsService.getAllAggregateChartSubscriptionsInOrg({
      orgId: orgId,
    })
      .then((charts) => {
        setCharts(charts);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function fetchMeetingStats(orgId: number) {
    await StatisticsService.getOrgMeetingStats({
      orgId: orgId,
      start: moment().subtract(1, "year").toISOString(),
      until: moment().toISOString(),
      tz: moment.tz.guess(true),
    }).then((data) => {
      setMeetingStats(data);
    });

    await StatisticsService.getOrgMeetingStats({
      orgId: orgId,
      start: moment().startOf("month").toISOString(),
      until: moment().toISOString(),
      tz: moment.tz.guess(true),
    }).then((data) => {
      setMeetingStatsMonth(data);
    });
  }

  useEffect(() => {
    if (currently_selected_organization) {
      OrganizationsService.getOrganization({
        orgId: currently_selected_organization,
      })
        .then((org) => {
          setStatus(PageStatus.SUCCESS);
          setOrganization(org as OrganizationResponseForAdmins);
          fetchMeetingStats(org.id);
          fetchCharts(org.id);
        })
        .catch((err) => {
          setError({ message: "Could not get org" });
          console.error(`Error ${err}`);
        });
    }
  }, [currently_selected_organization]);

  useEffect(() => {
    if (organization) {
      fetchCharts(organization.id);
    }
  }, [organization, updateCharts]);

  if (currently_selected_role !== Auth0AccountRole.ORG_ADMIN) {
    return (
      <ErrorBlock
        status={PageStatus.ERROR}
        title="Unauthorized"
        message="You do not have permission to access this page."
      />
    );
  }

  return (
    <div className="container-fluid mx-10 page--student-data">
      <LoadingBlock status={status} />
      <ErrorBlock status={status} message={error?.message} />

      {organization && (
        <>
          <div className="page--header">
            <TabNavbar
              activeTab={activeTab}
              handleTabClick={(tab) => {
                setActiveTab(tab);
              }}
              options={options}
            />
          </div>

          {activeTab === "Financials" && (
            <div>
              <OrgPayments orgId={organization.id} />
            </div>
          )}

          {activeTab === "CRM" && (
            <>
              <CRM organization={organization} />
            </>
          )}

          {activeTab === "Metrics" &&
            charts &&
            meetingStats &&
            meetingStatsMonth && (
              <>
                <div className="ml-5 md:ml-0 mb-5 flex flex-row gap-x-5">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        color={ButtonColor.SKYBLUE}
                        size={ButtonSize.SMALL}
                      >
                        Add New Chart +
                      </Button>
                    </DialogTrigger>

                    <CreateChartDialog
                      organization={organization}
                      updateCharts={updateCharts}
                      setUpdateCharts={setUpdateCharts}
                    />
                  </Dialog>
                </div>

                <Metrics
                  charts={charts}
                  meetingStats={meetingStats}
                  meetingStatsMonth={meetingStatsMonth}
                  organization={organization}
                  updateCharts={updateCharts}
                  setUpdateCharts={setUpdateCharts}
                />
              </>
            )}
        </>
      )}
    </div>
  );
}
