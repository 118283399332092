import { useEffect, useState } from "react";
import {
  ApiError,
  CustomDataTypeKeyResponse,
  ManageCrmService,
} from "client/openapi";
import {
  DialogClose,
  DialogContent,
  DialogActions,
  DialogAction,
} from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import Notifications from "util/notifications";
import { PageStatus } from "types";
import { TrashIcon } from "@radix-ui/react-icons";

export default function EditCustomType({
  keyId,
  currentName,
  keys,
  setKeys,
}: {
  keyId: number;
  currentName: string;
  keys: CustomDataTypeKeyResponse[];
  setKeys: (keys: CustomDataTypeKeyResponse[]) => void;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<ApiError>();
  const [name, setName] = useState<string>(currentName);
  const existingCustomFieldKey = keys.find((k) => k.id === keyId);

  // We'll track each "option" row in an array of objects
  const [existingOptions, setExistingOptions] = useState<
    Array<{
      id: number;
      option_name: string;
      isEditing: boolean;
      newValueIfEdited: string;
      isDeleted: boolean;
    }>
  >([]);

  // Track new lines typed into a separate text area for brand-new options
  const [newOptionsText, setNewOptionsText] = useState("");

  // ---------- Lifecycle: Initialize local state from orgDataKey ----------
  useEffect(() => {
    // Map out the existing values into local array
    const mappedOptions =
      existingCustomFieldKey?.values?.map((v) => ({
        id: v.id,
        option_name: v.option_name,
        isEditing: false,
        isDeleted: false,
        newValueIfEdited: v.option_name,
      })) || [];
    setExistingOptions(mappedOptions);
  }, [existingCustomFieldKey?.values]);

  // ---------- "Save" logic: Update custom field key name, edit/delete existing, add new ----------
  async function handleSave() {
    if (!existingCustomFieldKey) {
      handleCancel();
      return;
    }
    setStatus(PageStatus.LOADING);

    try {
      // (1) Update the key name if changed
      if (name !== currentName) {
        await ManageCrmService.updateCustomDataTypeKey({
          keyId: existingCustomFieldKey.id,
          requestBody: {
            name: name,
          },
        });
      }

      // (2) For each option row: check if updated or deleted
      const promises: Promise<any>[] = [];
      existingOptions.forEach((o) => {
        if (o.isDeleted) {
          // Marked for deletion
          promises.push(
            ManageCrmService.deleteCustomDataTypeValue({ valueId: o.id })
          );
        } else if (
          o.newValueIfEdited.trim() &&
          o.newValueIfEdited !== o.option_name
        ) {
          // If user changed the name
          promises.push(
            ManageCrmService.updateCustomDataTypeValue({
              valueId: o.id,
              requestBody: { option_name: o.newValueIfEdited.trim() },
            })
          );
        }
      });

      // (3) For newly added lines
      const newLines = newOptionsText
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line.length > 0);

      if (newLines.length > 0) {
        const payload = newLines.map((val) => ({
          custom_data_type_key_id: existingCustomFieldKey.id,
          option_name: val,
        }));
        promises.push(
          ManageCrmService.createCustomDataTypeValue({
            requestBody: payload,
          })
        );
      }

      // Run all requests in parallel
      await Promise.all(promises);

      // Update the local keys state with the modified key
      const updatedKeys = keys.map((k) => {
        if (k.id === keyId) {
          // Get the non-deleted existing options
          const remainingOptions = existingOptions
            .filter((o) => !o.isDeleted)
            .map((o) => ({
              id: o.id,
              option_name: o.newValueIfEdited.trim() || o.option_name,
              custom_data_type_key_id: keyId,
            }));

          // Get any new options from the text area
          const newLines = newOptionsText
            .split("\n")
            .map((line) => line.trim())
            .filter((line) => line.length > 0);

          // Create placeholder IDs for new values (will be replaced by server response)
          const newValues = newLines.map((line, idx) => ({
            id: -1 - idx, // Temporary negative IDs to avoid conflicts
            option_name: line,
            custom_data_type_key_id: keyId,
          }));

          return {
            ...k,
            name: name,
            values: [...remainingOptions, ...newValues],
          };
        }
        return k;
      });

      setKeys(updatedKeys);
      setNewOptionsText("");
      setStatus(PageStatus.SUCCESS);
      Notifications.success("Custom field updated!");
    } catch (e: ApiError | any) {
      setStatus(PageStatus.ERROR);
      setError(e);
      console.error(e);
    }
  }

  // ---------- "Cancel" logic: revert changes / close ----------
  function handleCancel() {
    // Optional: revert local changes before closing
    setName(currentName);
    setExistingOptions(
      keys
        .find((k) => k.id === keyId)
        ?.values?.map((v) => ({
          id: v.id,
          option_name: v.option_name,
          isEditing: false,
          isDeleted: false,
          newValueIfEdited: v.option_name,
        })) || []
    );
    setNewOptionsText("");
  }

  // ---------- track whether anything changed to enable "Save" button ----------
  const somethingChanged = (() => {
    if (!existingCustomFieldKey) return false;
    if (name !== currentName) return true;

    for (const o of existingOptions) {
      if (o.isDeleted) return true;
      if (o.newValueIfEdited.trim() !== o.option_name.trim()) return true;
    }

    const newLines = newOptionsText
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0);

    if (newLines.length > 0) return true;
    return false;
  })();

  useEffect(() => {
    if (error) {
      Notifications.error(
        error?.body?.detail || "An unexpected error occurred."
      );
    }
  }, [error]);

  // ---------- local helpers for toggling or marking options ----------
  function toggleEditOption(id: number) {
    setExistingOptions((prev) =>
      prev.map((o) =>
        o.id === id
          ? {
              ...o,
              isEditing: !o.isEditing,
              // Only reset newValueIfEdited when cancelling edit mode
              ...(o.isEditing ? { newValueIfEdited: o.option_name } : {}),
            }
          : o
      )
    );
  }

  function markDeleted(id: number) {
    setExistingOptions((prev) =>
      prev.map((o) => (o.id === id ? { ...o, isDeleted: true } : o))
    );
  }
  function unmarkDeleted(id: number) {
    setExistingOptions((prev) =>
      prev.map((o) => (o.id === id ? { ...o, isDeleted: false } : o))
    );
  }
  function saveDraftName(id: number) {
    setExistingOptions((prev) =>
      prev.map((o) =>
        o.id === id
          ? { ...o, isEditing: false, option_name: o.newValueIfEdited }
          : o
      )
    );
  }

  return (
    <DialogContent
      onClose={handleCancel}
      className="org-data-key-dialog flex flex-col"
    >
      <div className="text-center">
        <h2 className="new-org-data-key--title">Edit Custom Field</h2>
      </div>

      <div className="bg-gray-50 p-3 rounded flex flex-col flex-grow">
        <label htmlFor="customFieldKeyName">
          <strong>Custom Field Key Name</strong>
        </label>
        <input
          id="customFieldKeyName"
          type="text"
          className="input mt-1 mb-4"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {/* Existing Options */}
        <div className="mb-2">
          <strong>Existing Values</strong>
        </div>
        <div
          className="scrollable-table"
          style={{ maxHeight: 400, overflow: "auto" }}
        >
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b">
                <th className="text-left py-2">Value</th>
                <th className="text-right py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {existingOptions.map((opt) => {
                if (opt.isDeleted) {
                  return (
                    <tr key={opt.id} className="text-gray-400 line-through">
                      <td className="py-1">{opt.newValueIfEdited}</td>
                      <td className="py-1 text-right">
                        <button
                          type="button"
                          className="mr-2"
                          style={{ color: "#ac98fc" }}
                          onClick={() => unmarkDeleted(opt.id)}
                        >
                          Undo
                        </button>
                      </td>
                    </tr>
                  );
                }

                return (
                  <tr key={opt.id}>
                    <td className="py-2">
                      {opt.isEditing ? (
                        <input
                          type="text"
                          className="input w-1/2 h-6 p-0 mt-0 mr-0 ml-0 mb-4"
                          value={opt.newValueIfEdited}
                          onChange={(e) =>
                            setExistingOptions((prev) =>
                              prev.map((row) =>
                                row.id === opt.id
                                  ? {
                                      ...row,
                                      newValueIfEdited: e.target.value,
                                    }
                                  : row
                              )
                            )
                          }
                        />
                      ) : (
                        opt.newValueIfEdited
                      )}
                    </td>
                    <td className="py-1 flex justify-end">
                      {opt.isEditing ? (
                        <div className="mr-2 flex gap-2">
                          <Button
                            size={ButtonSize.EXTRA_SMALL}
                            color="#ac98fc"
                            onClick={() => saveDraftName(opt.id)}
                          >
                            Save
                          </Button>
                          <Button
                            size={ButtonSize.EXTRA_SMALL}
                            fill={ButtonFill.HOLLOW}
                            color="#ac98fc"
                            onClick={() => toggleEditOption(opt.id)}
                          >
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <div className="mr-2 flex gap-2">
                          <Button
                            size={ButtonSize.EXTRA_SMALL}
                            color="#ac98fc"
                            onClick={() => toggleEditOption(opt.id)}
                          >
                            Edit
                          </Button>
                          <Button
                            color="#ac98fc"
                            fill={ButtonFill.HOLLOW}
                            size={ButtonSize.EXTRA_SMALL}
                            onClick={() => markDeleted(opt.id)}
                          >
                            <TrashIcon />
                          </Button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
              {existingOptions.length === 0 && (
                <tr>
                  <td colSpan={2} className="text-center py-2">
                    No existing values
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* New Values */}
        <div className="mt-4">
          <label htmlFor="newOptionsText">
            <strong>Add New Values (one per line)</strong>
          </label>
          <textarea
            id="newOptionsText"
            className="input mt-1"
            style={{ minHeight: "150px" }}
            value={newOptionsText}
            onChange={(e) => setNewOptionsText(e.target.value)}
          />
        </div>

        <div className="mt-auto pt-4 border-t">
          <DialogActions>
            <DialogAction
              primary
              color={
                status === PageStatus.LOADING || !somethingChanged
                  ? ButtonColor.GRAY
                  : ButtonColor.PURPLE
              }
              disabled={status === PageStatus.LOADING || !somethingChanged}
              onClick={handleSave}
            >
              Save Changes &rarr;
            </DialogAction>

            <DialogClose asChild>
              <DialogAction
                color={ButtonColor.PURPLE}
                disabled={status === PageStatus.LOADING}
                onClick={handleCancel}
              >
                Cancel
              </DialogAction>
            </DialogClose>
          </DialogActions>
        </div>
      </div>
    </DialogContent>
  );
}
