/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApprovalStatus } from "../models/ApprovalStatus";
import type { MeetingAdminResponse } from "../models/MeetingAdminResponse";
import type { MeetingCreate } from "../models/MeetingCreate";
import type { MeetingCreatePublicBookingPage } from "../models/MeetingCreatePublicBookingPage";
import type { MeetingLimitedResponse } from "../models/MeetingLimitedResponse";
import type { MeetingStatus } from "../models/MeetingStatus";
import type { MeetingStudentAdminResponse } from "../models/MeetingStudentAdminResponse";
import type { MeetingStudentBillingStatus } from "../models/MeetingStudentBillingStatus";
import type { MeetingStudentLimitedResponse } from "../models/MeetingStudentLimitedResponse";
import type { MeetingStudentUpdate } from "../models/MeetingStudentUpdate";
import type { MeetingTutorAdminResponse } from "../models/MeetingTutorAdminResponse";
import type { MeetingTutorLimitedResponse } from "../models/MeetingTutorLimitedResponse";
import type { MeetingTutorPaymentStatus } from "../models/MeetingTutorPaymentStatus";
import type { MeetingTutorUpdate } from "../models/MeetingTutorUpdate";
import type { MeetingUpdate } from "../models/MeetingUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class MeetingsService {
  /**
   * Get Meetings By Tutor
   * Get meetings for a tutor in a specified timeframe from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getMeetingsByTutor({
    tutorId,
    start,
    until,
    name,
    locationId,
    otherLocation,
    orgSubjectId,
    reviewedAndApprovedByAdmin,
    reviewedAndApprovedByAllTutors,
    meetingAcceptedByAllTutors,
    tutorPaid,
    meetingAcceptedByThisTutor,
    meetingReviewedAndApprovedByThisTutor,
    skip,
    limit = 1000,
  }: {
    tutorId: number;
    start?: string | null;
    until?: string | null;
    name?: string | null;
    locationId?: number | null;
    otherLocation?: string | null;
    orgSubjectId?: number | null;
    reviewedAndApprovedByAdmin?: ApprovalStatus | null;
    reviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    meetingAcceptedByAllTutors?: MeetingStatus | null;
    tutorPaid?: MeetingTutorPaymentStatus | null;
    meetingAcceptedByThisTutor?: MeetingStatus | null;
    meetingReviewedAndApprovedByThisTutor?: ApprovalStatus | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<MeetingLimitedResponse | MeetingAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/tutors/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        start: start,
        until: until,
        name: name,
        location_id: locationId,
        other_location: otherLocation,
        org_subject_id: orgSubjectId,
        reviewed_and_approved_by_admin: reviewedAndApprovedByAdmin,
        reviewed_and_approved_by_all_tutors: reviewedAndApprovedByAllTutors,
        meeting_accepted_by_all_tutors: meetingAcceptedByAllTutors,
        tutor_paid: tutorPaid,
        meeting_accepted_by_this_tutor: meetingAcceptedByThisTutor,
        meeting_reviewed_and_approved_by_this_tutor:
          meetingReviewedAndApprovedByThisTutor,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings For All Tutors For Account
   * Get meetings in a specified timeframe from the database for all tutors tied to an account.
   * @returns MeetingLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsForAllTutorsForAccount({
    start,
    until,
    name,
    locationId,
    otherLocation,
    orgSubjectId,
    reviewedAndApprovedByAdmin,
    reviewedAndApprovedByAllTutors,
    meetingAcceptedByAllTutors,
    tutorPaid,
    meetingAcceptedByThisTutor,
    meetingReviewedAndApprovedByThisTutor,
    skip,
    limit = 1000,
  }: {
    start?: string | null;
    until?: string | null;
    name?: string | null;
    locationId?: number | null;
    otherLocation?: string | null;
    orgSubjectId?: number | null;
    reviewedAndApprovedByAdmin?: ApprovalStatus | null;
    reviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    meetingAcceptedByAllTutors?: MeetingStatus | null;
    tutorPaid?: MeetingTutorPaymentStatus | null;
    meetingAcceptedByThisTutor?: MeetingStatus | null;
    meetingReviewedAndApprovedByThisTutor?: ApprovalStatus | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<MeetingLimitedResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/account/tutors",
      query: {
        start: start,
        until: until,
        name: name,
        location_id: locationId,
        other_location: otherLocation,
        org_subject_id: orgSubjectId,
        reviewed_and_approved_by_admin: reviewedAndApprovedByAdmin,
        reviewed_and_approved_by_all_tutors: reviewedAndApprovedByAllTutors,
        meeting_accepted_by_all_tutors: meetingAcceptedByAllTutors,
        tutor_paid: tutorPaid,
        meeting_accepted_by_this_tutor: meetingAcceptedByThisTutor,
        meeting_reviewed_and_approved_by_this_tutor:
          meetingReviewedAndApprovedByThisTutor,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Student
   * Get meetings for a student in a specified timeframe from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getMeetingsByStudent({
    studentId,
    start,
    until,
    name,
    locationId,
    otherLocation,
    orgSubjectId,
    reviewedAndApprovedByAdmin,
    reviewedAndApprovedByAllTutors,
    meetingAcceptedByAllTutors,
    studentBilled,
    attended,
    skip,
    limit = 1000,
  }: {
    studentId: number;
    start?: string | null;
    until?: string | null;
    name?: string | null;
    locationId?: number | null;
    otherLocation?: string | null;
    orgSubjectId?: number | null;
    reviewedAndApprovedByAdmin?: ApprovalStatus | null;
    reviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    meetingAcceptedByAllTutors?: MeetingStatus | null;
    studentBilled?: MeetingStudentBillingStatus | null;
    attended?: boolean | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<MeetingLimitedResponse | MeetingAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/students/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        start: start,
        until: until,
        name: name,
        location_id: locationId,
        other_location: otherLocation,
        org_subject_id: orgSubjectId,
        reviewed_and_approved_by_admin: reviewedAndApprovedByAdmin,
        reviewed_and_approved_by_all_tutors: reviewedAndApprovedByAllTutors,
        meeting_accepted_by_all_tutors: meetingAcceptedByAllTutors,
        student_billed: studentBilled,
        attended: attended,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings For All Students For Account
   * Get meetings in a specified timeframe from the database for all tutors tied to an account.
   * @returns MeetingLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsForAllStudentsForAccount({
    start,
    until,
    name,
    locationId,
    otherLocation,
    orgSubjectId,
    reviewedAndApprovedByAdmin,
    reviewedAndApprovedByAllTutors,
    meetingAcceptedByAllTutors,
    studentBilled,
    attended,
    skip,
    limit = 1000,
  }: {
    start?: string | null;
    until?: string | null;
    name?: string | null;
    locationId?: number | null;
    otherLocation?: string | null;
    orgSubjectId?: number | null;
    reviewedAndApprovedByAdmin?: ApprovalStatus | null;
    reviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    meetingAcceptedByAllTutors?: MeetingStatus | null;
    studentBilled?: MeetingStudentBillingStatus | null;
    attended?: boolean | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<MeetingLimitedResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/account/students",
      query: {
        start: start,
        until: until,
        name: name,
        location_id: locationId,
        other_location: otherLocation,
        org_subject_id: orgSubjectId,
        reviewed_and_approved_by_admin: reviewedAndApprovedByAdmin,
        reviewed_and_approved_by_all_tutors: reviewedAndApprovedByAllTutors,
        meeting_accepted_by_all_tutors: meetingAcceptedByAllTutors,
        student_billed: studentBilled,
        attended: attended,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Parent
   * Get meetings for a parent's students in a specified timeframe from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getMeetingsByParent({
    parentId,
    start,
    until,
    name,
    locationId,
    otherLocation,
    orgSubjectId,
    reviewedAndApprovedByAdmin,
    reviewedAndApprovedByAllTutors,
    meetingAcceptedByAllTutors,
    studentBilled,
    attended,
    skip,
    limit = 1000,
  }: {
    parentId: number;
    start?: string | null;
    until?: string | null;
    name?: string | null;
    locationId?: number | null;
    otherLocation?: string | null;
    orgSubjectId?: number | null;
    reviewedAndApprovedByAdmin?: ApprovalStatus | null;
    reviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    meetingAcceptedByAllTutors?: MeetingStatus | null;
    studentBilled?: MeetingStudentBillingStatus | null;
    attended?: boolean | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<MeetingLimitedResponse | MeetingAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/parents/{parent_id}",
      path: {
        parent_id: parentId,
      },
      query: {
        start: start,
        until: until,
        name: name,
        location_id: locationId,
        other_location: otherLocation,
        org_subject_id: orgSubjectId,
        reviewed_and_approved_by_admin: reviewedAndApprovedByAdmin,
        reviewed_and_approved_by_all_tutors: reviewedAndApprovedByAllTutors,
        meeting_accepted_by_all_tutors: meetingAcceptedByAllTutors,
        student_billed: studentBilled,
        attended: attended,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings For All Parents For Account
   * Get meetings in a specified timeframe from the database for all
   * parents (specifically their students) tied to an account.
   * @returns MeetingLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsForAllParentsForAccount({
    start,
    until,
    name,
    locationId,
    otherLocation,
    orgSubjectId,
    reviewedAndApprovedByAdmin,
    reviewedAndApprovedByAllTutors,
    meetingAcceptedByAllTutors,
    studentBilled,
    attended,
    skip,
    limit = 1000,
  }: {
    start?: string | null;
    until?: string | null;
    name?: string | null;
    locationId?: number | null;
    otherLocation?: string | null;
    orgSubjectId?: number | null;
    reviewedAndApprovedByAdmin?: ApprovalStatus | null;
    reviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    meetingAcceptedByAllTutors?: MeetingStatus | null;
    studentBilled?: MeetingStudentBillingStatus | null;
    attended?: boolean | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<MeetingLimitedResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/account/parents",
      query: {
        start: start,
        until: until,
        name: name,
        location_id: locationId,
        other_location: otherLocation,
        org_subject_id: orgSubjectId,
        reviewed_and_approved_by_admin: reviewedAndApprovedByAdmin,
        reviewed_and_approved_by_all_tutors: reviewedAndApprovedByAllTutors,
        meeting_accepted_by_all_tutors: meetingAcceptedByAllTutors,
        student_billed: studentBilled,
        attended: attended,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Org
   * Get meetings for an org in a specified timeframe from the database.
   * @returns MeetingAdminResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsByOrg({
    orgId,
    start,
    until,
    name,
    locationId,
    otherLocation,
    orgSubjectId,
    reviewedAndApprovedByAdmin,
    reviewedAndApprovedByAllTutors,
    meetingAcceptedByAllTutors,
    skip,
    limit = 1000,
  }: {
    orgId: number;
    start?: string | null;
    until?: string | null;
    name?: string | null;
    locationId?: number | null;
    otherLocation?: string | null;
    orgSubjectId?: number | null;
    reviewedAndApprovedByAdmin?: ApprovalStatus | null;
    reviewedAndApprovedByAllTutors?: ApprovalStatus | null;
    meetingAcceptedByAllTutors?: MeetingStatus | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<Array<MeetingAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
        name: name,
        location_id: locationId,
        other_location: otherLocation,
        org_subject_id: orgSubjectId,
        reviewed_and_approved_by_admin: reviewedAndApprovedByAdmin,
        reviewed_and_approved_by_all_tutors: reviewedAndApprovedByAllTutors,
        meeting_accepted_by_all_tutors: meetingAcceptedByAllTutors,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Session
   * Get meetings by session.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getMeetingsBySession({
    sessionId,
    start,
    until,
  }: {
    sessionId: number;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<Array<MeetingLimitedResponse | MeetingAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/sessions/{session_id}",
      path: {
        session_id: sessionId,
      },
      query: {
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meeting By Id
   * Get a meeting by id.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getMeetingById({
    meetingId,
  }: {
    meetingId: number;
  }): CancelablePromise<MeetingLimitedResponse | MeetingAdminResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/meetings/get_single_meeting/{meeting_id}",
      path: {
        meeting_id: meetingId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Meeting
   * Create a meeting or series of meetings.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createMeeting({
    requestBody,
  }: {
    requestBody: MeetingCreate;
  }): CancelablePromise<Array<MeetingLimitedResponse | MeetingAdminResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/meetings",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Edit Meeting
   * Create a session_edit and return the resulting meetings from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static editMeeting({
    meetingId,
    requestBody,
  }: {
    meetingId: number;
    requestBody: MeetingUpdate;
  }): CancelablePromise<Array<MeetingLimitedResponse | MeetingAdminResponse>> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/meetings/edits/{meeting_id}",
      path: {
        meeting_id: meetingId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Edit Meeting Student
   * Create a meeting_student_edit and return the resulting meeting_students from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static editMeetingStudent({
    meetingStudentId,
    requestBody,
  }: {
    meetingStudentId: number;
    requestBody: MeetingStudentUpdate;
  }): CancelablePromise<
    Array<MeetingStudentLimitedResponse | MeetingStudentAdminResponse>
  > {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/meetings/edits/meeting_student/{meeting_student_id}",
      path: {
        meeting_student_id: meetingStudentId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Edit Meeting Tutor
   * Create a meeting_tutor_edit and return the resulting meeting_tutors from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static editMeetingTutor({
    meetingTutorId,
    requestBody,
  }: {
    meetingTutorId: number;
    requestBody: MeetingTutorUpdate;
  }): CancelablePromise<
    Array<MeetingTutorLimitedResponse | MeetingTutorAdminResponse>
  > {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/meetings/edits/meeting_tutor/{meeting_tutor_id}",
      path: {
        meeting_tutor_id: meetingTutorId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Accept Or Reject Meeting As Tutor
   * Accept or reject a meeting as a tutor. This accepts a single meeting or
   * all future meetings if the meeting is part of a session.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static acceptOrRejectMeetingAsTutor({
    tutorId,
    sessionId,
    accept,
  }: {
    tutorId: number;
    sessionId: number;
    accept: boolean;
  }): CancelablePromise<
    Array<MeetingTutorLimitedResponse | MeetingTutorAdminResponse>
  > {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/meetings/edits/accept_or_reject_meeting_as_tutor/{tutor_id}/{session_id}",
      path: {
        tutor_id: tutorId,
        session_id: sessionId,
      },
      query: {
        accept: accept,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Meeting Through Public Booking Page
   * Create a meeting through the public booking page, optionally creating a new student and parent.
   * Returns success status.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createMeetingThroughPublicBookingPage({
    requestBody,
  }: {
    requestBody: MeetingCreatePublicBookingPage;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/meetings/meeting_create_through_public_booking_page",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
