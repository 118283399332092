import {
  ApiError,
  ManageCrmService,
  OrganizationDataKeyResponse,
} from "client/openapi";

import { ButtonColor } from "components/Button";
import {
  DialogClose,
  DialogContent,
  DialogActions,
  DialogAction,
} from "components/Dialog";
import { useEffect, useRef, useState } from "react";
import { PageStatus } from "types";
import Notifications from "util/notifications";
import { DatePicker } from "rsuite";
import { DataType } from "client/openapi";
import { Select } from "components/Select";

import "../index.css";

// Map for human-friendly data type display
const DATA_TYPE_DISPLAY_MAP: Record<DataType, string> = {
  [DataType.TEXT]: "Text",
  [DataType.FLOAT]: "Number",
  [DataType.DATE]: "Date",
  [DataType.BOOLEAN]: "Yes/No",
  [DataType.CUSTOM_DATA_TYPE]: "Custom",
};

type EditOrgDataKeyDialogProps = {
  keyId: number;
  currentName: string;
  keys: OrganizationDataKeyResponse[];
  setKeys: (keys: OrganizationDataKeyResponse[]) => void;
};

function EditOrgDataKeyDialog({
  keyId,
  currentName,
  keys,
  setKeys,
}: EditOrgDataKeyDialogProps) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<ApiError>();
  const [name, setName] = useState<string>(currentName);
  const dialogRef = useRef<HTMLDivElement>(null);

  // We'll find the existing key so we can pull dataType, min/max if float, etc.
  const existingKey = keys.find((k) => k.id === keyId);

  // Basic data-type states (for FLOAT / DATE):
  const [dataType] = useState<DataType>(
    existingKey?.data_type ?? DataType.TEXT
  );
  const [minValueIfFloat, setMinValueIfFloat] = useState<number | null>(null);
  const [maxValueIfFloat, setMaxValueIfFloat] = useState<number | null>(null);
  const [minValueIfDate, setMinValueIfDate] = useState<string | null>(null);
  const [maxValueIfDate, setMaxValueIfDate] = useState<string | null>(null);

  // Default value states
  const [defaultContentText, setDefaultContentText] = useState<string>("");
  const [defaultContentFloat, setDefaultContentFloat] = useState<number | null>(
    null
  );
  const [defaultContentDate, setDefaultContentDate] = useState<string | null>(
    null
  );
  const [defaultContentBool, setDefaultContentBool] = useState<boolean | null>(
    null
  );
  const [defaultCustomDataTypeValueId, setDefaultCustomDataTypeValueId] =
    useState<number | null>(null);

  useEffect(() => {
    if (existingKey) {
      setMinValueIfFloat(existingKey.min_value_if_float ?? null);
      setMaxValueIfFloat(existingKey.max_value_if_float ?? null);
      setMinValueIfDate(existingKey.min_value_if_date ?? null);
      setMaxValueIfDate(existingKey.max_value_if_date ?? null);
      // Set default values
      setDefaultContentText(existingKey.default_content_text ?? "");
      setDefaultContentFloat(existingKey.default_content_float ?? null);
      setDefaultContentDate(existingKey.default_content_date ?? null);
      setDefaultContentBool(existingKey.default_content_bool ?? null);
      setDefaultCustomDataTypeValueId(
        existingKey.default_custom_data_type_value?.id ?? null
      );
    }
  }, [existingKey]);

  // Render field for default value based on data type
  function renderField() {
    switch (dataType) {
      case DataType.TEXT: {
        return (
          <input
            type="text"
            className="input mt-2"
            placeholder="Default text value"
            value={defaultContentText}
            maxLength={300}
            onChange={(e) => setDefaultContentText(e.target.value)}
          />
        );
      }

      case DataType.FLOAT: {
        return (
          <input
            type="number"
            step="any"
            className="input mt-2"
            placeholder="Default number value"
            value={defaultContentFloat ?? ""}
            min={minValueIfFloat ?? undefined}
            max={maxValueIfFloat ?? undefined}
            onChange={(e) => {
              const val = e.target.value ? parseFloat(e.target.value) : null;
              if (val === null || !isNaN(val)) {
                setDefaultContentFloat(val);
              }
            }}
          />
        );
      }

      case DataType.BOOLEAN: {
        return (
          <Select
            className="mt-2"
            value={
              defaultContentBool === true
                ? { value: "true", label: "Yes" }
                : defaultContentBool === false
                ? { value: "false", label: "No" }
                : null
            }
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            placeholder="--Select default value--"
            onChange={(option) => {
              if (!option) {
                setDefaultContentBool(null);
              } else {
                setDefaultContentBool(option.value === "true");
              }
            }}
            isClearable
          />
        );
      }

      case DataType.DATE: {
        return (
          <DatePicker
            oneTap
            className="input mt-2"
            value={defaultContentDate ? new Date(defaultContentDate) : null}
            size="sm"
            format="yyyy-MM-dd"
            placement="bottomStart"
            container={() => dialogRef.current || document.body}
            onChange={(val) => {
              if (!val) {
                setDefaultContentDate(null);
                return;
              }
              const localStart = new Date(val);
              localStart.setHours(0, 0, 0, 0);
              setDefaultContentDate(localStart.toISOString());
            }}
            cleanable
            block
          />
        );
      }

      case DataType.CUSTOM_DATA_TYPE: {
        if (!existingKey?.custom_data_type_key?.values) return null;

        const customOptions = existingKey.custom_data_type_key.values.map(
          (opt) => ({
            value: opt.id.toString(),
            label: opt.option_name,
          })
        );

        return (
          <Select
            className="mt-2"
            value={
              defaultCustomDataTypeValueId
                ? {
                    value: defaultCustomDataTypeValueId.toString(),
                    label:
                      existingKey.custom_data_type_key.values.find(
                        (v) => v.id === defaultCustomDataTypeValueId
                      )?.option_name || "",
                  }
                : null
            }
            options={customOptions}
            placeholder="--Select default value--"
            onChange={(option) => {
              setDefaultCustomDataTypeValueId(
                option ? parseInt(option.value, 10) : null
              );
            }}
            isClearable
          />
        );
      }

      default:
        return null;
    }
  }

  // -----------------------------
  //  Submitting the updates
  // -----------------------------
  async function updateKey() {
    setStatus(PageStatus.LOADING);

    // 1) Update the basic OrgDataKey (e.g. name, min_value_if_date, etc.)
    const requestBody = {
      name: name,
      min_value_if_float: dataType === DataType.FLOAT ? minValueIfFloat : null,
      max_value_if_float: dataType === DataType.FLOAT ? maxValueIfFloat : null,
      min_value_if_date: dataType === DataType.DATE ? minValueIfDate : null,
      max_value_if_date: dataType === DataType.DATE ? maxValueIfDate : null,
      // Add default values
      default_content_text:
        dataType === DataType.TEXT ? defaultContentText : null,
      default_content_float:
        dataType === DataType.FLOAT ? defaultContentFloat : null,
      default_content_date:
        dataType === DataType.DATE ? defaultContentDate : null,
      default_content_bool:
        dataType === DataType.BOOLEAN ? defaultContentBool : null,
      default_custom_data_type_value_id:
        dataType === DataType.CUSTOM_DATA_TYPE
          ? defaultCustomDataTypeValueId
          : null,
    };

    ManageCrmService.updateOrgDataKey({
      orgDataKeyId: keyId,
      requestBody,
    })
      .then((updatedKey) => {
        const newKeys = keys.map((k) => (k.id === keyId ? updatedKey : k));
        setKeys(newKeys);
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Field updated!`);
      })
      .catch((e: ApiError | any) => {
        setStatus(PageStatus.ERROR);
        setError(e);
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function handleSubmit(ev: React.FormEvent) {
    ev.preventDefault();
    if (!name.trim()) {
      Notifications.error("Please enter a name for the field");
      return;
    }
    updateKey();
  }

  useEffect(() => {
    if (error) {
      Notifications.error(
        error?.body?.detail || "An unexpected error occurred."
      );
    }
  }, [error]);

  if (!existingKey) {
    return <div className="p-4">Unable to load key for editing.</div>;
  }

  // Basic logic to see if float/date fields changed (for enabling the "Save" button).
  const floatDateChanged =
    minValueIfFloat !== existingKey.min_value_if_float ||
    maxValueIfFloat !== existingKey.max_value_if_float ||
    minValueIfDate !== existingKey.min_value_if_date ||
    maxValueIfDate !== existingKey.max_value_if_date;

  // Check if default values changed
  const defaultValuesChanged =
    defaultContentText !== existingKey?.default_content_text ||
    defaultContentFloat !== existingKey?.default_content_float ||
    defaultContentDate !== existingKey?.default_content_date ||
    defaultContentBool !== existingKey?.default_content_bool ||
    defaultCustomDataTypeValueId !==
      existingKey?.default_custom_data_type_value?.id;

  // If we have changed the base org key name or anything else
  const hasChanged =
    name !== currentName || floatDateChanged || defaultValuesChanged;

  function handleClose() {
    // If user cancels, revert changes:
    setName(currentName);
    setMinValueIfFloat(existingKey?.min_value_if_float ?? null);
    setMaxValueIfFloat(existingKey?.max_value_if_float ?? null);
    setMinValueIfDate(existingKey?.min_value_if_date ?? null);
    setMaxValueIfDate(existingKey?.max_value_if_date ?? null);
    // Revert default values
    setDefaultContentText(existingKey?.default_content_text ?? "");
    setDefaultContentFloat(existingKey?.default_content_float ?? null);
    setDefaultContentDate(existingKey?.default_content_date ?? null);
    setDefaultContentBool(existingKey?.default_content_bool ?? null);
    setDefaultCustomDataTypeValueId(
      existingKey?.default_custom_data_type_value?.id ?? null
    );
  }

  return (
    <DialogContent
      ref={dialogRef}
      onClose={handleClose}
      className="org-data-key-dialog flex flex-col"
    >
      <div className="text-center">
        <h2 className="new-org-data-key--title">
          Update &quot;{currentName}&quot;
        </h2>
        <div>
          <i>Data Type - {DATA_TYPE_DISPLAY_MAP[dataType]}</i>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="mt-4 flex flex-col flex-grow">
        {/* Field Name (the org_data_keys.name) */}
        <label htmlFor="name">
          <strong>Field Name</strong>
        </label>
        <input
          id="name"
          name="name"
          type="text"
          className="input"
          placeholder="Field Name"
          value={name || ""}
          maxLength={50}
          onChange={(e) => setName(e.target.value)}
        />

        <div>
          <hr className="border-t border-gray-200 my-4" />
          <i className="text-sm text-gray-500">Optional</i>

          {/* If FLOAT: Show min/max float fields */}
          {dataType === DataType.FLOAT && (
            <div>
              <div className="flex gap-4">
                <div className="my-3 flex-1">
                  <label
                    htmlFor="minValueIfFloat"
                    className="text-sm text-gray-500"
                  >
                    <i>Min Value</i>
                  </label>
                  <input
                    type="number"
                    step="any"
                    className="input mt-2"
                    placeholder="(e.g. 200)"
                    id="minValueIfFloat"
                    value={minValueIfFloat ?? ""}
                    onChange={(e) =>
                      setMinValueIfFloat(
                        e.target.value ? parseFloat(e.target.value) : null
                      )
                    }
                  />
                </div>

                <div className="my-3 flex-1">
                  <label
                    htmlFor="maxValueIfFloat"
                    className="text-sm text-gray-500"
                  >
                    <i>Max Value</i>
                  </label>
                  <input
                    type="number"
                    step="any"
                    className="input mt-2"
                    placeholder="(e.g. 800)"
                    id="maxValueIfFloat"
                    value={maxValueIfFloat ?? ""}
                    onChange={(e) =>
                      setMaxValueIfFloat(
                        e.target.value ? parseFloat(e.target.value) : null
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}

          {/* If DATE: Show min/max date fields */}
          {dataType === DataType.DATE && (
            <div>
              <div className="flex gap-4">
                <div className="my-3 flex-1">
                  <label
                    htmlFor="minValueIfDate"
                    className="text-sm text-gray-500"
                  >
                    <i>Min Date</i>
                  </label>
                  <DatePicker
                    oneTap
                    className="pt-1"
                    value={minValueIfDate ? new Date(minValueIfDate) : null}
                    size="sm"
                    format="yyyy-MM-dd"
                    placement="bottomStart"
                    container={() => dialogRef.current || document.body}
                    onChange={(val) => {
                      if (!val) {
                        setMinValueIfDate(null);
                        return;
                      }
                      const localStart = new Date(val);
                      localStart.setHours(0, 0, 0, 0);
                      setMinValueIfDate(localStart.toISOString());
                    }}
                    cleanable
                    block
                  />
                </div>

                <div className="my-3 flex-1">
                  <label
                    htmlFor="maxValueIfDate"
                    className="text-sm text-gray-500"
                  >
                    <i>Max Date</i>
                  </label>
                  <DatePicker
                    oneTap
                    className="pt-1"
                    value={maxValueIfDate ? new Date(maxValueIfDate) : null}
                    size="sm"
                    format="yyyy-MM-dd"
                    placement="bottomStart"
                    container={() => dialogRef.current || document.body}
                    onChange={(val) => {
                      if (!val) {
                        setMaxValueIfDate(null);
                        return;
                      }
                      const localEnd = new Date(val);
                      localEnd.setHours(23, 59, 59, 999);
                      setMaxValueIfDate(localEnd.toISOString());
                    }}
                    cleanable
                    block
                  />
                </div>
              </div>
            </div>
          )}

          {/* Default Value Section */}
          <div className="mt-1">
            <label className="text-sm text-gray-500">
              <i>Default Value</i>
            </label>

            {renderField()}
          </div>
          <hr className="border-t border-gray-200 my-4" />
        </div>

        {/* Actions */}
        <div className="mt-auto pt-4 border-t">
          <DialogActions>
            <DialogAction
              primary={true}
              color={
                status === PageStatus.LOADING || !hasChanged
                  ? ButtonColor.GRAY
                  : ButtonColor.PURPLE
              }
              type="submit"
              disabled={status === PageStatus.LOADING || !hasChanged}
            >
              Save Field &rarr;
            </DialogAction>

            <DialogClose asChild>
              <DialogAction
                color={ButtonColor.PURPLE}
                disabled={status === PageStatus.LOADING}
                onClick={handleClose}
              >
                Cancel
              </DialogAction>
            </DialogClose>
          </DialogActions>
        </div>
      </form>
    </DialogContent>
  );
}

export default EditOrgDataKeyDialog;
