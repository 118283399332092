import { DialogClose } from "@radix-ui/react-dialog";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import {
  ApiError,
  OrgSubjectsService,
  OrganizationSubjectAdminResponse,
  OrganizationSubjectUpdate,
} from "client/openapi";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { DialogAction, DialogActions, DialogContent } from "components/Dialog";

import { useEffect, useState } from "react";
import { APIResponse, PageStatus, SubjectBase, SubjectCategory } from "types";

import Notifications from "util/notifications";
import "./index.css";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function ManageOrgSubjectsDialog({
  orgId,
  subjects,
  setSubjects,
}: {
  orgId: number;
  subjects: OrganizationSubjectAdminResponse[];
  setSubjects: React.Dispatch<
    React.SetStateAction<OrganizationSubjectAdminResponse[]>
  >;
}) {
  const emptySubject = {
    category: SubjectCategory.OTHER,
    level: null,
    name: null,
    default_price_for_students: 0,
    default_pay_for_tutors: 0,
  };

  const emptyEditSubject = {
    name: undefined,
    default_price_for_students: undefined,
    default_pay_for_tutors: undefined,
  };

  const [newSubject, setNewSubject] = useState<SubjectBase>(emptySubject);
  const [editedSubject, setEditedSubject] =
    useState<OrganizationSubjectUpdate>(emptyEditSubject);
  const [editId, setEditId] = useState<number>();
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();
  const [invalidPrice, setInvalidPrice] = useState<boolean>(false);

  // let subjectLevels = Object.entries(SubjectLevel).map(([value, label]) => ({
  //   value,
  //   label,
  // }));

  // let subjectCategories = Object.entries(SubjectCategory).map(
  //   ([value, label]) => ({
  //     value,
  //     label,
  //   })
  // );

  async function createSubject() {
    if (!newSubject.category) {
      return setError({ message: "Category is a required field." });
    }

    if (!newSubject.name) {
      return setError({ message: "Name is a required field." });
    }

    setStatus(PageStatus.LOADING);

    await OrgSubjectsService.createOrgSubject({
      requestBody: {
        org_id: orgId,
        name: newSubject.name,
        default_price_for_students: newSubject.default_price_for_students,
        default_pay_for_tutors: newSubject.default_pay_for_tutors,
        active: true,
      },
    })
      .then((newSubject) => {
        setSubjects([...subjects, newSubject]);
        clearForm();
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Subject created!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to create subject." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function editSubject() {
    if (!editedSubject.name) {
      return setError({ message: "Name is a required field." });
    }

    if (
      editedSubject.default_price_for_students === null ||
      editedSubject.default_price_for_students === undefined
    ) {
      return setError({ message: "Student pay is a required field." });
    }

    if (
      editedSubject.default_pay_for_tutors === null ||
      editedSubject.default_pay_for_tutors === undefined
    ) {
      return setError({ message: "Tutor Compensation is a required field." });
    }

    setStatus(PageStatus.LOADING);

    await OrgSubjectsService.updateSubject({
      subjectId: editId as number,
      requestBody: {
        name: editedSubject.name ?? undefined,
        default_price_for_students:
          editedSubject.default_price_for_students ?? undefined,
        default_pay_for_tutors:
          editedSubject.default_pay_for_tutors ?? undefined,
      },
    })
      .then((editedSubject) => {
        const updatedSubjects = subjects.map((sub) => {
          if (sub.id === editId) {
            return editedSubject;
          }
          return sub;
        });

        setSubjects(updatedSubjects);
        clearEdit();
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Subject edited!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "Unable to edit subject." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function handleUpdateName(ev) {
    const value = ev.target.value;

    setNewSubject({
      ...newSubject,
      [ev.target.name]: value,
    });
  }

  function handleEditName(ev) {
    const value = ev.target.value;

    setEditedSubject({
      ...editedSubject,
      [ev.target.name]: value,
    });
  }

  // function handleDropdownUpdate(field, value) {
  //   setNewSubject({
  //     ...newSubject,
  //     [field]: value,
  //   });
  // }

  // function handleCategoryUpdate(options) {
  //   handleDropdownUpdate("category", options.label);
  // }

  // function handleLevelUpdate(options) {
  //   handleDropdownUpdate("level", options?.label || null);
  // }

  const handlePricingChange: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) > 5000) {
      setInvalidPrice(true);
      setError({
        message: "Please enter a value less than or equal to $5000.00",
      });
      return;
    }

    // value is under limit
    if (Number(value) < 0) {
      setInvalidPrice(true);
      setError({
        message: "Please enter a value greater than or equal to $0.00",
      });
      return;
    }

    setInvalidPrice(false);

    setNewSubject({
      ...newSubject,
      [name as string]: Number(value) * 100,
    });
  };

  const handlePricingEdit: CurrencyInputProps["onValueChange"] = (
    value,
    name
  ) => {
    // value is over limit
    if (Number(value) > 5000) {
      setInvalidPrice(true);
      setError({
        message: "Please enter a value less than or equal to $5000.00",
      });
      return;
    }

    // value is under limit
    if (Number(value) < 0) {
      setInvalidPrice(true);
      setError({
        message: "Please enter a value greater than or equal to $0.00",
      });
      return;
    }

    setInvalidPrice(false);

    setEditedSubject({
      ...editedSubject,
      [name as string]: Number(value) * 100,
    });
  };

  function handleSubmit() {
    return async (ev) => {
      ev.preventDefault();
      await createSubject();
    };
  }

  function handleEdit() {
    return async (ev) => {
      ev.preventDefault();
      await editSubject();
      setEditId(undefined);
    };
  }

  function clearForm() {
    setNewSubject(emptySubject);
    setStatus(undefined);
  }

  function clearEdit() {
    setEditedSubject(emptyEditSubject);
    setStatus(undefined);
  }

  function handleClose() {
    setNewSubject(emptySubject);
    setEditedSubject(emptyEditSubject);
    setEditId(undefined);
    setStatus(undefined);
  }

  async function updateSubjectStatus(id: number, active: boolean) {
    await OrgSubjectsService.updateSubject({
      subjectId: id,
      requestBody: {
        active,
      },
    })
      .then((updatedSubject) => {
        setSubjects([...subjects.filter((s) => s.id !== id), updatedSubject]);
        Notifications.success(
          `Subject ${active ? "reactivated" : "deactivated"}!`
        );
      })
      .catch((e: ApiError) => {
        setError({ error: "Unable to change subject's status." });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  return (
    <>
      <DialogContent className="mangage-subjects-dialog">
        <h2 className="invite-dialog--title">Manage Subjects</h2>

        {subjects.length > 0 && (
          <table className="table">
            <thead className="text-left text-xs text-gray-500 =">
              <tr>
                <th className="w-1/6">Subject/Activity</th>
                <th className="w-1/6">Students Pay (per hour)</th>
                <th className="w-1/6">Tutors Recieve (per hour)</th>
                <th className="w-1/12">Actions</th>
              </tr>
            </thead>
            <tbody>
              {subjects
                .sort((a, b) =>
                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                )
                .map((s) => (
                  <tr
                    key={s.id}
                    className="subject-item odd:bg-gray-50 even:bg-white"
                  >
                    <td>
                      {editId === s.id ? (
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="input w-3/4 h-1/6"
                          placeholder={`${s.name}`}
                          value={editedSubject.name || ""}
                          onChange={handleEditName}
                          disabled={status === PageStatus.LOADING}
                          required
                        />
                      ) : (
                        <div title={s.name} className="display-subject-title">
                          {s.name}
                          {!s.active ? <small> (Hidden)</small> : ""}
                        </div>
                      )}
                    </td>
                    <td>
                      {editId === s.id ? (
                        <CurrencyInput
                          className="input  w-1/2 h-1/6"
                          id="default_price_for_students"
                          name="default_price_for_students"
                          placeholder={`$${
                            (s.default_price_for_students as number) / 100
                          }`}
                          defaultValue={
                            (s.default_price_for_students as number) / 100
                          }
                          prefix={"$"}
                          decimalsLimit={2}
                          allowNegativeValue={false}
                          onValueChange={handlePricingEdit}
                        />
                      ) : (
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          (s.default_price_for_students as number) / 100.0
                        )
                      )}
                    </td>
                    <td>
                      {editId === s.id ? (
                        <CurrencyInput
                          className="input  w-1/2 h-1/6"
                          id="default_pay_for_tutors"
                          name="default_pay_for_tutors"
                          placeholder={`$${
                            (s.default_pay_for_tutors as number) / 100
                          }`}
                          defaultValue={
                            (s.default_pay_for_tutors as number) / 100
                          }
                          prefix={"$"}
                          decimalsLimit={2}
                          allowNegativeValue={false}
                          onValueChange={handlePricingEdit}
                        />
                      ) : (
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format((s.default_pay_for_tutors as number) / 100.0)
                      )}
                    </td>
                    <td>
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "90%",
                        }}
                      >
                        {s.active ? (
                          <AiFillEye
                            data-tip="View Details"
                            data-for="viewDetail"
                            onClick={() => updateSubjectStatus(s.id, false)}
                            style={{
                              color: "#809BF7",
                              fontSize: "24px",
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            onClick={() => updateSubjectStatus(s.id, true)}
                            style={{
                              color: "#FFB341",
                              fontSize: "24px",
                            }}
                          />
                        )}

                        {editId === s.id ? (
                          <Button
                            size={ButtonSize.EXTRA_SMALL}
                            color={
                              invalidPrice
                                ? ButtonColor.GRAY
                                : ButtonColor.GREEN
                            }
                            disabled={
                              status === PageStatus.LOADING || invalidPrice
                            }
                            onClick={handleEdit()}
                          >
                            {status === PageStatus.LOADING ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        ) : (
                          <Button
                            size={ButtonSize.EXTRA_SMALL}
                            color={ButtonColor.PURPLE}
                            onClick={() => {
                              setEditId(s.id);
                              setEditedSubject({
                                name: s.name,
                                default_price_for_students:
                                  s.default_price_for_students,
                                default_pay_for_tutors:
                                  s.default_pay_for_tutors,
                              });
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}

        {/* {subjects.length > 0 && (
          <div className="subjects-management--list">
            {subjects
              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              .map((s) => (
                <div className="subjects-management--item" key={s.id}>
                  <div className="subject-name">
                    <b>{concatenateSubject(s)}</b>
                    {!s.active ? <small> (Hidden)</small> : ""}
                  </div>
                  {s.active ? (
                    <Button
                      size={ButtonSize.EXTRA_SMALL}
                      color={ButtonColor.ORANGE}
                      onClick={() => updateSubjectStatus(s.id, false)}
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      size={ButtonSize.EXTRA_SMALL}
                      color={ButtonColor.GREEN}
                      onClick={() => updateSubjectStatus(s.id, true)}
                    >
                      Reactivate
                    </Button>
                  )}
                </div>
              ))}
          </div>
        )} */}

        <h2 className="invite-dialog--title mt-5">Add Subject</h2>
        {/* <label htmlFor="category" className="input-label">
            Category
            <Select
              id="category"
              name="category"
              className="my-2"
              options={subjectCategories}
              value={subjectCategories.find(
                (s) => s.value === newSubject.category
              )}
              onChange={handleCategoryUpdate}
            />
          </label> */}
        {/* <label htmlFor="level" className="mt-3 input-label">
              Level (optional)
              <Select
                id="level"
                name="level"
                className="mt-2"
                options={subjectLevels}
                value={subjectLevels.find((s) => s.value === newSubject.level)}
                onChange={handleLevelUpdate}
                isClearable
              />
            </label> */}
        <div className="add-subject">
          <label htmlFor="name" className="input-label ">
            Name <br />
            <input
              id="name"
              name="name"
              type="text"
              className="input"
              placeholder="Physics"
              value={newSubject.name || ""}
              onChange={handleUpdateName}
              disabled={status === PageStatus.LOADING}
              required
            />
          </label>

          <label htmlFor="student-pay" className="input-label">
            Students Pay <br />
            <CurrencyInput
              className="input"
              id="default_price_for_students"
              name="default_price_for_students"
              placeholder="$100.00"
              prefix={"$"}
              decimalsLimit={2}
              allowNegativeValue={false}
              onValueChange={handlePricingChange}
            />
          </label>

          <label htmlFor="student-pay" className="input-label">
            Tutors Recieve <br />
            <CurrencyInput
              className="input"
              id="default_pay_for_tutors"
              name="default_pay_for_tutors"
              placeholder="$50.00"
              prefix={"$"}
              decimalsLimit={2}
              allowNegativeValue={false}
              onValueChange={handlePricingChange}
            />
          </label>
          <div>
            <div className="mt-7"></div>
            <Button
              size={ButtonSize.SMALL}
              color={invalidPrice ? ButtonColor.GRAY : ButtonColor.PURPLE}
              disabled={status === PageStatus.LOADING || invalidPrice}
              onClick={handleSubmit()}
            >
              {status === PageStatus.LOADING ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <>
                  Add <ArrowRightIcon />
                </>
              )}
            </Button>
          </div>
        </div>

        <DialogActions>
          <DialogClose asChild>
            <DialogAction
              color={ButtonColor.PURPLE}
              onClick={() => handleClose()}
            >
              Close
            </DialogAction>
          </DialogClose>
        </DialogActions>
      </DialogContent>
    </>
  );
}

export default ManageOrgSubjectsDialog;
