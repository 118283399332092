import React, { useState, useEffect, useRef, ClipboardEvent } from "react";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { Select } from "components/Select";
import {
  MeetingsService,
  MessagesService,
  OrganizationSubjectPublicResponse,
  SessionFrequency,
  StudentExtraLimitedResponse,
  TutorPublicResponse,
} from "client/openapi";
import IntlTelInput from "intl-tel-input/react";
import "./index.css";
import "intl-tel-input/build/css/intlTelInput.css";
import Notifications from "util/notifications";

export default function ConfirmBooking({
  orgId,
  selectedStartTime,
  selectedTutor,
  selectedSubject,
  setShowFinalStep,
  setCurrentStep,
}: {
  orgId: number;
  selectedStartTime: string;
  selectedTutor: TutorPublicResponse;
  selectedSubject: OrganizationSubjectPublicResponse;
  setShowFinalStep: (show: boolean) => void;
  setCurrentStep: (step: number) => void;
}) {
  const [mode, setMode] = useState<"existing" | "new">();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [sendingCode, setSendingCode] = useState(false);

  // 2) The 6-digit code
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [verificationError, setVerificationError] = useState<string | null>(
    null
  );
  const [students, setStudents] = useState<StudentExtraLimitedResponse[]>([]);
  const [selectedStudentId, setSelectedStudentId] = useState<number | null>(
    null
  );

  // For re-sending code
  const [resendCooldown, setResendCooldown] = useState<number>(0);
  const intervalRef = useRef<any>(null);

  const [newStudentBeingAdded, setNewStudentBeingAdded] = useState(false);
  const [parentFirstName, setParentFirstName] = useState("");
  const [parentLastName, setParentLastName] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentPhone, setParentPhone] = useState("");

  const [newParentBeingAdded, setNewParentBeingAdded] = useState(false);
  const [studentFirstName, setStudentFirstName] = useState("");
  const [studentLastName, setStudentLastName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentPhone, setStudentPhone] = useState("");

  // Add this state near the other state declarations
  const [isBooking, setIsBooking] = useState(false);

  // Minimal check for enabling "Book Meeting" on new student flow
  function allNewStudentFieldsFilled() {
    if (mode === "existing" && newStudentBeingAdded) {
      return studentFirstName.trim() && studentLastName.trim();
    }

    if (mode === "new" && newParentBeingAdded && newStudentBeingAdded) {
      return (
        parentFirstName.trim() &&
        parentLastName.trim() &&
        parentEmail.trim() &&
        parentPhone.trim() &&
        studentFirstName.trim() &&
        studentLastName.trim()
      );
    }

    if (mode === "new" && newStudentBeingAdded && !newParentBeingAdded) {
      return (
        studentFirstName.trim() &&
        studentLastName.trim() &&
        studentEmail.trim() &&
        studentPhone.trim()
      );
    }
  }

  // Start resend countdown if needed
  useEffect(() => {
    if (resendCooldown > 0 && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setResendCooldown((prev) => {
          if (prev <= 1) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
  }, [resendCooldown]);

  // ----------------------------------------------------------------
  // 1) SENDING CODE for existing student
  // ----------------------------------------------------------------
  function sendCode() {
    if (!phoneNumber) {
      alert("Please enter a phone number first.");
      return;
    }
    setSendingCode(true);
    MessagesService.requestVerificationCode({ phoneNumber })
      .then(() => {
        setCode(["", "", "", "", "", ""]);
        setVerificationError(null);
        setResendCooldown(60);
      })
      .catch((err) => {
        console.error("sendVerificationCode error:", err);
        alert("Error sending verification code.");
      })
      .finally(() => setSendingCode(false));
  }

  // ----------------------------------------------------------------
  // 2) 6-digit code logic
  // ----------------------------------------------------------------

  // For copy–pasting 6 digits
  function handleCodePaste(e: ClipboardEvent) {
    const pastedData = e.clipboardData.getData("Text").replace(/\D/g, ""); // digits only
    if (!pastedData) return;

    // Fill up to 6
    const newDigits = pastedData.slice(0, 6).split("");
    const merged = code.map((val, i) => newDigits[i] || val);
    for (let i = 0; i < 6; i++) {
      merged[i] = newDigits[i] || "";
    }
    setCode(merged);
  }

  function handleCodeChange(value: string, index: number) {
    // Only accept 0–9, single char
    if (!/^\d?$/.test(value)) return;

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Move focus to next box
    if (value && index < 5) {
      const nextInput = document.getElementById(`code-${index + 1}`);
      nextInput?.focus();
    }
  }

  function handleCodeKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) {
    if (e.key === "Backspace" && !code[index]) {
      if (index > 0) {
        const prevInput = document.getElementById(
          `code-${index - 1}`
        ) as HTMLInputElement;
        prevInput?.focus();
        const newCode = [...code];
        newCode[index - 1] = "";
        setCode(newCode);
      }
    }
  }

  // If code is fully entered (6 digits), attempt verify
  useEffect(() => {
    if (phoneNumber && code.every((digit) => digit.length === 1)) {
      const completeCode = code.join("");
      MessagesService.validateVerificationCodeAndReturnStudents({
        phoneNumber,
        code: completeCode,
        orgId: orgId,
      })
        .then((resp) => {
          if (resp.status === "approved") {
            setVerificationError(null);
            setStudents(resp.students || []);
          } else {
            setVerificationError(
              "Invalid verification code. Please try again."
            );
            setCode(["", "", "", "", "", ""]);
          }
        })
        .catch((err) => {
          console.error("checkVerificationCodeAndReturnStudents error:", err);
          setVerificationError("Error verifying code. Please try again.");
          setCode(["", "", "", "", "", ""]);
        });
    }
  }, [code]);

  // ----------------------------------------------------------------
  // 3) Book Meeting
  // ----------------------------------------------------------------
  async function handleBookMeeting() {
    setIsBooking(true);

    MeetingsService.createMeetingThroughPublicBookingPage({
      requestBody: {
        org_id: orgId,
        start: selectedStartTime,
        duration: 60,
        name: selectedSubject.name,
        other_location: "Contact Tutor",
        add_students:
          mode === "existing" && selectedStudentId ? [selectedStudentId] : [],
        add_tutors: [selectedTutor.id],
        org_subject_id: selectedSubject.id,
        frequency: SessionFrequency.SINGLE,
        new_student: newStudentBeingAdded,
        new_parent: newParentBeingAdded,
        student_first_name: studentFirstName,
        student_last_name: studentLastName,
        student_email: studentEmail,
        student_phone_number: studentPhone,
        parent_first_name: parentFirstName,
        parent_last_name: parentLastName,
        parent_email: parentEmail,
        parent_phone_number: parentPhone,
      },
    })
      .then(() => {
        Notifications.success(`Meeting Booked!`);
        setShowFinalStep(false);
        setCurrentStep(1);
        setIsBooking(false);
      })
      .catch(() => {
        Notifications.error(`Meeting cannot be booked at this time.`);
        setIsBooking(false);
      });
  }

  // ----------------------------------------------------------------
  // 4) "EXISTING STUDENT" flow
  // ----------------------------------------------------------------
  const existingStudentOrParentStep = () => {
    return (
      <div>
        <h2 className="text-lg font-semibold mb-4">Existing User</h2>

        {!students.length ? (
          <>
            {/* 1) Use IntlTelInput for phoneNumber */}
            <div className="mb-2">
              <IntlTelInput
                inputProps={{
                  className: "input mt-2",
                  name: "studentPhone",
                }}
                onChangeNumber={(number) => setPhoneNumber(number)}
                initOptions={{
                  loadUtils: () => import("intl-tel-input/build/js/utils.js"),
                  strictMode: true,
                  autoPlaceholder: "aggressive",
                  placeholderNumberType: "MOBILE",
                  countryOrder: ["us", "gb", "ca"],
                  initialCountry: "auto",
                  geoIpLookup: (success, failure) => {
                    fetch("https://ipapi.co/json/")
                      .then((res) => res.json())
                      .then((data) => success(data.country_code))
                      .catch(() => failure());
                  },
                  separateDialCode: true,
                  nationalMode: true,
                }}
              />
            </div>

            <div className="flex items-center gap-2 mb-2">
              <Button
                color={ButtonColor.GREEN}
                fill={ButtonFill.DEFAULT}
                size={ButtonSize.SMALL}
                onClick={sendCode}
                disabled={sendingCode || resendCooldown > 0}
              >
                {resendCooldown > 0
                  ? `Resend in ${resendCooldown}s`
                  : "Send Code ->"}
              </Button>
            </div>

            {verificationError && (
              <div className="text-red-600 text-sm mb-2">
                {verificationError}
              </div>
            )}

            {/* 6 code digits */}
            {sendingCode ? (
              <div>Sending code...</div>
            ) : (
              <div className="flex gap-2 mb-3" onPaste={handleCodePaste}>
                {code.map((digit, idx) => (
                  <input
                    key={idx}
                    id={`code-${idx}`}
                    className="code-box"
                    value={digit}
                    onChange={(e) => handleCodeChange(e.target.value, idx)}
                    onKeyDown={(e) => handleCodeKeyDown(e, idx)}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <div>
            {!newStudentBeingAdded ? (
              <div>
                <Select
                  id="students"
                  options={students.map((st) => ({
                    label: `${st.first_name}`,
                    value: st.id,
                  }))}
                  onChange={(opt) => {
                    if (!opt) return;
                    setSelectedStudentId(opt.value);
                  }}
                  placeholder="Select Student..."
                />
                <div className="mt-4">
                  <Button
                    color={
                      isBooking || !selectedStudentId
                        ? ButtonColor.GRAY
                        : ButtonColor.GREEN
                    }
                    onClick={handleBookMeeting}
                    disabled={isBooking || !selectedStudentId}
                  >
                    {isBooking ? "Booking..." : "Book Meeting"}
                  </Button>
                </div>
                <p
                  className="text-sm text-blue-500 underline mt-2 cursor-pointer"
                  onClick={() => {
                    setNewStudentBeingAdded(true);
                  }}
                >
                  Want to add a new student to your account?
                </p>
              </div>
            ) : (
              <div className="grid gap-2">
                <input
                  className="booking-input"
                  placeholder="Student First Name"
                  value={studentFirstName}
                  onChange={(e) => setStudentFirstName(e.target.value)}
                />
                <input
                  className="booking-input"
                  placeholder="Student Last Name"
                  value={studentLastName}
                  onChange={(e) => setStudentLastName(e.target.value)}
                />
                <div className="mt-4">
                  <Button
                    color={
                      isBooking || !studentFirstName || !studentLastName
                        ? ButtonColor.GRAY
                        : ButtonColor.GREEN
                    }
                    onClick={handleBookMeeting}
                    disabled={
                      isBooking || !studentFirstName || !studentLastName
                    }
                  >
                    {isBooking ? "Booking..." : "Book Meeting"}
                  </Button>
                </div>
                <p
                  className="text-sm text-blue-500 underline mt-2 cursor-pointer"
                  onClick={() => {
                    setNewStudentBeingAdded(false);
                    setStudentFirstName("");
                    setStudentLastName("");
                  }}
                >
                  Want to select from existing students?
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  // ----------------------------------------------------------------
  // 5) "NEW STUDENT" flow
  // ----------------------------------------------------------------
  const newStudentOrParentStep = () => {
    return (
      <div>
        <h2 className="text-lg font-semibold mb-4">
          {newParentBeingAdded && newStudentBeingAdded
            ? "New Student and Parent"
            : "New Student"}
        </h2>

        {newParentBeingAdded && newStudentBeingAdded ? (
          <>
            <div className="grid gap-2">
              <input
                className="booking-input"
                placeholder="Parent First Name"
                value={parentFirstName}
                onChange={(e) => setParentFirstName(e.target.value)}
              />
              <input
                className="booking-input"
                placeholder="Parent Last Name"
                value={parentLastName}
                onChange={(e) => setParentLastName(e.target.value)}
              />
              <input
                className="booking-input"
                placeholder="Parent Email"
                value={parentEmail}
                onChange={(e) => setParentEmail(e.target.value)}
              />

              <IntlTelInput
                inputProps={{
                  className: "input mt-2",
                  name: "studentPhone",
                }}
                onChangeNumber={(number) => setParentPhone(number)}
                initOptions={{
                  loadUtils: () => import("intl-tel-input/build/js/utils.js"),
                  strictMode: true,
                  autoPlaceholder: "aggressive",
                  placeholderNumberType: "MOBILE",
                  countryOrder: ["us", "gb", "ca"],
                  initialCountry: "auto",
                  geoIpLookup: (success, failure) => {
                    fetch("https://ipapi.co/json/")
                      .then((res) => res.json())
                      .then((data) => success(data.country_code))
                      .catch(() => failure());
                  },
                  separateDialCode: true,
                  nationalMode: true,
                }}
              />

              <input
                className="booking-input"
                placeholder="Student First Name"
                value={studentFirstName}
                onChange={(e) => setStudentFirstName(e.target.value)}
              />
              <input
                className="booking-input"
                placeholder="Student Last Name"
                value={studentLastName}
                onChange={(e) => setStudentLastName(e.target.value)}
              />
            </div>
            <p
              className="text-sm text-blue-500 underline mt-2 cursor-pointer"
              onClick={() => {
                setNewParentBeingAdded(false);
                setNewStudentBeingAdded(true);
              }}
            >
              Booking for yourself or don't have a parent?
            </p>
          </>
        ) : (
          <>
            <div className="grid gap-2">
              <input
                className="booking-input"
                placeholder="Student First Name"
                value={studentFirstName}
                onChange={(e) => setStudentFirstName(e.target.value)}
              />
              <input
                className="booking-input"
                placeholder="Student Last Name"
                value={studentLastName}
                onChange={(e) => setStudentLastName(e.target.value)}
              />
              <input
                className="booking-input"
                placeholder="Student Email"
                value={studentEmail}
                onChange={(e) => setStudentEmail(e.target.value)}
              />

              <IntlTelInput
                inputProps={{
                  className: "input mt-2",
                  name: "studentPhone",
                }}
                onChangeNumber={(number) => setStudentPhone(number)}
                initOptions={{
                  loadUtils: () => import("intl-tel-input/build/js/utils.js"),
                  strictMode: true,
                  autoPlaceholder: "aggressive",
                  placeholderNumberType: "MOBILE",
                  countryOrder: ["us", "gb", "ca"],
                  initialCountry: "auto",
                  geoIpLookup: (success, failure) => {
                    fetch("https://ipapi.co/json/")
                      .then((res) => res.json())
                      .then((data) => success(data.country_code))
                      .catch(() => failure());
                  },
                  separateDialCode: true,
                  nationalMode: true,
                }}
              />
            </div>
            <p
              className="text-sm text-blue-500 underline mt-2 cursor-pointer"
              onClick={() => {
                setNewParentBeingAdded(true);
                setNewStudentBeingAdded(true);
              }}
            >
              Booking on behalf of a student?
            </p>
          </>
        )}

        <div className="mt-4">
          <Button
            color={
              !allNewStudentFieldsFilled() || isBooking
                ? ButtonColor.GRAY
                : ButtonColor.GREEN
            }
            disabled={!allNewStudentFieldsFilled() || isBooking}
            onClick={handleBookMeeting}
          >
            {isBooking ? "Booking..." : "Book Meeting"}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white rounded-md">
      <p className="text-sm text-gray-600 my-6">
        Choose <strong>Existing User</strong> if you've booked here before or
        <strong> New User</strong> if you're booking for the first time.
      </p>

      <div className="flex gap-4 mb-6">
        <Button
          color={ButtonColor.GREEN}
          fill={mode === "existing" ? ButtonFill.DEFAULT : ButtonFill.HOLLOW}
          onClick={() => {
            setMode("existing");
            setNewParentBeingAdded(false);
            setNewStudentBeingAdded(false);
          }}
        >
          Existing User
        </Button>
        <Button
          color={ButtonColor.GREEN}
          fill={mode === "new" ? ButtonFill.DEFAULT : ButtonFill.HOLLOW}
          onClick={() => {
            setMode("new");
            setNewParentBeingAdded(true);
            setNewStudentBeingAdded(true);
          }}
        >
          New User
        </Button>
      </div>

      {mode === "existing" && existingStudentOrParentStep()}
      {mode === "new" && newStudentOrParentStep()}
    </div>
  );
}
