import { Pencil1Icon } from "@radix-ui/react-icons";
import { useState } from "react";
import { Button, ButtonColor } from "components/Button";
import {
  StudentLimitedResponse,
  StudentAdminResponse,
  StudentsService,
} from "client/openapi";
import "./index.css";
import { APIResponse, PageStatus } from "types";
import { concatenateName } from "util/concatenateName";
import { generateInitials } from "util/generateInitials";
import PhoneInput from "react-phone-input-2";
import { DatePicker } from "rsuite";
import moment from "moment";
import Notifications from "util/notifications";

export default function StudentInfoAndIcon({
  student,
  setStudent,
  setStatus,
  setError,
}: {
  student: StudentLimitedResponse | StudentAdminResponse;
  setStudent: (student: StudentLimitedResponse | StudentAdminResponse) => void;
  setStatus: (status: PageStatus) => void;
  setError: (error: APIResponse) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [firstNameInput, setFirstNameInput] = useState(
    student.first_name || ""
  );
  const [lastNameInput, setLastNameInput] = useState(student.last_name || "");
  const [phoneInput, setPhoneInput] = useState(student.phone_number || "");
  const [birthdayInput, setBirthdayInput] = useState(
    "birth_date" in student ? student.birth_date || "" : ""
  );
  const [addressInput, setAddressInput] = useState(
    "address_line_1" in student ? student.address_line_1 || "" : ""
  );
  const [emailInput, setEmailInput] = useState(student.email || "");

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && isEditing) {
      updateStudentInfo();
    }
  };

  const handleCancel = () => {
    setFirstNameInput(student.first_name || "");
    setLastNameInput(student.last_name || "");
    setPhoneInput(student.phone_number || "");
    setBirthdayInput("birth_date" in student ? student.birth_date || "" : "");
    setAddressInput(
      "address_line_1" in student ? student.address_line_1 || "" : ""
    );
    setEmailInput(student.email || "");
    setIsEditing(false);
  };

  async function updateStudentInfo() {
    const dataChanged =
      ("address_line_1" in student
        ? addressInput !== student.address_line_1
        : addressInput !== "") ||
      ("birth_date" in student
        ? birthdayInput !== student.birth_date
        : birthdayInput !== "") ||
      phoneInput !== student.phone_number ||
      firstNameInput !== student.first_name ||
      lastNameInput !== student.last_name ||
      emailInput !== student.email;

    if (emailInput && !emailInput.includes("@")) {
      Notifications.error("Please enter a valid email address.");
      return;
    }

    if (dataChanged) {
      let localStudent = { ...student };

      try {
        const updatedStudent = await StudentsService.updateStudent({
          studentId: student.id,
          requestBody: {
            birth_date: birthdayInput ? birthdayInput : null,
            address_line_1: addressInput ? addressInput : null,
            phone_number: phoneInput && phoneInput !== "1" ? phoneInput : null,
            first_name: firstNameInput,
            last_name: lastNameInput,
            email: emailInput,
          },
        });

        localStudent = { ...localStudent, ...updatedStudent };

        setStudent(localStudent);
      } catch (e: any) {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to update tutor info" });
        console.error(`Error (#${e.status}): ${e.message}`);
      }
    }

    setIsEditing(false);
  }

  const handlePhoneChange = (newPhone: string) => {
    setPhoneInput(newPhone);
  };

  return (
    <div className="w-3/5 md:w-full h-full flex flex-col justify-start items-center">
      <div
        className="w-11/12 aspect-square rounded-2xl text-white flex justify-center items-center text-center font-bold text-8xl"
        style={{ backgroundColor: "var(--skyblue)" }}
      >
        {generateInitials(concatenateName(student), 2)}
      </div>
      <div className="w-11/12 flex flex-col mt-10 border-t-2 relative gap-y-2.5">
        {!isEditing && (
          <Pencil1Icon
            className="absolute mt-5 right-0 w-5 h-5 cursor-pointer"
            onClick={() => setIsEditing(true)}
          />
        )}

        <table>
          <tr>
            <td className="pt-3">First Name:</td>
            <td className="pt-3">
              {isEditing ? (
                <input
                  id="first-name"
                  value={firstNameInput}
                  className="w-7/12 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setFirstNameInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-12/12">{firstNameInput}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Last Name:</td>
            <td className="pt-3">
              {isEditing ? (
                <input
                  id="last-name"
                  value={lastNameInput}
                  className="w-7/12 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setLastNameInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-12/12">{lastNameInput}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Email:</td>
            <td className="pt-3">
              {isEditing ? (
                <input
                  id="email"
                  value={emailInput}
                  className="w-7/12 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setEmailInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-12/12 break-words">{emailInput}</div>
              )}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Phone:</td>
            <td className="pt-3">
              {isEditing ? (
                <PhoneInput
                  country={"us"}
                  value={phoneInput}
                  onChange={handlePhoneChange}
                  prefix="+"
                  inputClass="student-phone-input-edit"
                  placeholder="+1 (555) 555-5555"
                  dropdownStyle={{ display: "none" }}
                  countryCodeEditable={false}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : phoneInput === null ||
                phoneInput === "1" ||
                phoneInput === "" ||
                phoneInput === undefined ? (
                ""
              ) : (
                <PhoneInput
                  country={"us"}
                  value={phoneInput}
                  onChange={handlePhoneChange}
                  prefix="+"
                  inputClass="student-phone-input-view"
                  placeholder="+1 (555) 555-5555"
                  dropdownStyle={{ display: "none" }}
                  countryCodeEditable={false}
                  disabled={true}
                  onKeyDown={handleEnterKeyPress}
                />
              )}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Birthday:</td>
            <td className="pt-3">
              {isEditing ? (
                <DatePicker
                  oneTap
                  defaultValue={
                    new Date(
                      new Date(birthdayInput).setDate(
                        new Date(birthdayInput).getDate() + 1
                      )
                    )
                  }
                  onChange={(value) =>
                    value !== null
                      ? setBirthdayInput(moment.utc(value).format("YYYY-MM-DD"))
                      : setBirthdayInput("")
                  }
                  onKeyDown={(e) => e.preventDefault()}
                  format="yyyy-MM-dd"
                  size="xs"
                  cleanable={true}
                  className="tutorDatePicker"
                  block
                />
              ) : (
                <div className="w-12/12">
                  {birthdayInput && birthdayInput !== null ? birthdayInput : ""}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td className="pt-3">Address:</td>
            <td className="pt-3">
              {isEditing ? (
                <input
                  id="address"
                  value={addressInput}
                  className="w-7/12 border border-black rounded-md pl-1 h-6"
                  onChange={(e) => setAddressInput(e.target.value)}
                  onKeyDown={handleEnterKeyPress}
                />
              ) : (
                <div className="w-12/12">{addressInput}</div>
              )}
            </td>
          </tr>
        </table>

        {isEditing && (
          <div className="flex flex-row gap-2">
            <Button
              color={ButtonColor.SKYBLUE}
              onClick={updateStudentInfo}
              extraClasses="w-20 h-7"
            >
              Save
            </Button>

            <Button
              color={ButtonColor.RED}
              onClick={handleCancel}
              extraClasses="w-20 h-7"
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
