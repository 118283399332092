import { Helmet } from "react-helmet";
import { PageStatus } from "types";
import "./index.css";

export enum StatusBlockDisplay {
  INLINE = "inline",
  STANDALONE = "standalone",
}

function StatusBlock({
  title,
  message,
  display = StatusBlockDisplay.STANDALONE,
}: {
  title: string;
  message: string;
  display: StatusBlockDisplay;
}) {
  return (
    <div
      className={
        display === "standalone"
          ? "status-block--standalone container py-5 mx-auto text-center"
          : "status-block--inline text-left"
      }
    >
      <div className="row justify-content-md-center align-items-center">
        <h1 className="status-block--title">{title}</h1>
        <p className="tutor_bio">{message}</p>
      </div>
    </div>
  );
}

export function LoadingBlock({
  status,
  title = "Loading",
  message = "Please wait...",
  display = StatusBlockDisplay.STANDALONE,
}: {
  status?: PageStatus;
  title?: string;
  message?: string;
  display?: StatusBlockDisplay;
}) {
  return (
    <>
      {status && status === PageStatus.LOADING && (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>

          <StatusBlock title={title} message={message} display={display} />
        </>
      )}
    </>
  );
}

export function ErrorBlock({
  status,
  title = "Oh no!",
  message = "This resource could not be loaded.",
  display = StatusBlockDisplay.STANDALONE,
}: {
  status: PageStatus;
  title?: string;
  message?: string;
  display?: StatusBlockDisplay;
}) {
  return (
    <>
      {status === PageStatus.ERROR && (
        <>
          {/* <Helmet>
            <title>{title}</title>
          </Helmet> */}

          <StatusBlock title={title} message={message} display={display} />
        </>
      )}
    </>
  );
}

export default StatusBlock;
