import { useState, useEffect, forwardRef } from "react";
import {
  AvailabilitiesService,
  AvailabilityMessage,
  MeetingTutorLimitedResponse,
  MeetingAdminResponse,
  MeetingLimitedResponse,
  Auth0AccountRole,
  MeetingStudentLimitedResponse,
  MeetingStudentAdminResponse,
  MeetingTutorAdminResponse,
  StudentAdminResponse,
  TutorAdminResponse,
  TutorLimitedResponse,
  StudentLimitedResponse,
  OrganizationSubjectAdminResponse,
  OrganizationSubjectPublicResponse,
} from "client/openapi";
import moment from "moment-timezone";
import { formatTimeToHMMA } from "util/time";

import { AttendeesList } from "components/MeetingAttendees";
import { MeetingDetails } from "components/MeetingDialog";
import Notifications from "util/notifications";
import { APIResponse } from "types";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { Select } from "components/Select";

const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "HH:mm";

export default function AvailabilitySidebar({
  availabilitySidebarDate,
  event,
  tutors,
  role,
  editing,
  creating,
  subject,
  removeAttendee,
  updateAttendees,
  currentMeetingInfo,
  isAvailabilitySidebarOpen,
  setIsAvailabilitySidebarOpen,
  saveMeetingDetails,
  availableSubjects,
  handleSubjectChange,
}: {
  availabilitySidebarDate: string;
  event: MeetingAdminResponse | MeetingLimitedResponse | undefined;
  tutors: (
    | MeetingTutorLimitedResponse
    | MeetingTutorAdminResponse
    | TutorLimitedResponse
    | TutorAdminResponse
  )[];
  role: Auth0AccountRole;
  editing: boolean;
  creating: boolean;
  subject:
    | OrganizationSubjectPublicResponse
    | OrganizationSubjectAdminResponse
    | undefined;
  removeAttendee: (userType: Auth0AccountRole, id: number) => void;
  updateAttendees: (
    userType: Auth0AccountRole,
    attendees: (
      | MeetingTutorLimitedResponse
      | MeetingStudentLimitedResponse
      | MeetingTutorAdminResponse
      | MeetingStudentAdminResponse
      | StudentLimitedResponse
      | StudentAdminResponse
      | TutorLimitedResponse
      | TutorAdminResponse
    )[]
  ) => void;
  currentMeetingInfo: MeetingDetails;
  isAvailabilitySidebarOpen: boolean;
  setIsAvailabilitySidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  saveMeetingDetails: (updatedMeeting: MeetingDetails) => void;
  availableSubjects:
    | OrganizationSubjectPublicResponse[]
    | OrganizationSubjectAdminResponse[]
    | undefined;
  handleSubjectChange: (
    subject:
      | OrganizationSubjectPublicResponse
      | OrganizationSubjectAdminResponse
  ) => void;
}) {
  const timezone = moment.tz.guess(true);
  const [selectedDate, setSelectedDate] = useState(
    new Date(availabilitySidebarDate)
  );
  const [tutorAvailabilities, setTutorAvailabilities] = useState<
    Map<number, AvailabilityMessage[]>
  >(new Map());
  const [tutorCombinations, setTutorCombinations] = useState<
    Array<{ comboKey: string; availabilities: AvailabilityMessage[] }>
  >([]);
  const [error, setError] = useState<APIResponse>();

  const duration = currentMeetingInfo?.duration;

  const fetchData = async () => {
    const tutor_ids = currentMeetingInfo?.tutor_ids;
    if (!tutor_ids || tutor_ids.length === 0) {
      return;
    }

    const fetchedTutorAvailabilities = new Map<number, AvailabilityMessage[]>();

    for (const tutor_id of tutor_ids) {
      try {
        const tutor_availabilities =
          await AvailabilitiesService.getAvailabilitiesByTutor({
            tutorId: tutor_id,
            start: moment(availabilitySidebarDate)
              .startOf("day")
              .utc()
              .toISOString(),
            until: moment(availabilitySidebarDate)
              .add(1, "day")
              .startOf("day")
              .utc()
              .toISOString(),
            duration: duration,
          });

        // Convert UTC availabilities to local timezone
        const localAvailabilities = tutor_availabilities.map(
          (availability) => ({
            ...availability,
            start: moment.utc(availability.start).tz(timezone).format(),
          })
        );

        fetchedTutorAvailabilities.set(tutor_id, localAvailabilities);
      } catch (error: any) {
        console.error(
          `${error}: Error fetching availability for tutor ${tutor_id}`
        );
        Notifications.error(
          "There was an error fetching tutor availabilities. Please try again."
        );
      }
    }

    setTutorAvailabilities(fetchedTutorAvailabilities);
  };

  useEffect(() => {
    setSelectedDate(new Date(availabilitySidebarDate));
    if (tutors.length !== 0 && currentMeetingInfo?.tutor_ids?.length) {
      setError(undefined);
      fetchData();
    }
  }, [tutors, currentMeetingInfo, availabilitySidebarDate]);

  function generateAllCombinations(arr: number[]): number[][] {
    const results: number[][] = [];
    const length = arr.length;

    for (let subsetMask = 1; subsetMask < 1 << length; subsetMask++) {
      const subset: number[] = [];
      for (let i = 0; i < length; i++) {
        if ((subsetMask & (1 << i)) !== 0) {
          subset.push(arr[i]);
        }
      }
      subset.sort((a, b) => a - b);
      results.push(subset);
    }

    return results;
  }

  useEffect(() => {
    const availabilityMap = new Map<string, AvailabilityMessage[]>();
    const allAvailabilities: {
      availability: AvailabilityMessage;
      tutorId: number;
    }[] = [];

    tutorAvailabilities.forEach((availabilities, tutorId) => {
      availabilities.forEach((availability) => {
        allAvailabilities.push({ availability, tutorId });
      });
    });

    const timeSlotMap = new Map<
      string,
      { tutors: number[]; availability: AvailabilityMessage }
    >();

    allAvailabilities.forEach(({ availability, tutorId }) => {
      const key = `${availability.start}-${availability.duration}`;
      if (!timeSlotMap.has(key)) {
        timeSlotMap.set(key, { tutors: [tutorId], availability });
      } else {
        timeSlotMap.get(key)!.tutors.push(tutorId);
      }
    });

    timeSlotMap.forEach(({ tutors, availability }) => {
      tutors.sort((a, b) => a - b);
      const comboKey = tutors.join(",");
      if (!availabilityMap.has(comboKey)) {
        availabilityMap.set(comboKey, [availability]);
      } else {
        availabilityMap.get(comboKey)!.push(availability);
      }
    });

    const tutor_ids = currentMeetingInfo?.tutor_ids || [];
    const allCombos = generateAllCombinations(tutor_ids);

    const finalCombos: {
      comboKey: string;
      availabilities: AvailabilityMessage[];
    }[] = [];

    allCombos.forEach((combo) => {
      const comboKey = combo.join(",");
      if (availabilityMap.has(comboKey)) {
        const comboAvail = availabilityMap.get(comboKey)!;

        // Sort by time (HH:mm), ignoring the date
        comboAvail.sort((a, b) => {
          const aTime = moment(a.start).format("HH:mm");
          const bTime = moment(b.start).format("HH:mm");
          return aTime.localeCompare(bTime);
        });

        finalCombos.push({ comboKey, availabilities: comboAvail });
      } else {
        finalCombos.push({ comboKey, availabilities: [] });
      }
    });

    // Sort combos by number of tutors (descending)
    finalCombos.sort((a, b) => {
      const aCount = a.comboKey.split(",").length;
      const bCount = b.comboKey.split(",").length;
      return bCount - aCount;
    });

    setTutorCombinations(finalCombos);
  }, [tutorAvailabilities, currentMeetingInfo]);

  const tutorIdToNameMap = new Map<number, string>();
  tutors.forEach((tutor) => {
    const tId = "tutor" in tutor ? tutor.tutor.id : tutor.id;
    const tName = "tutor" in tutor ? tutor.tutor : tutor;
    tutorIdToNameMap.set(tId, `${tName.first_name} ${tName.last_name}`);
  });

  // Handle changing start time
  const handleChangeStartTime = (ev: { value: string }) => {
    const updatedMeeting = {
      ...currentMeetingInfo,
      start:
        moment(currentMeetingInfo.start).format(DATE_FORMAT) +
        "T" +
        moment(ev.value, TIME_FORMAT).format().split("T")[1],
    };

    saveMeetingDetails(updatedMeeting);
    setIsAvailabilitySidebarOpen(false);
  };

  const combosByTutorCount = new Map<number, any[]>();
  tutorCombinations.forEach(({ comboKey, availabilities }) => {
    const tutorIds = comboKey.split(",").map((id) => parseInt(id, 10));
    const tutorCount = tutorIds.length;
    const tutorNames = tutorIds
      .map((id) => tutorIdToNameMap.get(id))
      .join(", ");
    const hasAvailability = availabilities.length > 0;
    const allTutorsAvailable =
      tutorCount === (currentMeetingInfo?.tutor_ids?.length || 0);

    const comboData = {
      comboKey,
      tutorNames,
      availabilities,
      allTutorsAvailable,
      hasAvailability,
    };

    if (!combosByTutorCount.has(tutorCount)) {
      combosByTutorCount.set(tutorCount, []);
    }
    combosByTutorCount.get(tutorCount)!.push(comboData);
  });

  const sortedTutorCounts = Array.from(combosByTutorCount.keys()).sort(
    (a, b) => b - a
  );

  function renderGroup(tutorCount: number) {
    const combos = combosByTutorCount.get(tutorCount)!;
    const withAvailability = combos.filter((c: any) => c.hasAvailability);
    const noAvailability = combos.filter((c: any) => !c.hasAvailability);

    return (
      <>
        {withAvailability.map((c: any, idx: number) => (
          <div key={`${c.comboKey}-with-${idx}`} className="tutor-combination">
            <h3 className="font-semibold mt-4">{c.tutorNames}</h3>
            <div className="flex flex-wrap gap-2 mt-2">
              {c.availabilities.map(
                (slot: AvailabilityMessage, slotIdx: number) => {
                  const startTime = moment(slot.start);
                  return (
                    <Button
                      key={`${c.comboKey}-with-${idx}-slot-${slotIdx}`}
                      onClick={() => {
                        handleChangeStartTime({
                          value: startTime.format(TIME_FORMAT),
                        });
                        setIsAvailabilitySidebarOpen(false);
                      }}
                      size={ButtonSize.EXTRA_SMALL}
                      color={ButtonColor.GREEN}
                      fill={ButtonFill.HOLLOW}
                    >
                      {`${formatTimeToHMMA(startTime.toDate())}`}
                    </Button>
                  );
                }
              )}
            </div>
          </div>
        ))}
        {noAvailability.map((c: any, idx: number) => (
          <div
            key={`${c.comboKey}-no-${idx}`}
            className="tutor-combination mt-4"
          >
            <h3 className="font-semibold">{c.tutorNames}</h3>
            <div className="mt-2 text-sm italic text-gray-500">
              no times available
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <div className="bg-gray-100 rounded-lg col-span-12 md:col-span-3 m-0 availability-sidebar-wrapper">
      <div className="bg-white p-5 rounded-t-lg">
        <div className="col-span-2">
          <Select
            id="subject"
            options={availableSubjects}
            value={subject ? subject : undefined}
            getOptionLabel={(s) => `${s.name}`}
            getOptionValue={(s) => s.id.toString()}
            placeholder="Subject..."
            onChange={handleSubjectChange}
            title="Add subject"
          />
        </div>
      </div>

      <div
        className="cursor-pointer text-green-500 ml-5 mt-5"
        onClick={() => setIsAvailabilitySidebarOpen(false)}
      >
        want to hide tutor availability?
      </div>

      {!error && isAvailabilitySidebarOpen ? (
        <>
          <div className="px-5">
            <div>
              <h2 className="mb-1">
                <span className="font-bold">Find a Tutor</span> -{" "}
                {moment(availabilitySidebarDate).format("MMM DD YYYY")}
              </h2>
              <AttendeesList
                attendees={tutors ?? []}
                typeOfAttendees={Auth0AccountRole.ORG_TUTOR}
                editing={editing}
                creating={creating}
                event={event}
                subject={subject}
                linkPrefix={
                  role === Auth0AccountRole.ORG_ADMIN
                    ? "/manage/tutors/"
                    : "/tutors/"
                }
                currentMeetingInfo={currentMeetingInfo}
                remove={removeAttendee}
                updateAttendees={updateAttendees}
              />
            </div>
          </div>

          <div className="px-5 pb-5">
            {sortedTutorCounts.map((count, idx) => (
              <div key={`group-${count}-${idx}`}>{renderGroup(count)}</div>
            ))}
          </div>
        </>
      ) : error ? (
        <div className="p-5 text-red-600 font-semibold">{error.message}</div>
      ) : null}
    </div>
  );
}
