import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonColor } from "components/Button";
import { Dialog } from "components/Dialog";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import {
  ApiError,
  Auth0AccountRole,
  OrganizationSettingsResponse,
  OrganizationsService,
} from "client/openapi";
import { concatenateName } from "util/concatenateName";
import { generateInitials } from "util/generateInitials";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import "./index.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import PersonalSettings from "./personalSettings";
import OrganizationSettings from "./orgSettings";
import Notifications from "util/notifications";

const ProfileDropDown = () => {
  const { logout } = useAuth0();
  const { isLoading } = useAuth0();
  const { currently_selected_role, currently_selected_organization, account } =
    useContext(OrgRolesAndAccountContext);

  const [error, setError] = useState<ApiError>();

  const [orgSettings, setOrgSettings] =
    useState<OrganizationSettingsResponse>();

  // State for the dropdown menu.
  const [isOpen, setIsOpen] = useState(false);

  // Separate states for the Personal and Organization settings dialogs.
  const [isPersonalDialogOpen, setIsPersonalDialogOpen] = useState(false);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem(
      account ? account.reference_id : "OrgRolesAndAccountState"
    );
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  // Retrieve settings data
  const getSettingsData = useCallback(() => {
    if (!isLoading && account) {
      // Organization settings (only if user is ORG_ADMIN)
      if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
        OrganizationsService.getOrganizationSettings({
          orgId: currently_selected_organization as number,
        })
          .then((orgRes) => {
            setOrgSettings(orgRes);
          })
          .catch((err) => {
            setError(err);
          });
      }
    }
  }, [
    currently_selected_organization,
    currently_selected_role,
    isLoading,
    account,
  ]);

  useEffect(() => {
    if (!isLoading && account) {
      getSettingsData();
    }
  }, [account, getSettingsData, isLoading]);

  useEffect(() => {
    if (error) {
      Notifications.error(error.body.detail);
    }
  }, [error]);

  // Close dropdown if user clicks outside of it.
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="dropdown" ref={dropdownRef}>
        <Button
          color={ButtonColor.GREEN}
          onClick={toggleDropdown}
          extraClasses={"nav__button " + (isOpen ? "activeDropDown" : "")}
        >
          <div className="profileBtn">
            <ChevronDownIcon />
            <span>{account?.first_name}</span>
            <div className="initials">
              <p>
                {account ? generateInitials(concatenateName(account), 2) : ""}
              </p>
            </div>
          </div>
        </Button>

        {isOpen && (
          <div className="dropdownContent">
            <button
              onClick={() => {
                setIsPersonalDialogOpen(true);
                setIsOpen(false);
              }}
            >
              {currently_selected_role === Auth0AccountRole.ORG_ADMIN &&
                "Personal "}
              Settings
            </button>

            {currently_selected_role === Auth0AccountRole.ORG_ADMIN && (
              <button
                onClick={() => {
                  setIsOrgDialogOpen(true);
                  setIsOpen(false);
                }}
              >
                Organization Settings
              </button>
            )}

            <button onClick={handleLogout}>Log Out</button>
          </div>
        )}
      </div>

      <Dialog
        open={isPersonalDialogOpen}
        onOpenChange={(open) => setIsPersonalDialogOpen(open)}
      >
        {account && <PersonalSettings account={account} />}
      </Dialog>

      {currently_selected_role === Auth0AccountRole.ORG_ADMIN && (
        <Dialog
          open={isOrgDialogOpen}
          onOpenChange={(open) => setIsOrgDialogOpen(open)}
        >
          {orgSettings && <OrganizationSettings orgSettings={orgSettings} />}
        </Dialog>
      )}
    </>
  );
};

export default ProfileDropDown;
