/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Logical operators for combining conditions.
 */
export enum LogicalOperator {
  AND = "AND",
  OR = "OR",
}
