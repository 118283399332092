/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_get_parents_with_students } from "../models/Body_get_parents_with_students";
import type { Body_get_students_with_parents } from "../models/Body_get_students_with_parents";
import type { Body_get_students_with_tutors } from "../models/Body_get_students_with_tutors";
import type { Body_get_tutors_with_students } from "../models/Body_get_tutors_with_students";
import type { ConditionGroup } from "../models/ConditionGroup";
import type { OrganizationDataResponse } from "../models/OrganizationDataResponse";
import type { ParentLimitedResponse } from "../models/ParentLimitedResponse";
import type { StudentLimitedResponse } from "../models/StudentLimitedResponse";
import type { TutorLimitedResponse } from "../models/TutorLimitedResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class QueryCrmService {
  /**
   * Get Org Data For Student
   * Get all org data on a student from the database.
   * @returns OrganizationDataResponse Successful Response
   * @throws ApiError
   */
  public static getOrgDataForStudent({
    orgId,
    studentId,
  }: {
    orgId: number;
    studentId: number;
  }): CancelablePromise<Array<OrganizationDataResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/query_crm/data_for_student",
      query: {
        org_id: orgId,
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Org Data For Parent
   * Get all org data on a parent from the database.
   * @returns OrganizationDataResponse Successful Response
   * @throws ApiError
   */
  public static getOrgDataForParent({
    orgId,
    parentId,
  }: {
    orgId: number;
    parentId: number;
  }): CancelablePromise<Array<OrganizationDataResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/query_crm/data_for_parent",
      query: {
        org_id: orgId,
        parent_id: parentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Org Data For Tutor
   * Get all org data on a tutor from the database.
   * @returns OrganizationDataResponse Successful Response
   * @throws ApiError
   */
  public static getOrgDataForTutor({
    orgId,
    tutorId,
  }: {
    orgId: number;
    tutorId: number;
  }): CancelablePromise<Array<OrganizationDataResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/query_crm/data_for_tutor",
      query: {
        org_id: orgId,
        tutor_id: tutorId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students
   * Get all students that match the given criteria.
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getStudents({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: ConditionGroup;
  }): CancelablePromise<Array<StudentLimitedResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/query_crm/students",
      query: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parents
   * Get all parents that match the given criteria.
   * @returns ParentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getParents({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: ConditionGroup;
  }): CancelablePromise<Array<ParentLimitedResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/query_crm/parents",
      query: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutors
   * Get all tutors that match the given criteria.
   * @returns TutorLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getTutors({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: ConditionGroup;
  }): CancelablePromise<Array<TutorLimitedResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/query_crm/tutors",
      query: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students With Parents
   * Example:
   * 1) get the parents that match 'parent_criteria'
   * 2) get students that match 'student_criteria'
   * who also have a parent that matches 'parent_criteria'
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsWithParents({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: Body_get_students_with_parents;
  }): CancelablePromise<Array<StudentLimitedResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/query_crm/students-with-parents",
      query: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parents With Students
   * Example:
   * 1) get the students that match 'student_criteria'
   * 2) get parents that match 'parent_criteria'
   * who also have a student that matches 'student_criteria'
   * @returns ParentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getParentsWithStudents({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: Body_get_parents_with_students;
  }): CancelablePromise<Array<ParentLimitedResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/query_crm/parents-with-students",
      query: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Students With Tutors
   * Example:
   * 1) get the tutors that match 'tutor_criteria'
   * 2) get students that match 'student_criteria'
   * who also have a tutor that matches 'tutor_criteria'
   * @returns StudentLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getStudentsWithTutors({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: Body_get_students_with_tutors;
  }): CancelablePromise<Array<StudentLimitedResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/query_crm/students-with-tutors",
      query: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutors With Students
   * Example:
   * 1) get the students that match 'student_criteria'
   * 2) get tutors that match 'tutor_criteria'
   * who also have a student that matches 'student_criteria'
   * @returns TutorLimitedResponse Successful Response
   * @throws ApiError
   */
  public static getTutorsWithStudents({
    orgId,
    requestBody,
  }: {
    orgId: number;
    requestBody: Body_get_tutors_with_students;
  }): CancelablePromise<Array<TutorLimitedResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/query_crm/tutors-with-students",
      query: {
        org_id: orgId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
