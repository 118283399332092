import "./index.css";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import moment from "moment";
import { FaClock } from "react-icons/fa6";
import { Dialog, DialogTrigger } from "components/Dialog";
import MeetingDialog from "components/MeetingDialog";
import {
  MeetingAdminResponse,
  AccountingSystemForCalculatingFinancialStatistics,
  ApprovalStatus,
  TadpoleToTutorTransactionType,
  Auth0AccountRole,
  TransactionStatus,
  TadpoleToTutorTransactionResponse,
  TutorLimitedResponse,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import {
  getTransactionStatusColor,
  isFailed,
  isPaymentSuccessful,
} from "pages/manage/students/[id]/PaymentsTab/Transactions/statusHelper";
import RetryModal from "pages/manage/students/[id]/PaymentsTab/Modals/Actions/Retry";
import TransactionMeetingsPopup from "pages/manage/students/[id]/PaymentsTab/Transactions/TransactionMeetingDetails";
import { useState } from "react";

/** Human-readable transaction types. */
export const tadpoleToTutorTransactionTypeToHumanReadable = [
  {
    value:
      TadpoleToTutorTransactionType.AUTOMATIC_PAYMENT_FOR_A_GROUP_OF_MEETINGS,
    label: "Automatic Payment for Meetings",
  },
  {
    value: TadpoleToTutorTransactionType.MEMBER_OF_AUTOMATIC_PAYMENT_GROUP,
    label: "Pending Payment, queued to be included in next payroll run",
  },
];

/**
 * Determine whether we show "Refund" or "Retry" as the status action.
 */
const getStatusAction = (status: TransactionStatus) => {
  if (isPaymentSuccessful(status)) {
    return { value: "SUCCESSFUL", label: "Refund" };
  }
  if (isFailed(status)) {
    return { value: "FAILED", label: "Retry" };
  }
  return null;
};

function Transactions({
  transactions,
  tutor,
  accountingSystem,
}: {
  transactions: TadpoleToTutorTransactionResponse[] | MeetingAdminResponse[];
  tutor: TutorLimitedResponse;
  accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
}) {
  const isMeetingSystem =
    accountingSystem ===
    AccountingSystemForCalculatingFinancialStatistics.MEETINGS;

  const isTransactions =
    accountingSystem ===
    AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS;

  return (
    <div className="w-full max-h-[50rem] overflow-y-auto sm:overflow-x-visible max-sm:overflow-x-auto">
      <table className="w-full border-collapse">
        {/* Sticky header row */}
        <thead className="sticky top-0 z-3 shadow-sm bg-white">
          {isTransactions && (
            <tr className="border-b-2 border-gray-300">
              <th className="text-left p-2 text-sm font-bold w-[22.5%]">
                Date
              </th>
              <th className="text-left p-2 text-sm font-bold w-[15%]">
                Amount
              </th>
              <th className="text-left p-2 text-sm font-bold w-[20%]">
                Service
              </th>
              <th className="text-left p-2 text-sm font-bold w-[10%]">Type</th>
              <th className="p-2 text-sm font-bold w-[10%] text-center">
                Status
              </th>
              <th className="p-2 text-sm font-bold w-[12.5%] text-center">
                Actions
              </th>
            </tr>
          )}
          {isMeetingSystem && (
            <tr className="border-b-2 border-gray-300">
              <th className="text-left p-2 text-sm font-bold w-[8.5%]">Date</th>
              <th className="text-left p-2 text-sm font-bold w-[18%]">
                Service
              </th>
              <th className="text-left p-2 text-sm font-bold w-[19%]">
                Revenue
              </th>
              <th className="text-left p-2 text-sm font-bold w-[20%]">
                Compensation
              </th>
              <th className="text-left p-2 text-sm font-bold w-[10%]">
                Net Income
              </th>
              <th className="p-2 text-sm font-bold w-[10%] text-center">
                Status
              </th>
              <th className="p-2 text-sm font-bold w-[10%] text-center">
                Actions
              </th>
            </tr>
          )}
        </thead>

        <tbody>
          {transactions.map((transaction, index) => {
            if (isTransactions) {
              const txn = transaction as TadpoleToTutorTransactionResponse;
              const date = moment(txn.timestamp).format("MM/DD/YYYY");
              const amount = (txn.amount / 100).toFixed(2);
              const service = txn.reason;
              const type =
                tadpoleToTutorTransactionTypeToHumanReadable.find(
                  (t) => txn.tadpole_to_tutor_transaction_type === t.value
                )?.label ?? "Unknown";

              const statusIcon = (() => {
                const statusColor = getTransactionStatusColor(txn.status);
                switch (statusColor) {
                  case "green":
                    return <IoCheckmarkCircle className="mx-auto green-mark" />;
                  case "purple":
                    return (
                      <IoCheckmarkCircle className="mx-auto purple-mark" />
                    );
                  case "orange":
                    return (
                      <FaClock
                        className="mx-auto"
                        style={{ color: "#FFB341", fontSize: "20px" }}
                      />
                    );
                  case "red":
                    return <IoCloseCircle className="mx-auto red-mark" />;
                  default:
                    return (
                      <FaClock
                        className="mx-auto"
                        style={{ color: "lightblue", fontSize: "20px" }}
                      />
                    );
                }
              })();

              const statusAction = getStatusAction(txn.status);

              const actions = (
                <div className="flex flex-row gap-1 items-center justify-center">
                  {[
                    TadpoleToTutorTransactionType.AUTOMATIC_PAYMENT_FOR_A_GROUP_OF_MEETINGS,
                    TadpoleToTutorTransactionType.MEMBER_OF_AUTOMATIC_PAYMENT_GROUP,
                  ].includes(txn.tadpole_to_tutor_transaction_type) && (
                    <LazyTransactionMeetingsPopup transaction={txn} />
                  )}

                  {statusAction?.label === "Retry" && !txn.retried && (
                    <Dialog>
                      <DialogTrigger>
                        <Button
                          color={ButtonColor.SKYBLUE}
                          size={ButtonSize.EXTRA_SMALL}
                          fill={ButtonFill.HOLLOW}
                        >
                          {statusAction.label}
                        </Button>
                      </DialogTrigger>
                      {statusAction.label === "Retry" && (
                        <RetryModal transaction={txn} />
                      )}
                    </Dialog>
                  )}
                </div>
              );

              return (
                <tr
                  key={index}
                  className="bg-white border-b border-gray-200 hover:bg-gray-50"
                >
                  <td className="p-2 text-sm">{date}</td>
                  <td className="p-2 text-sm">${amount}</td>
                  <td className="p-2 text-sm">{service}</td>
                  <td className="p-2 text-sm">{type}</td>
                  <td className="p-2 text-sm text-center">{statusIcon}</td>
                  <td className="p-2 text-sm text-center">{actions}</td>
                </tr>
              );
            }

            if (isMeetingSystem) {
              const meeting = transaction as MeetingAdminResponse;
              const date = moment(meeting.start).format("MM/DD/YYYY");
              const service = meeting.name;

              const totalBilled = meeting.students.reduce(
                (sum, mt) =>
                  sum +
                  (mt.rate_student_billed_at / 100) * (meeting.duration / 60),
                0
              );
              const revenue =
                meeting.tutors.length > 0
                  ? totalBilled / meeting.tutors.length
                  : 0;
              const compensation = meeting.tutors
                .filter((mt) => mt.tutor.id === tutor.id)
                .reduce(
                  (sum, mt) =>
                    sum +
                    (mt.rate_tutor_paid_at / 100) * (meeting.duration / 60),
                  0
                );
              const netIncome = revenue - compensation;

              const statusIcon =
                meeting.reviewed_and_approved_by_admin ===
                ApprovalStatus.APPROVED ? (
                  <IoCheckmarkCircle className="mx-auto green-mark" />
                ) : meeting.reviewed_and_approved_by_admin ===
                  ApprovalStatus.DENIED ? (
                  <IoCloseCircle className="mx-auto red-mark" />
                ) : (
                  <FaClock
                    className="mx-auto"
                    style={{ color: "#FFB341", fontSize: "20px" }}
                  />
                );

              const actions = (
                <div className="flex flex-row gap-1 items-center justify-center">
                  <LazyMeetingDialog meeting={meeting} />
                </div>
              );

              return (
                <tr
                  key={index}
                  className="bg-white border-b border-gray-200 hover:bg-gray-50"
                >
                  <td className="p-2 text-sm">{date}</td>
                  <td className="p-2 text-sm">{service}</td>
                  <td className="p-2 text-sm">${revenue.toFixed(2)}</td>
                  <td className="p-2 text-sm">${compensation.toFixed(2)}</td>
                  <td className="p-2 text-sm">${netIncome.toFixed(2)}</td>
                  <td className="p-2 text-sm text-center">{statusIcon}</td>
                  <td className="p-2 text-sm text-center">{actions}</td>
                </tr>
              );
            }

            return null;
          })}
        </tbody>
      </table>
    </div>
  );
}

function LazyTransactionMeetingsPopup({
  transaction,
}: {
  transaction: TadpoleToTutorTransactionResponse;
}) {
  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button
          color={ButtonColor.PURPLE}
          size={ButtonSize.EXTRA_SMALL}
          fill={ButtonFill.HOLLOW}
        >
          View
        </Button>
      </DialogTrigger>
      {open && <TransactionMeetingsPopup transaction={transaction} />}
    </Dialog>
  );
}

function LazyMeetingDialog({ meeting }: { meeting: MeetingAdminResponse }) {
  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button
          color={ButtonColor.PURPLE}
          size={ButtonSize.EXTRA_SMALL}
          fill={ButtonFill.HOLLOW}
        >
          View
        </Button>
      </DialogTrigger>
      {open && (
        <MeetingDialog
          isCreate={false}
          role={Auth0AccountRole.ORG_ADMIN}
          event={meeting}
          setEvents={() => Promise.resolve()}
        />
      )}
    </Dialog>
  );
}

export default Transactions;
