import React, { useEffect, useState } from "react";
import { Select } from "components/Select";
import { Tag } from "components/Tag/TagChip";
import { TagColor, TagSize } from "components/Tag";
import {
  CRMVisualizationColumnResponse,
  CRMVisualizationResponse,
  OrganizationDataKeyResponse,
  VisualizationsService,
  Auth0AccountRole,
} from "client/openapi";
import EditVisualizationDialog from "./EditVisualizationDialog";
import DeleteVisualizationDialog from "./DeleteVisualizationDialog";
import CreateVisualizationDialog from "./CreateVisualizationDialog";
import AddOrEditColumnDialog from "./AddOrEditColumnDialog";
import ColumnActionsDialog from "./ColumnActionsDialog";
import "./index.css";
import { PageStatus } from "types/pageStatus.interface";
import { ErrorBlock } from "components/StatusBlock";
import { Button, ButtonColor, ButtonShape } from "components/Button";
import { Dialog, DialogContent, DialogTrigger } from "components/Dialog";
import AdministrativeInfo from "pages/manage/students/[id]/StudentInfoTab/AdministrativeInfo";

// 1) Import your helper functions
import {
  addColumnToVisualization,
  updateColumnInVisualization,
  deleteColumnFromVisualization,
} from "./visualizationUtils"; // <-- your helper file path

export default function VisualizationView({
  orgId,
  studentKeys,
  parentKeys,
}: {
  orgId: number;
  studentKeys: OrganizationDataKeyResponse[];
  parentKeys: OrganizationDataKeyResponse[];
}) {
  const [visualizations, setVisualizations] = useState<
    CRMVisualizationResponse[]
  >([]);
  const [selectedVisualization, setSelectedVisualization] = useState<
    CRMVisualizationResponse | undefined
  >(undefined);
  const [isLoadingVisualizations, setIsLoadingVisualizations] = useState(true);
  const [exportingData, setExportingData] = useState(false);

  const [addColumnDialogOpen, setAddColumnDialogOpen] = useState(false);
  const [editingColumnId, setEditingColumnId] = useState<number | null>(null);

  const fetchVisualizations = async () => {
    try {
      setIsLoadingVisualizations(true);
      const data = await VisualizationsService.getVisualizations({ orgId });
      setVisualizations(data);
      if (data.length > 0) {
        setSelectedVisualization(data[0]);
      }
      setIsLoadingVisualizations(false);
    } catch (error) {
      console.error("Error fetching visualizations:", error);
      setIsLoadingVisualizations(false);
    }
  };

  useEffect(() => {
    fetchVisualizations();
  }, [orgId]);

  const handleVisualizationCreated = (
    newVisualization: CRMVisualizationResponse
  ) => {
    setVisualizations((prev) => [...prev, newVisualization]);
    setSelectedVisualization(newVisualization);
  };

  const handleVisualizationUpdate = (updated: CRMVisualizationResponse) => {
    setVisualizations((prevVisualizations) =>
      prevVisualizations.map((v) => (v.id === updated.id ? updated : v))
    );
    setSelectedVisualization(updated);
  };

  const handleVisualizationDelete = () => {
    if (!selectedVisualization) return;
    setVisualizations((prevVisualizations) =>
      prevVisualizations.filter((v) => v.id !== selectedVisualization.id)
    );
    setSelectedVisualization(undefined);
  };

  const handleColumnAdded = (newColumn: CRMVisualizationColumnResponse) => {
    setSelectedVisualization((prev) => {
      if (!prev) return prev;
      return addColumnToVisualization(prev, newColumn);
    });
    setAddColumnDialogOpen(false);
  };

  const handleColumnUpdated = (
    updatedColumn: CRMVisualizationColumnResponse
  ) => {
    setSelectedVisualization((prev) => {
      if (!prev) return prev;
      return updateColumnInVisualization(prev, updatedColumn);
    });
    setEditingColumnId(null);
  };

  const handleColumnDeleted = (deletedColumnId: number) => {
    setSelectedVisualization((prev) => {
      if (!prev) return prev;
      return deleteColumnFromVisualization(prev, deletedColumnId);
    });
    setEditingColumnId(null);
  };

  if (isLoadingVisualizations) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-900"></div>
      </div>
    );
  }

  if (visualizations.length === 0) {
    return (
      <div className="visualization-container">
        <div
          className="mx-auto w-full"
          style={{
            display: "flex",
            backgroundColor: "#f8f8f8",
            borderRadius: "0.75rem",
            alignItems: "center",
            padding: "5rem 1.5rem",
            textAlign: "center",
          }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ErrorBlock
              status={PageStatus.ERROR}
              title="No visualizations"
              message="You have not created any visualizations yet."
            />
            <CreateVisualizationDialog
              orgId={orgId}
              onVisualizationCreated={handleVisualizationCreated}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="visualization-container">
      <div className="visualization-selector mb-6 flex flex-col sm:flex-row gap-2">
        <div className="flex flex-col sm:flex-row gap-2">
          <div style={{ minWidth: "200px" }}>
            <Select
              options={visualizations
                .filter((v) => v != null && typeof v.id !== "undefined")
                .map((v) => ({
                  value: v.id.toString(),
                  label: v.name,
                }))}
              value={
                selectedVisualization?.id
                  ? {
                      value: selectedVisualization.id.toString(),
                      label: selectedVisualization.name,
                    }
                  : undefined
              }
              onChange={(option) => {
                const selected = visualizations.find(
                  (v) => v?.id?.toString() === option?.value
                );
                setSelectedVisualization(selected || undefined);
              }}
              placeholder="Select a visualization..."
            />
          </div>
          <div className="flex gap-1">
            {selectedVisualization && (
              <>
                <EditVisualizationDialog
                  visualization={selectedVisualization}
                  onUpdate={handleVisualizationUpdate}
                />
                <DeleteVisualizationDialog
                  visualization={selectedVisualization}
                  onDelete={handleVisualizationDelete}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex gap-2">
          <CreateVisualizationDialog
            orgId={orgId}
            onVisualizationCreated={handleVisualizationCreated}
          />
        </div>
      </div>

      {selectedVisualization && selectedVisualization.columns?.length === 0 && (
        <div
          className="mx-auto w-full"
          style={{
            display: "flex",
            backgroundColor: "#f8f8f8",
            borderRadius: "0.75rem",
            alignItems: "center",
            padding: "5rem 1.5rem",
            textAlign: "center",
          }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ErrorBlock
              status={PageStatus.ERROR}
              title="No columns"
              message="This visualization has no columns yet."
            />
            <div className="mt-4">
              <Dialog
                open={addColumnDialogOpen}
                onOpenChange={setAddColumnDialogOpen}
              >
                <DialogTrigger asChild>
                  <Button color={ButtonColor.PURPLE}>Add Column</Button>
                </DialogTrigger>
                <AddOrEditColumnDialog
                  visualization={selectedVisualization}
                  onColumnUpdated={handleColumnUpdated}
                  onColumnAdded={handleColumnAdded}
                  studentKeys={studentKeys}
                  parentKeys={parentKeys}
                  setIsOpen={setAddColumnDialogOpen}
                />
              </Dialog>
            </div>
          </div>
        </div>
      )}

      {selectedVisualization && selectedVisualization.columns?.length > 0 && (
        <div className="visualization-columns">
          {[...selectedVisualization.columns]
            .sort((a, b) => a.order_index - b.order_index)
            .map((column, index) => (
              <div
                key={column.id}
                className={`visualization-column ${
                  index % 2 === 0 ? "bg-purple-50" : "bg-blue-50"
                }`}
              >
                <Dialog
                  open={editingColumnId === column.id}
                  onOpenChange={(open) =>
                    setEditingColumnId(open ? column.id : null)
                  }
                >
                  <DialogTrigger asChild>
                    <div className="column-header cursor-pointer">
                      <h3 className="text-white font-bold bg-purple-600 hover:bg-purple-500 p-2 rounded-t transition-colors">
                        {column.name.length > 20
                          ? column.name.slice(0, 20) + "..."
                          : column.name}
                      </h3>
                    </div>
                  </DialogTrigger>
                  <ColumnActionsDialog
                    key={column.id}
                    column={column}
                    visualization={selectedVisualization}
                    onColumnUpdated={handleColumnUpdated}
                    onColumnAdded={handleColumnAdded}
                    onColumnDeleted={handleColumnDeleted}
                    studentKeys={studentKeys}
                    parentKeys={parentKeys}
                    setIsOpen={(open) =>
                      setEditingColumnId(open ? column.id : null)
                    }
                    orgId={orgId}
                  />
                </Dialog>

                <div className="column-content">
                  {column.students_in_column?.map((student) => (
                    <Dialog key={student.id}>
                      <DialogTrigger asChild>
                        <div className="cursor-pointer">
                          <Tag
                            item={`${student.first_name} ${student.last_name}`}
                            color={TagColor.PURPLE}
                            size={TagSize.MEDIUM}
                          />
                        </div>
                      </DialogTrigger>
                      <DialogContent>
                        <div className="column-admin-info-dialog-content">
                          <AdministrativeInfo
                            exportingData={exportingData}
                            setExportingData={setExportingData}
                            userType={Auth0AccountRole.ME}
                            student={student}
                            organization_id={orgId}
                            showViewProfileButton={true}
                          />
                        </div>
                      </DialogContent>
                    </Dialog>
                  ))}
                  {column.parents_in_column?.map((parent) => (
                    <Dialog key={parent.id}>
                      <DialogTrigger asChild>
                        <div className="cursor-pointer">
                          <Tag
                            item={`${parent.first_name} ${parent.last_name}`}
                            color={TagColor.BLUE}
                            size={TagSize.MEDIUM}
                          />
                        </div>
                      </DialogTrigger>
                      <DialogContent>
                        <div className="column-admin-info-dialog-content">
                          <AdministrativeInfo
                            exportingData={exportingData}
                            setExportingData={setExportingData}
                            userType={Auth0AccountRole.PARENT}
                            parent={parent}
                            organization_id={orgId}
                            showViewProfileButton={true}
                          />
                        </div>
                      </DialogContent>
                    </Dialog>
                  ))}
                </div>
              </div>
            ))}

          <div className="visualization-column bg-gray-50 flex items-center justify-center">
            <Dialog
              open={addColumnDialogOpen}
              onOpenChange={setAddColumnDialogOpen}
            >
              <DialogTrigger asChild>
                <div className="w-full">
                  <div className="column-header cursor-pointer">
                    <h3 className="text-white font-bold bg-blue-400 hover:bg-blue-300 p-2 rounded-t transition-colors">
                      Add Column
                    </h3>
                  </div>
                  <div className="column-content">
                    <div className="flex items-center justify-center mt-20">
                      <Button
                        shape={ButtonShape.CIRCLE}
                        color={ButtonColor.DARK_BLUE}
                      >
                        <span className="mx-2 font-bold text-xl">+</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </DialogTrigger>

              <AddOrEditColumnDialog
                visualization={selectedVisualization}
                onColumnAdded={handleColumnAdded}
                onColumnUpdated={handleColumnUpdated}
                setIsOpen={setAddColumnDialogOpen}
                studentKeys={studentKeys}
                parentKeys={parentKeys}
              />
            </Dialog>
          </div>
        </div>
      )}
    </div>
  );
}
