import { useContext, useEffect, useState } from "react";
import RadioInput from "components/Inputs/RadioInput";
import {
  ApiError,
  OrganizationSettingsResponse,
  OrganizationsService,
  // StudentLimitedResponse,
  // StudentsService,
  // TutorLimitedResponse,
  // TutorsService,
} from "client/openapi";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { PageStatus } from "types";
import { DialogActions, DialogClose, DialogContent } from "components/Dialog";
import { Button } from "components/Button";
import { ButtonColor } from "components/Button";
import "./index.css";

export default function OrganizationSettings({
  orgSettings,
}: {
  orgSettings: OrganizationSettingsResponse;
}) {
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  const [error, setError] = useState<ApiError>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.SUCCESS);

  const [tutorsCanViewUnassignedStudents, setTutorsCanViewUnassignedStudents] =
    useState<boolean>(orgSettings.tutors_can_view_unassigned_students);
  const [
    autoReviewAndApproveMeetingsForAdmin,
    setAutoReviewAndApproveMeetingsForAdmin,
  ] = useState<boolean>(orgSettings.auto_review_and_approve_meetings_for_admin);
  const [
    autoReviewAndApproveMeetingsForTutors,
    setAutoReviewAndApproveMeetingsForTutors,
  ] = useState<boolean>(
    orgSettings.auto_review_and_approve_meetings_for_tutors
  );
  const [
    requireTutorsToAcceptMeetingsBookedByAdmins,
    setRequireTutorsToAcceptMeetingsBookedByAdmins,
  ] = useState<boolean>(
    orgSettings.require_tutors_to_accept_meetings_booked_by_admins
  );
  const [
    requireTutorsToAcceptMeetingsBookedByTutors,
    setRequireTutorsToAcceptMeetingsBookedByTutors,
  ] = useState<boolean>(
    orgSettings.require_tutors_to_accept_meetings_booked_by_tutors
  );
  const [
    requireTutorsToAcceptMeetingsBookedByStudentsOrParents,
    setRequireTutorsToAcceptMeetingsBookedByStudentsOrParents,
  ] = useState<boolean>(
    orgSettings.require_tutors_to_accept_meetings_booked_by_students_or_parents
  );
  const [gracePeriodInput, setGracePeriodInput] = useState<number>(
    orgSettings.grace_period_for_editing_meetings_before_billing_and_payroll
  );

  const updateOrganizationSettings = () => {
    setStatus(PageStatus.LOADING);
    OrganizationsService.updateOrganizationSettings({
      orgId: currently_selected_organization as number,
      requestBody: {
        tutors_can_view_unassigned_students: tutorsCanViewUnassignedStudents,
        auto_review_and_approve_meetings_for_admin:
          autoReviewAndApproveMeetingsForAdmin,
        auto_review_and_approve_meetings_for_tutors:
          autoReviewAndApproveMeetingsForTutors,
        require_tutors_to_accept_meetings_booked_by_admins:
          requireTutorsToAcceptMeetingsBookedByAdmins,
        require_tutors_to_accept_meetings_booked_by_tutors:
          requireTutorsToAcceptMeetingsBookedByTutors,
        require_tutors_to_accept_meetings_booked_by_students_or_parents:
          requireTutorsToAcceptMeetingsBookedByStudentsOrParents,
        grace_period_for_editing_meetings_before_billing_and_payroll:
          gracePeriodInput,
      },
    })
      .then((res) => {
        Notifications.success("Organization settings updated successfully");
        setStatus(PageStatus.SUCCESS);
      })
      .catch((err) => {
        setError(err);
        setStatus(PageStatus.ERROR);
      });
  };

  // const [tutorUpForPromotion, setTutorUpForPromotion] =
  //   useState<TutorLimitedResponse>();
  // const [tutor, setTutor] = useState<TutorLimitedResponse>();
  // const [tutors, setTutors] = useState<TutorLimitedResponse[]>([]);
  // const [student, setStudent] = useState<StudentLimitedResponse>();
  // const [students, setStudents] = useState<StudentLimitedResponse[]>([]);
  // const [dontSpamRemoveTutorButton, setDontSpamRemoveTutorButton] =
  //   useState<boolean>(false);
  // const [dontSpamRemoveStudentButton, setDontSpamRemoveStudentButton] =
  //   useState<boolean>(false);
  // const [dontSpamAdminPromotion, setDontSpamAdminPromotion] =
  //   useState<boolean>(false);

  // const handleTutorUpForPromotionChange = (option: any) => {
  //   setTutorUpForPromotion(option);
  // };

  // const handleTutorChange = (option: any) => {
  //   setTutor(option);
  // };

  // const handleStudentChange = (option: any) => {
  //   setStudent(option);
  // };

  // const getAndSetTutors = useCallback(async () => {
  //   TutorsService.getTutorsByOrganization({
  //     orgId: currently_selected_organization as number,
  //   })
  //     .then((tutors) => {
  //       setTutors(tutors);
  //     })
  //     .catch((error) => {
  //       Notifications.error("Could not get tutors");
  //       console.error(`Error (#${error.status}): ${error.message}`);
  //     });
  // }, [currently_selected_organization]);

  // const getAndSetStudents = useCallback(async () => {
  //   StudentsService.getStudentsByOrganizationIfAdmin({
  //     orgId: currently_selected_organization as number,
  //   })
  //     .then((students) => {
  //       setStudents(students);
  //     })
  //     .catch((error) => {
  //       Notifications.error("Could not get students");
  //       console.error(`Error (#${error.status}): ${error.message}`);
  //     });
  // }, [currently_selected_organization]);

  // useEffect(() => {
  //   getAndSetTutors();
  //   getAndSetStudents();
  // }, [getAndSetStudents, getAndSetTutors]);

  const handleGracePeriodBlur = () => {
    if (gracePeriodInput === 0) {
      // If empty/0, revert to 1
      setGracePeriodInput(1);
    } else {
      const clampedValue = Math.max(1, Math.min(14, gracePeriodInput));
      setGracePeriodInput(clampedValue);
    }
  };

  useEffect(() => {
    if (error) {
      Notifications.error(error.body.detail);
    }
  }, [error]);

  const isDisabled =
    status === PageStatus.LOADING ||
    (tutorsCanViewUnassignedStudents ===
      orgSettings?.tutors_can_view_unassigned_students &&
      autoReviewAndApproveMeetingsForAdmin ===
        orgSettings?.auto_review_and_approve_meetings_for_admin &&
      autoReviewAndApproveMeetingsForTutors ===
        orgSettings?.auto_review_and_approve_meetings_for_tutors &&
      requireTutorsToAcceptMeetingsBookedByAdmins ===
        orgSettings?.require_tutors_to_accept_meetings_booked_by_admins &&
      requireTutorsToAcceptMeetingsBookedByTutors ===
        orgSettings?.require_tutors_to_accept_meetings_booked_by_tutors &&
      requireTutorsToAcceptMeetingsBookedByStudentsOrParents ===
        orgSettings?.require_tutors_to_accept_meetings_booked_by_students_or_parents &&
      gracePeriodInput ===
        orgSettings?.grace_period_for_editing_meetings_before_billing_and_payroll);

  const onClose = () => {
    setStatus(PageStatus.SUCCESS);
    setError(undefined);
    setTutorsCanViewUnassignedStudents(
      orgSettings.tutors_can_view_unassigned_students
    );
    setAutoReviewAndApproveMeetingsForAdmin(
      orgSettings.auto_review_and_approve_meetings_for_admin
    );
    setAutoReviewAndApproveMeetingsForTutors(
      orgSettings.auto_review_and_approve_meetings_for_tutors
    );
    setRequireTutorsToAcceptMeetingsBookedByAdmins(
      orgSettings.require_tutors_to_accept_meetings_booked_by_admins
    );
    setRequireTutorsToAcceptMeetingsBookedByTutors(
      orgSettings.require_tutors_to_accept_meetings_booked_by_tutors
    );
    setRequireTutorsToAcceptMeetingsBookedByStudentsOrParents(
      orgSettings.require_tutors_to_accept_meetings_booked_by_students_or_parents
    );
    setGracePeriodInput(
      orgSettings.grace_period_for_editing_meetings_before_billing_and_payroll
    );
  };

  return (
    <DialogContent
      onClose={onClose}
      className="dialog-content dialog-content--left wide bg-neutral-50 font-montserrat pb-2 min-h-[85vh] overflow-x-hidden"
      alignLeft={true}
      gradientHeader={true}
    >
      <h1 className="title mb-6">Organization Settings</h1>
      <div className="infoItem">
        <p className="label">Tutors Can :</p>
        <div className="radioContainer">
          <RadioInput
            id={"book-sessions-with-all-students"}
            name={"book-sessions-with-all-students"}
            value={"true"}
            label="See and book sessions with all students."
            setValue={() => setTutorsCanViewUnassignedStudents(true)}
            checked={tutorsCanViewUnassignedStudents}
          />
        </div>
        <div className="radioContainer">
          <RadioInput
            id={"see-sessions-with-paired-students"}
            name={"see-sessions-with-paired-students"}
            value={"false"}
            label="Only see and book sessions with students they are paired with."
            setValue={() => setTutorsCanViewUnassignedStudents(false)}
            checked={tutorsCanViewUnassignedStudents === false}
          />
        </div>
      </div>

      <div className="infoItem">
        <p className="label">Auto review and approve meetings for admin:</p>
        <div className="radioContainer">
          <RadioInput
            id={"auto-approve-admin-yes"}
            name={"auto-approve-admin"}
            value={"true"}
            label="Yes"
            setValue={() => setAutoReviewAndApproveMeetingsForAdmin(true)}
            checked={autoReviewAndApproveMeetingsForAdmin}
          />
        </div>
        <div className="radioContainer">
          <RadioInput
            id={"auto-approve-admin-no"}
            name={"auto-approve-admin"}
            value={"false"}
            label="No"
            setValue={() => setAutoReviewAndApproveMeetingsForAdmin(false)}
            checked={autoReviewAndApproveMeetingsForAdmin === false}
          />
        </div>
      </div>

      <div className="infoItem">
        <p className="label">Auto review and approve meetings for tutors:</p>
        <div className="radioContainer">
          <RadioInput
            id={"auto-approve-tutors-yes"}
            name={"auto-approve-tutors"}
            value={"true"}
            label="Yes"
            setValue={() => setAutoReviewAndApproveMeetingsForTutors(true)}
            checked={autoReviewAndApproveMeetingsForTutors}
          />
        </div>
        <div className="radioContainer">
          <RadioInput
            id={"auto-approve-tutors-no"}
            name={"auto-approve-tutors"}
            value={"false"}
            label="No"
            setValue={() => setAutoReviewAndApproveMeetingsForTutors(false)}
            checked={autoReviewAndApproveMeetingsForTutors === false}
          />
        </div>
      </div>

      <div className="infoItem">
        <p className="label">
          Require tutors to accept meetings booked by admins:
        </p>
        <div className="radioContainer">
          <RadioInput
            id={"require-admin-yes"}
            name={"require-admin"}
            value={"true"}
            label="Yes"
            setValue={() =>
              setRequireTutorsToAcceptMeetingsBookedByAdmins(true)
            }
            checked={requireTutorsToAcceptMeetingsBookedByAdmins}
          />
        </div>
        <div className="radioContainer">
          <RadioInput
            id={"require-admin-no"}
            name={"require-admin"}
            value={"false"}
            label="No"
            setValue={() =>
              setRequireTutorsToAcceptMeetingsBookedByAdmins(false)
            }
            checked={requireTutorsToAcceptMeetingsBookedByAdmins === false}
          />
        </div>
      </div>

      <div className="infoItem">
        <p className="label">
          Require tutors to accept meetings booked by tutors:
        </p>
        <div className="radioContainer">
          <RadioInput
            id={"require-tutor-yes"}
            name={"require-tutor"}
            value={"true"}
            label="Yes"
            setValue={() =>
              setRequireTutorsToAcceptMeetingsBookedByTutors(true)
            }
            checked={requireTutorsToAcceptMeetingsBookedByTutors}
          />
        </div>
        <div className="radioContainer">
          <RadioInput
            id={"require-tutor-no"}
            name={"require-tutor"}
            value={"false"}
            label="No"
            setValue={() =>
              setRequireTutorsToAcceptMeetingsBookedByTutors(false)
            }
            checked={requireTutorsToAcceptMeetingsBookedByTutors === false}
          />
        </div>
      </div>

      <div className="infoItem">
        <p className="label">
          Require tutors to accept meetings booked by students or parents:
        </p>
        <div className="radioContainer">
          <RadioInput
            id={"require-students-yes"}
            name={"require-students"}
            value={"true"}
            label="Yes"
            setValue={() =>
              setRequireTutorsToAcceptMeetingsBookedByStudentsOrParents(true)
            }
            checked={requireTutorsToAcceptMeetingsBookedByStudentsOrParents}
          />
        </div>
        <div className="radioContainer">
          <RadioInput
            id={"require-students-no"}
            name={"require-students"}
            value={"false"}
            label="No"
            setValue={() =>
              setRequireTutorsToAcceptMeetingsBookedByStudentsOrParents(false)
            }
            checked={
              requireTutorsToAcceptMeetingsBookedByStudentsOrParents === false
            }
          />
        </div>
      </div>

      <div className="infoItem">
        <p className="label">
          Grace period for editing meetings before billing and payroll (must be
          between 1 and 14 days):
        </p>
        <input
          type="number"
          style={{
            borderRadius: "8px",
            border: "1px solid #ccc",
            backgroundColor: "#e6ffe6",
            padding: "5px",
            fontSize: "14px",
            width: "100px",
          }}
          value={gracePeriodInput}
          onChange={(e) => {
            setGracePeriodInput(parseInt(e.target.value) || 1);
          }}
          onBlur={handleGracePeriodBlur}
        />
      </div>

      {/* The following block remains commented as requested */}
      {/* <div>
        <p className="text-sm font-bold mb-1">Make a Tutor an Admin</p>
        <div className="flex gap-3">
          <div className="w-1/4">
            <Select
              id="tutors-up-for-promotion"
              options={tutors}
              value={tutorUpForPromotion as TutorLimitedResponse}
              getOptionLabel={(t) =>
                t.account.first_name + " " + t.account.last_name
              }
              getOptionValue={(t) => t.account.reference_id}
              placeholder="Tutor..."
              isDisabled={tutors?.length === 0}
              onChange={handleTutorUpForPromotionChange}
            />
          </div>
          <div>
            <Button
              color={
                tutorUpForPromotion === undefined ||
                tutorUpForPromotion === null ||
                dontSpamAdminPromotion
                  ? ButtonColor.GRAY
                  : ButtonColor.GREEN
              }
              disabled={
                tutorUpForPromotion === undefined ||
                tutorUpForPromotion === null ||
                dontSpamAdminPromotion
              }
              onClick={addAccountRole}
              size={ButtonSize.DEFAULT}
            >
              Make Admin
            </Button>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-bold mt-5 mb-1">Remove a Tutor</p>
        <div className="flex gap-3">
          <div className="w-1/4">
            <Select
              id="tutors"
              options={tutors}
              value={tutor as TutorLimitedResponse}
              getOptionLabel={(t) =>
                t.account.first_name + " " + t.account.last_name
              }
              getOptionValue={(t) => t.account.reference_id}
              placeholder="Tutor..."
              isDisabled={tutors?.length === 0}
              onChange={handleTutorChange}
            />
          </div>
          <div>
            <Button
              color={
                tutor === undefined ||
                tutor === null ||
                dontSpamRemoveTutorButton
                  ? ButtonColor.GRAY
                  : ButtonColor.GREEN
              }
              disabled={
                tutor === undefined ||
                tutor === null ||
                dontSpamRemoveTutorButton
              }
              onClick={removeTutor}
              size={ButtonSize.DEFAULT}
            >
              Remove Tutor
            </Button>
          </div>
        </div>
      </div>
      <div>
        <p className="text-sm font-bold mt-5 mb-1">Remove a Student</p>
        <div className="flex gap-3">
          <div className="w-1/4">
            <Select
              id="students"
              options={students}
              value={student as StudentLimitedResponse}
              getOptionLabel={(s) =>
                s.account.first_name + " " + s.account.last_name
              }
              getOptionValue={(s) => s.account.reference_id}
              placeholder="Student..."
              isDisabled={students?.length === 0}
              onChange={handleStudentChange}
            />
          </div>
          <div>
            <Button
              color={
                student === undefined ||
                student === null ||
                dontSpamRemoveStudentButton
                  ? ButtonColor.GRAY
                  : ButtonColor.GREEN
              }
              disabled={
                student === undefined ||
                student === null ||
                dontSpamRemoveStudentButton
              }
              onClick={removeStudent}
              size={ButtonSize.DEFAULT}
            >
              Remove Student
            </Button>
          </div>
        </div>
      </div> */}

      <DialogActions>
        <div className="buttons" style={{ marginTop: "30px" }}>
          <div className="actionButton">
            <Button
              color={isDisabled ? ButtonColor.GRAY : ButtonColor.GREEN}
              onClick={() => updateOrganizationSettings()}
              disabled={isDisabled}
            >
              Save
            </Button>
          </div>
          <div className="actionButton">
            <DialogClose>
              <Button color={ButtonColor.GRAY} onClick={onClose}>
                Cancel
              </Button>
            </DialogClose>
          </div>
        </div>
      </DialogActions>
    </DialogContent>
  );
}
