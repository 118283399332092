import { Dialog, DialogContent, DialogTrigger } from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { TrashIcon } from "@radix-ui/react-icons";
import { CRMVisualizationColumnResponse } from "client/openapi";
import { VisualizationsService } from "client/openapi";
import Notifications from "util/notifications";

interface DeleteColumnDialogProps {
  column: CRMVisualizationColumnResponse;
  onDelete: () => void;
}

export default function DeleteColumnDialog({
  column,
  onDelete,
}: DeleteColumnDialogProps) {
  const handleDelete = async () => {
    try {
      await VisualizationsService.deleteVisualizationColumn({
        visualizationId: column.visualization_id,
        columnId: column.id,
      });
      onDelete();
      Notifications.success("Column deleted successfully");
    } catch (error) {
      console.error("Error deleting column:", error);
      Notifications.error("Failed to delete column");
    }
  };

  return (
    <DialogContent className="dialog-content">
      <div className="p-4">
        <h2 className="text-lg font-bold mb-4 text-center">Delete Column</h2>
        <p className="mb-4 text-center">
          Are you sure you want to delete the "{column.name}" column? This
          action cannot be undone.
        </p>
        <div className="flex justify-center gap-2">
          <Button
            color={ButtonColor.GRAY}
            onClick={() => {
              const dialog = document.querySelector('[role="dialog"]');
              if (dialog) {
                (dialog as HTMLElement).style.display = "none";
              }
            }}
          >
            Cancel
          </Button>
          <Button color={ButtonColor.RED} onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
    </DialogContent>
  );
}
