import { useState, useEffect, useMemo } from "react";
import {
  ManageCrmService,
  ApiError,
  CustomDataTypeKeyResponse,
} from "client/openapi";
import { DialogContent, DialogActions, DialogClose } from "components/Dialog";
import { Button, ButtonColor } from "components/Button";
import Notifications from "util/notifications";
import { PageStatus } from "types";

export default function CreateCustomType({
  organizationId,
  keys,
  setKeys,
}: {
  organizationId: number;
  keys: CustomDataTypeKeyResponse[];
  setKeys: (keys: CustomDataTypeKeyResponse[]) => void;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<ApiError>();
  const [name, setName] = useState("");
  // New state for capturing multiline values input
  const [valuesText, setValuesText] = useState("");

  // Keep track of duplicate lines
  const [duplicateValues, setDuplicateValues] = useState<string[]>([]);

  // 1. Parse non-empty lines from the textarea
  const nonEmptyLines = useMemo(() => {
    return valuesText
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0);
  }, [valuesText]);

  useEffect(() => {
    const seen = new Set<string>();
    const duplicates: string[] = [];

    for (const line of nonEmptyLines) {
      if (seen.has(line.toLowerCase())) {
        duplicates.push(line);
      } else {
        seen.add(line.toLowerCase());
      }
    }

    setDuplicateValues(duplicates);
  }, [nonEmptyLines]);

  async function handleCreateCustomType() {
    setStatus(PageStatus.LOADING);

    const lines = valuesText
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0);

    // Construct the request payload for new custom data type + its values
    const requestBody = {
      key_create: {
        org_id: organizationId,
        name: name,
      },
      values_create: lines.map((optionName) => ({
        option_name: optionName,
      })),
    };

    ManageCrmService.createCustomDataType({
      requestBody,
    })
      .then((res) => {
        setKeys([...keys, res]);
        setStatus(PageStatus.SUCCESS);
        clearFormValues();
        Notifications.success("Custom data type created!");
      })
      .catch((e) => {
        setStatus(PageStatus.ERROR);
        setError(e);
      });
  }

  // 3. Disable create if...
  // - status is loading
  // - name is empty
  // - no lines entered
  // - there are duplicates
  const isCreateDisabled =
    status === PageStatus.LOADING ||
    !name.trim() ||
    nonEmptyLines.length === 0 ||
    duplicateValues.length > 0;

  const clearFormValues = () => {
    setName("");
    setValuesText("");
  };

  useEffect(() => {
    if (error) {
      Notifications.error(
        error?.body.detail || "An unexpected error occurred."
      );
    }
  }, [error]);

  return (
    <DialogContent className="dialog-content" onClose={clearFormValues}>
      <form>
        {/* Custom Field Name */}
        <label htmlFor="customFieldName">Custom Field Name</label>
        <input
          id="customFieldName"
          type="text"
          className="input mt-2 mb-4"
          placeholder="Enter a name for your custom field"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        {/* Multiline Text Area for Values */}
        <label htmlFor="customFieldValues" className="mt-2">
          Enter values, one per line:
        </label>
        <textarea
          id="customFieldValues"
          className="input"
          style={{ minHeight: "320px" }}
          placeholder="Enter each value on a new line"
          value={valuesText}
          onChange={(e) => setValuesText(e.target.value)}
        />

        {/* 4. Show an error if duplicates were found */}
        {duplicateValues.length > 0 && (
          <div className="text-red-500 text-sm mt-2">
            <strong>Duplicate values detected:</strong>{" "}
            {duplicateValues.join(", ")}
          </div>
        )}

        <div className="mt-3">
          <DialogActions>
            <Button
              primary
              color={isCreateDisabled ? ButtonColor.GRAY : ButtonColor.PURPLE}
              disabled={isCreateDisabled}
              onClick={handleCreateCustomType}
            >
              Create &rarr;
            </Button>

            <DialogClose asChild>
              <Button
                color={ButtonColor.GRAY}
                disabled={status === PageStatus.LOADING}
                onClick={clearFormValues}
              >
                Cancel
              </Button>
            </DialogClose>
          </DialogActions>
        </div>
      </form>
    </DialogContent>
  );
}
