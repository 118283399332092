/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationSubjectAdminResponse } from "../models/OrganizationSubjectAdminResponse";
import type { OrganizationSubjectCreate } from "../models/OrganizationSubjectCreate";
import type { OrganizationSubjectPublicResponse } from "../models/OrganizationSubjectPublicResponse";
import type { OrganizationSubjectUpdate } from "../models/OrganizationSubjectUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OrgSubjectsService {
  /**
   * Get Subjects By Org
   * Get all organization subjects for a specified organization from the database.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getSubjectsByOrg({
    orgId,
    active,
    skip,
    limit = 1000,
  }: {
    orgId: number;
    active?: boolean | null;
    skip?: number;
    limit?: number;
  }): CancelablePromise<
    | Array<OrganizationSubjectAdminResponse>
    | Array<OrganizationSubjectPublicResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/org_subjects/organizations/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        active: active,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Org Subject
   * Create a new organization subject and returns the resulting subject from the database.
   * @returns OrganizationSubjectAdminResponse Successful Response
   * @throws ApiError
   */
  public static createOrgSubject({
    requestBody,
  }: {
    requestBody: OrganizationSubjectCreate;
  }): CancelablePromise<OrganizationSubjectAdminResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/org_subjects",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Subject
   * Update an organization subject by its ID using specified attributes
   * within OrganizationSubjectUpdate to update the database and returns
   * the updated subject.
   * @returns OrganizationSubjectAdminResponse Successful Response
   * @throws ApiError
   */
  public static updateSubject({
    subjectId,
    requestBody,
  }: {
    subjectId: number;
    requestBody: OrganizationSubjectUpdate;
  }): CancelablePromise<OrganizationSubjectAdminResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/org_subjects/{subject_id}",
      path: {
        subject_id: subjectId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
