import { useContext, useEffect, useRef, useState } from "react";
import { APIResponse, PageStatus } from "types";
import { getRoleColor } from "util/contextColor";
import Notifications from "util/notifications";
import "./index.css";

import {
  ApiError,
  Auth0AccountRole,
  StudentLimitedResponse,
  OrganizationDataResponse,
  OrganizationDataValueResponse,
  OrganizationDataKeyResponse,
  DataType,
  OrganizationDataValueCreate,
  OrganizationDataValueUpdate,
  ParentLimitedResponse,
} from "client/openapi";

import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { QueryCrmService, ManageCrmService } from "client/openapi";
import { DatePicker } from "rsuite";
import { Select } from "components/Select";

/**
 * This component:
 * 1) Keeps "orgData" in state to display the DB values.
 * 2) For TEXT/FLOAT, we maintain separate "draftEdits" so we don't overwrite
 *    DB values in real-time. On blur, we check if there's an actual change:
 *    - If the user typed something, we compare to old DB value
 *    - If changed => create/update; if same => do nothing
 *    - If the user never typed => do nothing
 * 3) For BOOLEAN/DATE/CUSTOM, we save immediately on change.
 * 4) Clearing a select sets the value to null => triggers delete.
 */
function UserDataDialog({
  beingViewedBy,
  userType,
  student,
  parent,
  dialogRef,
}: {
  beingViewedBy: Auth0AccountRole;
  userType: Auth0AccountRole;
  student?: StudentLimitedResponse;
  parent?: ParentLimitedResponse;
  dialogRef?: React.RefObject<HTMLDivElement>;
}) {
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse | null>(null);

  /**
   * orgData: array of OrganizationDataResponse from the DB
   */
  const [orgData, setOrgData] = useState<OrganizationDataResponse[]>([]);

  /**
   * draftEdits: for TEXT/FLOAT, we store typed input by keyId
   * If a keyId is absent, user never typed.
   */
  const [draftEdits, setDraftEdits] = useState<{ [keyId: number]: string }>({});

  /**
   * savingKeys: which key IDs are "Saving..."
   */
  const [savingKeys, setSavingKeys] = useState<number[]>([]);

  const MAX_TEXT_LENGTH = 300;

  const { account } = useContext(OrgRolesAndAccountContext);

  /** Fetch data from new CRM endpoint */
  async function fetchData() {
    setStatus(PageStatus.LOADING);

    if (userType === Auth0AccountRole.ME) {
      QueryCrmService.getOrgDataForStudent({
        orgId: student?.org_id as number,
        studentId: student?.id as number,
      })
        .then((dataResult) => {
          setOrgData(dataResult);
          setStatus(PageStatus.SUCCESS);
        })
        .catch((e: any) => {
          setStatus(PageStatus.ERROR);
          setError({ message: "An unexpected error occurred" });
          console.error(`Error (#${e?.code}): ${e?.message}`);
        });
    } else if (userType === Auth0AccountRole.PARENT) {
      QueryCrmService.getOrgDataForParent({
        orgId: parent?.org_id as number,
        parentId: parent?.id as number,
      })
        .then((dataResult) => {
          setOrgData(dataResult);
          setStatus(PageStatus.SUCCESS);
        })
        .catch((e: any) => {
          setStatus(PageStatus.ERROR);
          setError({ message: "An unexpected error occurred" });
          console.error(`Error (#${e?.code}): ${e?.message}`);
        });
    }
  }

  useEffect(() => {
    if (account) {
      fetchData();
    }
  }, [account]);

  useEffect(() => {
    if (error) {
      Notifications.error(error.message || "An unexpected error occurred.");
    }
  }, [error]);

  // -------------------------
  //    CRUD Logic
  // -------------------------

  function buildCreatePayload(
    key: OrganizationDataKeyResponse,
    rawValue: string | number | boolean | null
  ): OrganizationDataValueCreate {
    const { id: org_data_key_id } = key;
    const payload: OrganizationDataValueCreate = {
      org_data_key_id,
      student_id: userType === Auth0AccountRole.ME ? student?.id : undefined,
      parent_id: userType === Auth0AccountRole.PARENT ? parent?.id : undefined,
    };

    switch (key.data_type) {
      case DataType.TEXT:
        payload.content_text = rawValue as string;
        break;
      case DataType.FLOAT:
        payload.content_float = rawValue as number;
        break;
      case DataType.DATE:
        payload.content_date = rawValue as string;
        break;
      case DataType.BOOLEAN:
        payload.content_bool = rawValue as boolean;
        break;
      case DataType.CUSTOM_DATA_TYPE:
        payload.custom_data_type_value_id = rawValue as number;
        break;
    }
    return payload;
  }

  function buildUpdatePayload(
    key: OrganizationDataKeyResponse,
    rawValue: string | number | boolean | null
  ): OrganizationDataValueUpdate {
    return {
      content_text:
        key.data_type === DataType.TEXT ? (rawValue as string) : null,
      content_float:
        key.data_type === DataType.FLOAT ? (rawValue as number) : null,
      content_date:
        key.data_type === DataType.DATE ? (rawValue as string) : null,
      content_bool:
        key.data_type === DataType.BOOLEAN ? (rawValue as boolean) : null,
      custom_data_type_value_id:
        key.data_type === DataType.CUSTOM_DATA_TYPE
          ? (rawValue as number)
          : null,
    };
  }

  async function createValue(
    key: OrganizationDataKeyResponse,
    newValue: string | number | boolean | null
  ) {
    // text length check
    if (key.data_type === DataType.TEXT && typeof newValue === "string") {
      if (newValue.length > MAX_TEXT_LENGTH) {
        setError({
          message: `Value for "${key.name}" exceeds ${MAX_TEXT_LENGTH} chars.`,
        });
        return;
      }
    }
    setSavingKeys((prev) => [...prev, key.id]);

    const payload = buildCreatePayload(key, newValue);
    try {
      const createdValue = await ManageCrmService.createOrgDataValue({
        requestBody: payload,
      });
      updateOrgDataInState(key.id, createdValue);
      Notifications.success(`"${key.name}" created!`);
    } catch (err) {
      const e = err as ApiError;
      setStatus(PageStatus.ERROR);
      setError({ message: "Could not create data" });
      console.error(`Error (#${e.status}): ${e.message}`);
    } finally {
      setSavingKeys((prev) => prev.filter((k) => k !== key.id));
    }
  }

  async function updateValue(
    key: OrganizationDataKeyResponse,
    valueRecord: OrganizationDataValueResponse,
    newValue: string | number | boolean | null
  ) {
    // If user clears => delete
    if (newValue == null || newValue === "") {
      return deleteValue(key, valueRecord);
    }
    // text length check
    if (key.data_type === DataType.TEXT && typeof newValue === "string") {
      if (newValue.length > MAX_TEXT_LENGTH) {
        setError({
          message: `Value for "${key.name}" exceeds ${MAX_TEXT_LENGTH} chars.`,
        });
        return;
      }
    }

    setSavingKeys((prev) => [...prev, key.id]);
    const payload = buildUpdatePayload(key, newValue);
    try {
      const updatedValue = await ManageCrmService.updateOrgDataValue({
        orgDataValueId: valueRecord.id,
        requestBody: payload,
      });
      updateOrgDataInState(key.id, updatedValue);
      Notifications.success(`"${key.name}" updated!`);
    } catch (err) {
      const e = err as ApiError;
      setStatus(PageStatus.ERROR);
      setError({ message: "Could not update data" });
      console.error(`Error (#${e.status}): ${e.message}`);
    } finally {
      setSavingKeys((prev) => prev.filter((k) => k !== key.id));
    }
  }

  async function deleteValue(
    key: OrganizationDataKeyResponse,
    valueRecord: OrganizationDataValueResponse
  ) {
    setSavingKeys((prev) => [...prev, key.id]);
    try {
      await ManageCrmService.deleteOrgDataValue({
        orgDataValueId: valueRecord.id,
      });
      updateOrgDataInState(key.id, null);
      Notifications.success(`"${key.name}" deleted!`);
    } catch (err) {
      const e = err as ApiError;
      setStatus(PageStatus.ERROR);
      setError({ message: "Could not delete data" });
      console.error(`Error (#${e.status}): ${e.message}`);
    } finally {
      setSavingKeys((prev) => prev.filter((k) => k !== key.id));
    }
  }

  function updateOrgDataInState(
    keyId: number,
    newValue: OrganizationDataValueResponse | null
  ) {
    setOrgData((prev) =>
      prev.map((item) =>
        item.org_data_key.id === keyId
          ? { ...item, org_data_value: newValue }
          : item
      )
    );
    // Clear out any draft for this key
    setDraftEdits((prev) => {
      const copy = { ...prev };
      delete copy[keyId];
      return copy;
    });
  }

  // ------------------------------------
  // TEXT & FLOAT (Draft-based approach)
  // ------------------------------------

  /**
   * handleTypingChange:
   *   - Record typed input in draftEdits. If user never types, draftEdits won't have an entry.
   */
  function handleTypingChange(keyId: number, newVal: string) {
    setDraftEdits((prev) => ({
      ...prev,
      [keyId]: newVal,
    }));
  }

  /**
   * handleBlurInput:
   *   1) If user never typed for this key, do nothing. (Prevents deleting on click in/out.)
   *   2) Else compare draft vs. DB. If unchanged => do nothing.
   *   3) If changed => create/update or delete if empty.
   */
  function handleBlurInput(
    key: OrganizationDataKeyResponse,
    orgVal: OrganizationDataValueResponse | null
  ) {
    const keyId = key.id;
    // (1) If user never typed for this key => do nothing
    if (draftEdits[keyId] === undefined) {
      return;
    }

    // The draft the user typed
    const draft = draftEdits[keyId];

    if (key.data_type === DataType.TEXT) {
      const oldVal = orgVal?.content_text ?? "";
      if (draft === oldVal) {
        return; // no change
      }

      // changed => create or update
      if (!orgVal || !orgVal.id) {
        createValue(key, draft);
      } else {
        updateValue(key, orgVal, draft);
      }
      return;
    }

    if (key.data_type === DataType.FLOAT) {
      const oldVal = orgVal?.content_float ?? null;
      if (!draft) {
        // user typed empty => user wants to delete?
        if (oldVal == null) {
          return; // no change
        }
        // we do have an existing value => delete
        if (orgVal && orgVal.id) {
          deleteValue(key, orgVal);
        }
        return;
      }

      // parse the typed draft
      const parsed = parseFloat(draft);
      if (isNaN(parsed)) {
        setError({ message: `Invalid float for "${key.name}".` });
        setDraftEdits((prev) => ({ ...prev, [keyId]: "" }));
        return;
      }

      let clampedVal = parsed;
      if (
        typeof key.min_value_if_float === "number" &&
        clampedVal < key.min_value_if_float
      ) {
        clampedVal = key.min_value_if_float;
      }
      if (
        typeof key.max_value_if_float === "number" &&
        clampedVal > key.max_value_if_float
      ) {
        clampedVal = key.max_value_if_float;
      }

      if (oldVal === clampedVal) {
        return; // no change
      }

      if (!orgVal || !orgVal.id) {
        // create
        createValue(key, clampedVal);
      } else {
        // update
        updateValue(key, orgVal, clampedVal);
      }
    }
  }

  // -------------------------------------------
  // BOOLEAN, DATE, CUSTOM => Immediate changes
  // -------------------------------------------
  function handleImmediateChange(
    key: OrganizationDataKeyResponse,
    orgVal: OrganizationDataValueResponse | null,
    newValue: string | number | boolean | null
  ) {
    if (!orgVal || !orgVal.id) {
      // no record => create
      createValue(key, newValue);
    } else {
      // existing => update
      updateValue(key, orgVal, newValue);
    }
  }

  // -------------------------------------------
  // Renders each data field with logic
  // -------------------------------------------
  function renderField(item: OrganizationDataResponse) {
    const { org_data_key, org_data_value } = item;
    const {
      data_type,
      min_value_if_float,
      max_value_if_float,
      min_value_if_date,
      max_value_if_date,
    } = org_data_key;

    switch (data_type) {
      case DataType.TEXT: {
        const typed =
          draftEdits[org_data_key.id] ?? org_data_value?.content_text ?? "";
        return (
          <input
            type="text"
            className="mt-1 input"
            placeholder="Enter text..."
            disabled={savingKeys.includes(org_data_key.id)}
            value={typed}
            maxLength={MAX_TEXT_LENGTH}
            onChange={(e) =>
              handleTypingChange(org_data_key.id, e.target.value)
            }
            onBlur={() => handleBlurInput(org_data_key, org_data_value)}
          />
        );
      }

      case DataType.FLOAT: {
        const typed =
          draftEdits[org_data_key.id] ??
          (org_data_value?.content_float != null
            ? String(org_data_value.content_float)
            : "");
        return (
          <input
            type="number"
            step="any"
            className="mt-1 input"
            disabled={savingKeys.includes(org_data_key.id)}
            value={typed}
            min={min_value_if_float ?? undefined}
            max={max_value_if_float ?? undefined}
            onChange={(e) =>
              handleTypingChange(org_data_key.id, e.target.value)
            }
            onBlur={() => handleBlurInput(org_data_key, org_data_value)}
          />
        );
      }

      case DataType.BOOLEAN: {
        const boolSelected =
          org_data_value?.content_bool === true
            ? { value: "true", label: "Yes" }
            : org_data_value?.content_bool === false
            ? { value: "false", label: "No" }
            : null;
        return (
          <Select
            className="mt-1"
            isDisabled={savingKeys.includes(org_data_key.id)}
            value={boolSelected}
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            placeholder="--Select--"
            onChange={(option) => {
              if (!option) {
                // user cleared => null => triggers delete
                handleImmediateChange(org_data_key, org_data_value, null);
              } else {
                const boolVal = option.value === "true" ? true : false;
                handleImmediateChange(org_data_key, org_data_value, boolVal);
              }
            }}
            isClearable
          />
        );
      }

      case DataType.DATE: {
        const currentDateValue = org_data_value?.content_date
          ? new Date(org_data_value.content_date)
          : null;
        const minDate = min_value_if_date
          ? new Date(min_value_if_date)
          : undefined;
        const maxDate = max_value_if_date
          ? new Date(max_value_if_date)
          : undefined;

        return (
          <DatePicker
            oneTap
            className="mt-1 input"
            value={currentDateValue}
            size="xs"
            format="yyyy-MM-dd"
            placement="bottomStart"
            disabled={savingKeys.includes(org_data_key.id)}
            disabledDate={(date) => {
              if (!date) return false;
              if (minDate && date < minDate) return true;
              if (maxDate && date > maxDate) return true;
              return false;
            }}
            onChange={(val) => {
              const isoString = val
                ? (val as Date).toISOString().slice(0, 10)
                : null;
              handleImmediateChange(org_data_key, org_data_value, isoString);
            }}
            container={() => dialogRef?.current || document.body}
            cleanable={true}
          />
        );
      }

      case DataType.CUSTOM_DATA_TYPE: {
        const fieldOptions = org_data_key.custom_data_type_key?.values || [];
        const selectedId = org_data_value?.custom_data_type_value?.id || "";
        const selectOptions = fieldOptions.map((opt) => ({
          value: opt.id.toString(),
          label: opt.option_name,
        }));

        const customSelected = selectedId
          ? selectOptions.find((opt) => opt.value === selectedId.toString())
          : null;

        return (
          <Select
            className="mt-1"
            isDisabled={savingKeys.includes(org_data_key.id)}
            value={customSelected}
            options={selectOptions}
            placeholder="--Select--"
            onChange={(option) => {
              if (!option) {
                handleImmediateChange(org_data_key, org_data_value, null);
              } else {
                const valNum = parseInt(option.value);
                handleImmediateChange(org_data_key, org_data_value, valNum);
              }
            }}
            isClearable
          />
        );
      }

      default:
        return null;
    }
  }

  return (
    <div className="org-data--dialog">
      {status === PageStatus.LOADING && (
        <div className="flex flex-col items-center justify-center gap-4 py-5">
          <span className="spinner-border" role="status" aria-hidden="true" />
          <p>Loading...</p>
        </div>
      )}

      {status === PageStatus.SUCCESS && orgData.length === 0 && (
        <p>No custom fields have been defined for this student.</p>
      )}

      {status === PageStatus.SUCCESS && orgData.length > 0 && (
        <div className="org-data">
          {orgData
            .sort((a, b) =>
              a.org_data_key.name.localeCompare(b.org_data_key.name)
            )
            .map((item) => (
              <section
                className={`org-data--pair ${getRoleColor(beingViewedBy)}`}
                key={item.org_data_key.id}
              >
                <div className="org-data--status">
                  <label className="leading-5 input-label">
                    {item.org_data_key.name}
                  </label>
                  <span>
                    {savingKeys.includes(item.org_data_key.id)
                      ? "Saving..."
                      : ""}
                  </span>
                </div>
                {renderField(item)}
              </section>
            ))}
        </div>
      )}
    </div>
  );
}

export default UserDataDialog;
