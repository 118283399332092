/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomDataTypeKeyAndValuesCreate } from "../models/CustomDataTypeKeyAndValuesCreate";
import type { CustomDataTypeKeyResponse } from "../models/CustomDataTypeKeyResponse";
import type { CustomDataTypeKeyUpdate } from "../models/CustomDataTypeKeyUpdate";
import type { CustomDataTypeValueCreate } from "../models/CustomDataTypeValueCreate";
import type { CustomDataTypeValueResponse } from "../models/CustomDataTypeValueResponse";
import type { CustomDataTypeValueUpdate } from "../models/CustomDataTypeValueUpdate";
import type { DeleteResponse } from "../models/DeleteResponse";
import type { OrganizationDataKeyCreate } from "../models/OrganizationDataKeyCreate";
import type { OrganizationDataKeyResponse } from "../models/OrganizationDataKeyResponse";
import type { OrganizationDataKeyUpdate } from "../models/OrganizationDataKeyUpdate";
import type { OrganizationDataValueCreate } from "../models/OrganizationDataValueCreate";
import type { OrganizationDataValueResponse } from "../models/OrganizationDataValueResponse";
import type { OrganizationDataValueUpdate } from "../models/OrganizationDataValueUpdate";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ManageCrmService {
  /**
   * Get Student Data Keys
   * Get all student data keys from the database.
   * @returns OrganizationDataKeyResponse Successful Response
   * @throws ApiError
   */
  public static getStudentDataKeys({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<OrganizationDataKeyResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/manage_crm/keys/by_org/{org_id}/students",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parent Data Keys
   * Get all parent data keys from the database.
   * @returns OrganizationDataKeyResponse Successful Response
   * @throws ApiError
   */
  public static getParentDataKeys({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<OrganizationDataKeyResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/manage_crm/keys/by_org/{org_id}/parents",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Data Keys
   * Get all tutor data keys from the database.
   * @returns OrganizationDataKeyResponse Successful Response
   * @throws ApiError
   */
  public static getTutorDataKeys({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<OrganizationDataKeyResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/manage_crm/keys/by_org/{org_id}/tutors",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Org Data Key
   * Create a new org data key and returns the resulting key from the database.
   * @returns OrganizationDataKeyResponse Successful Response
   * @throws ApiError
   */
  public static createOrgDataKey({
    requestBody,
  }: {
    requestBody: OrganizationDataKeyCreate;
  }): CancelablePromise<OrganizationDataKeyResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/manage_crm/keys",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Org Data Key
   * Update a org data key by its ID using specified attributes
   * within OrganizationDataKeyUpdate to update the database and
   * return the updated org data key.
   * @returns OrganizationDataKeyResponse Successful Response
   * @throws ApiError
   */
  public static updateOrgDataKey({
    orgDataKeyId,
    requestBody,
  }: {
    orgDataKeyId: number;
    requestBody: OrganizationDataKeyUpdate;
  }): CancelablePromise<OrganizationDataKeyResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/manage_crm/keys/{org_data_key_id}",
      path: {
        org_data_key_id: orgDataKeyId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Org Data Key
   * Delete an org data key by its ID from the database.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteOrgDataKey({
    orgDataKeyId,
  }: {
    orgDataKeyId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/manage_crm/keys/{org_data_key_id}",
      path: {
        org_data_key_id: orgDataKeyId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Org Data Value
   * Create a new org data value and returns the resulting value from the database.
   * @returns OrganizationDataValueResponse Successful Response
   * @throws ApiError
   */
  public static createOrgDataValue({
    requestBody,
  }: {
    requestBody: OrganizationDataValueCreate;
  }): CancelablePromise<OrganizationDataValueResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/manage_crm/values",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Org Data Value
   * Update a org data value by its ID using specified attributes
   * within OrganizationDataValueUpdate to update the database and
   * return the updated org data value.
   * @returns OrganizationDataValueResponse Successful Response
   * @throws ApiError
   */
  public static updateOrgDataValue({
    orgDataValueId,
    requestBody,
  }: {
    orgDataValueId: number;
    requestBody: OrganizationDataValueUpdate;
  }): CancelablePromise<OrganizationDataValueResponse> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/manage_crm/values/{org_data_value_id}",
      path: {
        org_data_value_id: orgDataValueId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Org Data Value
   * Delete an org data value by its ID from the database.
   * @returns DeleteResponse Successful Response
   * @throws ApiError
   */
  public static deleteOrgDataValue({
    orgDataValueId,
  }: {
    orgDataValueId: number;
  }): CancelablePromise<DeleteResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/manage_crm/values/{org_data_value_id}",
      path: {
        org_data_value_id: orgDataValueId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Custom Data Types
   * Get all custom data type keys for an organization.
   * @returns CustomDataTypeKeyResponse Successful Response
   * @throws ApiError
   */
  public static getCustomDataTypes({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<CustomDataTypeKeyResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/manage_crm/custom_data_types/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Custom Data Type
   * Create a new custom data type key and returns the resulting key from the database.
   * @returns CustomDataTypeKeyResponse Successful Response
   * @throws ApiError
   */
  public static createCustomDataType({
    requestBody,
  }: {
    requestBody: CustomDataTypeKeyAndValuesCreate;
  }): CancelablePromise<CustomDataTypeKeyResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/manage_crm/custom_data_type/{org_data_key_id}",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Custom Data Type Value
   * Create a new custom data type value for an existing key and
   * return the resulting values from the database.
   * @returns CustomDataTypeValueResponse Successful Response
   * @throws ApiError
   */
  public static createCustomDataTypeValue({
    requestBody,
  }: {
    requestBody: Array<CustomDataTypeValueCreate>;
  }): CancelablePromise<Array<CustomDataTypeValueResponse>> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/manage_crm/add_values_to_key",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Custom Data Type Key
   * Update an existing custom data type key and return the updated key from the database.
   * @returns CustomDataTypeKeyResponse Successful Response
   * @throws ApiError
   */
  public static updateCustomDataTypeKey({
    keyId,
    requestBody,
  }: {
    keyId: number;
    requestBody: CustomDataTypeKeyUpdate;
  }): CancelablePromise<CustomDataTypeKeyResponse> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/manage_crm/custom-data-type-keys/{key_id}",
      path: {
        key_id: keyId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Custom Data Type Key
   * Delete an existing custom data type key.
   * @returns void
   * @throws ApiError
   */
  public static deleteCustomDataTypeKey({
    keyId,
  }: {
    keyId: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/manage_crm/custom-data-type-keys/{key_id}",
      path: {
        key_id: keyId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Update Custom Data Type Value
   * Update an existing custom data type value and return the updated value from the database.
   * @returns CustomDataTypeValueResponse Successful Response
   * @throws ApiError
   */
  public static updateCustomDataTypeValue({
    valueId,
    requestBody,
  }: {
    valueId: number;
    requestBody: CustomDataTypeValueUpdate;
  }): CancelablePromise<CustomDataTypeValueResponse> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/manage_crm/custom-data-type-values/{value_id}",
      path: {
        value_id: valueId,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Delete Custom Data Type Value
   * Delete an existing custom data type value.
   * @returns void
   * @throws ApiError
   */
  public static deleteCustomDataTypeValue({
    valueId,
  }: {
    valueId: number;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/manage_crm/custom-data-type-values/{value_id}",
      path: {
        value_id: valueId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
