import { useMediaQuery } from "usehooks-ts";
import { Select } from "components/Select";
import { IconType } from "react-icons";
import "./index.css";

export default function TabNavbar({
  activeTab,
  handleTabClick,
  options,
  classes,
}: {
  activeTab: string;
  handleTabClick: (tab: string) => void;
  options: {
    value: string;
    icon: any;
  }[];
  classes?: string;
}) {
  const isMedium = useMediaQuery("(max-width: 768px)");

  return isMedium ? (
    <Select
      options={options.map((option) => ({
        value: option.value,
        label: option.value,
      }))}
      onChange={(selectedOption) => {
        if (selectedOption) {
          handleTabClick(selectedOption.value);
        }
      }}
      value={{ value: activeTab, label: activeTab }}
      className="w-1/2"
    />
  ) : (
    <div className={`border-b ${classes}`}>
      <ul className="list-none flex flex-row space-between gap-x-10 pl-6">
        {options.map((option) => (
          <li
            key={option.value}
            className={`tab-item pr-4 mr-6 ${
              activeTab === option.value ? "active-tab" : ""
            }`}
            onClick={() => handleTabClick(option.value)}
          >
            {activeTab === option.value ? (
              <option.icon className="w-6 h-6" />
            ) : (
              <option.icon className="w-6 h-6 tab-text" />
            )}
            <span
              className={
                activeTab === option.value ? `active-tab-text` : `tab-text`
              }
            >
              {option.value}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
