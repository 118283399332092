import {
  ApiError,
  Auth0AccountRole,
  CustomDataTypeKeyResponse,
  ManageCrmService,
  OrganizationDataKeyResponse,
  OrganizationResponseForAdmins,
} from "client/openapi";
import { useEffect, useState } from "react";
import Notifications from "util/notifications";
import { APIResponse, PageStatus } from "types";

import CustomTypes from "./CustomTypes";
import DataFields from "./UserData";

export default function CRMdata({
  organization,
}: {
  organization: OrganizationResponseForAdmins;
}) {
  const [studentKeys, setStudentKeys] = useState<OrganizationDataKeyResponse[]>(
    []
  );
  const [parentKeys, setParentKeys] = useState<OrganizationDataKeyResponse[]>(
    []
  );
  const [customKeys, setCustomKeys] = useState<CustomDataTypeKeyResponse[]>([]);
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();
  const [isLoadingStudentKeys, setIsLoadingStudentKeys] = useState(true);
  const [isLoadingParentKeys, setIsLoadingParentKeys] = useState(true);
  const [isLoadingCustomKeys, setIsLoadingCustomKeys] = useState(true);

  async function getStudentDataKeys(orgId: number) {
    setIsLoadingStudentKeys(true);
    setStatus(PageStatus.LOADING);

    await ManageCrmService.getStudentDataKeys({ orgId })
      .then((keys) => {
        setStudentKeys(keys);
        setIsLoadingStudentKeys(false);
        if (!isLoadingParentKeys) {
          setStatus(PageStatus.SUCCESS);
        }
      })
      .catch((e: ApiError) => {
        setIsLoadingStudentKeys(false);
        setStatus(PageStatus.ERROR);
        setError({ message: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function getParentDataKeys(orgId: number) {
    setIsLoadingParentKeys(true);
    setStatus(PageStatus.LOADING);

    await ManageCrmService.getParentDataKeys({ orgId })
      .then((keys) => {
        setParentKeys(keys);
        setIsLoadingParentKeys(false);
        if (!isLoadingStudentKeys) {
          setStatus(PageStatus.SUCCESS);
        }
      })
      .catch((e: ApiError) => {
        setIsLoadingParentKeys(false);
        setStatus(PageStatus.ERROR);
        setError({ message: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  async function getCustomDataTypes(orgId: number) {
    setIsLoadingCustomKeys(true);
    setStatus(PageStatus.LOADING);

    await ManageCrmService.getCustomDataTypes({ orgId })
      .then((keys) => {
        setCustomKeys(keys);
        setIsLoadingCustomKeys(false);
        if (!isLoadingStudentKeys && !isLoadingParentKeys) {
          setStatus(PageStatus.SUCCESS);
        }
      })
      .catch((e: ApiError) => {
        setIsLoadingCustomKeys(false);
        setStatus(PageStatus.ERROR);
        setError({ message: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  useEffect(() => {
    getStudentDataKeys(organization.id);
    getParentDataKeys(organization.id);
    getCustomDataTypes(organization.id);
  }, [organization.id]);

  useEffect(() => {
    if (error?.message) {
      Notifications.error(error.message);
    }
  }, [error]);

  if (isLoadingStudentKeys || isLoadingParentKeys || isLoadingCustomKeys) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-900"></div>
      </div>
    );
  }

  return (
    <div style={{ marginBottom: "150px" }}>
      <div>
        <DataFields
          organization={organization}
          keys={studentKeys}
          setKeys={setStudentKeys}
          setStatus={setStatus}
          setError={setError}
          userType={Auth0AccountRole.ME}
        />
      </div>
      <div className="mt-10">
        <DataFields
          organization={organization}
          keys={parentKeys}
          setKeys={setParentKeys}
          setStatus={setStatus}
          setError={setError}
          userType={Auth0AccountRole.PARENT}
        />
      </div>
      <div className="mt-10">
        <CustomTypes
          organization={organization}
          keys={customKeys}
          setKeys={setCustomKeys}
          setStatus={setStatus}
          setError={setError}
        />
      </div>
    </div>
  );
}
