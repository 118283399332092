import "./index.css";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import moment from "moment";
import { FaClock } from "react-icons/fa6";
import {
  PayoutTransactionResponse,
  StudentToOrgTransactionResponse,
  TadpoleToTutorTransactionResponse,
  OrgToTadpoleTransactionResponse,
  TransactionType,
  AccountingSystemForCalculatingFinancialStatistics,
  MeetingAdminResponse,
  StudentToOrgTransactionType,
  TadpoleToTutorTransactionType,
  ApprovalStatus,
  Auth0AccountRole,
  TransactionStatus,
  PaymentsService,
  PaymentMethodType,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { Dialog, DialogTrigger } from "components/Dialog";
import MeetingDialog from "components/MeetingDialog";
import { getTransactionStatusColor } from "pages/manage/students/[id]/PaymentsTab/Transactions/statusHelper";
import TransactionMeetingsPopup from "pages/manage/students/[id]/PaymentsTab/Transactions/TransactionMeetingDetails";
import { useNavigate } from "react-router-dom";
import Notifications from "util/notifications";
import { useState } from "react";

// Human-readable helpers
export const studentToOrgTransactionTypeToHumanReadable = [
  { value: StudentToOrgTransactionType.DIRECT, label: "Direct charge" },
  { value: StudentToOrgTransactionType.UPFRONT, label: "Upfront charge" },
  {
    value: StudentToOrgTransactionType.AUTOMATIC_CHARGE_FOR_A_GROUP_OF_MEETINGS,
    label: "Automatic Charge",
  },
  {
    value: StudentToOrgTransactionType.MEMBER_OF_AUTOMATIC_CHARGE_GROUP,
    label: "Member of Automatic Charge Group",
  },
  {
    value: StudentToOrgTransactionType.MEETING_PAID_WITH_CREDITS,
    label: "Meeting Paid with Credits",
  },
  {
    value: StudentToOrgTransactionType.CANCELLATION_FEE,
    label: "Cancellation Fee",
  },
  {
    value: StudentToOrgTransactionType.RESCHEDULING_FEE,
    label: "Rescheduling Fee",
  },
  { value: StudentToOrgTransactionType.ABSENCE_FEE, label: "Absence Fee" },
  { value: StudentToOrgTransactionType.REFUND, label: "Refund" },
];

const tadpoleToTutorTransactionTypeToHumanReadable = [
  {
    value:
      TadpoleToTutorTransactionType.AUTOMATIC_PAYMENT_FOR_A_GROUP_OF_MEETINGS,
    label: "Automatic Payment",
  },
  {
    value: TadpoleToTutorTransactionType.MEMBER_OF_AUTOMATIC_PAYMENT_GROUP,
    label: "Member of Automatic Payment Group",
  },
];

const paymentMethodToHumanReadable = [
  { value: PaymentMethodType.ACH, label: " - ACH" },
  { value: PaymentMethodType.CARD, label: " - Card" },
  { value: PaymentMethodType.INVOICE, label: " - Invoice" },
  { value: PaymentMethodType.TADPOLE_CREDITS, label: "" },
];

function Transactions({
  transactions,
  accountingSystem,
}: {
  transactions:
    | (
        | StudentToOrgTransactionResponse
        | OrgToTadpoleTransactionResponse
        | TadpoleToTutorTransactionResponse
        | PayoutTransactionResponse
      )[]
    | MeetingAdminResponse[];
  accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
}) {
  const isMeetingSystem =
    accountingSystem ===
    AccountingSystemForCalculatingFinancialStatistics.MEETINGS;

  const isTransactions =
    accountingSystem ===
    AccountingSystemForCalculatingFinancialStatistics.TRANSACTIONS;

  const [confirmingPayrollIds, setConfirmingPayrollIds] = useState<{
    [key: string]: boolean;
  }>({});
  const [processingPayrollIds, setProcessingPayrollIds] = useState<{
    [key: string]: boolean;
  }>({});
  const navigate = useNavigate();

  return (
    <div className="w-full max-h-[50rem] overflow-y-auto sm:overflow-x-visible max-sm:overflow-x-auto">
      <table className="w-full border-collapse">
        {/* Sticky header row (on vertical scroll). */}
        <thead className="sticky top-0 z-3 shadow-sm bg-white">
          {/* HEADERS FOR TRANSACTIONS */}
          {isTransactions && (
            <tr className="border-b-2 border-gray-300">
              <th className="text-left p-2 text-sm font-bold w-1/6">Date</th>
              <th className="text-left p-2 text-sm font-bold w-1/5">People</th>
              <th className="text-left p-2 text-sm font-bold w-1/6">Amount</th>
              <th className="text-left p-2 text-sm font-bold w-1/6">Service</th>
              <th className="text-left p-2 text-sm font-bold w-1/6">Type</th>
              <th className="p-2 text-sm font-bold w-1/12 text-center">
                Status
              </th>
              <th className="p-2 text-sm font-bold w-1/12 text-center">
                Actions
              </th>
            </tr>
          )}

          {/* HEADERS FOR MEETINGS */}
          {isMeetingSystem && (
            <tr className="border-b-2 border-gray-300">
              <th className="text-left p-2 text-sm font-bold w-1/12">Date</th>
              <th className="text-left p-2 text-sm font-bold w-2/12">
                Service
              </th>
              <th className="text-left p-2 text-sm font-bold w-2/12">
                Revenue
              </th>
              <th className="text-left p-2 text-sm font-bold w-2/12">
                Compensation
              </th>
              <th className="text-left p-2 text-sm font-bold w-1/12">Net</th>
              <th className="p-2 text-sm font-bold w-1/12 text-center">
                Status
              </th>
              <th className="p-2 text-sm font-bold w-1/12 text-center">
                Actions
              </th>
            </tr>
          )}
        </thead>

        <tbody>
          {transactions.map((transaction, index) => {
            if (isTransactions) {
              const txn = transaction as
                | StudentToOrgTransactionResponse
                | OrgToTadpoleTransactionResponse
                | TadpoleToTutorTransactionResponse
                | PayoutTransactionResponse;

              const date = moment(txn.timestamp).format("MM/DD/YYYY");
              const people = (() => {
                if (
                  txn.transaction_type ===
                  TransactionType.STUDENT_TO_ORGANIZATION
                ) {
                  const stx = txn as StudentToOrgTransactionResponse;
                  return stx.student.first_name + " " + stx.student.last_name;
                }
                if (txn.transaction_type === TransactionType.TADPOLE_TO_TUTOR) {
                  const ttx = txn as TadpoleToTutorTransactionResponse;
                  return ttx.tutor.first_name + " " + ttx.tutor.last_name;
                }
                if (
                  txn.transaction_type ===
                    TransactionType.ORGANIZATION_TO_TADPOLE ||
                  txn.transaction_type === TransactionType.PAYOUT_TO_BANK
                ) {
                  return "Company";
                }
                return "N/A";
              })();

              const amount = (txn.amount / 100).toFixed(2);
              const service = "reason" in txn ? txn.reason : "";

              const type = (() => {
                if (
                  txn.transaction_type ===
                  TransactionType.STUDENT_TO_ORGANIZATION
                ) {
                  const stx = txn as StudentToOrgTransactionResponse;
                  return (
                    (studentToOrgTransactionTypeToHumanReadable.find(
                      (t) => t.value === stx.student_to_org_transaction_type
                    )?.label ?? "Unknown") +
                    (paymentMethodToHumanReadable.find(
                      (t) => t.value === stx.payment_method_type
                    )?.label ?? "")
                  );
                } else if (
                  txn.transaction_type === TransactionType.TADPOLE_TO_TUTOR
                ) {
                  const ttx = txn as TadpoleToTutorTransactionResponse;
                  return (
                    tadpoleToTutorTransactionTypeToHumanReadable.find(
                      (t) => t.value === ttx.tadpole_to_tutor_transaction_type
                    )?.label ?? "Unknown"
                  );
                } else if (
                  txn.transaction_type ===
                  TransactionType.ORGANIZATION_TO_TADPOLE
                ) {
                  return "Funding Payroll";
                } else {
                  return "Payout";
                }
              })();

              const statusColor = getTransactionStatusColor(txn.status);
              const statusIcon = (() => {
                switch (statusColor) {
                  case "green":
                    return <IoCheckmarkCircle className="mx-auto green-mark" />;
                  case "purple":
                    return (
                      <IoCheckmarkCircle className="mx-auto purple-mark" />
                    );
                  case "orange":
                    return (
                      <FaClock
                        className="mx-auto"
                        style={{ color: "#FFB341", fontSize: "20px" }}
                      />
                    );
                  case "red":
                    return <IoCloseCircle className="mx-auto red-mark" />;
                  default:
                    return (
                      <FaClock
                        className="mx-auto"
                        style={{ color: "lightblue", fontSize: "20px" }}
                      />
                    );
                }
              })();

              const actions = (
                <div className="flex flex-row gap-1 items-center">
                  {(txn.transaction_type ===
                    TransactionType.STUDENT_TO_ORGANIZATION &&
                    [
                      StudentToOrgTransactionType.MEETING_PAID_WITH_CREDITS,
                      StudentToOrgTransactionType.AUTOMATIC_CHARGE_FOR_A_GROUP_OF_MEETINGS,
                      StudentToOrgTransactionType.MEMBER_OF_AUTOMATIC_CHARGE_GROUP,
                      StudentToOrgTransactionType.REFUND,
                    ].includes(
                      (txn as StudentToOrgTransactionResponse)
                        .student_to_org_transaction_type
                    )) ||
                  (txn.transaction_type === TransactionType.TADPOLE_TO_TUTOR &&
                    [
                      TadpoleToTutorTransactionType.MEMBER_OF_AUTOMATIC_PAYMENT_GROUP,
                      TadpoleToTutorTransactionType.AUTOMATIC_PAYMENT_FOR_A_GROUP_OF_MEETINGS,
                    ].includes(
                      (txn as TadpoleToTutorTransactionResponse)
                        .tadpole_to_tutor_transaction_type
                    )) ||
                  txn.transaction_type ===
                    TransactionType.ORGANIZATION_TO_TADPOLE ? (
                    <LazyTransactionMeetingsPopup
                      transaction={
                        txn as
                          | StudentToOrgTransactionResponse
                          | TadpoleToTutorTransactionResponse
                      }
                    />
                  ) : null}

                  {txn.transaction_type ===
                    TransactionType.ORGANIZATION_TO_TADPOLE &&
                    txn.status ===
                      TransactionStatus.AWAITING_CONFIRMATION_TO_FUND_PAYROLL && (
                      <>
                        {!confirmingPayrollIds[txn.id] ? (
                          <Button
                            onClick={() =>
                              setConfirmingPayrollIds((prev) => ({
                                ...prev,
                                [txn.id]: true,
                              }))
                            }
                            color={ButtonColor.DARK_BLUE}
                            size={ButtonSize.EXTRA_SMALL}
                          >
                            Trigger
                          </Button>
                        ) : processingPayrollIds[txn.id] ? (
                          <span className="text-green-600 text-xs">
                            Payroll Triggered
                          </span>
                        ) : (
                          <Button
                            onClick={() => {
                              setProcessingPayrollIds((prev) => ({
                                ...prev,
                                [txn.id]: true,
                              }));
                              PaymentsService.queuePayroll({
                                transactionId: txn.id,
                                orgId: (txn as OrgToTadpoleTransactionResponse)
                                  .org_id,
                              }).then(() => {
                                Notifications.success("Payroll will now run!");
                              });
                            }}
                            color={ButtonColor.GREEN}
                            size={ButtonSize.EXTRA_SMALL}
                          >
                            Confirm Payroll Run
                          </Button>
                        )}
                      </>
                    )}

                  {txn.transaction_type ===
                    TransactionType.TADPOLE_TO_TUTOR && (
                    <Button
                      onClick={() =>
                        navigate(
                          `/manage/tutors/${
                            (txn as TadpoleToTutorTransactionResponse).tutor_id
                          }`
                        )
                      }
                      color={ButtonColor.SKYBLUE}
                      size={ButtonSize.EXTRA_SMALL}
                      fill={ButtonFill.HOLLOW}
                    >
                      Tutor
                    </Button>
                  )}
                  {txn.transaction_type ===
                    TransactionType.STUDENT_TO_ORGANIZATION && (
                    <Button
                      onClick={() =>
                        navigate(
                          `/manage/students/${
                            (txn as StudentToOrgTransactionResponse).student_id
                          }#billing`
                        )
                      }
                      color={ButtonColor.SKYBLUE}
                      size={ButtonSize.EXTRA_SMALL}
                      fill={ButtonFill.HOLLOW}
                    >
                      Student
                    </Button>
                  )}
                </div>
              );

              return (
                <tr
                  key={index}
                  className="bg-white border-b border-gray-200 hover:bg-gray-50"
                >
                  <td className="p-2 text-sm">{date}</td>
                  <td className="p-2 text-sm">{people}</td>
                  <td className="p-2 text-sm">${amount}</td>
                  <td className="p-2 text-sm">{service}</td>
                  <td className="p-2 text-sm">{type}</td>
                  <td className="p-2 text-sm text-center">{statusIcon}</td>
                  <td className="p-2 text-sm text-center">{actions}</td>
                </tr>
              );
            }

            if (isMeetingSystem) {
              const meeting = transaction as MeetingAdminResponse;
              const date = moment(meeting.start).format("MM/DD/YYYY");
              const service = meeting.name;
              const revenue = meeting.students.reduce(
                (sum, ms) =>
                  sum +
                  (ms.rate_student_billed_at / 100) * (meeting.duration / 60),
                0
              );
              const compensation = meeting.tutors.reduce(
                (sum, mt) =>
                  sum + (mt.rate_tutor_paid_at / 100) * (meeting.duration / 60),
                0
              );
              const netIncome = revenue - compensation;

              const statusIcon =
                meeting.reviewed_and_approved_by_admin ===
                ApprovalStatus.APPROVED ? (
                  <IoCheckmarkCircle className="mx-auto green-mark" />
                ) : meeting.reviewed_and_approved_by_admin ===
                  ApprovalStatus.DENIED ? (
                  <IoCloseCircle className="mx-auto red-mark" />
                ) : (
                  <FaClock
                    className="mx-auto"
                    style={{ color: "#FFB341", fontSize: "20px" }}
                  />
                );

              const actions = <LazyMeetingDialog meeting={meeting} />;

              return (
                <tr
                  key={index}
                  className="bg-white border-b border-gray-200 hover:bg-gray-50"
                >
                  <td className="p-2 text-sm">{date}</td>
                  <td className="p-2 text-sm">{service}</td>
                  <td className="p-2 text-sm">${revenue.toFixed(2)}</td>
                  <td className="p-2 text-sm">${compensation.toFixed(2)}</td>
                  <td className="p-2 text-sm">${netIncome.toFixed(2)}</td>
                  <td className="p-2 text-sm text-center">{statusIcon}</td>
                  <td className="p-2 text-sm text-center">{actions}</td>
                </tr>
              );
            }

            return null;
          })}
        </tbody>
      </table>
    </div>
  );
}

function LazyTransactionMeetingsPopup({
  transaction,
}: {
  transaction:
    | StudentToOrgTransactionResponse
    | TadpoleToTutorTransactionResponse
    | OrgToTadpoleTransactionResponse;
}) {
  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button
          color={ButtonColor.PURPLE}
          size={ButtonSize.EXTRA_SMALL}
          fill={ButtonFill.HOLLOW}
        >
          Details
        </Button>
      </DialogTrigger>
      {open && <TransactionMeetingsPopup transaction={transaction} />}
    </Dialog>
  );
}

function LazyMeetingDialog({ meeting }: { meeting: MeetingAdminResponse }) {
  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button
          color={ButtonColor.PURPLE}
          size={ButtonSize.EXTRA_SMALL}
          fill={ButtonFill.HOLLOW}
        >
          View
        </Button>
      </DialogTrigger>
      {open && (
        <MeetingDialog
          isCreate={false}
          role={Auth0AccountRole.ORG_ADMIN}
          event={meeting}
          setEvents={() => Promise.resolve()}
        />
      )}
    </Dialog>
  );
}

export default Transactions;
