import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import moment from "moment";
import { useEffect, useState } from "react";
import RadioInput from "components/Inputs/RadioInput";
import { PageStatus } from "types";
import SuccessDialog from "components/SuccessDialog";
import {
  BookmarkIcon,
  CalendarIcon,
  SewingPinFilledIcon,
  TextIcon,
} from "@radix-ui/react-icons";
import {
  ApiError,
  MeetingAdminResponse,
  MeetingLimitedResponse,
  MeetingsService,
  MeetingStudentLimitedResponse,
  MeetingTutorLimitedResponse,
  OrganizationSubjectAdminResponse,
  OrganizationSubjectPublicResponse,
  MeetingTutorAdminResponse,
  MeetingStudentAdminResponse,
  TutorLimitedResponse,
  TutorAdminResponse,
  StudentLimitedResponse,
  StudentAdminResponse,
} from "client/openapi";
import { CHANGE_OPTIONS } from "./changeOptions";
import Notifications from "util/notifications";

import { MeetingDetails } from "../index";

const TIME_FORMAT = "h:mma";
const FULL_TIME_FORMAT = "HH:mm:ss";

export default function SaveChangesDialog({
  event,
  originalMeetingInfo,
  currentMeetingInfo,
  originalTutors,
  originalStudents,
  newTutors,
  newStudents,
  subject,
  continueFunction,
}: {
  event: MeetingAdminResponse | MeetingLimitedResponse;
  originalMeetingInfo: MeetingDetails;
  currentMeetingInfo: MeetingDetails;
  originalTutors: (
    | MeetingTutorLimitedResponse
    | MeetingTutorAdminResponse
    | TutorLimitedResponse
    | TutorAdminResponse
  )[];
  originalStudents: (
    | MeetingStudentLimitedResponse
    | MeetingStudentAdminResponse
    | StudentLimitedResponse
    | StudentAdminResponse
  )[];
  newTutors: (
    | MeetingTutorLimitedResponse
    | MeetingTutorAdminResponse
    | TutorLimitedResponse
    | TutorAdminResponse
  )[];
  newStudents: (
    | MeetingStudentLimitedResponse
    | MeetingStudentAdminResponse
    | StudentLimitedResponse
    | StudentAdminResponse
  )[];
  subject?:
    | OrganizationSubjectAdminResponse
    | OrganizationSubjectPublicResponse;
  continueFunction: () => void;
}) {
  const [status, setStatus] = useState<PageStatus>();

  // scheduling changes
  const [schedulingChangeMade, setSchedulingChangeMade] =
    useState<boolean>(false);
  const [endDateChanged, setEndDateChanged] = useState<boolean>(false);
  const [dateChanged, setDateChanged] = useState<boolean>(false);
  const [timeChanged, setTimeChanged] = useState<boolean>(false);
  const [durationChanged, setDurationChanged] = useState<boolean>(false);
  const [originalEndTime, setOriginalEndTime] = useState<string>("");
  const [newEndTime, setNewEndTime] = useState<string>("");
  const [originalDate, setOriginalDate] = useState<string>("");
  const [newDate, setNewDate] = useState<string>("");
  const [originalTime, setOriginalTime] = useState<string>("");
  const [newTime, setNewTime] = useState<string>("");

  // meeting changes
  const [meetingChangeMade, setMeetingChangeMade] = useState<boolean>(false);
  const [locationChanged, setLocationChanged] = useState<boolean>(false);
  const [subjectChanged, setSubjectChanged] = useState<boolean>(false);
  const [nameChanged, setNameChanged] = useState<boolean>(false);
  const [studentsChanged, setStudentsChanged] = useState<boolean>(false);
  const [tutorsChanged, setTutorsChanged] = useState<boolean>(false);

  const [date, setDate] = useState<string>("");
  const [changeType, setChangeType] = useState<CHANGE_OPTIONS>(
    CHANGE_OPTIONS.JUST_THIS_SESSION
  );

  async function makeMeetingChanges() {
    const originalTutorIds = originalTutors.map((t) =>
      "tutor" in t ? t.tutor.id : t.id
    );
    const addedTutors = currentMeetingInfo.tutor_ids.filter((t) => {
      return !originalTutorIds.includes(t);
    });
    const removedTutors = originalTutorIds.filter((t) => {
      return !currentMeetingInfo.tutor_ids.includes(t);
    });

    const originalStudentIds = originalStudents.map((s) =>
      "student" in s ? s.student.id : s.id
    );
    const addedStudents = currentMeetingInfo.student_ids.filter((s) => {
      return !originalStudentIds.includes(s);
    });
    const removedStudents = originalStudentIds.filter((s) => {
      return !currentMeetingInfo.student_ids.includes(s);
    });

    await MeetingsService.editMeeting({
      meetingId: event.id,
      requestBody: {
        add_students: addedStudents.length > 0 ? addedStudents : undefined,
        add_tutors: addedTutors.length > 0 ? addedTutors : undefined,
        remove_students:
          removedStudents.length > 0 ? removedStudents : undefined,
        remove_tutors: removedTutors.length > 0 ? removedTutors : undefined,
        name: nameChanged ? currentMeetingInfo.name : undefined,
        location_id: locationChanged
          ? currentMeetingInfo.location?.id
          : undefined,
        other_location: locationChanged
          ? currentMeetingInfo.other_location
          : undefined,
        org_subject_id: subjectChanged
          ? currentMeetingInfo.org_subject?.id
          : undefined,
        start:
          dateChanged || timeChanged
            ? moment.utc(currentMeetingInfo.start).format()
            : undefined,
        duration: durationChanged ? currentMeetingInfo.duration : undefined,
        ends_on_or_before: endDateChanged
          ? moment(currentMeetingInfo.ends_on_or_before)
              .endOf("day")
              .utc()
              .format()
          : undefined,
        apply_to_all_future_meetings:
          changeType === CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES ? true : false,
      },
    });
  }

  async function updateMeeting() {
    setStatus(PageStatus.LOADING);
    try {
      await makeMeetingChanges();
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        setStatus(PageStatus.ERROR);
        Notifications.error("Error: " + error.message + ". Please try again.");
        console.error(`Error (#${error.status}): ${error.message}`);
      } else {
        // Handle unexpected error types
        setStatus(PageStatus.ERROR);
        Notifications.error("An unexpected error occurred. Please try again.");
        console.error(`Unexpected error: ${error}`);
      }
      return;
    }

    setStatus(PageStatus.SUCCESS);
  }

  const handleUpdateApplyChanges = (ev) => {
    setChangeType(ev.target.value);
  };

  const canSave = () => {
    if (
      status === PageStatus.LOADING ||
      !(meetingChangeMade || schedulingChangeMade)
    ) {
      return false;
    }
    return true;
  };

  const resetDialog = () => {
    setStatus(undefined);
    setDate("");
    setChangeType(CHANGE_OPTIONS.JUST_THIS_SESSION);

    setSchedulingChangeMade(false);
    setEndDateChanged(false);
    setDateChanged(false);
    setTimeChanged(false);
    setDurationChanged(false);
    setOriginalEndTime("");
    setNewEndTime("");
    setOriginalDate("");
    setNewDate("");
    setOriginalTime("");
    setNewTime("");

    setMeetingChangeMade(false);
    setLocationChanged(false);
    setSubjectChanged(false);
    setNameChanged(false);
    continueFunction();
  };

  const getAddedStudentsAndTutors = () => {
    const addedStudentIds =
      currentMeetingInfo?.student_ids.filter(
        (student_id) => !originalMeetingInfo?.student_ids?.includes(student_id)
      ) || [];
    const addedStudents = newStudents.filter((student) => {
      const studentId = "student" in student ? student.student.id : student.id;
      return addedStudentIds.includes(studentId);
    });
    const addedTutorIds =
      currentMeetingInfo?.tutor_ids.filter(
        (tutor_id) => !originalMeetingInfo?.tutor_ids?.includes(tutor_id)
      ) || [];
    const addedTutors = newTutors.filter((tutor) => {
      const tutorId = "tutor" in tutor ? tutor.tutor.id : tutor.id;
      return addedTutorIds.includes(tutorId);
    });

    return addedStudents.length > 0 || addedTutors.length > 0 ? (
      <div className="flex flex-row mb-2">
        <span className="text-sm font-bold">Added</span>
        <div className="ml-4">
          {addedStudents.map((addedStudent, index) => {
            return (
              <span
                key={index}
                className="rounded-lg text-sm border border-green-500 items-center py-0.5 px-2 gap-1.5"
              >
                {`${
                  "student" in addedStudent
                    ? addedStudent.student.first_name
                    : addedStudent.first_name
                } ${
                  "student" in addedStudent
                    ? addedStudent.student.last_name
                    : addedStudent.last_name
                }`}
              </span>
            );
          })}

          {addedTutors.map((addedTutor, index) => {
            return (
              <span
                key={index}
                className="rounded-lg text-sm border border-indigo-400 items-center py-0.5 px-2 gap-1.5"
              >
                {`${
                  "tutor" in addedTutor
                    ? addedTutor.tutor.first_name
                    : addedTutor.first_name
                } ${
                  "tutor" in addedTutor
                    ? addedTutor.tutor.last_name
                    : addedTutor.last_name
                }`}
              </span>
            );
          })}
        </div>
      </div>
    ) : null;
  };

  const getRemovedStudentsAndTutors = () => {
    const removedStudentIds =
      originalMeetingInfo?.student_ids.filter(
        (student_id) => !currentMeetingInfo?.student_ids?.includes(student_id)
      ) || [];
    const removedStudents = originalStudents.filter((student) => {
      const studentId = "student" in student ? student.student.id : student.id;
      return removedStudentIds.includes(studentId);
    });

    const removedTutorIds =
      originalMeetingInfo?.tutor_ids.filter(
        (tutor_id) => !currentMeetingInfo?.tutor_ids?.includes(tutor_id)
      ) || [];
    const removedTutors = originalTutors.filter((tutor) => {
      const tutorId = "tutor" in tutor ? tutor.tutor.id : tutor.id;
      return removedTutorIds.includes(tutorId);
    });

    return removedStudents.length > 0 || removedTutors.length > 0 ? (
      <div className="flex flex-row">
        <span className="text-sm font-bold">Removed</span>
        <div className="ml-4">
          {removedStudents.map((removedStudent, index) => {
            return (
              <span
                key={index}
                className="rounded-lg text-sm border border-green-500 items-center py-0.5 px-2 gap-1.5"
              >
                {`${
                  "student" in removedStudent
                    ? removedStudent.student.first_name
                    : removedStudent.first_name
                } ${
                  "student" in removedStudent
                    ? removedStudent.student.last_name
                    : removedStudent.last_name
                }`}
              </span>
            );
          })}

          {removedTutors.map((removedTutor, index) => {
            return (
              <span
                key={index}
                className="rounded-lg text-sm border border-indigo-400 items-center py-0.5 px-2 gap-1.5"
              >
                {`${
                  "tutor" in removedTutor
                    ? removedTutor.tutor.first_name
                    : removedTutor.first_name
                } ${
                  "tutor" in removedTutor
                    ? removedTutor.tutor.last_name
                    : removedTutor.last_name
                }`}
              </span>
            );
          })}
        </div>
      </div>
    ) : null;
  };

  // As a user makes modifications to a meeting, detect all scheduling related changes.
  // This will later be used to render JSX as needed
  useEffect(() => {
    setOriginalDate(
      moment(originalMeetingInfo?.start.split("T")[0]).format("ddd, MMM Do")
    );

    setNewDate(
      moment(currentMeetingInfo?.start.split("T")[0]).format("ddd, MMM Do")
    );

    setOriginalTime(
      moment(
        originalMeetingInfo?.start.split("T")[1].substring(0, 8),
        FULL_TIME_FORMAT
      ).format(TIME_FORMAT)
    );

    setNewTime(
      moment(
        currentMeetingInfo?.start.split("T")[1].substring(0, 8),
        FULL_TIME_FORMAT
      ).format(TIME_FORMAT)
    );

    setOriginalEndTime(
      moment(
        originalMeetingInfo?.start.split("T")[1].substring(0, 8),
        FULL_TIME_FORMAT
      )
        .add(originalMeetingInfo?.duration, "minutes")
        .format(TIME_FORMAT)
    );

    setNewEndTime(
      moment(
        currentMeetingInfo?.start.split("T")[1].substring(0, 8),
        FULL_TIME_FORMAT
      )
        .add(currentMeetingInfo?.duration, "minutes")
        .format(TIME_FORMAT)
    );

    setDateChanged(originalDate !== newDate);

    setTimeChanged(originalTime !== newTime);

    setDurationChanged(
      originalMeetingInfo?.duration !== currentMeetingInfo?.duration
    );

    setEndDateChanged(
      currentMeetingInfo.ends_on_or_before
        ? currentMeetingInfo.ends_on_or_before !==
            originalMeetingInfo.ends_on_or_before
        : false
    );

    setSchedulingChangeMade(
      dateChanged || timeChanged || durationChanged || endDateChanged
    );
  }, [
    dateChanged,
    endDateChanged,
    newDate,
    currentMeetingInfo?.duration,
    currentMeetingInfo?.start,
    currentMeetingInfo.ends_on_or_before,
    newTime,
    originalDate,
    originalMeetingInfo?.duration,
    originalMeetingInfo?.start,
    originalMeetingInfo.ends_on_or_before,
    originalTime,
    timeChanged,
    durationChanged,
  ]);

  // As a user makes modifications to a meeting, detect all non-scheduling related changes.
  // This will later be used to render JSX as needed
  useEffect(() => {
    setLocationChanged(
      originalMeetingInfo?.location?.id !== currentMeetingInfo?.location?.id ||
        originalMeetingInfo?.other_location !==
          currentMeetingInfo?.other_location
    );
    setSubjectChanged(
      originalMeetingInfo?.org_subject?.id !==
        currentMeetingInfo?.org_subject?.id
    );
    setNameChanged(originalMeetingInfo?.name !== currentMeetingInfo?.name);

    setStudentsChanged(
      !originalMeetingInfo.student_ids.every((id) =>
        currentMeetingInfo.student_ids.includes(id)
      ) ||
        !currentMeetingInfo.student_ids.every((id) =>
          originalMeetingInfo.student_ids.includes(id)
        )
    );
    setTutorsChanged(
      !originalMeetingInfo.tutor_ids.every((id) =>
        currentMeetingInfo.tutor_ids.includes(id)
      ) ||
        !currentMeetingInfo.tutor_ids.every((id) =>
          originalMeetingInfo.tutor_ids.includes(id)
        )
    );

    setMeetingChangeMade(
      locationChanged ||
        subjectChanged ||
        nameChanged ||
        studentsChanged ||
        tutorsChanged
    );
  }, [
    currentMeetingInfo?.name,
    currentMeetingInfo.student_ids,
    currentMeetingInfo.tutor_ids,
    locationChanged,
    nameChanged,
    currentMeetingInfo?.other_location,
    currentMeetingInfo?.location?.id,
    originalMeetingInfo?.name,
    originalMeetingInfo.student_ids,
    originalMeetingInfo.tutor_ids,
    originalMeetingInfo?.other_location,
    originalMeetingInfo?.location?.id,
    subjectChanged,
    originalMeetingInfo?.org_subject?.id,
    currentMeetingInfo?.org_subject?.id,
    studentsChanged,
    tutorsChanged,
  ]);

  if (status === PageStatus.SUCCESS) {
    return <SuccessDialog message="Changes Saved!" onClose={resetDialog} />;
  }

  return (
    <>
      <div className="grid grid-cols-12 justify-self-end">
        <DialogContent
          className="dialog-content dialog-content--left wide h-fit max-h-[85vh] lg:max-w-[33vw] md:w-1/2 w-3/4 bg-neutral-50 font-montserrat pb-4 overflow-scroll"
          alignLeft={true}
          showClose={false}
        >
          <div className="meeting-dialog-new--title pt-4">
            <span>Save Changes?</span>
          </div>

          <p className="font-bold text-md border-b-2 pb-2 text-green-500">
            Scheduling Changes
          </p>
          {schedulingChangeMade ? (
            <>
              {(dateChanged || timeChanged || durationChanged) && (
                <div className="flex flex-col mt-2">
                  <span className="font-semibold text-sm mb-1">Old Time</span>
                  <span className="flex items-center text-sm mb-3">
                    <CalendarIcon height="20" width="20" />
                    &nbsp;{originalDate} from {originalTime}&nbsp;to&nbsp;
                    {originalEndTime}
                  </span>
                  <span className="font-semibold text-sm mb-1">New Time</span>
                  <span className="flex items-center text-sm mb-3">
                    <CalendarIcon height="20" width="20" />
                    &nbsp;
                    {moment(currentMeetingInfo?.start).format(
                      "ddd, MMM Do"
                    )}{" "}
                    from {moment(currentMeetingInfo?.start).format(TIME_FORMAT)}{" "}
                    to{" "}
                    {moment(currentMeetingInfo?.start)
                      .add(currentMeetingInfo?.duration, "minutes")
                      .format(TIME_FORMAT)}
                  </span>
                </div>
              )}
              {endDateChanged && (
                <div className="text-sm mt-2">
                  <b>Until -</b> This meeting will now end on or before{" "}
                  {moment(currentMeetingInfo.ends_on_or_before).format(
                    "ddd, MMM Do"
                  )}
                  .
                </div>
              )}
            </>
          ) : (
            <>No scheduling changes</>
          )}

          <p className="font-bold text-md border-b-2 pb-2 mt-4 text-green-500">
            Meeting Changes
          </p>
          {meetingChangeMade ? (
            <>
              <div className="flex flex-col text-sm">
                {nameChanged && (
                  <div className="flex w-full mt-2">
                    <span className="flex flex-col w-1/2 border-b pb-2">
                      <div>
                        <b>Current Name</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <TextIcon />
                        &nbsp;{originalMeetingInfo?.name}
                      </div>
                    </span>
                    <span className="flex flex-col w-fit border-b pb-2">
                      <div>
                        <b>New Name</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <TextIcon />
                        &nbsp;{currentMeetingInfo?.name}
                      </div>
                    </span>
                  </div>
                )}
                {locationChanged && (
                  <div className="flex w-full mt-2">
                    <span className="flex flex-col w-1/2 border-b pb-2">
                      <div>
                        <b>Current Location</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <SewingPinFilledIcon />
                        &nbsp;
                        {originalMeetingInfo.other_location
                          ? originalMeetingInfo.other_location
                          : originalMeetingInfo.location?.name}
                      </div>
                    </span>
                    <span className="flex flex-col w-fit border-b pb-2">
                      <div>
                        <b>New Location</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <SewingPinFilledIcon />
                        &nbsp;
                        {currentMeetingInfo?.location
                          ? currentMeetingInfo.location.name
                          : currentMeetingInfo.other_location}
                      </div>
                    </span>
                  </div>
                )}
                {subjectChanged && subject && (
                  <div className="flex w-full mt-2">
                    <span className="flex flex-col w-1/2 border-b pb-2">
                      <div>
                        <b>Current Subject</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <BookmarkIcon height="20" width="20" />
                        &nbsp;{originalMeetingInfo?.org_subject?.name}
                      </div>
                    </span>
                    <span className="flex flex-col w-fit border-b pb-2">
                      <div>
                        <b>New Subject</b>
                      </div>
                      <div className="overflow-hidden white-space-nowrap text-ellipsis flex flex-row items-center mt-1">
                        <BookmarkIcon height="20" width="20" />
                        &nbsp;{currentMeetingInfo?.org_subject?.name}
                      </div>
                    </span>
                  </div>
                )}
                <div className="mt-3 mb-1">
                  {getAddedStudentsAndTutors()}
                  {getRemovedStudentsAndTutors()}
                </div>
              </div>
            </>
          ) : (
            <>No meeting changes</>
          )}
          {(meetingChangeMade || schedulingChangeMade) && (
            <div className="mt-4">
              <div className="font-bold mb-2 text-md">
                Apply meeting changes to:
              </div>
              <div className="flex flex-row items-center gap-2 mb-2">
                <RadioInput
                  id={CHANGE_OPTIONS.JUST_THIS_SESSION}
                  name={CHANGE_OPTIONS.JUST_THIS_SESSION}
                  value={CHANGE_OPTIONS.JUST_THIS_SESSION}
                  label="Just this meeting"
                  setValue={handleUpdateApplyChanges}
                  checked={changeType === CHANGE_OPTIONS.JUST_THIS_SESSION}
                  required
                />
              </div>

              <div className="flex flex-row items-center gap-2 mb-2">
                <RadioInput
                  id={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                  name={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                  value={CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                  label={
                    schedulingChangeMade
                      ? `All future occurrences of this meeting, with future meetings switching to ${moment(
                          currentMeetingInfo?.start
                        ).format("dddd")}s from ${moment(
                          currentMeetingInfo?.start
                        ).format(TIME_FORMAT)} to ${moment(
                          currentMeetingInfo?.start
                        )
                          .add(currentMeetingInfo?.duration, "minutes")
                          .format(TIME_FORMAT)}`
                      : "All future occurrences of this meeting"
                  }
                  setValue={handleUpdateApplyChanges}
                  checked={changeType === CHANGE_OPTIONS.ALL_FUTURE_OCCURRENCES}
                  required
                />
              </div>
            </div>
          )}
          <div>
            <DialogActions>
              <DialogAction
                size={ButtonSize.SMALL}
                color={!canSave() ? ButtonColor.GRAY : ButtonColor.GREEN}
                fill={ButtonFill.DEFAULT}
                disabled={!canSave()}
                onClick={updateMeeting}
              >
                {status === PageStatus.LOADING ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <>Confirm</>
                )}
              </DialogAction>
              <DialogClose asChild>
                <Button
                  size={ButtonSize.SMALL}
                  color={ButtonColor.BLACK}
                  fill={ButtonFill.HOLLOW}
                >
                  Back
                </Button>
              </DialogClose>
            </DialogActions>
          </div>
        </DialogContent>
      </div>
    </>
  );
}
