import {
  CRMVisualizationResponse,
  CRMVisualizationColumnResponse,
} from "client/openapi";

/**
 * Add a new column at 1-based `newColumn.order_index`.
 * Ensures columns after that position are shifted right by +1.
 */
export function addColumnToVisualization(
  visualization: CRMVisualizationResponse,
  newColumn: CRMVisualizationColumnResponse
): CRMVisualizationResponse {
  const columns = [...visualization.columns];

  // Bound-check the desired 1-based order_index: [1..columns.length+1]
  let newIndex = newColumn.order_index;
  if (newIndex < 1) {
    newIndex = 1;
  }
  if (newIndex > columns.length + 1) {
    newIndex = columns.length + 1;
  }

  // Shift existing columns at or after newIndex up by +1
  const updatedColumns = columns.map((col) => {
    if (col.order_index >= newIndex) {
      return { ...col, order_index: col.order_index + 1 };
    }
    return col;
  });

  // Assign and push the new column
  newColumn.order_index = newIndex;
  updatedColumns.push(newColumn);

  // Sort by order_index so the columns array is in ascending order
  updatedColumns.sort((a, b) => a.order_index - b.order_index);

  return {
    ...visualization,
    columns: updatedColumns,
  };
}

/**
 * Update a column (matched by ID). If the column moves:
 * - from oldIndex -> newIndex (where newIndex < oldIndex):
 *     shift columns in [newIndex..oldIndex-1] up by +1
 * - from oldIndex -> newIndex (where newIndex > oldIndex):
 *     shift columns in [oldIndex+1..newIndex] down by -1
 *
 * Maintains 1..N ordering without reassigning everything.
 */
export function updateColumnInVisualization(
  visualization: CRMVisualizationResponse,
  updatedColumn: CRMVisualizationColumnResponse
): CRMVisualizationResponse {
  const { columns } = visualization;

  // Find the old column to get its existing index
  const oldColumn = columns.find((col) => col.id === updatedColumn.id);
  if (!oldColumn) {
    // Column not found; return as-is
    return visualization;
  }

  const oldIndex = oldColumn.order_index;
  let newIndex = updatedColumn.order_index;

  // Remove old column
  let updatedColumns = columns.filter((col) => col.id !== updatedColumn.id);

  // Bound-check newIndex
  if (newIndex < 1) {
    newIndex = 1;
  }
  if (newIndex > updatedColumns.length + 1) {
    newIndex = updatedColumns.length + 1;
  }

  if (newIndex < oldIndex) {
    // Moving the column "left" (to a smaller index):
    // Shift columns that are between [newIndex..oldIndex-1] to the right by +1
    updatedColumns = updatedColumns.map((col) => {
      if (col.order_index >= newIndex && col.order_index < oldIndex) {
        return { ...col, order_index: col.order_index + 1 };
      }
      return col;
    });
  } else if (newIndex > oldIndex) {
    // Moving the column "right" (to a larger index):
    // Shift columns that are between (oldIndex..newIndex] to the left by -1
    updatedColumns = updatedColumns.map((col) => {
      if (col.order_index <= newIndex && col.order_index > oldIndex) {
        return { ...col, order_index: col.order_index - 1 };
      }
      return col;
    });
  }

  // Now insert the updated column at newIndex
  updatedColumn.order_index = newIndex;
  updatedColumns.push(updatedColumn);

  // Sort for a clean ascending array
  updatedColumns.sort((a, b) => a.order_index - b.order_index);

  return {
    ...visualization,
    columns: updatedColumns,
  };
}

/**
 * Delete a column by ID and shift all columns to the right of
 * the deleted column "left" by -1. Maintains 1..N ordering.
 */
export function deleteColumnFromVisualization(
  visualization: CRMVisualizationResponse,
  columnId: number
): CRMVisualizationResponse {
  const { columns } = visualization;

  // Find the old column for its order_index
  const oldColumn = columns.find((col) => col.id === columnId);
  if (!oldColumn) {
    // Column not found; return as-is
    return visualization;
  }

  const oldIndex = oldColumn.order_index;

  // Remove the column from the array
  let updatedColumns = columns.filter((col) => col.id !== columnId);

  // Shift columns with order_index > oldIndex left by -1
  updatedColumns = updatedColumns.map((col) => {
    if (col.order_index > oldIndex) {
      return { ...col, order_index: col.order_index - 1 };
    }
    return col;
  });

  // Sort again for a tidy ascending order
  updatedColumns.sort((a, b) => a.order_index - b.order_index);

  return {
    ...visualization,
    columns: updatedColumns,
  };
}
