/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the status of a task.
 */
export enum TaskStatus {
  COMPLETE = "COMPLETE",
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
}
