import { useAuth0 } from "@auth0/auth0-react";
import {
  ApiError,
  Auth0AccountRole,
  MeetingLimitedResponse,
  MeetingsService,
  MeetingStats,
  NoteResponse,
  OrganizationResponseForTutors,
  OrganizationsService,
  ParentLimitedResponse,
  ParentsService,
  StatisticsService,
  StudentLimitedResponse,
  StudentsService,
} from "client/openapi";
import { ErrorBlock, LoadingBlock } from "components/StatusBlock";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { APIResponse, PageStatus } from "types";
import TabNavbar from "components/TabNavbar";
import { GoPerson, GoCreditCard } from "react-icons/go";
import { BarChartIcon, FileTextIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import ParentInfo from "./ParentInfo";
import ParentInfoTab from "./ParentInfoTab";
import "./index.css";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import NotesTab from "./NotesTab";
import MetricTab from "./MetricTab/MetricTab";
import PaymentsTab from "./PaymentsTab/PaymentsTab";

const timezone = moment.tz.guess(true);

export default function ParentDetail() {
  const { isLoading } = useAuth0();
  const navigate = useNavigate();

  const { currently_selected_organization, currently_selected_role, account } =
    useContext(OrgRolesAndAccountContext);

  let { id: rawParentId } = useParams();

  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [error, setError] = useState<APIResponse>();
  const [exportingMeetings, setExportingMeetings] = useState<boolean>(false);
  const [parent, setParent] = useState<ParentLimitedResponse>();
  const [organization, setOrganization] =
    useState<OrganizationResponseForTutors>();
  const [meetings, setMeetings] = useState<MeetingLimitedResponse[]>();
  const [orgStudents, setOrgStudents] = useState<StudentLimitedResponse[]>();
  const [parentStudents, setParentStudents] =
    useState<StudentLimitedResponse[]>();
  const [activeTab, setActiveTab] = useState<string>("Parent Info");
  const [meetingStats, setMeetingStats] = useState<MeetingStats>();

  const [newInternalNoteOpen, setNewInternalNoteOpen] =
    useState<boolean>(false);
  const [newParentNoteOpen, setNewParentNoteOpen] = useState<boolean>(false);
  const [internalNotes, setInternalNotes] = useState<NoteResponse[]>([]);
  const [parentNotes, setParentNotes] = useState<NoteResponse[]>([]);

  const options = [
    { value: "Notes", icon: FileTextIcon },
    { value: "Parent Info", icon: GoPerson },
    { value: "Metrics", icon: BarChartIcon },
    { value: "Billing", icon: GoCreditCard },
  ];

  // useEffect to check URL hash, navigates the user to the appropriate tab
  useEffect(() => {
    const hash = window.location.hash;

    if (hash === "#notes") {
      setActiveTab("Notes");
    }

    if (hash === "#parent") {
      setActiveTab("Parent Info");
    }

    if (hash === "#metrics") {
      setActiveTab("Metrics");
    }

    if (hash === "#billing") {
      setActiveTab("Billing");
    }
  }, []);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  async function getParent() {
    if (!rawParentId) {
      setStatus(PageStatus.ERROR);
      setError({ message: "No parent specified." });
      return;
    }

    const parentId = Number.parseInt(rawParentId);

    ParentsService.getParent({ parentId: parentId })
      .then((p) => {
        setParent(p);
        setParentStudents(p.students);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch parent details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  function getData() {
    if (isLoading) {
      return;
    }

    MeetingsService.getMeetingsByParent({
      parentId: parent?.id as number,
      until: moment().toISOString(),
    })
      .then((m) => {
        setMeetings(m);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch organization details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    OrganizationsService.getOrganization({
      orgId: currently_selected_organization as number,
    })
      .then((o) => {
        setOrganization(o as OrganizationResponseForTutors);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ message: "Unable to fetch organization details" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });

    if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
      StudentsService.getStudentsByOrganizationIfAdmin({
        orgId: currently_selected_organization as number,
      })
        .then((data) => {
          setOrgStudents(data);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ message: "Unable to fetch organization students" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    } else if (currently_selected_role === Auth0AccountRole.ORG_TUTOR) {
      StudentsService.getStudentsByOrganizationIfTutor({
        orgId: currently_selected_organization as number,
      })
        .then((data) => {
          setOrgStudents(data);
        })
        .catch((e: ApiError) => {
          setStatus(PageStatus.ERROR);
          setError({ message: "Unable to fetch organization students" });
          console.error(`Error (#${e.status}): ${e.message}`);
        });
    }

    StatisticsService.getParentMeetingStats({
      parentId: parent?.id as number,
      start: moment().subtract(1, "year").toISOString(),
      until: moment().toISOString(),
      tz: timezone,
    }).then((data) => {
      setMeetingStats(data);
    });
  }

  useEffect(() => {
    if (!parent) {
      getParent();
    }

    if (!isLoading && currently_selected_organization && parent) {
      getData();
    }
  }, [isLoading, currently_selected_organization, parent]);

  if (
    ![Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
      currently_selected_role as Auth0AccountRole
    )
  ) {
    return (
      <ErrorBlock
        status={PageStatus.ERROR}
        title="Unauthorized"
        message="You do not have permission to access this page."
      />
    );
  }

  return (
    <div className="container-fluid mx-8 page--parent-profile">
      <ErrorBlock status={status} title="Error" message={error?.message} />

      {account && parent && meetings && organization && orgStudents ? (
        <div className="mx-5 md:mx-0">
          <button onClick={() => navigate(-1)} className="flex items-center">
            <ArrowLeftIcon className="w-6 h-6 font-bold" />
            <p className="text-lg font-bold">Back</p>
          </button>

          <p className="text-4xl mt-5 font-bold">
            {parent.first_name} {parent.last_name}
          </p>
          <p className="text-2xl mt-2">Parent</p>

          <div className="my-5">
            <TabNavbar
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              options={options}
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-7">
            {activeTab === "Notes" && (
              <>
                <div className="col-span-2 flex justify-center">
                  <ParentInfo
                    parent={parent}
                    setParent={setParent}
                    setError={setError}
                    setStatus={setStatus}
                  />
                </div>
                <div className="col-span-5">
                  {activeTab === "Notes" && (
                    <NotesTab
                      newInternalNoteOpen={newInternalNoteOpen}
                      setNewInternalNoteOpen={setNewInternalNoteOpen}
                      newParentNoteOpen={newParentNoteOpen}
                      setNewParentNoteOpen={setNewParentNoteOpen}
                      parent={parent}
                      organization={organization}
                      internalNotes={internalNotes}
                      setInternalNotes={setInternalNotes}
                      parentNotes={parentNotes}
                      setParentNotes={setParentNotes}
                      account={account}
                    />
                  )}
                </div>
              </>
            )}

            {activeTab === "Parent Info" && (
              <div className="col-span-7">
                <ParentInfoTab
                  parent={parent}
                  orgStudents={orgStudents}
                  parentStudents={parentStudents as StudentLimitedResponse[]}
                  setParentStudents={setParentStudents}
                  meetings={meetings}
                  exportingMeetings={exportingMeetings}
                  setExportingMeetings={setExportingMeetings}
                  setStatus={setStatus}
                  setError={setError}
                />
              </div>
            )}
          </div>

          {activeTab === "Metrics" && meetingStats && (
            <div className="col-span-7">
              <MetricTab parent={parent} meetingStats={meetingStats} />
            </div>
          )}

          {activeTab === "Billing" &&
            (currently_selected_role === Auth0AccountRole.ORG_ADMIN ? (
              <div className="col-span-7">
                <PaymentsTab parent={parent} />
              </div>
            ) : (
              <div className="col-span-7 text-center">
                This information must be viewed as an admin!
              </div>
            ))}
        </div>
      ) : (
        <LoadingBlock status={PageStatus.LOADING} />
      )}
    </div>
  );
}
