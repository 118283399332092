import { Dialog, DialogContent, DialogTrigger } from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { TrashIcon } from "@radix-ui/react-icons";
import {
  CRMVisualizationResponse,
  VisualizationsService,
} from "client/openapi";
import Notifications from "util/notifications";

interface DeleteVisualizationDialogProps {
  visualization: CRMVisualizationResponse;
  onDelete: () => void;
}

export default function DeleteVisualizationDialog({
  visualization,
  onDelete,
}: DeleteVisualizationDialogProps) {
  const handleDelete = async () => {
    try {
      await VisualizationsService.deleteVisualization({
        visualizationId: visualization.id,
      });
      onDelete();
      Notifications.success("Visualization deleted successfully");
    } catch (error) {
      console.error("Error deleting visualization:", error);
      Notifications.error("Failed to delete visualization");
    }
  };

  return (
    <Dialog>
      <DialogTrigger>
        <Button
          color={ButtonColor.BLACK}
          fill={ButtonFill.HOLLOW}
          size={ButtonSize.SMALL}
        >
          <TrashIcon />
        </Button>
      </DialogTrigger>
      <DialogContent className="visualization-dialog-content">
        <div className="p-4">
          <h2 className="text-lg font-bold mb-4 text-center">
            Delete Visualization
          </h2>
          <p className="mb-4 text-center">
            Are you sure you want to delete the "{visualization.name}"
            visualization? This action cannot be undone.
          </p>
          <div className="flex justify-center gap-2">
            <Button
              color={ButtonColor.GRAY}
              onClick={() => {
                const dialog = document.querySelector('[role="dialog"]');
                if (dialog) {
                  (dialog as HTMLElement).style.display = "none";
                }
              }}
            >
              Cancel
            </Button>
            <Button color={ButtonColor.RED} onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
