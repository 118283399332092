import {
  Auth0AccountRole,
  MeetingAdminResponse,
  MeetingLimitedResponse,
  MeetingsService,
  MeetingCreate,
  MeetingStudentLimitedResponse,
  MeetingTutorLimitedResponse,
  OrganizationSubjectPublicResponse,
  OrganizationSubjectAdminResponse,
  SessionFrequency,
  OrganizationMeetingLocationResponse,
  MeetingTutorAdminResponse,
  MeetingStudentAdminResponse,
  TutorLimitedResponse,
  TutorAdminResponse,
  StudentLimitedResponse,
  StudentAdminResponse,
  LocationsService,
  OrgSubjectsService,
} from "client/openapi";
import "./index.css";
import "../Tag/TagChip/index.css";
import { DialogActions, DialogClose, DialogContent } from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import MeetingAttendees from "components/MeetingAttendees";
import { QuoteIcon, TrashIcon } from "@radix-ui/react-icons";
import moment from "moment";
import ViewMeetingDetails from "./Components/viewMeetingDetails";
import { useContext, useEffect, useState, useCallback } from "react";
import EditMeetingDetails from "./Components/editMeetingDetails";
import { PageStatus } from "types";
import CancelDialog from "./Popups/cancel";
import DiscardDialog from "./Popups/discard";
import SaveChangesDialog from "./Popups/saveChanges";
import MeetingDialogTrigger from "./Components/dialogTrigger";
import Notifications from "util/notifications";
import SuccessDialog from "components/SuccessDialog";
import AvailabilitySidebar from "components/AvailabilitySidebar.tsx";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/Tooltip";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import SelectOrganization from "components/SelectOrganization";

export const WEEKDAYS = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
export const defaultDuration = 60;

export interface MeetingDetails {
  meeting_id?: number;
  org_id: number;
  start: string;
  duration: number;
  ends_on_or_before: string | undefined;
  name?: string;
  location: OrganizationMeetingLocationResponse | undefined;
  other_location: string | undefined;
  student_ids: number[];
  tutor_ids: number[];
  frequency: SessionFrequency;
  org_subject:
    | OrganizationSubjectPublicResponse
    | OrganizationSubjectAdminResponse
    | undefined;
  locked: boolean;
}

export default function MeetingDialog({
  isCreate = false,
  role,
  event,
  setEvents,
}: {
  isCreate?: boolean;
  role: Auth0AccountRole;
  event?: MeetingAdminResponse | MeetingLimitedResponse;
  setEvents: (e?: string) => Promise<void>;
}) {
  const {
    currently_selected_role,
    currently_selected_organization,
    organizations_available_to_role,
    updateOrgRolesAndAccount,
  } = useContext(OrgRolesAndAccountContext);

  const nearest15Minutes = () => {
    const inputMoment = moment.tz(
      moment().format(),
      "YYYY-MM-DDTHH:mm:ssZ",
      moment.tz.guess()
    );
    const roundedMinutes = Math.round(inputMoment.minutes() / 15) * 15;
    inputMoment.minutes(roundedMinutes);
    inputMoment.seconds(0);
    return inputMoment.format();
  };

  const originalMeeting: MeetingDetails = {
    meeting_id: event?.id,
    org_id: event?.org_id ?? (currently_selected_organization as number),
    start: event?.start ? moment(event.start).format() : nearest15Minutes(),
    duration: event?.duration ?? defaultDuration,
    ends_on_or_before: event?.session?.ends_on_or_before ?? undefined,
    name: event?.name ?? "",
    location: event?.location ?? undefined,
    other_location: event?.other_location ?? "",
    student_ids: event?.students.map((s) => s.student.id) ?? [],
    tutor_ids: event?.tutors.map((t) => t.tutor.id) ?? [],
    frequency: event?.session?.frequency ?? SessionFrequency.EVERY_7_DAYS,
    org_subject: event?.org_subject ?? undefined,
    locked: event?.locked ?? false,
  };

  const [isEditing, setIsEditing] = useState<boolean>(isCreate);
  const isEditable = true;
  const [status, setStatus] = useState<PageStatus>();
  const [currentMeeting, setCurrentMeeting] =
    useState<MeetingDetails>(originalMeeting);
  const [additionalMeetings, setAdditionalMeetings] = useState<
    MeetingDetails[]
  >([]);
  const [students, setStudents] = useState<
    (
      | MeetingStudentLimitedResponse
      | MeetingStudentAdminResponse
      | StudentLimitedResponse
      | StudentAdminResponse
    )[]
  >(event?.students ?? []);
  const [tutors, setTutors] = useState<
    (
      | MeetingTutorLimitedResponse
      | MeetingTutorAdminResponse
      | TutorLimitedResponse
      | TutorAdminResponse
    )[]
  >(event?.tutors ?? []);
  const [subject, setSubject] = useState<
    | OrganizationSubjectPublicResponse
    | OrganizationSubjectAdminResponse
    | undefined
  >(event?.org_subject);
  const [availableSubjects, setAvailableSubjects] = useState<
    | OrganizationSubjectPublicResponse[]
    | OrganizationSubjectAdminResponse[]
    | undefined
  >();
  const [locationOptions, setLocationOptions] = useState<
    OrganizationMeetingLocationResponse[] | undefined
  >(undefined);
  const [isAvailabilitySidebarOpen, setIsAvailabilitySidebarOpen] =
    useState<boolean>(false);

  const [availabilitySidebarDate, setAvailabilitySidebarDate] =
    useState<string>(event?.start ?? new Date().toISOString());

  const [index, setIndex] = useState<number>(0);

  const [saveError, setSaveError] = useState<string>("");
  const [canSave, setCanSave] = useState<boolean>(false);

  const addNewMeeting = () => {
    const newMeeting = {
      ...currentMeeting,
      session_id: null,
      start: moment(
        additionalMeetings.length > 0
          ? additionalMeetings[additionalMeetings.length - 1].start
          : currentMeeting.start
      )
        .add(2, "days")
        .format(),
    };
    setAdditionalMeetings([...additionalMeetings, newMeeting]);
  };

  const deleteAdditionalMeeting = (index: number) => {
    const meetings = [currentMeeting, ...additionalMeetings];
    meetings.splice(index, 1);
    setCurrentMeeting(meetings[0]);
    setAdditionalMeetings(meetings.slice(1));
  };

  const saveMeetingDetails = (meeting: MeetingDetails, index: number) => {
    if (index === 0) {
      setCurrentMeeting(meeting);
    } else {
      const updatedMeetings = [...additionalMeetings];
      updatedMeetings[index - 1] = meeting;
      setAdditionalMeetings(updatedMeetings);
    }
  };

  const discardChanges = () => {
    setCurrentMeeting(originalMeeting);
    setIsEditing(isCreate);
    setSubject(event?.org_subject);
    setTutors(event?.tutors ?? []);
    setStudents(event?.students ?? []);
    setIsAvailabilitySidebarOpen(false);
    setSaveError("");
    setCanSave(false);
  };

  const checkCanSave = useCallback(() => {
    const meetings = [currentMeeting, ...additionalMeetings];

    // Check for duplicate sessions
    const stringified_meetings = meetings.map((obj) => JSON.stringify(obj));
    if (new Set(stringified_meetings).size !== meetings.length) {
      setSaveError("Please remove duplicate sessions");
      setCanSave(false);
      return;
    }

    // Use a simple for loop so we can break out on first error
    for (let i = 0; i < meetings.length; i++) {
      const meeting = meetings[i];
      if (
        !meeting ||
        meeting.org_subject === undefined ||
        meeting.student_ids.length === 0 ||
        meeting.tutor_ids.length === 0 ||
        (!meeting.location && !meeting.other_location) ||
        !meeting.start ||
        !meeting.duration ||
        !meeting.frequency ||
        (meeting.frequency !== SessionFrequency.SINGLE &&
          !meeting.ends_on_or_before)
      ) {
        const missingFields: string[] = [];
        if (meeting.org_subject === undefined) missingFields.push("subject");
        if (meeting.student_ids.length === 0) missingFields.push("students");
        if (meeting.tutor_ids.length === 0) missingFields.push("tutors");
        if (!meeting.location && !meeting.other_location)
          missingFields.push("location");
        if (!meeting.start) missingFields.push("start time");
        if (!meeting.duration) missingFields.push("duration");
        if (!meeting.frequency) missingFields.push("frequency");
        if (
          meeting.frequency !== SessionFrequency.SINGLE &&
          !meeting.ends_on_or_before
        ) {
          missingFields.push("end date");
        }

        setSaveError(`Please add the following: ${missingFields.join(", ")}`);
        setCanSave(false);
        return;
      }
    }

    // If we get here, all validations passed
    setSaveError("");
    setCanSave(true);
  }, [currentMeeting, additionalMeetings]);

  const handleRemoveAttendee = (userType: Auth0AccountRole, id: number) => {
    if (userType === Auth0AccountRole.ME) {
      const newStudents = students.filter((s) =>
        "student" in s ? s.student.id !== id : s.id !== id
      );
      handleAttendeesUpdate(Auth0AccountRole.ME, newStudents);
    } else {
      const newTutors = tutors.filter((t) =>
        "tutor" in t ? t.tutor.id !== id : t.id !== id
      );
      handleAttendeesUpdate(Auth0AccountRole.ORG_TUTOR, newTutors);
    }
  };

  const handleAttendeesUpdate = (
    userType: Auth0AccountRole,
    attendees: (
      | MeetingTutorLimitedResponse
      | MeetingStudentLimitedResponse
      | MeetingTutorAdminResponse
      | MeetingStudentAdminResponse
      | StudentLimitedResponse
      | StudentAdminResponse
      | TutorLimitedResponse
      | TutorAdminResponse
    )[]
  ) => {
    if (userType === Auth0AccountRole.ME) {
      setStudents(
        attendees as (
          | MeetingStudentLimitedResponse
          | MeetingStudentAdminResponse
          | StudentLimitedResponse
          | StudentAdminResponse
        )[]
      );
      const studentIds = attendees.map((s) =>
        "student" in s ? s.student.id : s.id
      );
      setCurrentMeeting({
        ...currentMeeting,
        student_ids: studentIds,
      });
      setAdditionalMeetings(
        additionalMeetings.map((meeting) => ({
          ...meeting,
          students: studentIds,
        }))
      );
    } else {
      setTutors(
        attendees as (
          | MeetingTutorLimitedResponse
          | MeetingTutorAdminResponse
          | TutorLimitedResponse
          | TutorAdminResponse
        )[]
      );
      const tutorIds = attendees.map((t) => ("tutor" in t ? t.tutor.id : t.id));
      setCurrentMeeting({
        ...currentMeeting,
        tutor_ids: tutorIds,
      });
      setAdditionalMeetings(
        additionalMeetings.map((meeting) => ({
          ...meeting,
          tutors: tutorIds,
        }))
      );
    }
  };

  const handleTitleChange = (e) => {
    setCurrentMeeting({
      ...currentMeeting,
      name: e.target.value,
    });
    setAdditionalMeetings(
      additionalMeetings.map((meeting) => ({
        ...meeting,
        name: e.target.value,
      }))
    );
  };

  useEffect(() => {
    if (event || currently_selected_organization) {
      // Fetch locations by org
      LocationsService.getLocationsByOrg({
        orgId: event?.org_id ?? (currently_selected_organization as number),
      })
        .then((locations) => {
          setLocationOptions(locations);

          if (
            event &&
            currentMeeting.location &&
            currentMeeting.location.org_id !== event.org_id
          ) {
            setCurrentMeeting((prevState) => ({
              ...prevState,
              location: undefined,
              other_location: undefined,
            }));

            setAdditionalMeetings(
              additionalMeetings.map((meeting) => ({
                ...meeting,
                location: undefined,
                other_location: undefined,
              }))
            );
          }
        })
        .catch((err) => {
          console.error("Failed to fetch locations:", err);
        });
    }
  }, [
    additionalMeetings,
    currentMeeting.location,
    currently_selected_organization,
    event,
  ]);

  const handleSubjectChange = (
    option:
      | OrganizationSubjectPublicResponse
      | OrganizationSubjectAdminResponse
      | undefined
  ) => {
    setSubject(option);
    setCurrentMeeting((prevState) => ({
      ...prevState,
      org_subject: option,
    }));
    setAdditionalMeetings(
      additionalMeetings.map((meeting) => ({
        ...meeting,
        org_subject: option,
      }))
    );
  };

  useEffect(() => {
    if (event || currently_selected_organization) {
      OrgSubjectsService.getSubjectsByOrg({
        orgId: event?.org_id ?? (currently_selected_organization as number),
        active: true,
      })
        .then((subjects) => {
          setAvailableSubjects(subjects);

          if (
            event &&
            currentMeeting.org_subject &&
            currentMeeting.org_subject.org_id !== event.org_id
          ) {
            setSubject(undefined);
          }
        })
        .catch((err) => {
          console.error("Failed to fetch subjects:", err);
        });
    }
  }, [currentMeeting.org_subject, currently_selected_organization, event]);

  async function createMeeting() {
    setStatus(PageStatus.LOADING);
    if (currentMeeting === undefined || !subject) return;
    const meetingsToCreate = [currentMeeting, ...additionalMeetings];

    try {
      const promises = meetingsToCreate.map(async (meetingInfo) => {
        const requestBody: MeetingCreate = {
          org_id: meetingInfo.org_id,
          start: moment.utc(meetingInfo.start).format(),
          duration: meetingInfo.duration,
          name:
            !meetingInfo.name || meetingInfo.name.trim() === ""
              ? (meetingInfo.org_subject?.name as string)
              : meetingInfo.name,
          add_students: meetingInfo.student_ids,
          add_tutors: meetingInfo.tutor_ids,
          org_subject_id: subject.id,
          location_id: meetingInfo.location?.id,
          other_location: meetingInfo.other_location,
          frequency: meetingInfo.frequency,
          ends_on_or_before: meetingInfo.ends_on_or_before
            ? moment(meetingInfo.ends_on_or_before).endOf("day").utc().format()
            : undefined,
        };
        return await MeetingsService.createMeeting({ requestBody });
      });
      await Promise.all(promises);
      setEvents(currentMeeting.start);
      setStatus(PageStatus.SUCCESS);
    } catch (e) {
      Notifications.error(`Error creating meeting: ${e}`);
      setStatus(PageStatus.ERROR);
    }
  }

  useEffect(() => {
    checkCanSave();
  }, [checkCanSave, currentMeeting, additionalMeetings, students, tutors]);

  if (status === PageStatus.SUCCESS) {
    return (
      <SuccessDialog
        message="Meeting created!"
        onClose={() => {
          setStatus(undefined);
        }}
      />
    );
  }

  return (
    <>
      <DialogContent
        className="dialog-content dialog-content--left wide bg-neutral-50 font-montserrat pb-2 min-h-[85vh] overflow-x-hidden"
        alignLeft={true}
        gradientHeader={true}
        onClose={discardChanges}
      >
        <div className="grid grid-cols-11 md:space-x-2 space-y-4 md:space-y-0 -translate-y-4">
          {isAvailabilitySidebarOpen ? (
            <AvailabilitySidebar
              availabilitySidebarDate={availabilitySidebarDate}
              event={event}
              tutors={tutors}
              role={role}
              editing={isEditing}
              creating={isCreate}
              subject={subject}
              removeAttendee={handleRemoveAttendee}
              updateAttendees={handleAttendeesUpdate}
              isAvailabilitySidebarOpen={isAvailabilitySidebarOpen}
              setIsAvailabilitySidebarOpen={setIsAvailabilitySidebarOpen}
              currentMeetingInfo={currentMeeting}
              saveMeetingDetails={(meeting) =>
                saveMeetingDetails(meeting, index)
              }
              availableSubjects={availableSubjects}
              handleSubjectChange={handleSubjectChange}
            />
          ) : (
            <MeetingAttendees
              editing={isEditing}
              creating={isCreate}
              event={event}
              tutors={tutors}
              students={students}
              availableSubjects={availableSubjects}
              subject={subject}
              currentMeetingInfo={currentMeeting}
              removeAttendee={handleRemoveAttendee}
              updateAttendees={handleAttendeesUpdate}
              handleSubjectChange={handleSubjectChange}
              setIsAvailabilitySidebarOpen={setIsAvailabilitySidebarOpen}
            />
          )}
          <div className="md:col-span-8 col-span-12 bg-white rounded-lg p-5 pb-4 gap-0 min-h-[70vh] flex flex-col">
            {isCreate &&
              currently_selected_role !== Auth0AccountRole.ORG_ADMIN &&
              organizations_available_to_role &&
              organizations_available_to_role.length > 1 && (
                <div
                  className="w-6/12 p-1"
                  style={{ backgroundColor: "#CFF7C4" }}
                >
                  <SelectOrganization />
                </div>
              )}
            <div className="grid grid-cols-12 p-2 pb-0 items-baseline">
              {!isEditing && (
                <div className="pt-2">
                  <QuoteIcon />
                </div>
              )}
              <div
                className={`block sm:grid sm:grid-cols-7 col-span-${
                  isEditing ? "12" : "11"
                } gap-4 border-b-2 pb-4 border-b-slate-300 items-center mb-4`}
              >
                {isEditing ? (
                  <input
                    id="title"
                    name="title"
                    type="text"
                    className={`p-2 text-3xl rounded-md font-bold border-gray-300 col-span-7 w-full`}
                    placeholder="Meeting Title"
                    value={currentMeeting.name ?? ""}
                    onChange={(e) => handleTitleChange(e)}
                  />
                ) : (
                  <h1 className="mt-6 text-3xl font-bold col-span-7 flex justify-between items-center">
                    {event?.name ?? event?.org_subject.name}
                  </h1>
                )}
              </div>
            </div>
            {isEditing ? (
              <div className="flex-1">
                {[currentMeeting, ...additionalMeetings].map((mtg, index) => {
                  return (
                    <div
                      key={`${mtg.start}--${mtg.duration}--${index}`}
                      className={`mb-4 grid grid-cols-12 items-center`}
                    >
                      <div
                        className={`col-span-${
                          additionalMeetings.length > 0 || index !== 0
                            ? "11"
                            : "12"
                        }`}
                      >
                        <EditMeetingDetails
                          id={index}
                          tutors={tutors}
                          event={event}
                          currentMeetingDetails={mtg}
                          orginalMeetingDetails={originalMeeting}
                          saveMeetingDetails={(meeting) => {
                            saveMeetingDetails(meeting, index);
                          }}
                          setAvailabilitySidebarDate={
                            setAvailabilitySidebarDate
                          }
                          setIndex={setIndex}
                          locationOptions={locationOptions}
                        />
                      </div>
                      {(additionalMeetings.length > 0 || index !== 0) && (
                        <div
                          className="col-span-1 cursor-pointer m-auto"
                          onClick={() => {
                            deleteAdditionalMeeting(index);
                          }}
                        >
                          <TrashIcon width="25" height="25" />
                        </div>
                      )}
                    </div>
                  );
                })}
                {isCreate && (
                  <Button
                    color={ButtonColor.GREEN}
                    size={ButtonSize.SMALL}
                    onClick={() => {
                      addNewMeeting();
                    }}
                  >
                    Add another session
                  </Button>
                )}
              </div>
            ) : (
              event && <ViewMeetingDetails event={event} />
            )}
            {isEditable && (
              <div className={`grid grid-cols-12`}>
                <div className="col-span-6 md:col-start-4 md:col-span-3 pr-2">
                  {isCreate ? (
                    <Tooltip>
                      <TooltipTrigger>
                        <span>
                          <DialogActions>
                            <Button
                              size={ButtonSize.SMALL}
                              color={
                                saveError !== "" ||
                                canSave === false ||
                                status === PageStatus.LOADING
                                  ? ButtonColor.GRAY
                                  : ButtonColor.GREEN
                              }
                              onClick={() => {
                                createMeeting();
                              }}
                              disabled={
                                saveError !== "" ||
                                canSave === false ||
                                status === PageStatus.LOADING
                              }
                              extraClasses={
                                saveError === "" ? "" : "pointer-events-none"
                              }
                            >
                              {status === PageStatus.LOADING ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <>Confirm</>
                              )}
                            </Button>
                          </DialogActions>
                        </span>
                      </TooltipTrigger>
                      {saveError !== "" && (
                        <TooltipContent>{saveError}</TooltipContent>
                      )}
                    </Tooltip>
                  ) : isEditing && currentMeeting && event ? (
                    <Tooltip>
                      <TooltipTrigger>
                        <span>
                          <MeetingDialogTrigger
                            label={"Save"}
                            dialog={
                              <SaveChangesDialog
                                event={event}
                                originalMeetingInfo={originalMeeting}
                                currentMeetingInfo={currentMeeting}
                                originalTutors={event.tutors}
                                originalStudents={event.students}
                                newTutors={tutors}
                                newStudents={students}
                                subject={subject}
                                continueFunction={() => {
                                  setIsEditing(false);
                                  setEvents();
                                }}
                              />
                            }
                            extraClasses={`mt-5 w-full bg-white ${
                              saveError === "" ? "" : "pointer-events-none"
                            }`}
                            size={ButtonSize.SMALL}
                            color={
                              saveError !== "" ||
                              canSave === false ||
                              status === PageStatus.LOADING
                                ? ButtonColor.GRAY
                                : ButtonColor.GREEN
                            }
                            disabled={
                              saveError !== "" ||
                              canSave === false ||
                              status === PageStatus.LOADING
                            }
                            loading={status === PageStatus.LOADING}
                          />
                        </span>
                      </TooltipTrigger>
                      {saveError !== "" && (
                        <TooltipContent>{saveError}</TooltipContent>
                      )}
                    </Tooltip>
                  ) : (
                    <DialogActions>
                      <Button
                        size={ButtonSize.SMALL}
                        color={
                          [
                            Auth0AccountRole.ME,
                            Auth0AccountRole.PARENT,
                          ].includes(
                            currently_selected_role as Auth0AccountRole
                          ) || currentMeeting.locked
                            ? ButtonColor.DARK_GRAY
                            : ButtonColor.GREEN
                        }
                        disabled={
                          [
                            Auth0AccountRole.ME,
                            Auth0AccountRole.PARENT,
                          ].includes(
                            currently_selected_role as Auth0AccountRole
                          ) || currentMeeting.locked
                        }
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      >
                        Edit
                      </Button>
                    </DialogActions>
                  )}
                </div>
                <div className="col-span-6 md:col-span-4 lg:col-span-3">
                  {isCreate ? (
                    <DialogActions>
                      <DialogClose asChild>
                        <Button
                          size={ButtonSize.SMALL}
                          color={ButtonColor.BLACK}
                          fill={ButtonFill.HOLLOW}
                          onClick={discardChanges}
                        >
                          Discard Changes
                        </Button>
                      </DialogClose>
                    </DialogActions>
                  ) : isEditing ? (
                    <MeetingDialogTrigger
                      label={"Discard Changes"}
                      dialog={
                        <DiscardDialog continueFunction={discardChanges} />
                      }
                      extraClasses="mt-5 w-full bg-white"
                      size={ButtonSize.SMALL}
                      color={ButtonColor.BLACK}
                      fill={ButtonFill.HOLLOW}
                    />
                  ) : (
                    event && (
                      <MeetingDialogTrigger
                        label={"Cancel Meeting"}
                        dialog={
                          <CancelDialog
                            event={event}
                            continueFunction={() => {
                              setIsEditing(false);
                              setEvents();
                            }}
                          />
                        }
                        extraClasses="mt-5 w-full bg-white"
                        size={ButtonSize.SMALL}
                        color={
                          [
                            Auth0AccountRole.ME,
                            Auth0AccountRole.PARENT,
                          ].includes(
                            currently_selected_role as Auth0AccountRole
                          ) || currentMeeting.locked
                            ? ButtonColor.DARK_GRAY
                            : ButtonColor.SKYBLUE
                        }
                        fill={ButtonFill.HOLLOW}
                        disabled={
                          [
                            Auth0AccountRole.ME,
                            Auth0AccountRole.PARENT,
                          ].includes(
                            currently_selected_role as Auth0AccountRole
                          ) || currentMeeting.locked
                        }
                      />
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </>
  );
}
