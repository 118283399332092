import {
  StudentLimitedResponse,
  TutorAdminResponse,
  TutorStudentsService,
} from "client/openapi";
import { useState, useEffect } from "react";
import { Button, ButtonColor, ButtonSize, ButtonFill } from "components/Button";
import { Select } from "components/Select";
import { concatenateName } from "util/concatenateName";
import Avatar from "components/Avatar";
import { generateInitials } from "util/generateInitials";
import { useNavigate } from "react-router-dom";

export default function Students({
  tutor,
  orgStudents,
}: {
  tutor: TutorAdminResponse;
  orgStudents: StudentLimitedResponse[];
}) {
  const navigate = useNavigate();

  const [tutorStudents, setTutorStudents] = useState<StudentLimitedResponse[]>(
    tutor.students
  );
  const [studentOptions, setStudentOptions] =
    useState<StudentLimitedResponse[]>(tutorStudents);
  const [addingStudent, setAddingStudent] = useState(false);

  async function handleAssignStudent(student: StudentLimitedResponse) {
    await TutorStudentsService.createTutorStudentRelationship({
      tutorId: tutor.id,
      studentId: student.id,
    })
      .then(() => {
        const updatedStudents = [...tutorStudents, student];
        setTutorStudents(updatedStudents);
      })
      .catch((err) => {
        console.error(
          `Unable to assign student ${student.id} to tutor ${tutor.id}: `,
          err
        );
      });
  }

  async function handleRemoveStudent(student: StudentLimitedResponse) {
    await TutorStudentsService.removeTutorStudentRelation({
      tutorId: tutor.id,
      studentId: student.id,
    })
      .then(() => {
        const updatedStudents = tutorStudents.filter(
          (s) => s.id !== student.id
        );
        setTutorStudents(updatedStudents);
      })
      .catch((err) => {
        console.error(
          `Unable to remove student ${student.id} from tutor ${tutor.id}: `,
          err
        );
      });
  }

  useEffect(() => {
    const filteredStudents = orgStudents.filter(
      (s) => !tutorStudents.some((t) => t.id === s.id)
    );
    setStudentOptions(filteredStudents);
  }, [orgStudents, tutor.students]);

  return (
    <section className="student-profile--section" id="student-students">
      <div className="student-profile--section-header">
        <h2>Students</h2>

        {addingStudent ? (
          <Select
            id="add-student"
            name="add-student"
            options={studentOptions}
            value={null}
            isDisabled={studentOptions?.length === 0}
            getOptionLabel={(a) => `${a ? concatenateName(a) : ""}`}
            getOptionValue={(a) => (a ? a.id.toString() : "")}
            onChange={(student: StudentLimitedResponse) =>
              handleAssignStudent(student)
            }
            className="w-36 h-9 text-sm"
          />
        ) : (
          <div className="flex flex-row gap-1.5 items-center">
            {studentOptions.length > 0 && (
              <Button
                color={ButtonColor.SKYBLUE}
                size={ButtonSize.SMALL}
                onClick={() => setAddingStudent(true)}
              >
                Assign Student
              </Button>
            )}
          </div>
        )}
      </div>

      <div className="tutor-profile--section-items">
        {tutorStudents.length > 0 ? (
          tutorStudents.map((student, i) => (
            <div
              key={i}
              className="tutor-profile--section-item tutor-detail--student"
            >
              <Avatar
                alt={concatenateName(student)}
                fallback={generateInitials(concatenateName(student), 2)}
                color="skyblue"
              />

              <div className="tutor-detail--student-details">
                <p>
                  {student.first_name} {student.last_name}
                </p>
                <div>
                  <Button
                    color={ButtonColor.SKYBLUE}
                    fill={ButtonFill.HOLLOW}
                    size={ButtonSize.EXTRA_SMALL}
                    onClick={() => handleRemoveStudent(student)}
                  >
                    remove
                  </Button>
                  <span className="ml-2">
                    <Button
                      onClick={() => navigate(`/manage/students/${student.id}`)}
                      color={ButtonColor.SKYBLUE}
                      size={ButtonSize.EXTRA_SMALL}
                    >
                      view
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="tutor-profile--section-item">No students.</div>
        )}
      </div>
    </section>
  );
}
