/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the type of data that can be collected about a student or parent when defining
 * OrgDataKeys.
 */
export enum DataType {
  TEXT = "TEXT",
  FLOAT = "FLOAT",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
  CUSTOM_DATA_TYPE = "CUSTOM_DATA_TYPE",
}
