import { useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { AccountResponse, AccountsService, ApiError } from "client/openapi";
import { useNavigate } from "react-router-dom";
import { FaLaptop } from "react-icons/fa";
import { DialogActions, DialogClose, DialogContent } from "components/Dialog";
import "intl-tel-input/build/css/intlTelInput.css";
import IntlTelInput from "intl-tel-input/react";
import Notifications from "util/notifications";
import { PageStatus } from "types";
import "./index.css";
import { MdModeEditOutline } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

export default function PersonalSettings({
  account,
}: {
  account: AccountResponse;
}) {
  const [phoneNumber, setPhoneNumber] = useState(account.phone_number);
  const [smsEnabled, setSmsEnabled] = useState(account.sms_enabled);
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);

  const navigate = useNavigate();

  const [error, setError] = useState<ApiError>();
  const [status, setStatus] = useState<PageStatus>(PageStatus.SUCCESS);

  const updatePersonalSettings = () => {
    setStatus(PageStatus.LOADING);
    AccountsService.updateAccount({
      requestBody: {
        phone_number: phoneNumber,
        sms_enabled: smsEnabled,
      },
    })
      .then((res) => {
        account.phone_number = phoneNumber;
        account.sms_enabled = smsEnabled;
        setStatus(PageStatus.SUCCESS);
        Notifications.success("Personal settings updated successfully");
      })
      .catch((err) => {
        setStatus(PageStatus.ERROR);
        setError(err);
      });
  };

  useEffect(() => {
    if (error) {
      Notifications.error(error.body.detail);
    }
  }, [error]);

  const isDisabled =
    status === PageStatus.LOADING ||
    (account.phone_number === phoneNumber &&
      account.sms_enabled === smsEnabled);

  const onClose = () => {
    setStatus(PageStatus.SUCCESS);
    setError(undefined);
    setPhoneNumber(account.phone_number);
    setSmsEnabled(account.sms_enabled);
    setIsEditingPhoneNumber(false);
  };

  return (
    <DialogContent
      onClose={onClose}
      className="dialog-content dialog-content--left wide bg-neutral-50 font-montserrat pb-2 min-h-[85vh] overflow-x-hidden"
      alignLeft={true}
      gradientHeader={true}
    >
      <h1 className="title mb-6">Personal Settings</h1>
      <div className="infoItem">
        <p className="label">Email</p>
        <p>{account.email}</p>
      </div>
      <div className="infoItem">
        <p className="label">Phone</p>
        <div className="phoneInputContainer">
          <div className="flex flex-row gap-4">
            <IntlTelInput
              disabled={!isEditingPhoneNumber}
              inputProps={{
                className: `input mt-2 mb-2 ${
                  !isEditingPhoneNumber ? "opacity-70 " : ""
                }`,
                name: "studentPhone",
                defaultValue: account.phone_number?.replace(
                  /^\+\d+\s\d{3}\s/,
                  ""
                ),
              }}
              onChangeNumber={(number) => setPhoneNumber(number)}
              initOptions={{
                loadUtils: () => import("intl-tel-input/build/js/utils.js"),
                strictMode: true,
                autoPlaceholder: "aggressive",
                placeholderNumberType: "MOBILE",
                countryOrder: ["us", "gb", "ca"],
                initialCountry: "auto",
                geoIpLookup: (success, failure) => {
                  fetch("https://ipapi.co/json/")
                    .then((res) => res.json())
                    .then((data) => success(data.country_code))
                    .catch(() => failure());
                },
                separateDialCode: true,
                nationalMode: true,
              }}
            />
            <div className="mt-4">
              {isEditingPhoneNumber ? (
                <RxCross2
                  onClick={() => setIsEditingPhoneNumber(false)}
                  size={20}
                />
              ) : (
                <MdModeEditOutline
                  onClick={() => setIsEditingPhoneNumber(true)}
                  size={20}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="infoItem">
        <p className="label">Enable SMS Notifications For Meeting Booking</p>
        <div className="flex items-center">
          <input
            type="checkbox"
            className={`!border-black rounded ${
              smsEnabled ? "!bg-green-400" : "!bg-none"
            } focus:!outline-none`}
            checked={smsEnabled}
            onChange={(e) => setSmsEnabled(e.target.checked)}
          />
        </div>
      </div>
      <div className="infoItem">
        <p className="label">Manage Webpage</p>
        <div className="flex items-center">
          <DialogClose>
            <Button
              color={ButtonColor.SKYBLUE}
              size={ButtonSize.SMALL}
              onClick={() => navigate("/manage/webpage")}
            >
              <FaLaptop />
            </Button>
          </DialogClose>
        </div>
      </div>

      <DialogActions>
        <div className="buttons" style={{ marginTop: "30px" }}>
          <div className="actionButton">
            <Button
              color={isDisabled ? ButtonColor.GRAY : ButtonColor.GREEN}
              onClick={() => updatePersonalSettings()}
              disabled={isDisabled}
            >
              Save
            </Button>
          </div>
          <div className="actionButton">
            <DialogClose>
              <Button color={ButtonColor.GRAY} onClick={onClose}>
                Cancel
              </Button>
            </DialogClose>
          </div>
        </div>
      </DialogActions>
    </DialogContent>
  );
}
