import React, { useMemo } from "react";
import "./index.css";
import { DatePicker } from "rsuite";
import moment from "moment";

import {
  Condition,
  ComparisonOperator,
  OrganizationDataKeyResponse,
  DataType,
} from "client/openapi";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { TrashIcon } from "@radix-ui/react-icons";
import { Select } from "components/Select";

type ConditionEditorProps = {
  condition: Condition;
  onChange: (updated: Condition) => void;
  onRemove: () => void;
  availableKeys: OrganizationDataKeyResponse[];
};

const comparisonOptions = [
  { value: ComparisonOperator.EQUALS, label: "equals" },
  { value: ComparisonOperator.NOT_EQUALS, label: "not_equals" },
  { value: ComparisonOperator.GREATER_THAN, label: "greater_than" },
  {
    value: ComparisonOperator.GREATER_THAN_OR_EQUAL,
    label: "greater_than_or_equal",
  },
  { value: ComparisonOperator.LESS_THAN, label: "less_than" },
  { value: ComparisonOperator.LESS_THAN_OR_EQUAL, label: "less_than_or_equal" },
  { value: ComparisonOperator.ILIKE, label: "ilike (contains)" },
  { value: ComparisonOperator.IN, label: "in" },
  { value: ComparisonOperator.NOT_IN, label: "not_in" },
];

export function ConditionEditor({
  condition,
  onChange,
  onRemove,
  availableKeys,
}: ConditionEditorProps) {
  const { org_data_key_id, comparison_operator, value, negate } = condition;

  const selectedKey = useMemo(
    () => availableKeys.find((k) => k.id === org_data_key_id),
    [org_data_key_id, availableKeys]
  );

  const dataType = selectedKey?.data_type || DataType.TEXT;

  /** When user changes the key, reset operator/value by default */
  function handleKeyChange(newKeyId: number) {
    onChange({
      ...condition,
      org_data_key_id: newKeyId,
      comparison_operator: ComparisonOperator.EQUALS,
      value: "",
    });
  }

  function handleOperatorChange(newOp: ComparisonOperator) {
    onChange({ ...condition, comparison_operator: newOp });
  }

  function handleValueChange(newVal: any) {
    onChange({ ...condition, value: newVal });
  }

  function toggleNegate() {
    onChange({ ...condition, negate: !negate });
  }

  // Helper to handle list input values
  const isListOperator = [
    ComparisonOperator.IN,
    ComparisonOperator.NOT_IN,
  ].includes(comparison_operator);

  const handleListInputChange = (
    inputValue: string,
    type: "text" | "number"
  ) => {
    if (!isListOperator) {
      // For non-list operators, handle as before
      if (type === "number") {
        handleValueChange(inputValue ? parseFloat(inputValue) : null);
      } else {
        handleValueChange(inputValue);
      }
    } else {
      // For list operators, just store the raw input
      handleValueChange(inputValue);
    }
  };

  let valueInput: React.ReactNode = null;

  switch (dataType) {
    case DataType.TEXT:
      valueInput = (
        <input
          className="input"
          type="text"
          value={String(value ?? "")}
          onChange={(e) => {
            handleListInputChange(e.target.value, "text");
          }}
          placeholder={
            isListOperator ? "Enter values separated by commas" : "Enter text"
          }
          style={{ width: 220 }}
        />
      );
      break;

    case DataType.FLOAT:
      valueInput = (
        <input
          className="input"
          type={isListOperator ? "text" : "number"}
          step="any"
          value={String(value ?? "")}
          onChange={(e) => {
            handleListInputChange(e.target.value, "number");
          }}
          placeholder={
            isListOperator
              ? "Enter numbers separated by commas"
              : "Enter number"
          }
          style={{ width: 220 }}
        />
      );
      break;

    case DataType.DATE:
      valueInput = (
        <DatePicker
          oneTap
          value={typeof value === "string" ? moment(value).toDate() : null}
          onChange={(date) =>
            handleValueChange(
              date ? moment(date).startOf("day").format("YYYY-MM-DD") : null
            )
          }
          format="yyyy-MM-dd"
          style={{ width: 220 }}
          size="md"
          block
        />
      );
      break;

    case DataType.BOOLEAN: {
      // Default to true if condition.value is not a boolean
      const booleanVal = typeof value === "boolean" ? value : true;

      valueInput = (
        <Select
          className="min-w-[220px]"
          value={
            isListOperator
              ? Array.isArray(value)
                ? value.map((v) => ({
                    value: String(v),
                    label: v ? "Yes" : "No",
                  }))
                : []
              : undefined
          }
          onChange={(option) => {
            if (isListOperator) {
              const values = option
                ? option.map((opt) => opt.value === "true")
                : [];
              handleValueChange(values);
            } else {
              handleValueChange(option?.value === "true");
            }
          }}
          options={[
            { value: "true", label: "Yes" },
            { value: "false", label: "No" },
          ]}
          isClearable={false}
          isMulti={isListOperator}
        />
      );
      break;
    }

    case DataType.CUSTOM_DATA_TYPE: {
      const customValues = selectedKey?.custom_data_type_key?.values || [];

      valueInput = (
        <Select
          className="min-w-[220px]"
          value={
            isListOperator
              ? Array.isArray(value)
                ? value.map((v) => ({
                    value: String(v),
                    label: String(v),
                  }))
                : []
              : value != null
              ? {
                  value: String(value),
                  label: String(value),
                }
              : null
          }
          onChange={(option) => {
            if (isListOperator) {
              // Store the option_names directly for backend
              const values = option ? option.map((opt) => opt.value) : [];
              handleValueChange(values);
            } else {
              const val = option ? option.value : null;
              handleValueChange(val);
            }
          }}
          options={customValues.map((cv) => ({
            value: cv.option_name,
            label: cv.option_name,
          }))}
          placeholder="--Select--"
          isClearable
          isMulti={isListOperator}
        />
      );
      break;
    }

    default:
      valueInput = (
        <input
          className="input"
          type="text"
          value={String(value ?? "")}
          onChange={(e) => handleValueChange(e.target.value)}
          style={{ width: 220 }}
        />
      );
  }

  return (
    <div className="flex flex-row">
      <div className="condition-row">
        {/* Center column: existing content */}

        {/* Key selector */}
        <Select
          className="min-w-[275px]"
          value={
            org_data_key_id
              ? {
                  value: org_data_key_id.toString(),
                  label:
                    availableKeys.find((k) => k.id === org_data_key_id)?.name ||
                    "",
                }
              : null
          }
          onChange={(option) =>
            handleKeyChange(option ? Number(option.value) : 0)
          }
          options={[
            { value: "0", label: "-- select field --", isDisabled: true },
            ...availableKeys.map((k) => ({
              value: k.id.toString(),
              label: k.name,
            })),
          ]}
          placeholder="-- select field --"
        />

        {/* Operator selector */}
        <Select
          className="min-w-[225px]"
          value={{
            value: comparison_operator,
            label:
              comparisonOptions.find((op) => op.value === comparison_operator)
                ?.label || "",
          }}
          onChange={(option) =>
            handleOperatorChange(option?.value as ComparisonOperator)
          }
          options={comparisonOptions}
          placeholder="-- select operator --"
        />

        {valueInput}
      </div>
      <div className="mt-4 ml-4">
        <Button
          color={ButtonColor.PURPLE}
          size={ButtonSize.SMALL}
          onClick={onRemove}
        >
          <TrashIcon className="w-4 h-4 " />
        </Button>
      </div>
    </div>
  );
}
