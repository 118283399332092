import {
  StudentLimitedResponse,
  ParentsService,
  ParentAdminResponse,
  Auth0AccountRole,
  ParentLimitedResponse,
} from "client/openapi";
import { useState, useEffect, useContext } from "react";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { Select } from "components/Select";
import { concatenateName } from "util/concatenateName";
import Avatar from "components/Avatar";
import { generateInitials } from "util/generateInitials";
import Notifications from "util/notifications";

import { useNavigate } from "react-router-dom";

import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

export default function Parents({
  student,
  orgParents,
  studentParents,
  setStudentParents,
}: {
  student: StudentLimitedResponse;
  orgParents: ParentAdminResponse[] | ParentLimitedResponse[];
  studentParents: ParentAdminResponse[] | ParentLimitedResponse[];
  setStudentParents: (
    students: ParentAdminResponse[] | ParentLimitedResponse[]
  ) => void;
}) {
  const { currently_selected_role } = useContext(OrgRolesAndAccountContext);
  const [parentOptions, setParentOptions] = useState<
    ParentAdminResponse[] | ParentLimitedResponse[]
  >(studentParents);
  const [addingParent, setAddingParent] = useState<boolean>(false);

  const [deleteIntentKey, setDeleteIntentKey] = useState<number>();

  const navigate = useNavigate();

  async function handleAssignParent(parent: ParentAdminResponse) {
    await ParentsService.addExistingStudentToParent({
      parentId: parent.id,
      studentId: student.id,
    })
      .then(() => {
        const updatedParents = [...studentParents, parent];
        setStudentParents(updatedParents);
        Notifications.success(
          `${parent.first_name} successfully linked to ${student.first_name}`
        );
      })
      .catch((err) => {
        Notifications.error(
          `Unable to link ${parent.first_name} to ${student.first_name}`
        );
        console.error(err);
      });
  }

  async function handleRemoveParent(parent: ParentAdminResponse) {
    await ParentsService.removeExistingStudentFromParent({
      parentId: parent.id,
      studentId: student.id,
    })
      .then(() => {
        const updatedParents = studentParents.filter((p) => p.id !== parent.id);
        setStudentParents(updatedParents);
        Notifications.success(
          `${parent.first_name} is no longer linked to ${student.first_name}`
        );
      })
      .catch((err) => {
        Notifications.error(
          `Unable to unlink ${parent.first_name} from ${student.first_name}`
        );
        console.error(err);
      });
  }

  useEffect(() => {
    const filteredParents = orgParents.filter(
      (s) => !studentParents.some((t) => t.id === s.id)
    );
    setParentOptions(filteredParents);
  }, [orgParents, studentParents]);

  return (
    <section className="student-profile--section" id="parent-students">
      <div className="student-profile--section-header">
        <h2>Parents</h2>

        {addingParent ? (
          <Select
            id="add-parent"
            name="add-parent"
            options={parentOptions}
            value={null}
            isDisabled={parentOptions?.length === 0}
            getOptionLabel={(a) => `${a ? concatenateName(a) : ""}`}
            getOptionValue={(a) => (a ? a.id.toString() : "")}
            onChange={(parent: ParentAdminResponse) =>
              handleAssignParent(parent)
            }
            className="w-36 h-9 text-sm"
          />
        ) : (
          <div className="flex flex-row gap-1.5 items-center">
            {parentOptions.length > 0 &&
              [Auth0AccountRole.ORG_ADMIN, Auth0AccountRole.ORG_TUTOR].includes(
                currently_selected_role as Auth0AccountRole
              ) && (
                <Button
                  color={ButtonColor.SKYBLUE}
                  size={ButtonSize.SMALL}
                  onClick={() => setAddingParent(true)}
                >
                  Assign Parent
                </Button>
              )}
          </div>
        )}
      </div>

      <div className="parent-profile--section-items">
        {studentParents.length > 0 ? (
          studentParents.map((parent, i) => (
            <div
              key={i}
              className="parent-profile--section-item parent-detail--student"
            >
              <Avatar
                alt={concatenateName(parent)}
                fallback={generateInitials(concatenateName(parent), 2)}
                color="skyblue"
              />

              <div className="parent-detail--student-details">
                <p>
                  {parent.first_name} {parent.last_name}
                </p>
                {[
                  Auth0AccountRole.ORG_ADMIN,
                  Auth0AccountRole.ORG_TUTOR,
                ].includes(currently_selected_role as Auth0AccountRole) && (
                  <div>
                    {deleteIntentKey === parent.id ? (
                      <>
                        <span style={{ marginRight: "5px" }}>
                          <Button
                            onClick={() => {
                              handleRemoveParent(parent);
                              setDeleteIntentKey(-1);
                            }}
                            color={ButtonColor.RED}
                            size={ButtonSize.EXTRA_SMALL}
                          >
                            confirm removal
                          </Button>
                        </span>
                        <span>
                          <Button
                            onClick={() => setDeleteIntentKey(-1)}
                            color={ButtonColor.SKYBLUE}
                            size={ButtonSize.EXTRA_SMALL}
                          >
                            cancel
                          </Button>
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ marginRight: "5px" }}>
                          <Button
                            color={ButtonColor.SKYBLUE}
                            fill={ButtonFill.HOLLOW}
                            size={ButtonSize.EXTRA_SMALL}
                            onClick={() => setDeleteIntentKey(parent.id)}
                          >
                            remove
                          </Button>
                        </span>
                        <span>
                          <Button
                            onClick={() =>
                              navigate(`/manage/parents/${parent.id}`)
                            }
                            color={ButtonColor.SKYBLUE}
                            size={ButtonSize.EXTRA_SMALL}
                          >
                            view
                          </Button>
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="parent-profile--section-item">No parents.</div>
        )}
      </div>
    </section>
  );
}
