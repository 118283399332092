import { DialogTrigger } from "components/Dialog";
import { ButtonFill, ButtonSize } from "components/Button";
import { ButtonColor } from "components/Button";
import { ApiError, Auth0AccountRole, ExportsService } from "client/openapi";
import {
  OrganizationDataKeyResponse,
  OrganizationResponseForAdmins,
} from "client/openapi";
import { useEffect, useState } from "react";
import Notifications from "util/notifications";
import { saveAs } from "file-saver";
import { APIResponse, PageStatus } from "types";
import { Button } from "components/Button";
import {
  DownloadIcon,
  Pencil1Icon,
  PlusIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { Dialog } from "components/Dialog";
import NewOrgDataKeyDialog from "components/OrgNoteKeyDialogs/New/createOrgDataKey";
import EditOrgDataKeyDialog from "components/OrgNoteKeyDialogs/Edit/editOrgDataKey";
import DeleteOrgDataKeyDialog from "components/OrgNoteKeyDialogs/Delete/deleteOrgDataKey";
import { ErrorBlock } from "components/StatusBlock";

/**
 * Responsive chunking logic:
 *  - < 600px => chunkSize = 1
 *  - 600..959 => chunkSize = 2
 *  - >= 960 => chunkSize = 3
 */
function useResponsiveChunkSize(): number {
  const [chunkSize, setChunkSize] = useState<number>(3); // default to 3

  useEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      if (width < 600) {
        setChunkSize(1);
      } else if (width < 960) {
        setChunkSize(2);
      } else {
        setChunkSize(3);
      }
    }
    // Listen for resizing
    window.addEventListener("resize", updateSize);
    // Initial check
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return chunkSize;
}

export default function DataFields({
  organization,
  keys,
  setKeys,
  setStatus,
  setError,
  userType,
}: {
  organization: OrganizationResponseForAdmins;
  keys: OrganizationDataKeyResponse[];
  setKeys: (keys: OrganizationDataKeyResponse[]) => void;
  setStatus: (status: PageStatus) => void;
  setError: (error: APIResponse) => void;
  userType: Auth0AccountRole;
}) {
  const [exportingData, setExportingData] = useState(false);

  // 1) Use the custom hook to determine how many columns we want (1, 2, or 3)
  const chunkSize = useResponsiveChunkSize();

  // 2) chunk the keys in groups of `chunkSize`
  function chunkKeys(
    keys: OrganizationDataKeyResponse[]
  ): OrganizationDataKeyResponse[][] {
    const chunks: OrganizationDataKeyResponse[][] = [];
    for (let i = 0; i < keys.length; i += chunkSize) {
      chunks.push(keys.slice(i, i + chunkSize));
    }
    return chunks;
  }

  // 3) Export logic
  async function exportUsers(userType: Auth0AccountRole) {
    if (!organization) {
      return Notifications.error("An unexpected error occurred");
    }

    setExportingData(true);
    try {
      let data;
      if (userType === Auth0AccountRole.ME) {
        data = await ExportsService.exportStudents({
          orgId: organization.id,
        });
      } else if (userType === Auth0AccountRole.PARENT) {
        data = await ExportsService.exportParents({
          orgId: organization.id,
        });
      }

      const blob = new Blob([data], { type: "text/csv" });
      saveAs(blob, `${roleDisplayNames[userType]}s.csv`);
      Notifications.success(
        `All ${roleDisplayNames[userType]}'s data have been saved to your computer!`
      );
    } catch (err) {
      const e = err as ApiError;
      setStatus(PageStatus.ERROR);
      setError({
        message: `Unable to export ${roleDisplayNames[userType]}'s data`,
      });
      console.error(`Error (#${e.status}): ${e.message}`);
    }
    setExportingData(false);
  }

  // Map Auth0AccountRole to user-friendly display names
  const roleDisplayNames = {
    [Auth0AccountRole.ME]: "Student",
    [Auth0AccountRole.PARENT]: "Parent",
    [Auth0AccountRole.ORG_TUTOR]: "Tutor",
  };

  // 4) If no keys, show the "No fields" block
  if (!keys || keys.length === 0) {
    return (
      <div
        className="mx-auto w-full"
        style={{
          display: "flex",
          backgroundColor: "#f8f8f8",
          borderRadius: "0.75rem",
          alignItems: "center",
          padding: "5rem 1.5rem",
          textAlign: "center",
        }}
      >
        <div className="flex flex-col items-center justify-center w-full">
          <ErrorBlock
            status={PageStatus.ERROR}
            title="No fields"
            message={`You have not created any ${roleDisplayNames[userType]} data yet.`}
          />
          <Dialog>
            <DialogTrigger asChild>
              <Button
                color={ButtonColor.PURPLE}
                size={ButtonSize.LARGE}
                extraClasses="mt-5"
              >
                Create Field <PlusIcon />
              </Button>
            </DialogTrigger>

            <NewOrgDataKeyDialog
              userType={userType}
              organization={organization}
              keys={keys}
              setKeys={setKeys}
            />
          </Dialog>
        </div>
      </div>
    );
  }

  // 5) If we have keys, create a table
  const sortedKeys = [...keys].sort((a, b) => a.name.localeCompare(b.name));
  const rowChunks = chunkKeys(sortedKeys); // each row has up to chunkSize items

  return (
    <section className="student-data--container">
      {/* Header row with "Student Data" title and "Export / Create" buttons */}
      <div className="student-data--header">
        <h2>
          <strong className="text-white">
            {roleDisplayNames[userType]} Data
          </strong>
        </h2>
        <div className="student-data--field-actions">
          <Button
            color="#FFFFFF"
            size={ButtonSize.SMALL}
            onClick={() => exportUsers(userType)}
          >
            <div style={{ color: "#98acfc" }}>
              Export {roleDisplayNames[userType]}s{" "}
              {exportingData ? (
                <span
                  className="spinner-border spinner-border-square"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <DownloadIcon />
              )}
            </div>
          </Button>

          <Dialog>
            <DialogTrigger asChild>
              <Button color="#FFFFFF" size={ButtonSize.SMALL}>
                <span style={{ color: "#98acfc" }}>
                  Create New Field <PlusIcon />
                </span>
              </Button>
            </DialogTrigger>
            <NewOrgDataKeyDialog
              userType={userType}
              organization={organization}
              keys={keys}
              setKeys={setKeys}
            />
          </Dialog>
        </div>
      </div>

      <table className="student-data--table">
        <tbody>
          {rowChunks.map((chunk, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowIndex % 2 === 1 ? "student-data--row-alt" : ""}
            >
              {/* We create exactly 'chunkSize' <td> in each row, or fewer if final row has fewer items */}
              {Array.from({ length: chunkSize }).map((_, colIdx) => {
                const keyItem = chunk[colIdx];
                if (!keyItem) {
                  return <td key={colIdx} className="student-data--cell" />;
                }
                return (
                  <td key={keyItem.id} className="student-data--cell">
                    <div className="student-data--field-inner">
                      {keyItem.name}
                      <div className="student-data--field-actions">
                        <Dialog>
                          <DialogTrigger asChild>
                            <Button color="#98acfc" size={ButtonSize.SMALL}>
                              <Pencil1Icon />
                            </Button>
                          </DialogTrigger>
                          <EditOrgDataKeyDialog
                            keyId={keyItem.id}
                            currentName={keyItem.name}
                            keys={keys}
                            setKeys={setKeys}
                          />
                        </Dialog>

                        <Dialog>
                          <DialogTrigger asChild>
                            <Button
                              color="#98acfc"
                              fill={ButtonFill.HOLLOW}
                              size={ButtonSize.EXTRA_SMALL}
                            >
                              <TrashIcon />
                            </Button>
                          </DialogTrigger>
                          <DeleteOrgDataKeyDialog
                            dataKey={keyItem}
                            keys={keys}
                            setKeys={setKeys}
                            userType={userType}
                          />
                        </Dialog>
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
