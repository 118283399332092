/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountingSystemForCalculatingFinancialStatistics } from "../models/AccountingSystemForCalculatingFinancialStatistics";
import type { ACHResponse } from "../models/ACHResponse";
import type { BooleanResponse } from "../models/BooleanResponse";
import type { CreditCardResponse } from "../models/CreditCardResponse";
import type { MeetingAdminResponse } from "../models/MeetingAdminResponse";
import type { OrgToTadpoleTransactionResponse } from "../models/OrgToTadpoleTransactionResponse";
import type { PaymentLinkCreate } from "../models/PaymentLinkCreate";
import type { PayoutPaymentCreate } from "../models/PayoutPaymentCreate";
import type { PayoutTransactionResponse } from "../models/PayoutTransactionResponse";
import type { StripeBalanceResponse } from "../models/StripeBalanceResponse";
import type { StripeSessionClientSecret } from "../models/StripeSessionClientSecret";
import type { StudentToOrgPaymentCreate } from "../models/StudentToOrgPaymentCreate";
import type { StudentToOrgTransactionResponse } from "../models/StudentToOrgTransactionResponse";
import type { TadpoleToTutorPaymentCreate } from "../models/TadpoleToTutorPaymentCreate";
import type { TadpoleToTutorTransactionResponse } from "../models/TadpoleToTutorTransactionResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class PaymentsService {
  /**
   * Create Student To Org Payment
   * Create a student-to-organization payment.
   * @returns StudentToOrgTransactionResponse Successful Response
   * @throws ApiError
   */
  public static createStudentToOrgPayment({
    requestBody,
  }: {
    requestBody: StudentToOrgPaymentCreate;
  }): CancelablePromise<StudentToOrgTransactionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/create_student_to_org_payment",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Tadpole To Tutor Payment
   * Create a tadpole-to-tutor payment.
   * @returns TadpoleToTutorTransactionResponse Successful Response
   * @throws ApiError
   */
  public static createTadpoleToTutorPayment({
    requestBody,
  }: {
    requestBody: TadpoleToTutorPaymentCreate;
  }): CancelablePromise<TadpoleToTutorTransactionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/create_tadpole_to_tutor_payment",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Payout
   * Create a payout for an organization or a tutor.
   * @returns PayoutTransactionResponse Successful Response
   * @throws ApiError
   */
  public static createPayout({
    requestBody,
  }: {
    requestBody: PayoutPaymentCreate;
  }): CancelablePromise<PayoutTransactionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/payout",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Create Refund
   * Issue a refund for a student_to_org_transaction.
   * @returns StudentToOrgTransactionResponse Successful Response
   * @throws ApiError
   */
  public static createRefund({
    transactionId,
  }: {
    transactionId: string;
  }): CancelablePromise<StudentToOrgTransactionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/refund/student_to_org_transaction/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Retry Transaction
   * Retry an org payout, tutor payout,or a student-to-organization payment.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static retryTransaction({
    transactionId,
  }: {
    transactionId: string;
  }): CancelablePromise<
    | PayoutTransactionResponse
    | StudentToOrgTransactionResponse
    | OrgToTadpoleTransactionResponse
    | TadpoleToTutorTransactionResponse
  > {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/retry/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Void Transaction Invoice
   * Void an invoice.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static voidTransactionInvoice({
    transactionId,
  }: {
    transactionId: string;
  }): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/void/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Resend Stripe Invoice
   * Resend an invoice to the customer.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static resendStripeInvoice({
    transactionId,
  }: {
    transactionId: string;
  }): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/resend_invoice/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Queue Payroll
   * Change an org-to-tadpole transaction's status from AWAITING_CONFIRMATION_TO_FUND_PAYROLL
   * to QUEUED.
   * @returns BooleanResponse Successful Response
   * @throws ApiError
   */
  public static queuePayroll({
    transactionId,
    orgId,
  }: {
    transactionId: string;
    orgId: number;
  }): CancelablePromise<BooleanResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/queue_payroll/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Transaction
   * Retrieve a transaction from the DB.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTransaction({
    transactionId,
  }: {
    transactionId: string;
  }): CancelablePromise<
    | PayoutTransactionResponse
    | StudentToOrgTransactionResponse
    | OrgToTadpoleTransactionResponse
    | TadpoleToTutorTransactionResponse
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/get_transaction_by_id/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Transactions By Tutor
   * Retrieve all payments an org has made to a tutor.
   * @returns TadpoleToTutorTransactionResponse Successful Response
   * @throws ApiError
   */
  public static getTransactionsByTutor({
    tutorId,
    accountingSystem,
    start,
    until,
  }: {
    tutorId: number;
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
    start: string | null;
    until: string | null;
  }): CancelablePromise<Array<TadpoleToTutorTransactionResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/get_transactions_by_tutor/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        accounting_system: accountingSystem,
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tadpole To Tutor Payments And Payouts By Account
   * Retrieve all tadpole-to-tutor payments and payouts for all tutors tied to an account.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTadpoleToTutorPaymentsAndPayoutsByAccount({
    accountId,
    start,
    until,
  }: {
    accountId: string;
    start: string | null;
    until: string | null;
  }): CancelablePromise<
    Array<PayoutTransactionResponse | TadpoleToTutorTransactionResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/get_tutor_payments_and_payouts_by_account/{account_id}",
      path: {
        account_id: accountId,
      },
      query: {
        start: start,
        until: until,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Transactions By Student
   * Get transactions by student_id.
   * @returns StudentToOrgTransactionResponse Successful Response
   * @throws ApiError
   */
  public static getTransactionsByStudent({
    studentId,
    start,
    until,
    accountingSystem,
    skip,
    limit,
  }: {
    studentId: number;
    start: string | null;
    until: string | null;
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
    skip?: number | null;
    limit?: number | null;
  }): CancelablePromise<Array<StudentToOrgTransactionResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/get_transactions_by_student/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        start: start,
        until: until,
        accounting_system: accountingSystem,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Transactions By Org
   * Get transactions by org_id.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTransactionsByOrg({
    orgId,
    accountingSystem,
    start,
    until,
    skip,
    limit,
  }: {
    orgId: number;
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
    start: string | null;
    until: string | null;
    skip?: number | null;
    limit?: number | null;
  }): CancelablePromise<
    Array<
      | StudentToOrgTransactionResponse
      | OrgToTadpoleTransactionResponse
      | TadpoleToTutorTransactionResponse
      | PayoutTransactionResponse
    >
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/get_transactions_by_org/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        accounting_system: accountingSystem,
        start: start,
        until: until,
        skip: skip,
        limit: limit,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Transactions By Funding Transaction Id
   * Get all tadpole-to-tutor transactions associated with
   * the org-to-tadpole transaction that funds them.
   * @returns TadpoleToTutorTransactionResponse Successful Response
   * @throws ApiError
   */
  public static getTransactionsByFundingTransactionId({
    fundingTransactionId,
  }: {
    fundingTransactionId: string;
  }): CancelablePromise<Array<TadpoleToTutorTransactionResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/transactions/funded_by/{funding_transaction_id}",
      path: {
        funding_transaction_id: fundingTransactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Direct Payments In Automatic Payment Group
   * Get tadpole-to-tutor transactions representing direct payments (those without a
   * meeting_tutor_id) tied to an Automatic Payment Group Transaction.
   * @returns TadpoleToTutorTransactionResponse Successful Response
   * @throws ApiError
   */
  public static getDirectPaymentsInAutomaticPaymentGroup({
    groupTransactionId,
  }: {
    groupTransactionId: string;
  }): CancelablePromise<Array<TadpoleToTutorTransactionResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/transactions/direct_payments_in_automatic_payment_group/{group_transaction_id}",
      path: {
        group_transaction_id: groupTransactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Student To Org Transaction
   * Get all meetings associated with a student-to-org transaction.
   * @returns MeetingAdminResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsByStudentToOrgTransaction({
    transactionId,
  }: {
    transactionId: string;
  }): CancelablePromise<Array<MeetingAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/meetings/student_to_org/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Meetings By Tadpole To Tutor Transaction
   * Get all meetings associated with a tadpole-to-tutor transaction.
   * @returns MeetingAdminResponse Successful Response
   * @throws ApiError
   */
  public static getMeetingsByTadpoleToTutorTransaction({
    transactionId,
  }: {
    transactionId: string;
  }): CancelablePromise<Array<MeetingAdminResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/meetings/tadpole_to_tutor/{transaction_id}",
      path: {
        transaction_id: transactionId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student To Org Transactions For Meeting
   * Get all student-to-org transactions associated with a meeting.
   * @returns StudentToOrgTransactionResponse Successful Response
   * @throws ApiError
   */
  public static getStudentToOrgTransactionsForMeeting({
    meetingId,
  }: {
    meetingId: number;
  }): CancelablePromise<Array<StudentToOrgTransactionResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/transactions/student_to_org/by_meeting/{meeting_id}",
      path: {
        meeting_id: meetingId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tadpole To Tutor Transactions For Meeting
   * Get all tadpole-to-tutor transactions associated with a meeting.
   * @returns TadpoleToTutorTransactionResponse Successful Response
   * @throws ApiError
   */
  public static getTadpoleToTutorTransactionsForMeeting({
    meetingId,
  }: {
    meetingId: number;
  }): CancelablePromise<Array<TadpoleToTutorTransactionResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/transactions/tadpole_to_tutor/by_meeting/{meeting_id}",
      path: {
        meeting_id: meetingId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Stripe Account Session By Org
   * Get an AccountSession.client_secret for the current org.
   * @returns StripeSessionClientSecret Successful Response
   * @throws ApiError
   */
  public static getStripeAccountSessionByOrg({
    currentOrg,
  }: {
    currentOrg: number;
  }): CancelablePromise<StripeSessionClientSecret> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/me/stripe_account_session/org/{current_org}",
      path: {
        current_org: currentOrg,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Stripe Account Session By Account
   * Get an AccountSession.client_secret for the current org.
   * @returns StripeSessionClientSecret Successful Response
   * @throws ApiError
   */
  public static getStripeAccountSessionByAccount(): CancelablePromise<StripeSessionClientSecret> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/me/stripe_account_session/account/{account_id}",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Stripe Balance For Org
   * Retrieve an organization's Stripe balance.
   * @returns StripeBalanceResponse Successful Response
   * @throws ApiError
   */
  public static getStripeBalanceForOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<StripeBalanceResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/balance/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Stripe Balance For Account
   * Retrieve an account's Stripe balance.
   * @returns StripeBalanceResponse Successful Response
   * @throws ApiError
   */
  public static getStripeBalanceForAccount({
    accountId,
  }: {
    accountId: string;
  }): CancelablePromise<StripeBalanceResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/balance/account/{account_id}",
      path: {
        account_id: accountId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Registered Payment Method From Individual
   * This can be used to remove a registered card from a student, parent, or account.
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static removeRegisteredPaymentMethodFromIndividual({
    paymentMethodId,
  }: {
    paymentMethodId: string;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/payments/payment_methods/individual/{payment_method_id}",
      path: {
        payment_method_id: paymentMethodId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Remove Registered Payment Method From Org
   * This can be used to remove a registered card from a student, parent, or account.
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public static removeRegisteredPaymentMethodFromOrg({
    paymentMethodId,
  }: {
    paymentMethodId: string;
  }): CancelablePromise<boolean> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/payments/payment_methods/org/{payment_method_id}",
      path: {
        payment_method_id: paymentMethodId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add Payment Method Student
   * Add a payment method onto a student.
   * @returns string Successful Response
   * @throws ApiError
   */
  public static addPaymentMethodStudent({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/add_payment_method_student/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add Payment Method Parent
   * Add a payment method onto a parent.
   * @returns string Successful Response
   * @throws ApiError
   */
  public static addPaymentMethodParent({
    parentId,
  }: {
    parentId: number;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/parent/add_payment_method/{parent_id}",
      path: {
        parent_id: parentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add Payment Method Account
   * Add a payment method onto a student.
   * @returns string Successful Response
   * @throws ApiError
   */
  public static addPaymentMethodAccount(): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/account/add_payment_method",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Add Payment Method Org
   * Add a payment method onto an organization.
   * @returns string Successful Response
   * @throws ApiError
   */
  public static addPaymentMethodOrg({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/organization/add_payment_method",
      query: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Generate Payment Link
   * Generate a payment link for a student or parent to add a payment method.
   * @returns string Successful Response
   * @throws ApiError
   */
  public static generatePaymentLink({
    orgId,
    content,
    requestBody,
  }: {
    orgId: number;
    content: string | null;
    requestBody: PaymentLinkCreate;
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/payments/generate_payment_link/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        content: content,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Cards
   * Get all cards belonging to a student.
   * @returns CreditCardResponse Successful Response
   * @throws ApiError
   */
  public static getStudentCards({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<CreditCardResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/student_cards/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Ach Accounts
   * Get all banks belonging to a student.
   * @returns ACHResponse Successful Response
   * @throws ApiError
   */
  public static getStudentAchAccounts({
    studentId,
  }: {
    studentId: number;
  }): CancelablePromise<Array<ACHResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/student_ach/{student_id}",
      path: {
        student_id: studentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parent Cards
   * Get all cards belonging to a parent.
   * @returns CreditCardResponse Successful Response
   * @throws ApiError
   */
  public static getParentCards({
    parentId,
  }: {
    parentId: number;
  }): CancelablePromise<Array<CreditCardResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/parent_cards/{parent_id}",
      path: {
        parent_id: parentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Parent Ach Accounts
   * Get all banks belonging to a student.
   * @returns ACHResponse Successful Response
   * @throws ApiError
   */
  public static getParentAchAccounts({
    parentId,
  }: {
    parentId: number;
  }): CancelablePromise<Array<ACHResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/parent_ach/{parent_id}",
      path: {
        parent_id: parentId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Account Cards
   * Get all cards belonging to an account.
   * @returns CreditCardResponse Successful Response
   * @throws ApiError
   */
  public static getAccountCards(): CancelablePromise<
    Array<CreditCardResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/account_cards",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Account Ach
   * Get all banks belonging to an account.
   * @returns ACHResponse Successful Response
   * @throws ApiError
   */
  public static getAccountAch(): CancelablePromise<Array<ACHResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/account_ach",
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Org Cards
   * Get all cards belonging to an organization.
   * @returns CreditCardResponse Successful Response
   * @throws ApiError
   */
  public static getOrgCards({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<CreditCardResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/org_cards/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Org Ach Accounts
   * Get all bank accounts belonging to an organization.
   * @returns ACHResponse Successful Response
   * @throws ApiError
   */
  public static getOrgAchAccounts({
    orgId,
  }: {
    orgId: number;
  }): CancelablePromise<Array<ACHResponse>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/org_ach/{org_id}",
      path: {
        org_id: orgId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Revenue
   * Get the revenue generated from a student in a specific org.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getStudentRevenue({
    studentId,
    orgId,
    start,
    until,
    accountingSystem,
  }: {
    studentId: number;
    orgId: number;
    start: string;
    until: string;
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/student_revenue/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
        accounting_system: accountingSystem,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Student Total Cost
   * Get the cost to tutor a student.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getStudentTotalCost({
    studentId,
    orgId,
    accountingSystem,
    start,
    until,
  }: {
    studentId: number;
    orgId: number;
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/student_cost/{student_id}",
      path: {
        student_id: studentId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
        accounting_system: accountingSystem,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Revenue
   * Get the revenue generated by a tutor.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTutorRevenue({
    tutorId,
    orgId,
    accountingSystem,
    start,
    until,
  }: {
    tutorId: number;
    orgId: number;
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/tutor_revenue/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
        accounting_system: accountingSystem,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Tutor Compensation
   * Get the total amount of compensation paid to a tutor.
   *
   * For cash basis accounting: Add automatic Tadpole to Tutor payments.
   *
   * For accrual basis accounting: Get meetings by tutor and add the amount
   * the tutor is paid for the meeting.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getTutorCompensation({
    tutorId,
    orgId,
    accountingSystem,
    start,
    until,
  }: {
    tutorId: number;
    orgId: number;
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/tutor_compensation/{tutor_id}",
      path: {
        tutor_id: tutorId,
      },
      query: {
        org_id: orgId,
        start: start,
        until: until,
        accounting_system: accountingSystem,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Org Revenue
   * Get the revenue generated by an org.
   *
   * For cash basis accounting: Add Direct, Upfront, and Automatic Charges and add Rescheduling,
   * Cancellation, and Attendance Fees. Subtract Refunds.
   *
   * For accrual basis accounting: Add Direct Charges and Rescheduling, Cancellation, and Attendance
   * Fees.Subtract Refunds. Get meetings by org and add the amount
   * each student is billed for the meeting.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getOrgRevenue({
    orgId,
    start,
    until,
    accountingSystem,
  }: {
    orgId: number;
    start: string;
    until: string;
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/org_revenue/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
        accounting_system: accountingSystem,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }

  /**
   * Get Org Cost Of Tutoring
   * Get the total cost of tutoring for an org.
   *
   * For cash basis accounting: Add automatic Tadpole to Tutor payments.
   *
   * For accrual basis accounting: Get meetings by org and add the amount
   * each tutor is paid for the meeting.
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getOrgCostOfTutoring({
    orgId,
    accountingSystem,
    start,
    until,
  }: {
    orgId: number;
    accountingSystem: AccountingSystemForCalculatingFinancialStatistics;
    start?: string | null;
    until?: string | null;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/payments/org/cost_of_tutoring/{org_id}",
      path: {
        org_id: orgId,
      },
      query: {
        start: start,
        until: until,
        accounting_system: accountingSystem,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Validation Error`,
        500: `Internal Server Error`,
      },
    });
  }
}
