import { useEffect, useRef, useState } from "react";
import {
  ManageCrmService,
  OrganizationDataKeyResponse,
  OrganizationNameResponse,
  Auth0AccountRole,
  DataType,
  CustomDataTypeKeyResponse,
  ApiError,
} from "client/openapi";

import { PageStatus } from "types";
import Notifications from "util/notifications";
import "../index.css";

import { Select } from "components/Select";
import {
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
} from "components/Dialog";
import { ButtonColor } from "components/Button";
import { DatePicker } from "rsuite";

type NewOrgDataKeyDialogProps = {
  organization: OrganizationNameResponse;
  keys: OrganizationDataKeyResponse[];
  setKeys: (keys: OrganizationDataKeyResponse[]) => void;
  userType: Auth0AccountRole;
};

function NewOrgDataKeyDialog({
  organization,
  keys,
  setKeys,
  userType,
}: NewOrgDataKeyDialogProps) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<ApiError>();
  const [name, setName] = useState<string>("");

  // Data Type
  const [dataType, setDataType] = useState<DataType>(DataType.TEXT);

  // Conditional states for Float
  const [minValueIfFloat, setMinValueIfFloat] = useState<number | null>(null);
  const [maxValueIfFloat, setMaxValueIfFloat] = useState<number | null>(null);

  // Conditional states for Date
  const [minValueIfDate, setMinValueIfDate] = useState<string | null>(null);
  const [maxValueIfDate, setMaxValueIfDate] = useState<string | null>(null);

  // Custom data field
  const [customDataTypeKeyId, setCustomDataTypeKeyId] = useState<number | null>(
    null
  );

  // Default value states
  const [defaultContentText, setDefaultContentText] = useState<string>("");
  const [defaultContentFloat, setDefaultContentFloat] = useState<number | null>(
    null
  );
  const [defaultContentDate, setDefaultContentDate] = useState<string | null>(
    null
  );
  const [defaultContentBool, setDefaultContentBool] = useState<boolean | null>(
    null
  );
  const [defaultCustomDataTypeValueId, setDefaultCustomDataTypeValueId] =
    useState<number | null>(null);

  // For listing existing custom data fields
  const [customKeys, setCustomKeys] = useState<CustomDataTypeKeyResponse[]>([]);

  const dialogRef = useRef<HTMLDivElement>(null);

  /** Example: fetch existing custom data fields (if that's how your API is structured).
   *  If you have a separate endpoint for custom data fields, call that here.
   */
  useEffect(() => {
    async function fetchCustomKeys() {
      const response = await ManageCrmService.getCustomDataTypes({
        orgId: organization.id,
      });

      setCustomKeys(response);
    }

    if (dataType === DataType.CUSTOM_DATA_TYPE) {
      fetchCustomKeys();
    }
  }, [dataType, organization.id, userType]);

  const clearFormValues = () => {
    setName("");
    setDataType(DataType.TEXT);
    setMinValueIfFloat(null);
    setMaxValueIfFloat(null);
    setMinValueIfDate(null);
    setMaxValueIfDate(null);
    setCustomDataTypeKeyId(null);
    // Clear default values
    setDefaultContentText("");
    setDefaultContentFloat(null);
    setDefaultContentDate(null);
    setDefaultContentBool(null);
    setDefaultCustomDataTypeValueId(null);
  };

  // Render field for default value based on data type
  function renderField() {
    switch (dataType) {
      case DataType.TEXT: {
        return (
          <input
            type="text"
            className="input mt-2"
            placeholder="Default text value"
            value={defaultContentText}
            maxLength={300}
            onChange={(e) => setDefaultContentText(e.target.value)}
          />
        );
      }

      case DataType.FLOAT: {
        return (
          <input
            type="number"
            step="any"
            className="input mt-2"
            placeholder="Default number value"
            value={defaultContentFloat ?? ""}
            min={minValueIfFloat ?? undefined}
            max={maxValueIfFloat ?? undefined}
            onChange={(e) => {
              const val = e.target.value ? parseFloat(e.target.value) : null;
              if (val === null || !isNaN(val)) {
                setDefaultContentFloat(val);
              }
            }}
          />
        );
      }

      case DataType.BOOLEAN: {
        return (
          <Select
            className="mt-2"
            value={
              defaultContentBool === true
                ? { value: "true", label: "Yes" }
                : defaultContentBool === false
                ? { value: "false", label: "No" }
                : null
            }
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
            placeholder="--Select default value--"
            onChange={(option) => {
              if (!option) {
                setDefaultContentBool(null);
              } else {
                setDefaultContentBool(option.value === "true");
              }
            }}
            isClearable
          />
        );
      }

      case DataType.DATE: {
        return (
          <DatePicker
            oneTap
            className="input mt-2"
            value={defaultContentDate ? new Date(defaultContentDate) : null}
            size="sm"
            format="yyyy-MM-dd"
            placement="bottomStart"
            container={() => dialogRef.current || document.body}
            onChange={(val) => {
              if (!val) {
                setDefaultContentDate(null);
                return;
              }
              const localStart = new Date(val);
              localStart.setHours(0, 0, 0, 0);
              setDefaultContentDate(localStart.toISOString());
            }}
            cleanable
            block
          />
        );
      }

      case DataType.CUSTOM_DATA_TYPE: {
        if (!customDataTypeKeyId) return null;

        const selectedCustomKey = customKeys.find(
          (ck) => ck.id === customDataTypeKeyId
        );
        if (!selectedCustomKey?.values) return null;

        const customOptions = selectedCustomKey.values.map((opt) => ({
          value: opt.id.toString(),
          label: opt.option_name,
        }));

        return (
          <Select
            className="mt-2"
            value={
              defaultCustomDataTypeValueId
                ? {
                    value: defaultCustomDataTypeValueId.toString(),
                    label:
                      selectedCustomKey.values.find(
                        (v) => v.id === defaultCustomDataTypeValueId
                      )?.option_name || "",
                  }
                : null
            }
            options={customOptions}
            placeholder="--Select default value--"
            onChange={(option) => {
              setDefaultCustomDataTypeValueId(
                option ? parseInt(option.value, 10) : null
              );
            }}
            isClearable
          />
        );
      }

      default:
        return null;
    }
  }

  async function createKey() {
    if (!name.trim()) {
      Notifications.error("Please enter a name for the field");
      return;
    }
    if (dataType === DataType.CUSTOM_DATA_TYPE && !customDataTypeKeyId) {
      Notifications.error("Please select a custom data field");
      return;
    }

    setStatus(PageStatus.LOADING);

    ManageCrmService.createOrgDataKey({
      requestBody: {
        org_id: organization.id,
        name: name,
        user_type: userType,
        data_type: dataType,
        min_value_if_float:
          dataType === DataType.FLOAT ? minValueIfFloat : null,
        max_value_if_float:
          dataType === DataType.FLOAT ? maxValueIfFloat : null,
        min_value_if_date: dataType === DataType.DATE ? minValueIfDate : null,
        max_value_if_date: dataType === DataType.DATE ? maxValueIfDate : null,
        custom_data_type_key_id:
          dataType === DataType.CUSTOM_DATA_TYPE ? customDataTypeKeyId : null,
        // Add default values
        default_content_text:
          dataType === DataType.TEXT ? defaultContentText : null,
        default_content_float:
          dataType === DataType.FLOAT ? defaultContentFloat : null,
        default_content_date:
          dataType === DataType.DATE ? defaultContentDate : null,
        default_content_bool:
          dataType === DataType.BOOLEAN ? defaultContentBool : null,
        default_custom_data_type_value_id:
          dataType === DataType.CUSTOM_DATA_TYPE
            ? defaultCustomDataTypeValueId
            : null,
      },
    })
      .then((res) => {
        setKeys([...keys, res]);
        setStatus(PageStatus.SUCCESS);
        Notifications.success(`Field created!`);
        clearFormValues();
      })
      .catch((e) => {
        setStatus(PageStatus.ERROR);
        setError(e);
      });
  }

  useEffect(() => {
    if (error) {
      Notifications.error(
        error?.body.detail || "An unexpected error occurred."
      );
    }
  }, [error]);

  const dataTypeOptions = [
    { value: DataType.TEXT, label: "Text" },
    { value: DataType.FLOAT, label: "Number" },
    { value: DataType.DATE, label: "Date" },
    { value: DataType.BOOLEAN, label: "True/False" },
    { value: DataType.CUSTOM_DATA_TYPE, label: "Custom" },
  ];

  const customFieldOptions = customKeys.map((ck) => ({
    value: ck.id.toString(),
    label: ck.name,
  }));

  // Add validation for the submit button
  const isSubmitDisabled =
    status === PageStatus.LOADING ||
    !name.trim() ||
    (dataType === DataType.CUSTOM_DATA_TYPE && !customDataTypeKeyId);

  return (
    <>
      <DialogContent
        ref={dialogRef}
        className="org-data-key-dialog flex flex-col"
        onClose={clearFormValues}
      >
        <div className="new-org-data-key--dialog-header">
          <h2 className="new-org-data-key--title">New Field</h2>
        </div>

        <form className="flex flex-col flex-grow">
          {/* Name */}
          <label htmlFor="name">
            <strong>Name</strong>
          </label>
          <input
            id="name"
            name="name"
            type="text"
            className="input"
            placeholder="(e.g. School, Grade, etc.)"
            value={name || ""}
            maxLength={50}
            onChange={(e) => setName(e.target.value)}
          />

          <div className="my-3">
            <label htmlFor="type">
              <strong>Data Type</strong>
            </label>
            <Select
              id="type"
              className="mt-2"
              value={
                dataTypeOptions.find((opt) => opt.value === dataType) || null
              }
              onChange={(option) => {
                if (option) {
                  setDataType(option.value as DataType);
                }
              }}
              options={dataTypeOptions}
              placeholder="-- Select Data Type --"
              isClearable={false}
            />
          </div>

          <div>
            <hr className="border-t border-gray-200 my-4" />
            <i className="text-sm text-gray-500">Optional</i>

            {/* If FLOAT: Show min/max float fields */}
            {dataType === DataType.FLOAT && (
              <div>
                <div className="flex gap-4">
                  <div className="my-3 flex-1">
                    <label
                      htmlFor="minValueIfFloat"
                      className="text-sm text-gray-500"
                    >
                      <i>Min Value</i>
                    </label>
                    <input
                      type="number"
                      step="any"
                      className="input mt-2"
                      placeholder="200"
                      id="minValueIfFloat"
                      value={minValueIfFloat ?? ""}
                      onChange={(e) =>
                        setMinValueIfFloat(
                          e.target.value ? parseFloat(e.target.value) : null
                        )
                      }
                    />
                  </div>
                  <div className="my-3 flex-1">
                    <label
                      htmlFor="maxValueIfFloat"
                      className="text-sm text-gray-500"
                    >
                      <i>Max Value</i>
                    </label>
                    <input
                      type="number"
                      step="any"
                      className="input mt-2"
                      placeholder="800"
                      id="maxValueIfFloat"
                      value={maxValueIfFloat ?? ""}
                      onChange={(e) =>
                        setMaxValueIfFloat(
                          e.target.value ? parseFloat(e.target.value) : null
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            {/* If DATE: Show min/max date fields */}
            {dataType === DataType.DATE && (
              <div>
                <div className="flex gap-4">
                  <div className="my-3 flex-1">
                    <label
                      htmlFor="minValueIfDate"
                      className="text-sm text-gray-500"
                    >
                      <i>Min Date</i>
                    </label>
                    <DatePicker
                      oneTap
                      className="pt-1"
                      value={minValueIfDate ? new Date(minValueIfDate) : null}
                      size="sm"
                      format="yyyy-MM-dd"
                      placement="bottomStart"
                      container={() => dialogRef.current || document.body}
                      onChange={(val) => {
                        const isoString = val
                          ? (val as Date).toISOString().slice(0, 10)
                          : null;
                        setMinValueIfDate(isoString);
                      }}
                      cleanable={true}
                      block
                    />
                  </div>
                  <div className="my-3 flex-1">
                    <label
                      htmlFor="maxValueIfDate"
                      className="text-sm text-gray-500"
                    >
                      <i>Max Date</i>
                    </label>
                    <DatePicker
                      oneTap
                      className="pt-1"
                      value={maxValueIfDate ? new Date(maxValueIfDate) : null}
                      size="sm"
                      format="yyyy-MM-dd"
                      placement="bottomStart"
                      container={() => dialogRef.current || document.body}
                      onChange={(val) => {
                        const isoString = val
                          ? (val as Date).toISOString().slice(0, 10)
                          : null;
                        setMaxValueIfDate(isoString);
                      }}
                      cleanable={true}
                      block
                    />
                  </div>
                </div>
              </div>
            )}

            {/* If CUSTOM: Show custom data field select & link to create new */}
            {dataType === DataType.CUSTOM_DATA_TYPE && (
              <div className="custom-field-conditions mt-3">
                <Select
                  className="mt-1"
                  value={
                    customDataTypeKeyId
                      ? {
                          value: customDataTypeKeyId.toString(),
                          label:
                            customKeys.find(
                              (ck) => ck.id === customDataTypeKeyId
                            )?.name || "",
                        }
                      : null
                  }
                  onChange={(option) =>
                    setCustomDataTypeKeyId(
                      option ? parseInt(option.value, 10) : null
                    )
                  }
                  options={customFieldOptions}
                  placeholder="-- Select Existing Custom Field --"
                  isClearable
                />
              </div>
            )}

            {/* Default Value Section */}
            <div className="mt-3">
              <label className="text-sm text-gray-500">
                <i>Default Value</i>
              </label>

              {renderField()}
            </div>

            <hr className="border-t border-gray-200 my-4" />
          </div>

          <div className="mt-auto pt-4 border-t">
            <DialogActions>
              <DialogAction
                primary
                color={isSubmitDisabled ? ButtonColor.GRAY : ButtonColor.PURPLE}
                onClick={createKey}
                disabled={isSubmitDisabled}
              >
                Create &rarr;
              </DialogAction>

              <DialogClose asChild>
                <DialogAction
                  color={ButtonColor.PURPLE}
                  disabled={status === PageStatus.LOADING}
                  onClick={clearFormValues}
                >
                  Cancel
                </DialogAction>
              </DialogClose>
            </DialogActions>
          </div>
        </form>
      </DialogContent>
    </>
  );
}

export default NewOrgDataKeyDialog;
