import React, { useState, useEffect } from "react";
import { Select } from "components/Select";
import {
  OrganizationSubjectPublicResponse,
  TutorPublicResponse,
  AvailabilitiesService,
  AvailabilityMessage,
} from "client/openapi";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment-timezone";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { isSameDay } from "date-fns";
import ConfirmBooking from "./ConfirmBooking"; // <-- Renamed import

export default function BookingInterface({
  availableSubjects,
  availableTutors,
  orgId,
}: {
  availableSubjects: OrganizationSubjectPublicResponse[];
  availableTutors: TutorPublicResponse[];
  orgId: number;
}) {
  // ----- 1) Subject selection -----
  const [subject, setSubject] =
    useState<OrganizationSubjectPublicResponse | null>(null);

  // ----- 2) Tutor selection -----
  const [tutor, setTutor] = useState<TutorPublicResponse | null>(null);

  // ----- 3) Date selection -----
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // ----- 4) Time selection -----
  const [selectedTime, setSelectedTime] = useState<string | null>(null);

  // For storing availability: day → array of UTC times (not offset)
  const [monthAvailabilities, setMonthAvailabilities] = useState<{
    [isoDate: string]: AvailabilityMessage[];
  }>({});

  // For storing the times available on a particular day
  const [dayTimeSlots, setDayTimeSlots] = useState<AvailabilityMessage[]>([]);

  // Keep track of which month is currently displayed in the DayPicker
  const [displayMonth, setDisplayMonth] = useState<Date>(new Date());

  // The user’s local timezone
  const localTz = moment.tz.guess();

  // user-selected timezone (defaults to local)
  const [selectedTimezone, setSelectedTimezone] = useState<string>(localTz);

  // 5) For mobile, track which “step” we are on (1..4)
  const [currentStep, setCurrentStep] = useState<number>(1);

  // If true, we show the final step (ConfirmBooking layout)
  const [showFinalStep, setShowFinalStep] = useState<boolean>(false);

  // Filter tutors based on selected subject
  const filteredTutors = subject
    ? availableTutors.filter((t) =>
        t.subjects.some((s) => s.org_subject.id === subject.id)
      )
    : [];

  /*
   * Fetch monthly availability whenever the user picks a tutor or changes
   * the visible month in the calendar.
   */
  useEffect(() => {
    if (!tutor) return;

    async function fetchMonthAvailability() {
      try {
        const startOfMonth = moment(displayMonth)
          .startOf("month")
          .utc()
          .toISOString();
        const endOfMonth = moment(displayMonth)
          .endOf("month")
          .utc()
          .toISOString();

        const results =
          await AvailabilitiesService.getAvailabilitiesByTutorForPublicBookingPage(
            {
              tutorId: tutor?.id as number,
              start: startOfMonth,
              until: endOfMonth,
              duration: 60,
            }
          );

        const mapByDay: { [dateString: string]: AvailabilityMessage[] } = {};

        results.forEach((avail: AvailabilityMessage) => {
          const local = moment.utc(avail.start).tz(selectedTimezone);
          const dayStr = local.format("YYYY-MM-DD");

          if (!mapByDay[dayStr]) {
            mapByDay[dayStr] = [];
          }
          mapByDay[dayStr].push({
            ...avail,
            start: avail.start, // keep server’s UTC
          });
        });

        setMonthAvailabilities(mapByDay);
      } catch (err) {
        console.error("Error fetching monthly availability", err);
      }
    }

    fetchMonthAvailability();
  }, [tutor, displayMonth, selectedTimezone]);

  /*
   * When a user picks a day, load the day’s times from `monthAvailabilities`.
   */
  function handleSelectDay(day: Date | undefined) {
    if (!day || !tutor) return;

    setSelectedDate(day);
    setSelectedTime(null);
    const dayStr = moment(day).format("YYYY-MM-DD");
    const times = monthAvailabilities[dayStr] || [];
    setDayTimeSlots(times);
    setCurrentStep(4);
  }

  /**
   * True if a given calendar day has at least one availability.
   */
  function hasAvailability(day: Date): boolean {
    if (!tutor) return false;
    const dayStr = moment(day).format("YYYY-MM-DD");
    return (monthAvailabilities[dayStr]?.length ?? 0) > 0;
  }

  /**
   * We want a darker green if a day is both “selected” and “hasAvailability”.
   */
  function isSelectedHasAvailability(day: Date): boolean {
    return (
      selectedDate != null &&
      isSameDay(day, selectedDate) &&
      hasAvailability(day)
    );
  }

  // Once the user picks a time, we show the final step (ConfirmBooking).
  function handleTimeClick(time: string) {
    setSelectedTime(time);
    setShowFinalStep(true);
  }

  // Next/Back step approach (mobile only)
  function goNext() {
    setCurrentStep((prev) => (prev < 4 ? prev + 1 : 4));
  }
  function goBack() {
    // If final step is showing, hide it
    if (showFinalStep) {
      setShowFinalStep(false);
      return;
    }
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : 1));
  }

  //--------------------------------------------------
  // Original 4 columns (desktop) or single-column steps (mobile)
  //--------------------------------------------------

  const renderColumn1 = () => (
    <div className="pr-4">
      <h3 className="font-semibold mb-2">1. Subject</h3>
      <Select
        id="subject"
        options={availableSubjects}
        value={subject || undefined}
        getOptionLabel={(s) => s.name}
        getOptionValue={(s) => s.id.toString()}
        placeholder="Subject..."
        onChange={(val) => {
          setSubject(val as OrganizationSubjectPublicResponse);
          setTutor(null);
          setSelectedDate(null);
          setDayTimeSlots([]);
          setSelectedTime(null);
          setShowFinalStep(false);
          setCurrentStep(2);
        }}
        title="Add subject"
      />
    </div>
  );

  const renderColumn2 = () => (
    <div className={`px-4 ${!subject ? "opacity-50 pointer-events-none" : ""}`}>
      <h3 className="font-semibold mb-2">2. Select Tutor</h3>
      <Select
        id="tutor"
        options={filteredTutors}
        value={tutor || null}
        getOptionLabel={(t) => t.first_name + " " + t.last_name}
        getOptionValue={(t) => t.id.toString()}
        placeholder="Tutor..."
        onChange={(val) => {
          setTutor(val as TutorPublicResponse);
          setSelectedDate(null);
          setDayTimeSlots([]);
          setSelectedTime(null);
          setShowFinalStep(false);
          setCurrentStep(3);
        }}
        title="Select Tutor"
      />
    </div>
  );

  const renderColumn3 = () => (
    <div
      className={`col-span-2 ${!tutor ? "opacity-50 pointer-events-none" : ""}`}
    >
      <h3 className="pl-5 pr-4 font-semibold mb-2">3. Select Date</h3>
      <div className="pl-2 min-w-[310px] max-w-[320px]">
        <DayPicker
          mode="single"
          selected={selectedDate || undefined}
          onSelect={handleSelectDay}
          month={displayMonth}
          onMonthChange={setDisplayMonth}
          modifiers={{
            hasAvailability: (day) => hasAvailability(day),
            selectedHasAvailability: (day) => isSelectedHasAvailability(day),
          }}
          modifiersClassNames={{
            hasAvailability: "bg-green-200 text-green-900 font-semibold",
            selected: "bg-blue-500 text-white font-semibold",
            selectedHasAvailability: "bg-green-400 text-white font-semibold",
          }}
        />
      </div>

      <div className="px-6 mt-4">
        <h4 className="mb-2">Timezone</h4>
        <Select
          id="timezone"
          options={moment.tz.names().map((tzName) => ({
            label: tzName,
            value: tzName,
          }))}
          value={{ label: selectedTimezone, value: selectedTimezone }}
          onChange={(option) => {
            if (!option) return;
            setSelectedTimezone(option.value);
          }}
          placeholder="Timezone..."
          title="Select Timezone"
        />
      </div>
    </div>
  );

  const renderColumn4 = () => (
    <div
      className={`pl-4 ml-2 ${
        !selectedDate ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      <h3 className="font-semibold mb-2">4. Select Time</h3>

      {dayTimeSlots.length === 0 ? (
        <p className="text-sm text-gray-500">
          {selectedDate
            ? "No times available for this day."
            : "Select a day first."}
        </p>
      ) : (
        <div className="flex flex-col gap-2 max-h-80 overflow-y-auto pr-2">
          {dayTimeSlots.map((slot, i) => {
            const startLocal = moment.utc(slot.start).tz(selectedTimezone);
            const displayLabel = startLocal.format("h:mm A");

            return (
              <Button
                key={i}
                onClick={() => handleTimeClick(slot.start)}
                size={ButtonSize.SMALL}
                color={ButtonColor.GREEN}
                fill={
                  selectedTime === slot.start
                    ? ButtonFill.DEFAULT
                    : ButtonFill.HOLLOW
                }
              >
                {displayLabel}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );

  // Original 4-column “table” (desktop) or single-column steps (mobile)
  const columnsView = (
    <>
      <div className="hidden md:grid grid-cols-5 divide-x divide-gray-200">
        {renderColumn1()}
        {renderColumn2()}
        {renderColumn3()}
        {renderColumn4()}
      </div>
      <div className="md:hidden">
        <div className="mb-4 text-sm text-gray-500">
          Step {currentStep} of 4
        </div>
        <div>
          {currentStep === 1 && renderColumn1()}
          {currentStep === 2 && renderColumn2()}
          {currentStep === 3 && renderColumn3()}
          {currentStep === 4 && renderColumn4()}
        </div>

        {/* (1) Keep the back button in the bottom left on mobile */}
        <div className="flex justify-between mt-4">
          {currentStep > 1 ? (
            <Button color={ButtonColor.GRAY} onClick={goBack}>
              Back
            </Button>
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  );

  //--------------------------------------------------
  // FINAL STEP: 2 columns side by side (desktop),
  // or stacked (mobile) with a grey line between them,
  // bigger heading on left col, "Meeting Details",
  // and the top of right col is "Final Steps to Confirm Meeting"
  //--------------------------------------------------
  const finalStepView = (
    <div
      className="grid md:grid-cols-5 divide-x divide-gray-200 min-h-[450px]" // (2) Keep min height
    >
      {/* Left col: col-span-2 */}
      <div className="md:col-span-2 p-4 bg-white flex flex-col">
        <div className="flex-grow">
          {/* (4) Bigger heading for "Meeting Details" */}
          <h2 className="text-xl font-semibold mb-3">Meeting Details</h2>

          {/* Vertical numbered list */}
          <h3 className="font-semibold mb-2">1. Subject: {subject?.name}</h3>
          <h3 className="font-semibold mb-2">
            2. Tutor: {tutor ? tutor.first_name + " " + tutor.last_name : ""}
          </h3>
          <h3 className="font-semibold mb-2">
            3. Date:{" "}
            {selectedDate
              ? moment(selectedDate).format("ddd MMM DD, YYYY") // (6) day of week
              : ""}
          </h3>
          <h3 className="font-semibold mb-2">
            4. Time:{" "}
            {selectedTime
              ? moment.utc(selectedTime).tz(selectedTimezone).format("h:mm A")
              : ""}
          </h3>
        </div>

        {/* (1) For mobile final step, place the back button at bottom left */}
        <div className="mt-4">
          <Button color={ButtonColor.GRAY} onClick={goBack}>
            Back
          </Button>
        </div>
      </div>

      {/* Right col: col-span-3 */}
      <div className="md:col-span-3 p-4 bg-white flex flex-col">
        {/* (5) Heading: "Final Steps to Confirm Meeting" */}
        <h2 className="text-xl font-semibold mb-3">
          Final Steps to Confirm Meeting
        </h2>

        {/* The rest is the ConfirmBooking component */}
        <div className="flex-grow">
          {selectedTime && tutor && subject && (
            <ConfirmBooking
              orgId={orgId}
              selectedStartTime={selectedTime}
              selectedTutor={tutor}
              selectedSubject={subject}
              setShowFinalStep={setShowFinalStep}
              setCurrentStep={setCurrentStep}
            />
          )}
        </div>
      </div>
    </div>
  );

  return (
    // (2) Ensure consistent min height at all times
    <div className="max-w-4xl mx-auto p-4 bg-white rounded-md shadow min-h-[450px]">
      {showFinalStep ? finalStepView : columnsView}
    </div>
  );
}
