import {
  AccountResponse,
  ApiError,
  Auth0AccountRole,
  InviteResponse,
  InviteStatus,
  InvitesService,
  OrganizationMeetingLocationResponse,
  OrganizationResponseForAdmins,
  OrganizationSubjectAdminResponse,
  OrganizationsService,
  ParentAdminResponse,
  StudentAdminResponse,
  TutorAdminResponse,
  USState,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill } from "components/Button";
import ManageOrgSubjectsDialog from "components/Dashboard/ManageOrgSubjectsDialog";
import { Statistic } from "components/Dashboard/Statistics";
import StudentsList from "components/Dashboard/StudentsList";
import TutorsList from "components/Dashboard/TutorsList";
import UpcomingMeetings from "components/Dashboard/UpcomingMeetings";
import { Dialog, DialogTrigger } from "components/Dialog";
import { LoadingBlock } from "components/StatusBlock";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PageStatus } from "types";
import pluralize from "util/pluralize";
import "../TutorDashboard/index.css";
import FilterCalendarDialog from "../FilterCalendarDialog";
import MeetingDialog from "components/MeetingDialog";
import "./index.css";
import { useAuth0 } from "@auth0/auth0-react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import ParentsList from "../ParentsList";
import Invites from "../InvitesModal/Invites";
import ManageTasksDialog from "../ManageTasksDialog";
import Notifications from "util/notifications";

export default function OrganizationDashboard() {
  const { isLoading } = useAuth0();

  const { currently_selected_organization, account } = useContext(
    OrgRolesAndAccountContext
  );

  // set up state
  const [status, setStatus] = useState<PageStatus>(PageStatus.LOADING);
  const [organization, setOrganization] =
    useState<OrganizationResponseForAdmins | null>();

  const [subjects, setSubjects] = useState<OrganizationSubjectAdminResponse[]>(
    []
  );

  const [students, setStudents] = useState<StudentAdminResponse[]>([]);
  const [parents, setParents] = useState<ParentAdminResponse[]>([]);
  const [tutors, setTutors] = useState<TutorAdminResponse[]>([]);
  const [admins, setAdmins] = useState<AccountResponse[]>([]);

  const [locations, setLocations] = useState<
    OrganizationMeetingLocationResponse[]
  >([]);

  const [subjectFilters, setSubjectFilters] = useState<number[]>();
  const [tutorFilters, setTutorFilters] = useState<number[]>();
  const [studentFilters, setStudentFilters] = useState<number[]>();
  const [locationFilters, setLocationFilters] = useState<number[]>();
  const [locationAddressFilters, setLocationAddressFilters] =
    useState<string[]>();
  const [locationCityFilters, setLocationCityFilters] = useState<string[]>();
  const [locationStateFilters, setLocationStateFilters] = useState<USState[]>();

  const [invites, setInvites] = useState<InviteResponse[]>();

  const [error, setError] = useState<ApiError>();

  const [stats, setStats] = useState<Statistic[]>([]);
  const [addedStart, setAddedStart] = useState<Date>();

  //fetch the current account's tutor
  const fetchOrganization = () => {
    setStatus(PageStatus.LOADING);

    OrganizationsService.getOrganization({
      orgId: currently_selected_organization as number,
    })
      .then((organizationResult) => {
        const orgAdminResponse =
          organizationResult as OrganizationResponseForAdmins;
        setStatus(PageStatus.SUCCESS);
        setOrganization(orgAdminResponse);
        setSubjects(
          orgAdminResponse.subjects as OrganizationSubjectAdminResponse[]
        );
        setStudents(orgAdminResponse.students as StudentAdminResponse[]);
        setParents(orgAdminResponse.parents as ParentAdminResponse[]);
        setTutors(orgAdminResponse.tutors as TutorAdminResponse[]);
        setAdmins(orgAdminResponse.admins as AccountResponse[]);
        setLocations(
          orgAdminResponse.locations as OrganizationMeetingLocationResponse[]
        );
        setStats([
          {
            label: pluralize("Tutor", "Tutors", orgAdminResponse.tutors.length),
            value: orgAdminResponse.tutors.length,
          },
          {
            label: pluralize(
              "Student",
              "Students",
              orgAdminResponse.students.length
            ),
            value: orgAdminResponse.students.length,
          },
          {
            label: pluralize(
              "Meeting this week",
              "Meetings this week",
              orgAdminResponse.num_sessions_week as number
            ),
            value: orgAdminResponse.num_sessions_week as number,
          },
          {
            label: pluralize(
              "Meeting this month",
              "Meetings this month",
              orgAdminResponse.num_sessions_month as number
            ),
            value: orgAdminResponse.num_sessions_month as number,
          },
        ]);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError(e);
      });
  };

  const getOrgInvites = async () => {
    InvitesService.getInvitesByAccountAndStatus({
      status: InviteStatus.PENDING,
    })
      .then((invites) => {
        setInvites(invites);
      })
      .catch((err: ApiError) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (!isLoading && currently_selected_organization) {
      fetchOrganization();
    }
  }, [currently_selected_organization, isLoading]);

  useEffect(() => {
    if (account) {
      getOrgInvites();
    }
  }, [account]);

  useEffect(() => {
    if (error) {
      console.log({ error });
      Notifications.error(error.message);
    }
  }, [error]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      <LoadingBlock status={status} />

      {status === PageStatus.SUCCESS && organization && (
        <>
          {invites && invites.length > 0 && (
            <div className="container ml-8 mb-4 w-8/12">
              <div className="mt-4 invite_block">
                <div className="tutor__profile_block-text">
                  <span className="primsecond-text">
                    You have been invited to an organization!
                  </span>
                  <p className="mt-1 secondary-text">
                    View invites and accept them if you'd like to tutor.
                  </p>
                </div>

                <Dialog>
                  <DialogTrigger asChild>
                    <button className="button button--green">
                      View Invites &rarr;
                    </button>
                  </DialogTrigger>
                  <Invites invites={invites} setInvites={setInvites} />
                </Dialog>
              </div>
            </div>
          )}

          <div className="m-4">
            <UpcomingMeetings
              studentFilters={studentFilters}
              tutorFilters={tutorFilters}
              subjectFilters={subjectFilters}
              locationFilters={locationFilters}
              locationAddressFilters={locationAddressFilters}
              locationCityFilters={locationCityFilters}
              locationStateFilters={locationStateFilters}
              addedStart={addedStart}
            >
              <div className="list--actions">
                <Dialog>
                  <DialogTrigger asChild>
                    <Button color={ButtonColor.GREEN}>Book Meeting</Button>
                  </DialogTrigger>

                  <MeetingDialog
                    isCreate={true}
                    role={Auth0AccountRole.ORG_ADMIN}
                    setEvents={(e?: string) =>
                      Promise.resolve(setAddedStart(moment(e).toDate()))
                    }
                  />
                </Dialog>

                {/* <Button
                  color={ButtonColor.GREEN}
                  fill={ButtonFill.HOLLOW}
                  disabled
                  title="Coming soon!"
                >
                  Log Activity
                </Button> */}

                <Dialog>
                  <DialogTrigger asChild>
                    <Button color={ButtonColor.GREEN} fill={ButtonFill.HOLLOW}>
                      Filter Calendar
                    </Button>
                  </DialogTrigger>

                  <FilterCalendarDialog
                    subjects={subjects}
                    students={students}
                    tutors={tutors}
                    locations={locations}
                    submitFilters={(
                      tutors,
                      students,
                      subjects,
                      locations,
                      locationAddresses,
                      locationCities,
                      locationStates
                    ) => {
                      setTutorFilters(tutors?.map((t) => t.id));
                      setStudentFilters(students?.map((s) => s.id));
                      setSubjectFilters(subjects?.map((s) => s.id));
                      setLocationFilters(locations?.map((l) => l.id));
                      setLocationAddressFilters(
                        locationAddresses
                          ?.map((l) => l.address_line_1)
                          .filter((addr): addr is string => !!addr)
                      );
                      setLocationCityFilters(
                        locationCities
                          ?.map((l) => l.city)
                          .filter((city): city is string => !!city)
                      );
                      setLocationStateFilters(
                        locationStates
                          ?.map((l) => l.state)
                          .filter((state): state is USState => !!state)
                      );
                    }}
                  />
                </Dialog>

                <Dialog>
                  <DialogTrigger asChild>
                    <Button color={ButtonColor.GREEN} fill={ButtonFill.HOLLOW}>
                      Manage Tasks
                    </Button>
                  </DialogTrigger>

                  <ManageTasksDialog />
                </Dialog>

                <Dialog>
                  <DialogTrigger asChild>
                    <Button color={ButtonColor.GREEN} fill={ButtonFill.HOLLOW}>
                      Manage Subjects
                    </Button>
                  </DialogTrigger>

                  <ManageOrgSubjectsDialog
                    orgId={organization.id}
                    subjects={subjects}
                    setSubjects={setSubjects}
                  />
                </Dialog>
              </div>
            </UpcomingMeetings>
          </div>

          <div className="m-4 mt-8">
            <TutorsList
              organization={organization}
              role={Auth0AccountRole.ORG_ADMIN}
            />
          </div>

          <div className="m-4 mt-8">
            <StudentsList
              role={Auth0AccountRole.ORG_ADMIN}
              organizationIfOnOrgDashboard={organization}
              org_name={organization.name}
            />
          </div>

          <div className="m-4 mt-8">
            <ParentsList
              organization={organization}
              role={Auth0AccountRole.ORG_ADMIN}
            />
          </div>
        </>
      )}
    </>
  );
}
