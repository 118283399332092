import {
  Condition,
  ConditionGroup,
  ComparisonOperator,
  OrganizationDataKeyResponse,
  DataType,
} from "client/openapi";

/** Validates a condition to ensure it has valid values */
export function validateCondition(
  condition: Condition,
  availableKeys: OrganizationDataKeyResponse[]
): boolean {
  if (!condition.org_data_key_id) return false;

  const key = availableKeys.find((k) => k.id === condition.org_data_key_id);
  if (!key) return false;

  // For list operators, we need to check if the array is empty
  if (
    [ComparisonOperator.IN, ComparisonOperator.NOT_IN].includes(
      condition.comparison_operator
    )
  ) {
    if (Array.isArray(condition.value)) {
      return condition.value.length > 0;
    }
    return false;
  }

  // For non-list operators, check based on data type
  switch (key.data_type) {
    case DataType.TEXT:
      return (
        condition.value !== null &&
        condition.value !== undefined &&
        condition.value !== ""
      );
    case DataType.FLOAT:
      return (
        condition.value !== null &&
        condition.value !== undefined &&
        !isNaN(Number(condition.value))
      );
    case DataType.DATE:
      return (
        condition.value !== null &&
        condition.value !== undefined &&
        condition.value !== ""
      );
    case DataType.BOOLEAN:
      return (
        condition.value !== null &&
        condition.value !== undefined &&
        condition.value !== ""
      );
    case DataType.CUSTOM_DATA_TYPE:
      return (
        condition.value !== null &&
        condition.value !== undefined &&
        condition.value !== ""
      );
    default:
      return true; // TEXT and other types allow empty strings
  }
}

/** Validates a condition group recursively */
export function validateConditionGroup(
  group: ConditionGroup,
  availableKeys: OrganizationDataKeyResponse[]
): boolean {
  return group.conditions.every((condition) => {
    if ("conditions" in condition) {
      // This is a nested group
      return validateConditionGroup(condition as ConditionGroup, availableKeys);
    } else {
      // This is a single condition
      return validateCondition(condition, availableKeys);
    }
  });
}

/** Checks if a query is valid for a single entity type */
export function isSingleEntityQueryValid(
  group: ConditionGroup,
  availableKeys: OrganizationDataKeyResponse[]
): boolean {
  return validateConditionGroup(group, availableKeys);
}

/** Checks if a query is valid for multiple entity types */
export function isMultiEntityQueryValid(
  groupA: ConditionGroup,
  groupB: ConditionGroup,
  keysA: OrganizationDataKeyResponse[],
  keysB: OrganizationDataKeyResponse[]
): boolean {
  const isGroupAValid = validateConditionGroup(groupA, keysA);
  if (!isGroupAValid) return false;
  return validateConditionGroup(groupB, keysB);
}
