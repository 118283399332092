import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import {
  MeetingTutorLimitedResponse,
  MeetingTutorAdminResponse,
  Auth0AccountRole,
  TutorsService,
  StudentsService,
  TutorLimitedResponse,
  ApiError,
  MeetingAdminResponse,
  MeetingLimitedResponse,
  TutorAdminResponse,
  MeetingStudentLimitedResponse,
  MeetingStudentAdminResponse,
  StudentAdminResponse,
  StudentLimitedResponse,
  MeetingsService,
  OrganizationSubjectAdminResponse,
  OrganizationSubjectPublicResponse,
  AvailabilitiesService,
  AvailableTutorsMessage,
} from "client/openapi";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import ComposeMessageDialog from "components/Dashboard/ComposeMessageDialog";
import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Select, SelectWithAvailability } from "components/Select";
import { concatenateName } from "util/concatenateName";
import { useAuth0 } from "@auth0/auth0-react";
import { Cross2Icon } from "@radix-ui/react-icons";
import CheckboxInput from "components/Inputs/CheckboxInput";
import MeetingAttendee from "components/MeetingAttendee";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import { TutorContext } from "components/Dashboard/TutorDashboard/tutorContext";
import { TagColor, TagSize } from "components/Tag";
import { Tag } from "components/Tag/TagChip";
import { MeetingDetails } from "components/MeetingDialog";
import moment from "moment";

export const getAttendeeId = (attendee: any): number => {
  return "student" in attendee
    ? attendee.student.id
    : "tutor" in attendee
    ? attendee.tutor.id
    : attendee.id;
};

export function AttendeesList({
  displayAttendees = true,
  attendees,
  typeOfAttendees,
  editing,
  creating,
  event,
  subject,
  linkPrefix,
  currentMeetingInfo,
  remove,
  updateAttendees,
  append,
}: {
  displayAttendees?: boolean;
  attendees: (
    | MeetingTutorLimitedResponse
    | MeetingStudentLimitedResponse
    | MeetingTutorAdminResponse
    | MeetingStudentAdminResponse
    | StudentLimitedResponse
    | StudentAdminResponse
    | TutorLimitedResponse
    | TutorAdminResponse
  )[];
  typeOfAttendees: Auth0AccountRole;
  editing: boolean;
  creating: boolean;
  event?: MeetingAdminResponse | MeetingLimitedResponse;
  subject?:
    | OrganizationSubjectPublicResponse
    | OrganizationSubjectAdminResponse;
  linkPrefix: string;
  currentMeetingInfo: MeetingDetails;
  remove: (userType: Auth0AccountRole, id: number) => void;
  updateAttendees: (
    userType: Auth0AccountRole,
    attendees: (
      | MeetingTutorLimitedResponse
      | MeetingStudentLimitedResponse
      | MeetingTutorAdminResponse
      | MeetingStudentAdminResponse
      | StudentLimitedResponse
      | StudentAdminResponse
      | TutorLimitedResponse
      | TutorAdminResponse
    )[]
  ) => void;
  append?: ReactNode[];
}) {
  const { user } = useAuth0();
  const { currently_selected_role, currently_selected_organization } =
    useContext(OrgRolesAndAccountContext);

  const { current_tutor, available_tutors } = useContext(TutorContext);
  const [tutorsWithAvailability, setTutorsWithAvailability] =
    useState<AvailableTutorsMessage>();
  const [possibleAttendees, setPossibleAttendees] = useState<
    | TutorLimitedResponse[]
    | StudentLimitedResponse[]
    | TutorAdminResponse[]
    | StudentAdminResponse[]
  >([]);
  const [newAttendees, setNewAttendees] = useState(attendees);
  const [tutorCorrespondingToThisMeeting, setTutorCorrespondingToThisMeeting] =
    useState<TutorLimitedResponse | TutorAdminResponse>();

  const [allTutorsForSubject, setAllTutorsForSubject] = useState<number[]>([]);

  useEffect(() => {
    if (available_tutors && current_tutor) {
      setTutorCorrespondingToThisMeeting(
        event
          ? available_tutors.find(
              (tutor) => tutor.org_id === currently_selected_organization
            )
          : current_tutor
      );
    }
  }, [available_tutors, current_tutor, event]);

  const handleAttendeesUpdate = useCallback(
    (options) => {
      const a = [...newAttendees, options];
      setNewAttendees(a);
      updateAttendees(typeOfAttendees, a);
    },
    [newAttendees, updateAttendees, typeOfAttendees]
  );

  const handleAttendeeRemove = (id) => {
    let castedAttendees;
    if (typeOfAttendees === Auth0AccountRole.ME) {
      castedAttendees = newAttendees as
        | MeetingStudentLimitedResponse[]
        | MeetingStudentAdminResponse[];
    } else {
      castedAttendees = newAttendees as
        | MeetingTutorLimitedResponse[]
        | MeetingTutorAdminResponse[];
    }

    setNewAttendees(
      castedAttendees.filter((a) => {
        return getAttendeeId(a) !== id;
      })
    );

    remove(typeOfAttendees, id);
  };

  async function getUsers() {
    if (!user || !currently_selected_role) {
      return;
    }

    const newAttendeeIds = newAttendees.map((a) => getAttendeeId(a));
    const filterAvailableAttendees = (attendees: any[]) =>
      attendees.filter((a) => !newAttendeeIds.includes(a.id));

    try {
      if (typeOfAttendees === Auth0AccountRole.ORG_TUTOR) {
        if (subject) {
          const allTutorsForSubject = await TutorsService.getTutorsBySubject({
            subjectId: subject.id,
          });

          setPossibleAttendees(filterAvailableAttendees(allTutorsForSubject));
        }
        return;
      }

      if (typeOfAttendees === Auth0AccountRole.ME) {
        const roleToServiceMap = {
          [Auth0AccountRole.ORG_ADMIN]:
            StudentsService.getStudentsByOrganizationIfAdmin,
          [Auth0AccountRole.ORG_TUTOR]:
            StudentsService.getStudentsByOrganizationIfTutor,
          [Auth0AccountRole.PARENT]:
            StudentsService.getStudentsByOrganizationIfParent,
          [Auth0AccountRole.ME]:
            StudentsService.getStudentsByOrganizationIfStudent,
        };

        const getStudentsService = roleToServiceMap[currently_selected_role];

        if (getStudentsService) {
          const allStudents = await getStudentsService({
            orgId: currently_selected_organization,
          });
          setPossibleAttendees(filterAvailableAttendees(allStudents));
        }
      }
    } catch (err) {
      console.error("Failed to fetch users:", err);
    }
  }

  const removeSelfIfTutor = (attendees: any[]) => {
    if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
      return attendees;
    }

    if (currently_selected_role === Auth0AccountRole.ORG_TUTOR) {
      return attendees.filter(
        (attendee) =>
          getAttendeeId(attendee) !== tutorCorrespondingToThisMeeting?.id
      );
    }
  };

  useEffect(() => {
    if (
      currently_selected_organization &&
      typeOfAttendees === Auth0AccountRole.ORG_TUTOR
    ) {
      AvailabilitiesService.getTutorsByAvailability({
        orgId: currently_selected_organization,
        start: moment(currentMeetingInfo.start)
          .startOf("day")
          .utc()
          .toISOString(),
        until: moment(currentMeetingInfo.start)
          .add(1, "day")
          .startOf("day")
          .utc()
          .toISOString(),
        exactStart: moment(currentMeetingInfo.start).utc().toISOString(),
        duration: currentMeetingInfo.duration,
      }).then((availabilities) => {
        setTutorsWithAvailability(availabilities);
      });
    }
  }, [
    currentMeetingInfo.duration,
    currentMeetingInfo.start,
    currently_selected_organization,
    typeOfAttendees,
  ]);

  useEffect(() => {
    if (event || currently_selected_organization) {
      getUsers();
    }
  }, [attendees, currently_selected_organization, event, subject]);

  // If you are on the tutor dashboard or student dashboard, the tutor object or student object
  // asosciated with your account and currently selected organization is auto added when
  // creating a new Meeting.
  useEffect(() => {
    if (creating) {
      if (
        current_tutor &&
        currently_selected_role === Auth0AccountRole.ORG_TUTOR &&
        typeOfAttendees === Auth0AccountRole.ORG_TUTOR
      ) {
        updateAttendees(typeOfAttendees, [...newAttendees, current_tutor]);
        setNewAttendees([...newAttendees, current_tutor]);
      }

      // if (
      //   current_student &&
      //   currently_selected_role === Auth0AccountRole.ME &&
      //   userType === Auth0AccountRole.ME
      // ) {
      //   updateAttendees(userType, [current_student]);
      //   setNewAttendees([current_student]);
      // }
    }
  }, [current_tutor]);

  // Everytime there is an update to attendees or the currently selected org, check that all attendees'
  // org_ids correspond with the currently selected org and that there are no duplicate attendees.
  // Meeting attendees that don't belong are cleared.
  useEffect(() => {
    if (editing || creating) {
      const current_org = currently_selected_organization as number;

      let castedAttendees;
      if (typeOfAttendees === Auth0AccountRole.ME) {
        castedAttendees = newAttendees as
          | MeetingStudentLimitedResponse[]
          | MeetingStudentAdminResponse[]
          | StudentLimitedResponse[]
          | StudentAdminResponse[];
      } else {
        castedAttendees = newAttendees as
          | MeetingTutorLimitedResponse[]
          | MeetingTutorAdminResponse[]
          | TutorLimitedResponse[]
          | TutorAdminResponse[];
      }

      // Make sure all attendees have the correct org id
      const attendeesThatBelong = castedAttendees.filter((attendee) => {
        const orgId =
          "student" in attendee
            ? attendee.student.org_id
            : "tutor" in attendee
            ? attendee.tutor.org_id
            : attendee.org_id;
        return orgId === current_org;
      });

      // Remove duplicates: track seen IDs and filter out repeated ones
      const seenIds = new Set();
      const uniqueAttendees = attendeesThatBelong.filter((attendee) => {
        const id = getAttendeeId(attendee);
        const isDuplicate = seenIds.has(id);
        seenIds.add(id);
        return !isDuplicate;
      });

      if (uniqueAttendees.length !== newAttendees.length) {
        if (typeOfAttendees === Auth0AccountRole.ORG_TUTOR) {
          updateAttendees(
            typeOfAttendees,
            uniqueAttendees as
              | MeetingTutorLimitedResponse[]
              | MeetingTutorAdminResponse[]
              | TutorLimitedResponse[]
              | TutorAdminResponse[]
          );
          setNewAttendees(
            uniqueAttendees as
              | MeetingTutorLimitedResponse[]
              | MeetingTutorAdminResponse[]
              | TutorLimitedResponse[]
              | TutorAdminResponse[]
          );
        } else if (typeOfAttendees === Auth0AccountRole.ME) {
          updateAttendees(
            typeOfAttendees,
            uniqueAttendees as
              | MeetingStudentLimitedResponse[]
              | MeetingStudentAdminResponse[]
              | StudentLimitedResponse[]
              | StudentAdminResponse[]
          );
          setNewAttendees(
            uniqueAttendees as
              | MeetingStudentLimitedResponse[]
              | MeetingStudentAdminResponse[]
              | StudentLimitedResponse[]
              | StudentAdminResponse[]
          );
        }
      }
    }
  }, [
    currently_selected_organization,
    newAttendees,
    typeOfAttendees,
    attendees,
    event,
    editing,
    creating,
  ]);

  const handleLinkToStudentOrTutorPage = (
    attendee:
      | MeetingStudentLimitedResponse
      | MeetingStudentAdminResponse
      | MeetingTutorLimitedResponse
      | MeetingTutorAdminResponse
      | StudentLimitedResponse
      | StudentAdminResponse
      | TutorLimitedResponse
      | TutorAdminResponse
  ) => {
    // Only org admins can view all profiles
    if (currently_selected_role === Auth0AccountRole.ORG_ADMIN) {
      return window.open(
        `${window.location.origin}${linkPrefix}${getAttendeeId(attendee)}`
      );
    }

    // Tutors can only view student profiles
    if (currently_selected_role === Auth0AccountRole.ORG_TUTOR) {
      // Check if we're viewing a student profile
      if (linkPrefix === "/manage/students/") {
        return window.open(
          `${window.location.origin}${linkPrefix}${getAttendeeId(attendee)}`
        );
      }
    }

    // Students can't view any profiles
    return;
  };

  return (
    <>
      {editing && (
        <div className="pb-4">
          {typeOfAttendees === Auth0AccountRole.ME ? (
            <Select
              id={`add-${typeOfAttendees}`}
              name={`add-${typeOfAttendees}`}
              options={possibleAttendees}
              value={null}
              getOptionLabel={(a) => `${a ? concatenateName(a) : ""}`}
              getOptionValue={(a) => (a ? getAttendeeId(a).toString() : "")}
              onChange={handleAttendeesUpdate}
              isDisabled={!subject}
              placeholder={subject ? undefined : "Select a subject to view"}
            />
          ) : (
            <SelectWithAvailability
              id={`add-${typeOfAttendees}`}
              name={`add-${typeOfAttendees}`}
              options={removeSelfIfTutor(possibleAttendees)}
              value={null}
              getOptionLabel={(a) => `${a ? concatenateName(a) : ""}`}
              getOptionValue={(a) => (a ? getAttendeeId(a).toString() : "")}
              onChange={handleAttendeesUpdate}
              isDisabled={!subject}
              placeholder={subject ? undefined : "Select a subject to view"}
              tutorsWithAvailability={tutorsWithAvailability}
            />
          )}
        </div>
      )}
      {displayAttendees &&
        attendees.map((attendee, index) => (
          <div className="pb-3" key={`${getAttendeeId(attendee)}-top-${index}`}>
            <MeetingAttendee
              userType={typeOfAttendees}
              key={`${getAttendeeId(attendee)}-attendee-${index}`}
              attendee={attendee}
              newView={true}
              onClick={() => {
                handleLinkToStudentOrTutorPage(attendee);
              }}
              append={append && append[index]}
            >
              {typeOfAttendees === Auth0AccountRole.ME && !editing && (
                <CheckboxInput
                  id={getAttendeeId(attendee).toString()}
                  checked={
                    "attended" in attendee ? attendee.attended : false
                  } /* MeetingStudents always have the attended field but the above accounts for hybrid types with tutor responses or Student responses.
                  Might cause a bug when booking a meeting as a student and the student is auto added to the meeting as a StudentRepsonse instead of a
                  MeetingStudent.*/
                  onCheck={(checked: boolean) => {
                    MeetingsService.editMeetingStudent({
                      meetingStudentId: attendee.id,
                      requestBody: {
                        attended: checked,
                      },
                    })
                      .then(() => {
                        Notifications.success("Attendance updated!");
                        // Update the local state to reflect the change
                        const updatedAttendees = attendees.map((a) =>
                          getAttendeeId(a) === getAttendeeId(attendee)
                            ? { ...a, attended: checked }
                            : a
                        );
                        updateAttendees(typeOfAttendees, updatedAttendees);
                      })
                      .catch((e: ApiError) => {
                        Notifications.error(
                          "Attendance could not be updated at this time!"
                        );
                      });
                  }}
                />
              )}
              {editing &&
                (typeOfAttendees === Auth0AccountRole.ME ||
                  (typeOfAttendees === Auth0AccountRole.ORG_TUTOR &&
                    tutorCorrespondingToThisMeeting?.id !==
                      getAttendeeId(attendee))) && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleAttendeeRemove(getAttendeeId(attendee));
                    }}
                  >
                    <Cross2Icon />
                  </div>
                )}
            </MeetingAttendee>
          </div>
        ))}
    </>
  );
}

export default function MeetingAttendees({
  tutors,
  students,
  editing,
  creating,
  event,
  availableSubjects,
  subject,
  currentMeetingInfo,
  removeAttendee,
  updateAttendees,
  handleSubjectChange,
  setIsAvailabilitySidebarOpen,
}: {
  tutors: (
    | MeetingTutorLimitedResponse
    | MeetingTutorAdminResponse
    | TutorLimitedResponse
    | TutorAdminResponse
  )[];
  students: (
    | MeetingStudentLimitedResponse
    | MeetingStudentAdminResponse
    | StudentLimitedResponse
    | StudentAdminResponse
  )[];
  editing: boolean;
  creating: boolean;
  event?: MeetingAdminResponse | MeetingLimitedResponse | undefined;
  availableSubjects:
    | OrganizationSubjectPublicResponse[]
    | OrganizationSubjectAdminResponse[]
    | undefined;
  subject:
    | OrganizationSubjectPublicResponse
    | OrganizationSubjectAdminResponse
    | undefined;
  currentMeetingInfo: MeetingDetails;
  removeAttendee: (userType: Auth0AccountRole, id: number) => void;
  updateAttendees: (
    userType: Auth0AccountRole,
    attendees: (
      | MeetingTutorLimitedResponse
      | MeetingStudentLimitedResponse
      | MeetingTutorAdminResponse
      | MeetingStudentAdminResponse
      | StudentLimitedResponse
      | StudentAdminResponse
      | TutorLimitedResponse
      | TutorAdminResponse
    )[]
  ) => void;
  handleSubjectChange: (
    subject:
      | OrganizationSubjectPublicResponse
      | OrganizationSubjectAdminResponse
  ) => void;
  setIsAvailabilitySidebarOpen: (open: boolean) => void;
}) {
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  return (
    <div className="bg-gray-100 rounded-lg col-span-12 md:col-span-3 m-0">
      <div className="bg-white p-5 rounded-t-lg">
        <div className="col-span-2">
          {editing ? (
            <Select
              id="subject"
              options={availableSubjects}
              value={subject ?? null}
              getOptionLabel={(s) => `${s.name}`}
              getOptionValue={(s) => s.id.toString()}
              placeholder="Subject..."
              onChange={handleSubjectChange}
              title="Add subject"
            />
          ) : (
            <Tag
              color={TagColor.GREEN}
              size={TagSize.NARROW}
              item={event?.org_subject.name as string}
            />
          )}
        </div>
      </div>
      <div className="p-5">
        {editing && (
          <div
            className="cursor-pointer text-green-500"
            onClick={() => setIsAvailabilitySidebarOpen(true)}
          >
            want to see when tutors are free?
          </div>
        )}
        <div className="pb-2">
          <h2 className="font-bold mb-1">Tutors</h2>
          <AttendeesList
            attendees={tutors ?? []}
            typeOfAttendees={Auth0AccountRole.ORG_TUTOR}
            subject={subject}
            editing={editing}
            creating={creating}
            event={event}
            linkPrefix={"/manage/tutors/"}
            currentMeetingInfo={currentMeetingInfo}
            remove={removeAttendee}
            updateAttendees={updateAttendees}
          />
        </div>
        <div className="flex justify-between">
          <h2 className="font-bold mb-1">Students</h2>
          {!editing && <h2 className="mb-1 mt-4 text-sm">Attendance</h2>}
        </div>
        <AttendeesList
          attendees={students ?? []}
          typeOfAttendees={Auth0AccountRole.ME}
          subject={subject}
          editing={editing}
          creating={creating}
          event={event}
          linkPrefix={"/manage/students/"}
          currentMeetingInfo={currentMeetingInfo}
          remove={removeAttendee}
          updateAttendees={updateAttendees}
        />
        {!editing ? (
          <Dialog>
            <DialogTrigger asChild>
              <Button
                extraClasses="mt-5 w-full bg-white"
                color={ButtonColor.GREEN}
                fill={ButtonFill.DEFAULT}
                size={ButtonSize.SMALL}
              >
                <p className="font-semibold">Message</p>
              </Button>
            </DialogTrigger>
            {!editing && (
              <ComposeMessageDialog
                students={students}
                tutors={tutors}
                org_id={
                  event
                    ? event.org_subject.org_id
                    : (currently_selected_organization as number)
                }
              />
            )}
          </Dialog>
        ) : null}
      </div>
    </div>
  );
}
