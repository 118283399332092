import { ArrowRightIcon } from "@radix-ui/react-icons";
import { ButtonColor, ButtonSize, LinkButton } from "components/Button";
import FeatureList from "components/FeatureList";
import FeaturesCarousel from "components/FeaturesCarousel";
import RequestDemo from "components/RequestDemo";
import DashboardIllustration from "images/LandingPage/DashboardIllustration";
import SystemsIllustration from "images/LandingPage/SystemsIllustration";
import "../styles/LandingPage/index.css";

export default function LandingPage() {
  return (
    <div style={{ overflowX: "hidden" }}>
      {/* landing section transition background */}
      <div className="absolute font-sans landing-page--hero">
        <div className="grid grid-cols-12 gap-x-12 py-12">
          <div className="pb-12 container pl-[5%] col-span-7 md:col-span-6 lg:col-span-8">
            <h1 className="relative text-4xl md:text-5xl lg:text-6xl landing-page--hero-title">
              <div className="mb-4 text-white">Software</div>
              <div className="mb-4">to build and operate</div>
              <div className="text-white"> tutoring programs.</div>
            </h1>

            <p className="pt-3 text-2xl font-bold drop-shadow-md">
              Give every student 1-to-1 attention.
            </p>
          </div>
          {/* To make the title block responsive */}
          <div className="relative col-span-5 md:col-span-6 lg:col-span-4"></div>
        </div>
      </div>

      {/* Dashboard */}
      <div className="font-sans relative">
        <div className="py-12 grid grid-cols-12 relative">
          <div className="pb-12 container col-span-7 md:col-span-6 lg:col-span-8"></div>
          <div className="relative float-right col-span-5 md:col-span-6 lg:col-span-4">
            <div className="dashboard">
              <DashboardIllustration />
            </div>
          </div>
        </div>
      </div>

      <div className="relative request-demo-btn">
        <LinkButton
          to="#"
          color={ButtonColor.BLUE}
          size={ButtonSize.DEFAULT}
          extraClasses="shadow-md request-demo-btn"
          style={{
            height: "4rem",
            fontSize: "20px",
            border: "none",
            background: "linear-gradient(90deg, #5D75F4 0%, #30CEDC 100%)",
          }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href =
              "https://calendly.com/ashenouda50/meet-and-greet";
          }}
        >
          Request a Demo <ArrowRightIcon />
        </LinkButton>
      </div>

      {/* system section */}
      <div
        className="container pt-20 py-4 pb-12 mx-auto text-center"
        id="about"
      >
        <div className="w-full max-w-2xl pt-10 mx-auto">
          <SystemsIllustration />
        </div>

        <div
          className="text-center text-xl mx-auto py-4 mt-10 w-[60%]"
          style={{ fontFamily: "Futura" }}
        >
          <div>
            Tadpole gives educators the infrastructure they need to build
            world-class tutoring programs.
          </div>
          <div className="mt-5">
            Use our software to run your program, drive growth, and teach
            better.
          </div>
          <div>Learn more below.</div>
        </div>
      </div>

      <div className="features-carousel">
        <FeaturesCarousel />
      </div>

      <div className="py-12" id="features">
        <FeatureList />
      </div>

      <div className="justify-center pt-6 pb-12" id="contact">
        <RequestDemo />
      </div>
    </div>
  );
}
