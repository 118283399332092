import { DialogContent } from "components/Dialog";
import { useCallback, useEffect, useState } from "react";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import PaymentMethods from "../../../../../../../../components/PaymentMethods/PaymentMethods";
import { APIResponse, PageStatus } from "types";
import SuccessDialog from "components/SuccessDialog";
import {
  StudentToOrgTransactionType,
  PaymentsService,
  OrgSubjectsService,
  OrganizationSubjectAdminResponse,
  PaymentMethodType,
  StudentLimitedResponse,
  StudentSubjectsService,
  OrganizationSubjectPublicResponse,
  StudentSubjectAdminResponse,
} from "client/openapi";
import Notifications from "util/notifications";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import ErrorDialog from "components/ErrorDialog";
import { Select } from "components/Select";

export default function TutoringUpfrontModal({
  orgId,
  student,
}: {
  orgId: number;
  student: StudentLimitedResponse;
}) {
  const [status, setStatus] = useState<PageStatus>(PageStatus.EDITING);
  const [hours, setHours] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [invalidHours, setInvalidHours] = useState<boolean>(true);
  const [invalidAmount, setInvalidAmount] = useState<boolean>(true);
  const [subjects, setSubjects] = useState<
    OrganizationSubjectAdminResponse[] | OrganizationSubjectPublicResponse[]
  >([]);
  const [selectedSubject, setSelectedSubject] =
    useState<OrganizationSubjectAdminResponse>();

  const [studentRate, setStudentRate] = useState<number>(0);
  const [error, setError] = useState<APIResponse>();
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [paymentMethodType, setPaymentMethodType] =
    useState<PaymentMethodType>();

  // Maps org_subject.id -> the StudentSubject object (so we can get studentSubject.id).
  const [studentSubjectsMap, setStudentSubjectsMap] = useState<
    Record<number, StudentSubjectAdminResponse>
  >({});

  const [
    makeSureUserDoesntSpamChargeButton,
    setMakeSureUserDoesntSpamChargeButton,
  ] = useState<boolean>(false);

  const handleCharge = async () => {
    setMakeSureUserDoesntSpamChargeButton(true);

    // Basic validation
    if (Number(hours) > 100) {
      setInvalidHours(true);
      setError({
        message: "Please charge for no more than 100 hours at a time",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    if (Number(hours) <= 0) {
      setInvalidHours(true);
      setError({
        message: "Hours must be greater than 0",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    if (hours === 0 || hours === null || hours === undefined) {
      setInvalidHours(true);
      setError({
        message: "Please specify how many hours you'd like to charge for",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    if (
      amount < 500 ||
      amount === null ||
      amount === undefined ||
      Number.isNaN(Number(amount))
    ) {
      setInvalidAmount(true);
      if (amount < 500) {
        setError({
          message: "Please charge for an amount greater than or equal to $5.00",
        });
      }
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    if (selectedSubject === undefined || selectedSubject === null) {
      setError({
        message: "Make sure your subject is selected!",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    if (!paymentMethodType || !paymentMethod) {
      setError({
        message: "Make sure your payment method is selected!",
      });
      setMakeSureUserDoesntSpamChargeButton(false);
      return;
    }

    // -----------
    // 1) Get or create the student's subject
    // -----------
    let studentSubjectId =
      studentSubjectsMap[selectedSubject.id]?.id ?? undefined;

    if (!studentSubjectId) {
      // Student does NOT have a StudentSubject for this org subject yet, so create one.
      try {
        const newStudentSubject =
          await StudentSubjectsService.createStudentSubject({
            requestBody: {
              student_id: student.id,
              org_subject_id: selectedSubject.id,
              // Set the custom bill rate if needed:
              custom_bill_rate: selectedSubject.default_price_for_students,
            },
          });

        studentSubjectId = newStudentSubject.id;
        // Store it in our map so we don't create it again next time:
        setStudentSubjectsMap((prev) => ({
          ...prev,
          [selectedSubject.id]: newStudentSubject,
        }));
      } catch (err) {
        setError(err as APIResponse);
        setMakeSureUserDoesntSpamChargeButton(false);
        return;
      }
    }

    // -----------
    // 2) Create the payment using the *student_subject_id*
    // -----------
    try {
      await PaymentsService.createStudentToOrgPayment({
        requestBody: {
          amount: amount,
          org_id: orgId,
          student_id: student.id,
          student_subject_id: studentSubjectId, // <-- The actual student_subject.id
          payment_method_id: paymentMethod.includes("@")
            ? undefined
            : paymentMethod,
          invoice_email: paymentMethod.includes("@")
            ? paymentMethod
            : undefined,
          payment_method_type: paymentMethodType,
          return_url: `${process.env.REACT_APP_FRONTEND_DOMAIN}/manage/students/${student.id}#billing`,
          reason: `${hours} hours of ${selectedSubject.name}`,
          hours: hours,
          student_to_org_transaction_type: StudentToOrgTransactionType.UPFRONT,
        },
      });

      setStatus(PageStatus.SUCCESS);
    } catch (err) {
      setError(err as APIResponse);
      setStatus(PageStatus.ERROR);
    }
  };

  const verifyHours: CurrencyInputProps["onValueChange"] = (value) => {
    // value is over limit
    if (Number(value) > 100) {
      setInvalidHours(true);
      setError({
        message: "Please charge for no more than 100 hours at a time",
      });
      return;
    }

    // value is under limit
    if (Number(value) <= 0) {
      setInvalidHours(true);
      setError({
        message: "Hours must be greater than 0",
      });
      return;
    }

    if (
      Number(value) === null ||
      Number(value) === undefined ||
      Number.isNaN(Number(value))
    ) {
      setInvalidHours(true);
      return;
    }

    setInvalidHours(false);
    setHours(Number(value));
    setAmount(studentRate * Number(value));
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const handleSubjectChange = (option: OrganizationSubjectAdminResponse) => {
    setSelectedSubject(option);

    // We use special student rate if it exists, otherwise default subject price
    const student_rate =
      studentSubjectsMap[option.id]?.custom_bill_rate ??
      option.default_price_for_students ??
      0;

    setStudentRate(student_rate);
    setAmount(student_rate * hours);
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const handleSelectPaymentMethod = (id: any) => {
    if (typeof id === "string" && id.includes("@")) {
      setPaymentMethod(id);
      setPaymentMethodType(PaymentMethodType.INVOICE);
    } else if (typeof id === "object") {
      setPaymentMethod(id.payment_method_id);
      setPaymentMethodType(
        id.brand ? PaymentMethodType.CARD : PaymentMethodType.ACH
      );
    } else {
      // Handle unexpected payment method type if necessary
      console.error("Unexpected payment method type", id);
    }
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const reset = () => {
    setInvalidHours(true);
    setInvalidAmount(true);
    setAmount(0);
    setHours(0);
    setSelectedSubject(undefined);
    setStudentRate(0);
    setPaymentMethod("");
    setPaymentMethodType(undefined);
    setMakeSureUserDoesntSpamChargeButton(false);
  };

  const getAndSetSubjects = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    OrgSubjectsService.getSubjectsByOrg({
      orgId: orgId,
    })
      .then((orgSubjects) => {
        setSubjects(orgSubjects);
      })
      .catch((error) => {
        setError(error);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [orgId]);

  // Grab all StudentSubjects for this student so we know which ones exist
  // and can build `studentSubjectsMap` as well as `specialStudentRates`.
  const getSpecialStudentRates = useCallback(async () => {
    setStatus(PageStatus.LOADING);
    StudentSubjectsService.getStudentSubjectsByStudentId({
      studentId: student.id,
    })
      .then((studentSubjects) => {
        const rates: Record<number, number> = {};
        const subjMap: Record<number, StudentSubjectAdminResponse> = {};

        studentSubjects.forEach((studSub) => {
          // org_subject.id is the key
          if (studSub.org_subject?.id) {
            subjMap[studSub.org_subject.id] = studSub;
            rates[studSub.org_subject.id] = studSub.custom_bill_rate ?? 0;
          }
        });

        setStudentSubjectsMap(subjMap);
      })
      .catch((error) => {
        setError(error);
        console.error(`Error (#${error.status}): ${error.message}`);
      });
  }, [student.id]);

  useEffect(() => {
    getAndSetSubjects();
    getSpecialStudentRates();
  }, [getAndSetSubjects, getSpecialStudentRates]);

  useEffect(() => {
    if (error) {
      Notifications.error(error?.message || "An unexpected error occurred.");
    }
  }, [error]);

  useEffect(() => {
    if (amount === 0) {
      setInvalidAmount(true);
      return;
    }

    if (amount < 500 || amount === null || amount === undefined) {
      setInvalidAmount(true);
      setError({
        message: "Please charge for an amount greater than or equal to $5.00",
      });
    } else {
      // If the amount is valid again, remove the 'invalid' state
      setInvalidAmount(false);
    }
  }, [amount]);

  if (status === PageStatus.SUCCESS) {
    return (
      <SuccessDialog
        message="Attempting charge. Reload the page to see your changes!"
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  if (status === PageStatus.ERROR) {
    return (
      <ErrorDialog
        message="Charge cannot be created at this time"
        onClose={() => {
          reset();
          setStatus(PageStatus.EDITING);
        }}
      />
    );
  }

  return (
    <DialogContent className="dialog-content max-w-[800px]" onClose={reset}>
      <div className="mt-3 font-semibold text-center header text-lg">
        Charge For Tutoring Upfront
      </div>

      <div className="mt-3 border border-gray-300 border-2 rounded-lg p-4 flex gap-3">
        <div className="w-5/12 mr-2">
          <p className="text-sm font-bold mb-1">Subject</p>
          <Select
            id="subject"
            options={subjects}
            value={selectedSubject as OrganizationSubjectAdminResponse}
            getOptionLabel={(s) => s.name}
            getOptionValue={(s) => String(s.id)}
            placeholder="Subject..."
            isDisabled={subjects?.length === 0}
            onChange={handleSubjectChange}
          />
        </div>

        <div className="w-3/12">
          <p className="text-sm font-bold mb-3">Rate</p>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(studentRate / 100)}
        </div>

        <div className="w-5/12">
          <p className="text-sm font-bold">Hours</p>
          {/* We are using a currency input, but only to easily handle numeric input. */}
          <CurrencyInput
            className="input w-11/12 h-3/6"
            id="hours"
            name="hours"
            placeholder={`10`}
            decimalsLimit={0}
            allowNegativeValue={false}
            onValueChange={verifyHours}
          />
        </div>

        <div className="w-3/12">
          <p className="text-sm font-bold mb-3">Total</p>
          {hours
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(amount / 100)
            : "$0.00"}
        </div>
      </div>

      <div className="mt-3 border border-gray-300 border-2 rounded-lg p-4">
        <p className="mb-2 text-sm font-bold">Bill to:</p>
        <div className="px-2">
          <PaymentMethods
            showAddCardButton
            selectable
            studentIfInitiatedByAdmin={student}
            handleSelectPaymentMethod={handleSelectPaymentMethod}
          />
        </div>
      </div>

      <div className="w-full text-center mt-6">
        <Button
          color={
            invalidHours ||
            invalidAmount ||
            selectedSubject === undefined ||
            paymentMethod === "" ||
            makeSureUserDoesntSpamChargeButton
              ? ButtonColor.GRAY
              : ButtonColor.GREEN
          }
          size={ButtonSize.DEFAULT}
          onClick={handleCharge}
          disabled={
            invalidHours ||
            invalidAmount ||
            selectedSubject === undefined ||
            paymentMethod === "" ||
            makeSureUserDoesntSpamChargeButton
          }
        >
          Charge
        </Button>
      </div>
    </DialogContent>
  );
}
