import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "components/Dialog";
import {
  Auth0AccountRole,
  MeetingAdminResponse,
  OrgToTadpoleTransactionResponse,
  PaymentsService,
  StudentToOrgTransactionResponse,
  StudentToOrgTransactionType,
  TadpoleToTutorTransactionResponse,
  TransactionType,
} from "client/openapi";
import moment from "moment";
import MeetingDialog from "components/MeetingDialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import {
  concatenateMeetingStudents,
  concatenateMeetingTutors,
} from "util/concatenateSubject";
import { useNavigate } from "react-router-dom";
import { FaClock } from "react-icons/fa6";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { getTransactionStatusColor } from "./statusHelper";

function TransactionMeetingsPopup({
  transaction,
}: {
  transaction:
    | StudentToOrgTransactionResponse
    | TadpoleToTutorTransactionResponse
    | OrgToTadpoleTransactionResponse;
}) {
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState<MeetingAdminResponse[]>([]);
  const [tadpoleToTutorTransactions, setTadpoleToTutorTransactions] = useState<
    TadpoleToTutorTransactionResponse[]
  >([]);
  const [studentToOrgTransactions, setStudentToOrgTransactions] = useState<
    StudentToOrgTransactionResponse[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (
      transaction.transaction_type === TransactionType.STUDENT_TO_ORGANIZATION
    ) {
      if (
        (transaction as StudentToOrgTransactionResponse)
          .student_to_org_transaction_type ===
        StudentToOrgTransactionType.REFUND
      ) {
        PaymentsService.getTransaction({
          transactionId: transaction.refunding_tadpole_transaction_id as string,
        })
          .then((res) => {
            console.log("REFUND_RETURNED", res);
            setStudentToOrgTransactions([
              res as StudentToOrgTransactionResponse,
            ]);
            setLoading(false);
          })
          .catch((e) => {
            setError("Failed to load refund transaction.");
            setLoading(false);
          });
      } else {
        PaymentsService.getMeetingsByStudentToOrgTransaction({
          transactionId: transaction.id,
        })
          .then((res) => {
            setMeetings(res);
            setLoading(false);
          })
          .catch((e) => {
            setError("Failed to load meetings.");
            setLoading(false);
          });
      }
    }

    if (transaction.transaction_type === TransactionType.TADPOLE_TO_TUTOR) {
      PaymentsService.getMeetingsByTadpoleToTutorTransaction({
        transactionId: transaction.id,
      })
        .then((res) => {
          setMeetings(res);
          setLoading(false);
        })
        .catch((e) => {
          setError("Failed to load meetings.");
          setLoading(false);
        });

      PaymentsService.getDirectPaymentsInAutomaticPaymentGroup({
        groupTransactionId: transaction.id,
      })
        .then((res) => {
          setTadpoleToTutorTransactions(res);
          setLoading(false);
        })
        .catch((e) => {
          setError("Failed to load direct payments.");
          setLoading(false);
        });
    }

    if (
      transaction.transaction_type === TransactionType.ORGANIZATION_TO_TADPOLE
    ) {
      PaymentsService.getTransactionsByFundingTransactionId({
        fundingTransactionId: transaction.id,
      })
        .then((res) => {
          setTadpoleToTutorTransactions(res);
          setLoading(false);
        })
        .catch((e) => {
          setError("Failed to load transactions.");
          setLoading(false);
        });
    }
  }, [transaction]);

  if (loading) {
    return (
      <DialogContent className="dialog-content max-w-[900px] p-6 bg-blue-50 rounded-md flex flex-col items-center space-y-4">
        {/* <div className="w-8 h-8 border-4 border-green-300 border-t-transparent border-solid rounded-full animate-spin"></div>
        <div className="text-blue-700 font-semibold">Loading meetings...</div> */}
      </DialogContent>
    );
  }

  if (error) {
    return (
      <DialogContent className="p-6 bg-red-50 rounded-md">
        <div className="text-red-700 font-semibold">{error}</div>
      </DialogContent>
    );
  }

  return (
    <>
      <DialogContent className="dialog-content max-w-[900px] p-6 bg-gray-50 rounded-md shadow-md">
        {meetings.length > 0 && (
          <div>
            <h2 className="text-xl font-bold mb-4 text-gray-800">
              Meetings Tied to Transaction
            </h2>
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse border border-gray-200 bg-white rounded-md shadow-sm">
                <thead className="bg-gradient-to-r from-blue-100 to-blue-50 text-gray-700">
                  <tr>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Date
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Time
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Meeting Name
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Students
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Tutors
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {meetings.map((meeting, index) => {
                    const start = moment(meeting.start);
                    const end = start.clone().add(meeting.duration, "minutes");
                    return (
                      <tr
                        key={meeting.id}
                        className={`${
                          index % 2 === 0 ? "bg-white" : "bg-gray-50"
                        } hover:bg-blue-50 transition-colors`}
                      >
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200 whitespace-nowrap">
                          {start.format("ddd, MMM Do")}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200 whitespace-nowrap">
                          {start.format("h:mma")} - {end.format("h:mma")}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200">
                          {meeting.name}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200">
                          {concatenateMeetingStudents(meeting.students)}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200">
                          {concatenateMeetingTutors(meeting.tutors)}
                        </td>
                        <td className="py-3 px-4 text-blue-500 border-b border-gray-200">
                          <Dialog>
                            <DialogTrigger>
                              <Button
                                color={ButtonColor.SKYBLUE}
                                size={ButtonSize.EXTRA_SMALL}
                                fill={ButtonFill.HOLLOW}
                                className="text-sm"
                              >
                                View
                              </Button>
                            </DialogTrigger>
                            <MeetingDialog
                              isCreate={false}
                              role={Auth0AccountRole.ORG_TUTOR}
                              event={meeting}
                              setEvents={() => Promise.resolve()}
                            />
                          </Dialog>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {tadpoleToTutorTransactions.length > 0 && (
          <div>
            <h2 className="text-xl font-bold my-4 text-gray-800">
              {transaction.transaction_type ===
              TransactionType.ORGANIZATION_TO_TADPOLE
                ? "Transactions tied to payroll"
                : "Direct payments tied to automatic payment"}
            </h2>
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse border border-gray-200 bg-white rounded-md shadow-sm">
                <thead className="bg-gradient-to-r from-blue-100 to-blue-50 text-gray-700">
                  <tr>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Amount
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Timestamp
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Type
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Tutor
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Status
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tadpoleToTutorTransactions.map((transaction, index) => {
                    const timestamp = moment(transaction.timestamp);
                    return (
                      <tr
                        key={transaction.id}
                        className={`${
                          index % 2 === 0 ? "bg-white" : "bg-gray-50"
                        } hover:bg-blue-50 transition-colors`}
                      >
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200 whitespace-nowrap">
                          ${transaction.amount / 100}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200">
                          {timestamp.format("MMM Do YYYY, h:mma")}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200">
                          {transaction.group_transaction_id
                            ? "Direct Payment"
                            : "Automatic Payment"}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200">
                          <Button
                            onClick={() => {
                              navigate(
                                `/manage/tutors/${transaction.tutor_id}`
                              );
                            }}
                            color={ButtonColor.SKYBLUE}
                            size={ButtonSize.EXTRA_SMALL}
                            fill={ButtonFill.HOLLOW}
                          >
                            {transaction.tutor.first_name}{" "}
                            {transaction.tutor.last_name}
                          </Button>
                        </td>
                        <td className="py-3 px-4 pr-7 text-gray-800 border-b border-gray-200">
                          {(() => {
                            const statusColor = getTransactionStatusColor(
                              transaction.status
                            );
                            switch (statusColor) {
                              case "green":
                                return (
                                  <IoCheckmarkCircle className="mx-auto green-mark" />
                                );
                              case "purple":
                                return (
                                  <IoCheckmarkCircle className="mx-auto purple-mark" />
                                );
                              case "orange":
                                return (
                                  <FaClock
                                    className="mx-auto"
                                    style={{
                                      color: "#FFB341",
                                      fontSize: "20px",
                                    }}
                                  />
                                );
                              case "red":
                                return (
                                  <IoCloseCircle className="mx-auto red-mark" />
                                );
                              default:
                                return (
                                  <FaClock
                                    className="mx-auto"
                                    style={{
                                      color: "lightblue",
                                      fontSize: "20px",
                                    }}
                                  />
                                );
                            }
                          })()}
                        </td>
                        {!transaction.group_transaction_id && (
                          <td className="py-3 px-4 text-blue-500 border-b border-gray-200">
                            <Dialog>
                              <DialogTrigger>
                                <Button
                                  color={ButtonColor.PURPLE}
                                  size={ButtonSize.EXTRA_SMALL}
                                  fill={ButtonFill.HOLLOW}
                                >
                                  View Details
                                </Button>
                              </DialogTrigger>
                              <TransactionMeetingsPopup
                                transaction={transaction}
                              />
                            </Dialog>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {studentToOrgTransactions.length > 0 && (
          <div>
            <h2 className="text-xl font-bold mb-4 text-gray-800">
              Transaction Refunded
            </h2>
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse border border-gray-200 bg-white rounded-md shadow-sm">
                <thead className="bg-gradient-to-r from-blue-100 to-blue-50 text-gray-700">
                  <tr>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Amount
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Timestamp
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Type
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Student
                    </th>
                    <th className="py-3 px-4 text-left font-semibold border-b border-gray-200">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {studentToOrgTransactions.map((transaction, index) => {
                    const timestamp = moment(transaction.timestamp);
                    return (
                      <tr
                        key={transaction.id}
                        className={`${
                          index % 2 === 0 ? "bg-white" : "bg-gray-50"
                        } hover:bg-blue-50 transition-colors`}
                      >
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200 whitespace-nowrap">
                          ${transaction.amount / 100}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200">
                          {timestamp.format("MMM Do YYYY, h:mma")}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200">
                          {(() => {
                            switch (
                              transaction.student_to_org_transaction_type
                            ) {
                              case StudentToOrgTransactionType.DIRECT:
                                return "Direct";
                              case StudentToOrgTransactionType.UPFRONT:
                                return "Upfront";
                              case StudentToOrgTransactionType.RESCHEDULING_FEE:
                                return "Rescheduling Fee";
                              case StudentToOrgTransactionType.CANCELLATION_FEE:
                                return "Cancellation Fee";
                              case StudentToOrgTransactionType.ABSENCE_FEE:
                                return "Absence Fee";
                              case StudentToOrgTransactionType.REFUND:
                                return "Refund";
                              case StudentToOrgTransactionType.MEETING_PAID_WITH_CREDITS:
                                return "Paid with Credits";
                              case StudentToOrgTransactionType.AUTOMATIC_CHARGE_FOR_A_GROUP_OF_MEETINGS:
                                return "Automatic Charge";
                              default:
                                return "Charge";
                            }
                          })()}
                        </td>
                        <td className="py-3 px-4 text-gray-800 border-b border-gray-200">
                          <Button
                            onClick={() => {
                              navigate(
                                `/manage/students/${transaction.student_id}#billing`
                              );
                            }}
                            color={ButtonColor.SKYBLUE}
                            size={ButtonSize.EXTRA_SMALL}
                            fill={ButtonFill.HOLLOW}
                          >
                            {transaction.student.first_name}{" "}
                            {transaction.student.last_name}
                          </Button>
                        </td>
                        <td className="py-3 px-4 text-blue-500 border-b border-gray-200">
                          <Dialog>
                            <DialogTrigger>
                              <Button
                                color={ButtonColor.PURPLE}
                                size={ButtonSize.EXTRA_SMALL}
                                fill={ButtonFill.HOLLOW}
                              >
                                View Details
                              </Button>
                            </DialogTrigger>
                            <TransactionMeetingsPopup
                              transaction={transaction}
                            />
                          </Dialog>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </DialogContent>
    </>
  );
}

export default TransactionMeetingsPopup;
