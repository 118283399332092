/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents all invite types.
 */
export enum InviteType {
  ADMIN = "Admin",
  TUTOR = "Tutor",
  STUDENT = "Student",
  PARENT = "Parent",
  ORGANIZATION = "Organization",
}
