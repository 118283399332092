/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Comparison operators for comparing values.
 */
export enum ComparisonOperator {
  EQUALS = "equals",
  NOT_EQUALS = "not_equals",
  GREATER_THAN = "greater_than",
  GREATER_THAN_OR_EQUAL = "greater_than_or_equal",
  LESS_THAN = "less_than",
  LESS_THAN_OR_EQUAL = "less_than_or_equal",
  ILIKE = "ilike",
  IN = "in",
  NOT_IN = "not_in",
}
