import {
  Dialog,
  DialogAction,
  DialogActions,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import {
  Auth0AccountRole,
  MeetingLimitedResponse,
  MeetingsService,
  MeetingStatus,
  TutorLimitedResponse,
  SessionFrequency,
} from "client/openapi";
import Notifications from "util/notifications";
import MeetingDialog from "components/MeetingDialog";
import moment from "moment";
import { concatenateMeetingStudents } from "util/concatenateSubject";
import React from "react";

export default function PendingMeetings({
  meetings,
  setMeetings,
  tutors,
}: {
  meetings: MeetingLimitedResponse[];
  setMeetings: (meetings: MeetingLimitedResponse[]) => void;
  tutors: TutorLimitedResponse[];
}) {
  const [processingMeetingId, setProcessingMeetingId] = React.useState<
    number | null
  >(null);

  // Group meetings by session_id and get earliest meeting for each session
  const groupedMeetings = React.useMemo(() => {
    const meetingsBySession = meetings.reduce((acc, meeting) => {
      if (!acc[meeting.session_id]) {
        acc[meeting.session_id] = [];
      }
      acc[meeting.session_id].push(meeting);
      return acc;
    }, {} as Record<number, MeetingLimitedResponse[]>);

    return Object.values(meetingsBySession).map((sessionMeetings) => {
      return sessionMeetings.reduce((earliest, current) =>
        moment(current.start).isBefore(moment(earliest.start))
          ? current
          : earliest
      );
    });
  }, [meetings]);

  function formatFrequency(meeting: MeetingLimitedResponse): string {
    const { session } = meeting;
    if (session.frequency === SessionFrequency.SINGLE) {
      return "once-off";
    }

    const endDate = session.ends_on_or_before
      ? moment(session.ends_on_or_before).format("M/D")
      : "ongoing";

    const frequencyMap = {
      [SessionFrequency.EVERY_7_DAYS]: "7 days",
      [SessionFrequency.EVERY_14_DAYS]: "14 days",
      [SessionFrequency.EVERY_21_DAYS]: "21 days",
      [SessionFrequency.EVERY_28_DAYS]: "28 days",
    };

    return `every ${frequencyMap[session.frequency]} until ${endDate}`;
  }

  function resolveMeeting({
    meeting,
    decision,
  }: {
    meeting: MeetingLimitedResponse;
    decision: MeetingStatus;
  }) {
    setProcessingMeetingId(meeting.id);

    const tutorId = meeting.tutors.find((mt) =>
      tutors.some((t) => t.id === mt.tutor.id)
    )?.tutor.id;

    if (!tutorId) {
      Notifications.error("Tutor not found");
      setProcessingMeetingId(null);
      return;
    }

    MeetingsService.acceptOrRejectMeetingAsTutor({
      tutorId,
      sessionId: meeting.session_id,
      accept: decision === MeetingStatus.ACCEPTED,
    })
      .then(() => {
        Notifications.success(
          `Meeting ${
            decision === MeetingStatus.ACCEPTED
              ? "accepted! Reload the page for changes to take effect!"
              : "rejected!"
          }`
        );

        // Remove all meetings with the same session_id
        setMeetings(
          meetings.filter(
            (remaining_meeting) =>
              remaining_meeting.session_id !== meeting.session_id
          )
        );
      })
      .catch((error) => {
        Notifications.error("Unable to respond to invite.");
      })
      .finally(() => {
        setProcessingMeetingId(null);
      });
  }

  return (
    <DialogContent className="dialog-content max-w-[900px]">
      <div className="mt-3 font-semibold text-center header text-lg">
        Pending Meetings
      </div>

      <div className="border rounded-lg border-gray-400 mt-6 overflow-hidden">
        <table className="w-full border-collapse">
          <thead className="bg-gray-100 sticky top-0">
            <tr className="text-left">
              <th className="px-6 py-3 text-sm font-semibold text-gray-600">
                Time
              </th>
              <th className="px-6 py-3 text-sm font-semibold text-gray-600">
                Frequency
              </th>
              <th className="px-6 py-3 text-sm font-semibold text-gray-600">
                Name
              </th>
              <th className="px-6 py-3 text-sm font-semibold text-gray-600">
                Students
              </th>
              <th className="px-6 py-3 text-sm font-semibold text-gray-600">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {groupedMeetings.map((meeting, index) => (
              <tr
                key={meeting.id}
                className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
              >
                <td className="px-6 py-4 text-sm whitespace-nowrap">
                  <span className="font-bold">
                    {moment(meeting.start).format("ddd MMM Do")}
                  </span>
                  <br />
                  {moment(meeting.start).format("h:mma")}-
                  {moment(meeting.start)
                    .add(meeting.duration, "minutes")
                    .format("h:mma")}
                </td>
                <td className="px-6 py-4 text-sm">
                  {formatFrequency(meeting)}
                </td>
                <td className="px-6 py-4 text-sm">{meeting.name}</td>
                <td className="px-6 py-4 text-sm">
                  {concatenateMeetingStudents(meeting.students)}
                </td>
                <td className="pl-2 pr-6 py-4 text-sm text-right">
                  <div className="flex justify-end gap-2">
                    <Dialog>
                      <DialogTrigger>
                        <Button
                          color={ButtonColor.SKYBLUE}
                          size={ButtonSize.EXTRA_SMALL}
                          fill={ButtonFill.HOLLOW}
                        >
                          View
                        </Button>
                      </DialogTrigger>
                      <MeetingDialog
                        isCreate={false}
                        role={Auth0AccountRole.ORG_TUTOR}
                        event={meeting}
                        setEvents={() => Promise.resolve()}
                      />
                    </Dialog>
                    <Button
                      color={
                        processingMeetingId === meeting.id
                          ? ButtonColor.GRAY
                          : ButtonColor.RED
                      }
                      size={ButtonSize.EXTRA_SMALL}
                      onClick={() => {
                        resolveMeeting({
                          meeting: meeting,
                          decision: MeetingStatus.REJECTED,
                        });
                      }}
                      disabled={processingMeetingId === meeting.id}
                    >
                      reject
                    </Button>
                    <Button
                      color={
                        processingMeetingId === meeting.id
                          ? ButtonColor.GRAY
                          : ButtonColor.GREEN
                      }
                      size={ButtonSize.EXTRA_SMALL}
                      onClick={() => {
                        resolveMeeting({
                          meeting: meeting,
                          decision: MeetingStatus.ACCEPTED,
                        });
                      }}
                      disabled={processingMeetingId === meeting.id}
                    >
                      accept
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <DialogActions>
        <DialogClose asChild>
          <DialogAction color={ButtonColor.PURPLE}>Close</DialogAction>
        </DialogClose>
      </DialogActions>
    </DialogContent>
  );
}
