import AdministrativeInfo from "./AdministrativeInfo";
import MeetingHistory from "../MeetingHistory";
import {
  StudentLimitedResponse,
  MeetingAdminResponse,
  MeetingLimitedResponse,
  ParentAdminResponse,
  ParentLimitedResponse,
  Auth0AccountRole,
} from "client/openapi";
import Parents from "./Parents";
import { useState } from "react";

export default function AdditionalStudentInfoTab({
  student,
  organization_id,
  exportingMeetings,
  setExportingMeetings,
  setStatus,
  setError,
  meetings,
  orgParents,
  studentParents,
  setStudentParents,
}: {
  student: StudentLimitedResponse;
  organization_id: number;
  exportingMeetings: boolean;
  setExportingMeetings: (boolean) => void;
  setStatus: (PageStatus) => void;
  setError: (APIResponse) => void;
  meetings: MeetingLimitedResponse[] | MeetingAdminResponse[];
  orgParents: ParentAdminResponse[] | ParentLimitedResponse[];
  studentParents: ParentAdminResponse[] | ParentLimitedResponse[];
  setStudentParents: (
    students: ParentAdminResponse[] | ParentLimitedResponse[]
  ) => void;
}) {
  const [exportingData, setExportingData] = useState<boolean>(false);

  return (
    <div className="grid grid-cols-1 gap-y-5 md:grid-cols-3 h-full">
      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <AdministrativeInfo
          exportingData={exportingData}
          setExportingData={setExportingData}
          userType={Auth0AccountRole.ME}
          student={student}
          organization_id={organization_id}
          exportingMeetings={exportingMeetings}
          setStatus={setStatus}
          setError={setError}
        />
      </div>

      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <Parents
          student={student}
          orgParents={orgParents}
          studentParents={studentParents}
          setStudentParents={setStudentParents}
        />
      </div>

      <div className="flex justify-end mx-5 mt-5 md:mx-0 md:mt-0">
        <MeetingHistory
          meetings={meetings}
          exportingMeetings={exportingMeetings}
          setExportingMeetings={setExportingMeetings}
          student={student}
          exportingData={exportingData}
          setStatus={setStatus}
          setError={setError}
        />
      </div>
    </div>
  );
}
