import {
  ApiError,
  StudentLimitedResponse,
  ParentLimitedResponse,
  TasksService,
  TaskStatus,
  AccountLimitedResponse,
  TaskRecipientCreate,
  TutorPublicResponse,
} from "client/openapi";
import { Button, ButtonColor, ButtonSize } from "components/Button";
import { DialogContent } from "components/Dialog";
import { Select } from "components/Select";
import { useContext, useEffect, useRef, useState } from "react";
import { DatePicker } from "rsuite";
import Notifications from "util/notifications";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";
import "./index.css";

interface UserOption {
  value: number | string;
  label: string;
}

interface TaskCreateHelper {
  name: string;
  content: string;
  due_date: string;
  status: TaskStatus;
  assigned_admins: UserOption[];
  assigned_tutors: UserOption[];
  assigned_parents: UserOption[];
  assigned_students: UserOption[];
  tagged_admins: UserOption[];
  tagged_tutors: UserOption[];
  tagged_parents: UserOption[];
  tagged_students: UserOption[];
}

function CreateTaskDialog({
  students,
  parents,
  tutors,
  admins,
  onClose,
  refreshTasks,
}: {
  students?: StudentLimitedResponse[];
  parents?: ParentLimitedResponse[];
  tutors?: TutorPublicResponse[];
  admins?: AccountLimitedResponse[];
  onClose: () => void;
  refreshTasks: () => void;
}) {
  const [taskCreate, setTaskCreate] = useState<TaskCreateHelper>({
    name: "",
    content: "",
    due_date: new Date().toISOString(),
    status: TaskStatus.PENDING,
    assigned_admins: [],
    assigned_tutors: [],
    assigned_parents: [],
    assigned_students: [],
    tagged_admins: [],
    tagged_tutors: [],
    tagged_parents: [],
    tagged_students: [],
  });

  const [error, setError] = useState<ApiError>();

  const dialogRef = useRef<HTMLDivElement>(null);

  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  const adminUsers: UserOption[] =
    admins?.map((a) => ({
      value: a.reference_id,
      label: `${a.first_name} ${a.last_name}`,
    })) ?? [];

  const tutorUsers: UserOption[] =
    tutors?.map((t) => ({
      value: t.id,
      label: `${t.first_name} ${t.last_name}`,
    })) ?? [];

  const parentUsers: UserOption[] =
    parents?.map((p) => ({
      value: p.id,
      label: `${p.first_name} ${p.last_name}`,
    })) ?? [];

  const studentUsers: UserOption[] =
    students?.map((s) => ({
      value: s.id,
      label: `${s.first_name} ${s.last_name}`,
    })) ?? [];

  const [isCreating, setIsCreating] = useState(false);

  const handleClose = () => {
    setTaskCreate({
      name: "",
      content: "",
      due_date: new Date().toISOString(),
      status: TaskStatus.PENDING,
      assigned_admins: [],
      assigned_tutors: [],
      assigned_parents: [],
      assigned_students: [],
      tagged_admins: [],
      tagged_tutors: [],
      tagged_parents: [],
      tagged_students: [],
    });
    onClose();
  };

  const handleCreate = () => {
    if (
      !taskCreate.name ||
      !taskCreate.content ||
      !taskCreate.due_date ||
      !(
        taskCreate.assigned_admins?.length > 0 ||
        taskCreate.assigned_tutors?.length > 0 ||
        taskCreate.assigned_parents?.length > 0 ||
        taskCreate.assigned_students?.length > 0
      )
    ) {
      Notifications.error(
        "Please make sure name, content, due date, and assignees are included!"
      );
      return;
    }

    setIsCreating(true);

    const assignedUsers: TaskRecipientCreate[] = [
      ...(taskCreate.assigned_admins ?? []).map((u) => ({
        account_id: String(u.value),
        student_id: null,
        parent_id: null,
        tutor_id: null,
      })),
      ...(taskCreate.assigned_tutors ?? []).map((u) => ({
        account_id: null,
        student_id: null,
        parent_id: null,
        tutor_id: Number(u.value),
      })),
      ...(taskCreate.assigned_parents ?? []).map((u) => ({
        account_id: null,
        student_id: null,
        parent_id: Number(u.value),
        tutor_id: null,
      })),
      ...(taskCreate.assigned_students ?? []).map((u) => ({
        account_id: null,
        student_id: Number(u.value),
        parent_id: null,
        tutor_id: null,
      })),
    ];

    const taggedUsers: TaskRecipientCreate[] = [
      ...(taskCreate.tagged_admins ?? []).map((u) => ({
        account_id: String(u.value),
        student_id: null,
        parent_id: null,
        tutor_id: null,
      })),
      ...(taskCreate.tagged_tutors ?? []).map((u) => ({
        account_id: null,
        student_id: null,
        parent_id: null,
        tutor_id: Number(u.value),
      })),
      ...(taskCreate.tagged_parents ?? []).map((u) => ({
        account_id: null,
        student_id: null,
        parent_id: Number(u.value),
        tutor_id: null,
      })),
      ...(taskCreate.tagged_students ?? []).map((u) => ({
        account_id: null,
        student_id: Number(u.value),
        parent_id: null,
        tutor_id: null,
      })),
    ];

    TasksService.createTask({
      requestBody: {
        name: taskCreate.name,
        content: taskCreate.content,
        due_date: taskCreate.due_date,
        org_id: currently_selected_organization as number,
        assigned_users: assignedUsers,
        tagged_users: taggedUsers,
      },
    })
      .then(() => {
        Notifications.success("Task created successfully");
        refreshTasks();
        handleClose();
      })
      .catch((err) => {
        setError(err as ApiError);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  useEffect(() => {
    if (error) {
      Notifications.error(error.body.detail || "An unexpected error occurred.");
    }
  }, [error]);

  const renderUserList = (type: "Assigned Users" | "Tagged Users") => {
    return (
      <div className="user-list mb-6">
        {type === "Assigned Users" && (
          <div className="mb-4">
            <h4 className="text-sm font-bold">Task Content</h4>
            <div className="content-input-wrapper">
              <textarea
                className="input w-full min-h-20 text-sm resize-none"
                placeholder="Task description"
                value={taskCreate.content}
                onChange={(e) => {
                  const textarea = e.target;
                  textarea.style.height = "auto";
                  textarea.style.height = `${textarea.scrollHeight}px`;
                  setTaskCreate({
                    ...taskCreate,
                    content: e.target.value,
                  });
                }}
                style={{ height: "auto" }}
              />
            </div>
          </div>
        )}
        <h4 className="text-sm font-bold">{type}</h4>
        <div className="user-selects grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <label className="text-sm">Admins</label>
            <Select
              isMulti
              value={
                type === "Assigned Users"
                  ? taskCreate.assigned_admins
                  : taskCreate.tagged_admins
              }
              onChange={(options) => {
                setTaskCreate({
                  ...taskCreate,
                  ...(type === "Assigned Users"
                    ? {
                        assigned_admins: options,
                      }
                    : {
                        tagged_admins: options,
                      }),
                });
              }}
              options={adminUsers}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                  pointerEvents: "auto",
                }),
                option: (base) => ({
                  ...base,
                  cursor: "pointer",
                }),
              }}
            />
          </div>
          <div>
            <label className="text-sm">Tutors</label>
            <Select
              isMulti
              value={
                type === "Assigned Users"
                  ? taskCreate.assigned_tutors
                  : taskCreate.tagged_tutors
              }
              onChange={(options) => {
                setTaskCreate({
                  ...taskCreate,
                  ...(type === "Assigned Users"
                    ? {
                        assigned_tutors: options,
                      }
                    : {
                        tagged_tutors: options,
                      }),
                });
              }}
              options={tutorUsers}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                  pointerEvents: "auto",
                }),
                option: (base) => ({
                  ...base,
                  cursor: "pointer",
                }),
              }}
            />
          </div>
          <div>
            <label className="text-sm">Parents</label>
            <Select
              isMulti
              value={
                type === "Assigned Users"
                  ? taskCreate.assigned_parents
                  : taskCreate.tagged_parents
              }
              onChange={(options) => {
                setTaskCreate({
                  ...taskCreate,
                  ...(type === "Assigned Users"
                    ? {
                        assigned_parents: options,
                      }
                    : {
                        tagged_parents: options,
                      }),
                });
              }}
              options={parentUsers}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                  pointerEvents: "auto",
                }),
                option: (base) => ({
                  ...base,
                  cursor: "pointer",
                }),
              }}
            />
          </div>
          <div>
            <label className="text-sm">Students</label>
            <Select
              isMulti
              value={
                type === "Assigned Users"
                  ? taskCreate.assigned_students
                  : taskCreate.tagged_students
              }
              onChange={(options) => {
                setTaskCreate({
                  ...taskCreate,
                  ...(type === "Assigned Users"
                    ? {
                        assigned_students: options,
                      }
                    : {
                        tagged_students: options,
                      }),
                });
              }}
              options={studentUsers}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                  pointerEvents: "auto",
                }),
                option: (base) => ({
                  ...base,
                  cursor: "pointer",
                }),
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <DialogContent
      ref={dialogRef}
      className="create-task-dialog"
      onClose={handleClose}
    >
      <h4 className="text-xl font-bold text-center mt-4">Create a Task</h4>
      <div className="task-item mt-8">
        <div className="task-header">
          <div className="task-preview">
            <div className="task-edit-form flex flex-row">
              <div className="name-input-wrapper">
                <input
                  type="text"
                  className="input pt-0 m-0 w-full"
                  value={taskCreate.name as string}
                  onChange={(e) =>
                    setTaskCreate({
                      ...taskCreate,
                      name: e.target.value,
                    })
                  }
                  placeholder="Task name"
                />
              </div>
              <DatePicker
                oneTap
                value={new Date(taskCreate.due_date as string)}
                size="md"
                format="yyyy-MM-dd"
                placement="bottomStart"
                onChange={(val) => {
                  if (!val || isNaN(new Date(val).getTime())) return;
                  try {
                    const localDate = new Date(val);
                    if (!isNaN(localDate.getTime())) {
                      setTaskCreate({
                        ...taskCreate,
                        due_date: localDate.toISOString(),
                      });
                    }
                  } catch (e) {
                    console.error("Invalid date:", e);
                  }
                }}
                container={() => dialogRef.current || document.body}
                cleanable
              />
            </div>
          </div>
          <div className="task-actions">
            <Select
              value={{
                value: taskCreate.status,
                label:
                  taskCreate.status.charAt(0).toUpperCase() +
                  taskCreate.status.slice(1).toLowerCase(),
              }}
              onChange={(option) => {
                setTaskCreate({
                  ...taskCreate,
                  status: option.value as TaskStatus,
                });
              }}
              options={Object.values(TaskStatus).map((status) => ({
                value: status,
                label:
                  status.charAt(0).toUpperCase() +
                  status.slice(1).toLowerCase(),
              }))}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                container: (base) => ({
                  ...base,
                  width: "200px",
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                  pointerEvents: "auto",
                }),
                option: (base) => ({
                  ...base,
                  cursor: "pointer",
                }),
              }}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
        </div>
        <div className="task-details">
          {renderUserList("Assigned Users")}
          {renderUserList("Tagged Users")}
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Button
          color={isCreating ? ButtonColor.GRAY : ButtonColor.GREEN}
          size={ButtonSize.DEFAULT}
          onClick={handleCreate}
          disabled={isCreating}
        >
          {isCreating ? "Creating..." : "Create +"}
        </Button>
      </div>
    </DialogContent>
  );
}

export default CreateTaskDialog;
