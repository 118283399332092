/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents all valid message types.
 */
export enum MessageType {
  MEETING_SCHEDULED = "Meeting Scheduled",
  MEETING_CANCELLED = "Meeting Cancelled",
  MEETING_RESCHEDULED = "Meeting Rescheduled",
  MEETING_UPDATED = "Meeting Updated",
  ATTENDANCE = "Attendance",
  ANNOUNCEMENT = "Announcement",
  TASK_ASSIGNMENT = "Task Assignment",
  DIRECT = "Direct",
  ORGANIZATION_INVITE = "Organization Invite",
  TUTOR_INVITE = "Tutor Invite",
  STUDENT_INVITE = "Student Invite",
  PARENT_INVITE = "Parent Invite",
  NEW_SIGNUP = "New Signup",
}
