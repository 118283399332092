import {
  ApiError,
  Auth0AccountRole,
  ManageCrmService,
  OrganizationDataKeyResponse,
} from "client/openapi";
import { DialogClose, DialogContent } from "components/Dialog";
import { useEffect, useState } from "react";
import { APIResponse, PageStatus } from "types";
import Notifications from "util/notifications";
import "../index.css";

function DeleteOrgDataKeyDialog({
  dataKey,
  keys,
  setKeys,
  userType,
}: {
  dataKey: OrganizationDataKeyResponse;
  keys: OrganizationDataKeyResponse[];
  setKeys: (keys: OrganizationDataKeyResponse[]) => void;
  userType: Auth0AccountRole;
}) {
  const [status, setStatus] = useState<PageStatus>();
  const [error, setError] = useState<APIResponse>();

  async function deleteKey() {
    await ManageCrmService.deleteOrgDataKey({ orgDataKeyId: dataKey.id })
      .then(() => {
        setStatus(PageStatus.SUCCESS);
        setKeys(keys.filter((k) => k.id !== dataKey.id));
        Notifications.success(`Field deleted!`);
      })
      .catch((e: ApiError) => {
        setStatus(PageStatus.ERROR);
        setError({ error: "An unexpected error occurred" });
        console.error(`Error (#${e.status}): ${e.message}`);
      });
  }

  useEffect(() => {
    error &&
      Notifications.error(error?.message || "An unexpected error occurred.");
  }, [error]);

  const roleMap = {
    [Auth0AccountRole.ME]: "student",
    [Auth0AccountRole.PARENT]: "parent",
    [Auth0AccountRole.ORG_TUTOR]: "tutor",
  };

  return (
    <>
      <DialogContent className="dialog-content">
        <div className="new-org-data-key--dialog">
          <div>
            <div>
              <h2 className="new-org-data-key--title">Are you sure?</h2>
            </div>
            <div>
              <p>
                All {roleMap[userType]} data associated with the "{dataKey.name}
                " field will be lost. This action is irreversible.
              </p>
            </div>
          </div>

          <div className="mt-4 clear-availability--actions">
            <button
              className="button button--small button--red"
              type="submit"
              disabled={status === PageStatus.LOADING}
              onClick={() => deleteKey()}
            >
              Delete Field &rarr;
            </button>
            <DialogClose asChild>
              <button
                className="button button--small button--red button--hollow"
                disabled={status === PageStatus.LOADING}
              >
                Cancel
              </button>
            </DialogClose>
          </div>
        </div>
      </DialogContent>
    </>
  );
}

export default DeleteOrgDataKeyDialog;
