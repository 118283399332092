import {
  CheckCircledIcon,
  ChevronDownIcon,
  Cross2Icon,
} from "@radix-ui/react-icons";
import {
  AvailableTutorsMessage,
  MeetingTutorLimitedResponse,
  TutorLimitedResponse,
} from "client/openapi";
import { clsx } from "clsx";
import { useState } from "react";
import ReactSelect, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  OptionProps,
  components,
  GroupBase,
} from "react-select";
import Creatable from "react-select/creatable";
import { getAttendeeId } from "components/MeetingAttendees";

const DropdownIndicator = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: ClearIndicatorProps<Option, IsMulti, Group>
) => {
  return (
    <components.ClearIndicator {...props}>
      <Cross2Icon />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: MultiValueRemoveProps<Option, IsMulti, Group>
) => {
  return (
    <components.MultiValueRemove {...props}>
      <Cross2Icon />
    </components.MultiValueRemove>
  );
};

const Option = (props: OptionProps) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row items-center gap-2">
        {props.children}
        {props.isSelected && <CheckCircledIcon className="text-purple" />}
      </div>
    </components.Option>
  );
};

const controlStyles = {
  base: "border rounded-md bg-white shadow-sm hover:cursor-pointer",
  focus: "border-indigo-300 ring ring-indigo-200 ring-opacity-50",
  nonFocus: "border-gray-300 hover:border-gray-400",
  disabled: "bg-neutral-100 cursor-not-allowed",
};
const placeholderStyles = "text-gray-400 pl-2 py-0.5";
const selectInputStyles = "px-2 py-0.5";
const valueContainerStyles = "gap-1";

const singleValueStyles = "leading-7 ml-2.5";
const multiValueStyles =
  "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
const multiValueLabelStyles = "leading-6";
const multiValueRemoveStyles =
  "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md p-0.5";

const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
  "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
const indicatorSeparatorStyles = "bg-gray-300";
const dropdownIndicatorStyles =
  "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";

const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
  base: "hover:cursor-pointer px-3 py-2 rounded",
  focus: "bg-gray-100 active:bg-gray-200",
  selected: "text-gray-500",
  disabled: "cursor-not-allowed",
};
const noOptionsMessageStyles =
  "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

export const Select = (props) => (
  <ReactSelect
    closeMenuOnSelect={!props.isMulti}
    hideSelectedOptions={false}
    unstyled
    styles={{
      input: (base) => ({
        ...base,
        "input:focus": {
          boxShadow: "none",
        },
      }),
      // On mobile, the label will truncate automatically, so we want to
      // override that behaviour.
      multiValueLabel: (base) => ({
        ...base,
        whiteSpace: "normal",
        overflow: "visible",
      }),
      control: (base, state) => ({
        ...base,
        transition: "none",
        backgroundColor: state.isDisabled ? "#f0f0f0" : base.backgroundColor,
        borderColor: state.isDisabled ? "#ccc" : base.borderColor,
        color: state.isDisabled ? "#ccc" : base.color,
      }),
    }}
    components={{ DropdownIndicator, ClearIndicator, MultiValueRemove, Option }}
    classNames={{
      control: ({ isFocused, isDisabled }) =>
        clsx(
          isFocused ? controlStyles.focus : controlStyles.nonFocus,
          isDisabled && controlStyles.disabled,
          controlStyles.base
        ),
      placeholder: () => placeholderStyles,
      input: () => selectInputStyles,
      valueContainer: () => valueContainerStyles,
      singleValue: () => singleValueStyles,
      multiValue: () => multiValueStyles,
      multiValueLabel: () => multiValueLabelStyles,
      multiValueRemove: () => multiValueRemoveStyles,
      indicatorsContainer: () => indicatorsContainerStyles,
      clearIndicator: () => clearIndicatorStyles,
      indicatorSeparator: () => indicatorSeparatorStyles,
      dropdownIndicator: () => dropdownIndicatorStyles,
      menu: () => menuStyles,
      groupHeading: () => groupHeadingStyles,
      option: ({ isDisabled, isFocused, isSelected }) =>
        clsx(
          isDisabled && optionStyles.disabled,
          isFocused && optionStyles.focus,
          isSelected && optionStyles.selected,
          optionStyles.base
        ),
      noOptionsMessage: () => noOptionsMessageStyles,
    }}
    {...props}
  />
);

export const CreatableSelect = (props) => {
  const [inputValue, setInputValue] = useState("");

  const getMatchingOption = (val) => {
    if (!props.options) return undefined;
    return props.options.find(
      (opt) => opt.label.toLowerCase() === val.toLowerCase()
    );
  };

  const createOption = (val) => {
    const newOption = { label: val, value: val, __isNew__: true };
    props.onChange && props.onChange(newOption);
  };

  const handleBlur = () => {
    const val = inputValue.trim();
    if (val === "") return;

    const matchedOption = getMatchingOption(val);
    if (matchedOption) {
      // If there's an exact match (ignoring case), use that location.
      props.onChange && props.onChange(matchedOption);
    } else {
      // Otherwise, create a new custom option.
      createOption(val);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      const val = inputValue.trim();
      const matchedOption = getMatchingOption(val);
      if (matchedOption) {
        props.onChange && props.onChange(matchedOption);
      } else {
        createOption(val);
      }
    }
  };

  return (
    <Creatable
      closeMenuOnSelect={!props.isMulti}
      hideSelectedOptions={false}
      unstyled
      styles={{
        input: (base) => ({
          ...base,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        multiValueLabel: (base) => ({
          ...base,
          whiteSpace: "normal",
          overflow: "visible",
        }),
        control: (base, state) => ({
          ...base,
          transition: "none",
          backgroundColor: state.isDisabled ? "#f0f0f0" : base.backgroundColor,
          borderColor: state.isDisabled ? "#ccc" : base.borderColor,
          color: state.isDisabled ? "#ccc" : base.color,
        }),
      }}
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        Option,
      }}
      classNames={{
        control: ({ isFocused, isDisabled }) =>
          clsx(
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            isDisabled && controlStyles.disabled,
            controlStyles.base
          ),
        placeholder: () => placeholderStyles,
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        clearIndicator: () => clearIndicatorStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isDisabled, isFocused, isSelected }) =>
          clsx(
            isDisabled && optionStyles.disabled,
            isFocused && optionStyles.focus,
            isSelected && optionStyles.selected,
            optionStyles.base
          ),
        noOptionsMessage: () => noOptionsMessageStyles,
      }}
      onInputChange={(val) => setInputValue(val)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      // We manually handle creation in handleBlur and handleKeyDown.
      // onCreateOption is not needed as we do it ourselves.
      {...props}
    />
  );
};

const OptionWithAvailability = (
  tutorsWithAvailability: AvailableTutorsMessage | undefined
) => {
  return (
    props: OptionProps<TutorLimitedResponse | MeetingTutorLimitedResponse>
  ) => {
    let availabilityClass = "";

    if (tutorsWithAvailability) {
      const { exact_availability, general_availability } =
        tutorsWithAvailability;

      // Use `props.data` inside `getAttendeeId`
      if (exact_availability.includes(getAttendeeId(props.data))) {
        availabilityClass = "bg-green-500 text-white";
      } else if (general_availability.includes(getAttendeeId(props.data))) {
        availabilityClass = "bg-green-100";
      }
    }

    return (
      <components.Option {...props}>
        <div
          className={clsx(
            optionStyles.base,
            availabilityClass,
            props.isFocused && optionStyles.focus,
            props.isSelected && optionStyles.selected,
            props.isDisabled && optionStyles.disabled
          )}
        >
          {props.label}
          {props.isSelected && <CheckCircledIcon className="text-purple" />}
        </div>
      </components.Option>
    );
  };
};

export const SelectWithAvailability = ({
  tutorsWithAvailability,
  ...props
}: {
  tutorsWithAvailability?: AvailableTutorsMessage;
  [x: string]: any;
}) => {
  return (
    <ReactSelect<TutorLimitedResponse | MeetingTutorLimitedResponse>
      closeMenuOnSelect={!props.isMulti}
      hideSelectedOptions={false}
      unstyled
      styles={{
        input: (base) => ({
          ...base,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        // On mobile, the label will truncate automatically, so we want to
        // override that behaviour.
        multiValueLabel: (base) => ({
          ...base,
          whiteSpace: "normal",
          overflow: "visible",
        }),
        control: (base, state) => ({
          ...base,
          transition: "none",
          backgroundColor: state.isDisabled ? "#f0f0f0" : base.backgroundColor,
          borderColor: state.isDisabled ? "#ccc" : base.borderColor,
          color: state.isDisabled ? "#ccc" : base.color,
        }),
      }}
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        // Provide our custom Option that highlights background color
        Option: OptionWithAvailability(tutorsWithAvailability),
      }}
      classNames={{
        control: ({ isFocused, isDisabled }) =>
          clsx(
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            isDisabled && controlStyles.disabled,
            controlStyles.base
          ),
        placeholder: () => placeholderStyles,
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        clearIndicator: () => clearIndicatorStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: () => "",
        noOptionsMessage: () => noOptionsMessageStyles,
      }}
      {...props}
    />
  );
};
