import { DialogTrigger } from "components/Dialog";
import { ButtonFill, ButtonSize } from "components/Button";
import {
  ApiError,
  CustomDataTypeKeyResponse,
  ExportsService,
} from "client/openapi";
import { OrganizationResponseForAdmins } from "client/openapi";
import { useEffect, useState } from "react";
import Notifications from "util/notifications";
import { saveAs } from "file-saver";
import { APIResponse, PageStatus } from "types";
import { Button } from "components/Button";
import {
  DownloadIcon,
  Pencil1Icon,
  PlusIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { Dialog } from "components/Dialog";

import { ErrorBlock } from "components/StatusBlock";
import CreateCustomType from "components/OrgNoteKeyDialogs/New/createCustomDataType";
import DeleteCustomDataTypeDialog from "components/OrgNoteKeyDialogs/Delete/deleteCustomType";
import EditCustomType from "components/OrgNoteKeyDialogs/Edit/editCustomType";

/**
 * Responsive chunking logic:
 *  - < 600px => chunkSize = 1
 *  - 600..959 => chunkSize = 2
 *  - >= 960 => chunkSize = 3
 */
function useResponsiveChunkSize(): number {
  const [chunkSize, setChunkSize] = useState<number>(3); // default to 3

  useEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      if (width < 600) {
        setChunkSize(1);
      } else if (width < 960) {
        setChunkSize(2);
      } else {
        setChunkSize(3);
      }
    }
    // Listen for resizing
    window.addEventListener("resize", updateSize);
    // Initial check
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return chunkSize;
}

export default function CustomTypes({
  organization,
  keys,
  setKeys,
  setStatus,
  setError,
}: {
  organization: OrganizationResponseForAdmins;
  keys: CustomDataTypeKeyResponse[];
  setKeys: (keys: CustomDataTypeKeyResponse[]) => void;
  setStatus: (status: PageStatus) => void;
  setError: (error: APIResponse) => void;
}) {
  const [exportingData, setExportingData] = useState(false);

  // 1) Use the custom hook to determine how many columns we want (1, 2, or 3)
  const chunkSize = useResponsiveChunkSize();

  // 2) chunk the keys in groups of `chunkSize`
  function chunkKeys(
    keys: CustomDataTypeKeyResponse[]
  ): CustomDataTypeKeyResponse[][] {
    const chunks: CustomDataTypeKeyResponse[][] = [];
    for (let i = 0; i < keys.length; i += chunkSize) {
      chunks.push(keys.slice(i, i + chunkSize));
    }
    return chunks;
  }

  async function exportTypes() {
    if (!organization) {
      return Notifications.error("An unexpected error occurred");
    }

    setExportingData(true);
    try {
      let data;
      data = await ExportsService.exportCustomDataTypes({
        orgId: organization.id,
      });

      const blob = new Blob([data], { type: "text/csv" });
      saveAs(blob, `custom-types.csv`);
      Notifications.success(
        `All custom types have been saved to your computer!`
      );
    } catch (err) {
      const e = err as ApiError;
      setStatus(PageStatus.ERROR);
      setError({
        message: `Unable to export custom types`,
      });
      console.error(`Error (#${e.status}): ${e.message}`);
    }
    setExportingData(false);
  }

  // 4) If no keys, show the "No fields" block
  if (!keys || keys.length === 0) {
    return (
      <div
        className="mx-auto w-full"
        style={{
          display: "flex",
          backgroundColor: "#f8f8f8",
          borderRadius: "0.75rem",
          alignItems: "center",
          padding: "5rem 1.5rem",
          textAlign: "center",
        }}
      >
        <div className="flex flex-col items-center justify-center w-full">
          <ErrorBlock
            status={PageStatus.ERROR}
            title="No custom types"
            message={`You have not created any custom types for user data yet.`}
          />
          <Dialog>
            <DialogTrigger asChild>
              <Button
                color="#ac98fc"
                size={ButtonSize.LARGE}
                extraClasses="mt-5"
              >
                Create Custom Type <PlusIcon />
              </Button>
            </DialogTrigger>

            <CreateCustomType
              organizationId={organization.id}
              keys={keys}
              setKeys={setKeys}
            />
          </Dialog>
        </div>
      </div>
    );
  }

  // 5) If we have keys, create a table
  const sortedKeys = [...keys].sort((a, b) => a.name.localeCompare(b.name));
  const rowChunks = chunkKeys(sortedKeys); // each row has up to chunkSize items

  return (
    <section className="custom-data--container">
      {/* Header row with "Student Data" title and "Export / Create" buttons */}
      <div className="custom-data--header">
        <h2>
          <strong className="text-white">Custom Types</strong>
        </h2>
        <div className="student-data--field-actions">
          <Button
            color="#FFFFFF"
            size={ButtonSize.SMALL}
            onClick={() => exportTypes()}
          >
            <div style={{ color: "#ac98fc" }}>
              Export Types
              {exportingData ? (
                <span
                  className="spinner-border spinner-border-square"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <DownloadIcon />
              )}
            </div>
          </Button>
          <Dialog>
            <DialogTrigger asChild>
              <Button color="#FFFFFF" size={ButtonSize.SMALL}>
                <span style={{ color: "#ac98fc" }}>
                  Create Custom Type
                  <PlusIcon />
                </span>
              </Button>
            </DialogTrigger>
            <CreateCustomType
              organizationId={organization.id}
              keys={keys}
              setKeys={setKeys}
            />
          </Dialog>
        </div>
      </div>

      <table className="student-data--table">
        <tbody>
          {rowChunks.map((chunk, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowIndex % 2 === 1 ? "student-data--row-alt" : ""}
            >
              {/* We create exactly 'chunkSize' <td> in each row, or fewer if final row has fewer items */}
              {Array.from({ length: chunkSize }).map((_, colIdx) => {
                const keyItem = chunk[colIdx];
                if (!keyItem) {
                  return <td key={colIdx} className="student-data--cell" />;
                }
                return (
                  <td key={keyItem.id} className="student-data--cell">
                    <div className="student-data--field-inner">
                      {keyItem.name}
                      <div className="student-data--field-actions">
                        <Dialog key={`dialog-${keyItem.id}`}>
                          <DialogTrigger asChild>
                            <Button color="#ac98fc" size={ButtonSize.SMALL}>
                              <Pencil1Icon />
                            </Button>
                          </DialogTrigger>
                          <EditCustomType
                            key={`edit-${keyItem.id}`}
                            keyId={keyItem.id}
                            currentName={keyItem.name}
                            keys={keys}
                            setKeys={setKeys}
                          />
                        </Dialog>
                        <Dialog>
                          <DialogTrigger asChild>
                            <Button
                              color="#ac98fc"
                              fill={ButtonFill.HOLLOW}
                              size={ButtonSize.EXTRA_SMALL}
                            >
                              <TrashIcon />
                            </Button>
                          </DialogTrigger>
                          <DeleteCustomDataTypeDialog
                            dataKey={keyItem}
                            keys={keys}
                            setKeys={setKeys}
                          />
                        </Dialog>
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
