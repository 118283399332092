import { TaskResponse } from "client/openapi";
import { DialogContent } from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";

interface DeleteTaskDialogProps {
  task: TaskResponse;
  onConfirm: () => void;
}

function DeleteTaskDialog({ task, onConfirm }: DeleteTaskDialogProps) {
  return (
    <DialogContent className="delete-task-dialog">
      <div className="delete-task-content">
        <h3 className="text-lg font-semibold mb-4">Delete Task</h3>
        <p className="mb-6">
          Are you sure you want to delete{" "}
          <span className="font-bold">{task.name}</span>? This action cannot be
          undone.
        </p>
        <div className="flex justify-end gap-3">
          <Button
            color={ButtonColor.BLACK}
            fill={ButtonFill.HOLLOW}
            size={ButtonSize.SMALL}
          >
            Cancel
          </Button>
          <Button
            color={ButtonColor.RED}
            fill={ButtonFill.DEFAULT}
            size={ButtonSize.SMALL}
            onClick={onConfirm}
          >
            Delete
          </Button>
        </div>
      </div>
    </DialogContent>
  );
}

export default DeleteTaskDialog;
