import { Dialog, DialogContent, DialogTrigger } from "components/Dialog";
import { Button, ButtonColor, ButtonFill, ButtonSize } from "components/Button";
import { Pencil1Icon } from "@radix-ui/react-icons";
import { useState } from "react";
import {
  CRMVisualizationResponse,
  VisualizationsService,
} from "client/openapi";
import Notifications from "util/notifications";

interface EditVisualizationDialogProps {
  visualization: CRMVisualizationResponse;
  onUpdate: (updated: CRMVisualizationResponse) => void;
}

export default function EditVisualizationDialog({
  visualization,
  onUpdate,
}: EditVisualizationDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(visualization.name);

  const handleSubmit = async () => {
    try {
      const updated = await VisualizationsService.updateVisualization({
        visualizationId: visualization.id,
        requestBody: { name },
      });
      onUpdate(updated);
      setIsOpen(false);
      Notifications.success("Visualization updated successfully");
    } catch (error) {
      console.error("Error updating visualization:", error);
      Notifications.error("Failed to update visualization");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        <Button
          color={ButtonColor.PURPLE}
          fill={ButtonFill.HOLLOW}
          size={ButtonSize.SMALL}
        >
          <Pencil1Icon />
        </Button>
      </DialogTrigger>
      <DialogContent className="visualization-dialog-content">
        <div className="p-4">
          <h2 className="text-lg font-bold text-center mb-4">
            Edit Visualization
          </h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border rounded-md"
              placeholder="Enter visualization name"
            />
          </div>
          <div className="flex justify-center gap-2">
            <Button color={ButtonColor.GRAY} onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              color={
                !name.trim() || name === visualization.name
                  ? ButtonColor.GRAY
                  : ButtonColor.PURPLE
              }
              onClick={handleSubmit}
              disabled={!name.trim() || name === visualization.name}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
